import React from 'react'
import Userheader from './Userheader'

const Services = () => {
    return (
        <div>
            <div className='bg-gradient-to-r from-violet-200 to-pink-200 h-screen'>
                <Userheader />
                <div className="container mx-auto p-10">
                    <h1 className="text-3xl font-semibold mb-6">Our Services</h1>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {/* <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                            <img src="https://tecdn.b-cdn.net/img/Photos/Slides/img%20(15).jpg" alt="Service 1" className="w-full h-48 object-cover" />
                            <div className="p-6">
                                <h2 className="text-xl font-semibold mb-2">Service Title 1</h2>
                                <p className="text-gray-700 leading-relaxed">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vel tortor eu massa accumsan
                                    commodo.
                                </p>
                                <a href="#" className="mt-4 inline-block text-blue-500 hover:underline">Learn More</a>
                            </div>
                        </div> */}

                        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                            <img src="../assets/img/GameService.png" alt="Service 2" className="w-full h-48 object-cover" />
                            <div className="p-6">
                                <h2 className="text-xl font-semibold mb-2">Tadam App</h2>
                                <p className="text-gray-700 leading-relaxed">
                                    The TaDam App is designed as a gaming
                                    application to make the patients' journey more
                                    enjoyable and simplified. It enables patients to
                                    provide feedback about their day while engaging
                                    in 3D gaming. Through the app, users can
                                    navigate and explore a virtual world, gathering
                                    information about their medical journey and
                                    gaining insights into their progress along this
                                    path.
                                </p>
                                <br></br>
                                <h2 className="text-base font-semibold mb-2">Download app here</h2>
                                <p>For Android : <a target={"_blank"} href="https://play.google.com/store/apps/details?id=com.Easytech.Tadam" className="mt-4 inline-block text-blue-500 hover:underline">Android</a></p>
                                <p>For IOS : <a target={"_blank"} href="https://www.apple.com/in/app-store/" className="mt-4 inline-block text-blue-500 hover:underline">IOS</a></p>
                            </div>
                        </div>

                        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                            <img src="../assets/img/FrontEnd.png" alt="Service 3" className="w-full h-48 object-cover" />
                            <div className="p-6">
                                <h2 className="text-xl font-semibold mb-2">Tadam Backoffice</h2>
                                <p className="text-gray-700 leading-relaxed">
                                    The Backoffice platform serves as the central
                                    control hub for the gaming app. It empowers
                                    doctors to meticulously configure all essential
                                    details regarding the protocol, medications,
                                    treatments, and more. These crucial pieces of
                                    information are then made accessible to
                                    patients through the app. Essentially, the
                                    Backoffice platform ensures that patients
                                    receive accurate and up-to-date information that
                                    aligns with their medical journey.
                                </p>
                                <a href="/login" className="mt-4 inline-block text-blue-500 hover:underline">Sign In</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Services