import React, { useEffect, useState } from 'react';
import { DahboardMenu } from '../DahboardMenu';
import { TopHeader } from '../TopHeader';
import { useNavigate } from 'react-router';
import { checkFileExistOrNotAction, getGiftListAction, getGiftUpatedAction, getStaffUpdatedAction } from '../../../Store/Action';
import { useDispatch, useSelector } from 'react-redux';
import DeleteItemPopup from '../../Popups/DeleteItemPopup';
import { toast } from 'react-toastify';
import { setEditOptionData, setGiftListStore } from '../../../Store/Reducer';
import { FilterbyFiledAsending, FilterbyFiledAsendingPropName, FilterbyFiledDsending, FilterbyFiledDsendingPropName } from '../../../util/helper';
import { async } from 'q';
import { buttonCSS } from '../../../util/contant';
import InfiniteScroll from 'react-infinite-scroll-component';
import { giftListURL } from '../../../Api/ContantUrls';
import { ThreeDots } from 'react-loader-spinner';


const GiftList = () => {
    const store = useSelector(state => state)
    const { giftListStore } = store;
    const [giftList, setGiftList] = useState([])
    const [selectedImage, setSelectedimage] = useState("");
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [deleteData, setDeleteData] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState("");
    const [selectedId, setSelectedId] = useState("");
    const [isUpdate, setIsUpdate] = useState(false);
    const [orginalData, setOriginalData] = useState([]);
    const [callOnce, setCallOnce] = useState(false);



    const [gNameFilter, setGNameFilter] = useState(false);
    const [gCodeFilter, setGCodeFilter] = useState(false);
    const [gAgeFilter, setGAgeFilter] = useState(false);
    const [gCoinsFilter, setGCoinsFilter] = useState(false);


    const [hasMore, setHasMore] = useState(false)
    const [pageCount, setPageCount] = useState(30)
    const [pageNumber, setPageNumber] = useState(1)

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const editDataIds = [];

    const FilterbyTitleOrder = (a, b) => {
        if (a.name && b.name) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
            }
        }
        return 0;
    }

    const FilterbyAgeGroupAsending = (a, b) => {
        const firstVal = a?.ageGroup?.name.split('-')[0];
        const secondVal = b?.ageGroup?.name.split('-')[0];

        if (parseInt(firstVal) < parseInt(secondVal)) {
            return -1;
        } else if (parseInt(firstVal) > parseInt(secondVal)) {
            return 1;
        } else {
            return a.name.localeCompare(b.name);
        }
    }

    const FilterbyAgeGroupDsending = (a, b) => {
        const firstVal = a?.ageGroup?.name.split('-')[0];
        const secondVal = b?.ageGroup?.name.split('-')[0];

        if (parseInt(firstVal) > parseInt(secondVal)) {
            return -1;
        } else if (parseInt(firstVal) < parseInt(secondVal)) {
            return 1;
        } else {
            return a.name.localeCompare(b.name);
        }
    }

    useEffect(() => {
        if (giftListStore.length === 0 && giftList.length === 0) {
            getGifts();
        }
        if (giftListStore.length !== 0 && giftList.length === 0) {
            setGiftList(giftListStore.slice().sort(FilterbyAgeGroupAsending));
            setOriginalData(giftListStore.slice().sort(FilterbyAgeGroupAsending))
            if (giftListStore.length !== 0 && !callOnce) {
                giftListStore.slice().sort(FilterbyAgeGroupAsending).map((val) => {
                    editDataIds.push(val._id)
                })
                dispatch(setEditOptionData(editDataIds))
                setCallOnce(!callOnce);
                if (giftListStore.length < pageCount) {
                    setHasMore(false)
                }
            }

        }

    }, [giftListStore, isUpdate])
    useEffect(() => () => {
        dispatch(setGiftListStore([]))
    }, []);

    const openDelete = (data, id, index) => {
        setDeleteData(data);
        setSelectedId(id);
        setSelectedIndex(index)
        setOpenDeletePopup(true);
    }

    const closeDeletePopup = () => {
        setOpenDeletePopup(false);
    }

    const getGifts = async () => {
        const reqObj = {
            "getLatestFirst": true,
            "pageNumber": pageNumber,
            "numberOfRecordsPerPage": pageCount,
            "filter": {
                "active": true
            }
        }
        dispatch(await getGiftListAction(reqObj));
        setHasMore(true)
    }

    const deleteGift = async () => {
        const reqObj = {
            "giftId": selectedId,
            "dataToUpdate": {
                "active": false
            }
        }
        const response = await getGiftUpatedAction(dispatch, reqObj);
        if (response?.status === 200 && response?.data) {
            toast.success("Success")
            const challenge = JSON.parse(JSON.stringify(giftList));
            challenge.splice(selectedIndex, 1);
            setGiftList(challenge);
            dispatch(setGiftListStore(challenge));
            setIsUpdate(!isUpdate)
        } else {
            toast.error("Failed")
        }
    }

    const filterData = (val, name) => {
        let check = val;
        let filterData = [];
        if (name === 'name') {
            setGNameFilter(!gNameFilter);
            if (check) {
                filterData = FilterbyFiledAsending(orginalData, name);
            } else {
                filterData = FilterbyFiledDsending(orginalData, name);
            }
        }
        if (name === 'code') {
            setGCodeFilter(!gCodeFilter);
            if (check) {
                filterData = FilterbyFiledAsending(orginalData, name);
            } else {
                filterData = FilterbyFiledDsending(orginalData, name);
            }
        }
        if (name === 'ageGroup') {
            setGAgeFilter(!gAgeFilter);
            if (check) {
                filterData = orginalData.sort(FilterbyAgeGroupAsending);
            } else {
                filterData = orginalData.sort(FilterbyAgeGroupDsending);
            }
        }
        if (name === 'coins') {
            setGCoinsFilter(!gCoinsFilter);
            if (check) {
                filterData = FilterbyFiledAsending(orginalData, name);
            } else {
                filterData = FilterbyFiledDsending(orginalData, name);
            }
        }
        filterData.map((val) => {
            editDataIds.push(val?._id)
        })
        dispatch(setEditOptionData(editDataIds))
        setGiftList(filterData);
        setIsUpdate(!isUpdate);
    }

    const showImage = async (url) => {
        const reqObj = { filepath: url }

        const response = await dispatch(checkFileExistOrNotAction(reqObj));
        if (response) {
            setSelectedimage(response)
        } else {
            setSelectedimage("")
            toast.error("Couldn't find file, Please upload again")
        }
    }

    const fetchMoreData = () => {
        setTimeout(async () => {
            const reqObj = {
                "getLatestFirst": true,
                "pageNumber": pageNumber + 1,
                "numberOfRecordsPerPage": pageCount,
                "filter": {
                    "active": true
                }
            }

            const response = await getStaffUpdatedAction(dispatch, giftListURL, reqObj, false);
            if (response?.status === 200 && response?.data) {
                setPageNumber(pageNumber + 1)
                const { payload } = response?.data;
                if (payload?.giftList.length !== 0) {
                    const listData = giftList.length === 0 ? giftListStore : giftList;
                    const bookDatas = [...listData, ...payload?.giftList]
                    setGiftList(bookDatas.slice().sort(FilterbyAgeGroupAsending))

                    bookDatas.slice().sort(FilterbyAgeGroupAsending).map(val => {
                        editDataIds.push(val._id)
                    })
                    dispatch(setEditOptionData(editDataIds))

                    if (payload?.giftList.length < pageCount) {
                        setHasMore(false)
                    }
                } else {
                    setHasMore(false)
                }
                // setHasMore(true)

            } else {
                setGiftList(giftList);
                setHasMore(false)
                // toast.error("Failed")
            }
            setIsUpdate(!isUpdate)
        }, 500);
    }

    return (
        <React.Fragment>
            <TopHeader />
            <DahboardMenu />
            {openDeletePopup && <DeleteItemPopup deleteData={deleteGift} closeModal={closeDeletePopup} modalIsOpen={openDeletePopup} isFrom="gift" data={deleteData} />}
            <div id="default-modall" tabIndex="-1" className={`fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full justify-center items-center flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80  inset-0 ${selectedImage ? "" : "hidden"}`} role="dialog" aria-modal="true">
                <div className="relative w-full max-w-2xl max-h-full">

                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

                        <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                            <button type="button" onClick={() => setSelectedimage("")} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modall">
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-6 space-y-6">
                            <img src={selectedImage} className="h-1/2" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-10 h-auto" >
                <div className="
max-w-[540px] sm:max-w-[604px] md:max-w-[720px] lg:max-w-[972px]
xl:max-w-full xl:pl-40  xl:ml-40 xl:px-12 2xl:max-w-[1400px] mx-auto
flex">
                    <div className="flex w-full flex-col ">
                        <div className="relative shadow-md sm:rounded-lg">
                            <div className="flex justify-end pb-3">
                                <div id="sticky-banner" tabindex="-1" class="fixed top-16 z-50 flex">
                                    <div class="flex items-end mx-auto">
                                        <button className={buttonCSS.addbtn} onClick={() => navigate('/dashboard/creategift')}>
                                            <svg className="h-6 w-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                                            </svg>
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <InfiniteScroll
                                    dataLength={giftList.length}
                                    next={fetchMoreData}
                                    loader={giftList.length !== 0 && <ThreeDots
                                        visible={true}
                                        height="80"
                                        width="80"
                                        color="#4fa94d"
                                        radius="9"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                    />}
                                    hasMore={hasMore}
                                    endMessage={
                                        <p style={{ textAlign: "center" }}>
                                            <b className='text-blue-500'>Yay! You have seen it all</b>
                                        </p>
                                    }
                                >
                                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                            <tr>
                                                <th scope="col" className="px-6 py-3">
                                                    <div className='flex items-center'>
                                                        <p> Name</p>
                                                        <a href='javascript:void(0);' onClick={() => filterData(gNameFilter, "name")}>
                                                            {gNameFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                            </svg> :
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                                </svg>}
                                                        </a>

                                                    </div>
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    <div className='flex items-center'>
                                                        <p> Code</p>
                                                        <a href='javascript:void(0);' onClick={() => filterData(gCodeFilter, "code")}>
                                                            {gCodeFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                            </svg> :
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                                </svg>}
                                                        </a>

                                                    </div>
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    <div className='flex items-center'>
                                                        <p> Age Group</p>
                                                        <a href='javascript:void(0);' onClick={() => filterData(gAgeFilter, "ageGroup")}>
                                                            {gAgeFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                            </svg> :
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                                </svg>}
                                                        </a>

                                                    </div>
                                                </th>

                                                <th scope="col" className="px-6 py-3">
                                                    <div className='flex items-center'>
                                                        <p> Coins</p>
                                                        <a href='javascript:void(0);' onClick={() => filterData(gCoinsFilter, "coins")}>
                                                            {gCoinsFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                            </svg> :
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                                </svg>}
                                                        </a>

                                                    </div>
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Image
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {giftList.length !== 0 && giftList.map((val, index) => {
                                                return <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                    <td scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{val.name}</td>
                                                    <td scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{val.code}</td>
                                                    <td scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{val?.ageGroup?.name}</td>
                                                    <td scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{val.coins}</td>
                                                    <td scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"><button onClick={() => showImage(val.image)} data-modal-target="default-modall" data-modal-toggle="default-modall" className="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">
                                                        View Image
                                                    </button></td>
                                                    <td className="px-6 py-4">
                                                        <a href="javascript:void(0);" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => { navigate('/dashboard/creategift?id=' + val._id) }}>Edit</a> /&nbsp;
                                                        <a href="javascript:void(0);" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => { openDelete(val, val?._id, index) }}>Delete</a>
                                                    </td>
                                                </tr>
                                            })}
                                            {giftList.length === 0 && <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                <th scope="row" colSpan={6} className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                    No data found
                                                </th>
                                            </tr>}
                                        </tbody>
                                    </table>
                                </InfiniteScroll>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}

export default GiftList