import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { adminRoleListcall, createAdmin } from '../../Store/Action';
import { registerInput } from '../../util/contant';
import Userheader from './Userheader';
import OtpInput from "otp-input-react";

const Registration = () => {
    const { register, formState: { errors }, handleSubmit, setValue, watch, clearErrors } = useForm();
    const dispatch = useDispatch();
    const store = useSelector(state => state)
    let roleList = store?.adminRoleList;
    const [selectedRole, setSelectedRole] = useState("")
    const [otpNumber, setOtpNumber] = useState();
    const [otpError, setOtpError] = useState(false);
    const [otpTiming, setOtpTiming] = useState(40);
    const [intervalId, setIntervalId] = useState(0);
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [userDataForOtp, setUserDataForOtp] = useState({});
    useEffect(() => {

        if (Object.keys(roleList).length === 0) {
            dispatch(adminRoleListcall());
        }
        if (store.isAdminCreated) {
            toast.success("Please login with your credentials");
            setTimeout(() => {
                window.location.href = '/login';
            }, 100);
        }

        if (intervalId && otpTiming <= 0) {
            clearInterval(intervalId);
            setOtpTiming(40);
            setIntervalId(0);
        }

    }, [otpTiming])

    const handleOtpTiming = async () => {
        let interval = setInterval(() => {
            setOtpTiming(otpTiming => otpTiming - 1);
        }, 1000);
        setIntervalId(interval);
    }

    const submitHandler = (data) => {
        const reqObj = data;
        reqObj.role = [selectedRole];
        reqObj.active = true;
        setIsOtpSent(true);
        handleOtpTiming();
        dispatch(createAdmin(reqObj))
    }

    const errorHandler = (err) => {
        setIsOtpSent(true);
        handleOtpTiming();
        //  console.log("err--------------------->", err)
    }
    const sendOtp = async (data) => {
        //   console.log("data--------------------->", data)
    }

    const handleResendOtp = () => {
        sendOtp(userDataForOtp);
        setOtpNumber("");
    }

    const selectRole = e => {
        clearErrors(e.target.name)
        setValue(e.target.name, e.target.value);
        setSelectedRole(e.target.value)
    }

    const handleOtpChange = (data) => {
        setOtpNumber(data);
        data ? setOtpError(false) : setOtpError(true);
    }


    return (
        <React.Fragment>
            <section className="bg-gray-50 dark:bg-gray-900 bg-cover bg-center h-screen" style={{ backgroundImage: "url(../../assets/img/pageBack.jpg)" }}>
                <Userheader />
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto mt-6 lg:py-0">
                    {/* <a href="#" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                    <img className="mr-2" src="../../assets/img/logo.png" alt="logo" />

                </a> */}
                    {true ? <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                Create and account
                            </h1>
                            <form className="space-y-4 md:space-y-6" onSubmit={e => e.preventDefault()}>
                                <div>
                                    <label for="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your Name</label>
                                    <input autoComplete='off' type="text" name="name" id="name" className={registerInput} placeholder="Name" {...register("name", { required: true })} />
                                    {errors.name && <span className="errSpan">Please enter your name</span>}
                                </div>
                                {/* <div>
                                    <label for="age" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your Age</label>
                                    <input type="number" name="age" id="age" className={registerInput} placeholder="Age" {...register("age", { required: true })} />
                                    {errors.age && <span className="errSpan">Please enter your age</span>}
                                </div> */}

                                <div>
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="generalDose">
                                        Your Roles
                                    </label>
                                    <select data-te-select-init className="border-2 border-gray-200 rounded w-full py-2 px-4 focus:bg-white focus:border-purple-500"
                                        id="roles" name="roles" {...register("roles", { required: true })} onChange={e => selectRole(e)}>
                                        <option value="">Choose role</option>
                                        {(Object.keys(roleList).length !== 0) && roleList.map((value, index) => {
                                            return <option value={value._id}>{value.name}</option>
                                        })}
                                        {/* <option value="2">Dietitians</option>
                                        <option value="2">Occupational Therapists</option>
                                        <option value="2">Pharmacists</option>
                                        <option value="2">Physiotherapists</option>
                                        <option value="2">Podiatrists</option>
                                        <option value="2">Speech Pathologists</option>
                                        <option value="1">Senior consultants – specialist doctors who see patients at specific times</option>
                                        <option value="2">Registrars – senior doctors who supervise residents, interns and students</option>
                                        <option value="2">Residents – look after patients on the ward and are in training for specialisation</option>
                                        <option value="2">Interns – have completed their studies and are now finishing their final year in hospital</option>
                                        <option value="2">Student doctors – undergraduate medical students</option> */}
                                    </select>
                                    {errors.roles && <span className="errSpan">Please choose your role</span>}
                                </div>
                                <div>
                                    <label for="mobile" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your Mobile</label>
                                    <input autoComplete='off' type="number" name="mobile" id="mobile" className={registerInput} placeholder="Mobile Number" {...register("mobile", {
                                        required: true, pattern: {
                                            value: /^(?:(?:(\+?972|\(\+?972\)|\+?\(972\))(?:\s|\.|-)?([1-9]\d?))|(0[23489]{1})|(0[57]{1}[0-9]))(?:\s|\.|-)?([^0\D]{1}\d{2}(?:\s|\.|-)?\d{4})$/,
                                            message: 'Enter valid mobile number'
                                        }
                                    })} />
                                    {/* {errors.mobile && <span className="errSpan">Please enter your mobile</span>} */}
                                    {errors?.mobile?.type === 'required' && <span className='errSpan'>Please enter your mobile</span>}
                                    {errors?.mobile?.type === 'pattern' && <span className='errSpan'>Please enter valid mobile number</span>}
                                </div>
                                <div>
                                    <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                                    <input autoComplete='off' type="email" name="email" id="email" className={registerInput} placeholder="Email" {...register("email", {
                                        required: true, pattern: {
                                            value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                            message: 'Enter valid mail id'
                                        }
                                    })} />
                                    {errors.email?.type === 'required' && <span className="errSpan">Please enter your email</span>}
                                    {errors?.email?.type === 'pattern' && <span className='errSpan'>Please enter valid mobile number</span>}
                                </div>
                                <div>
                                    <label for="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                                    <input autoComplete='off' type="password" name="password" id="password" placeholder="••••••••" className={registerInput} {...register("password", {
                                        required: true,
                                        pattern:
                                        {
                                            value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                                            message: 'Enter valid mail id'
                                        }
                                    })} />
                                    {errors.password?.type === 'required' && <span className="errSpan">Please enter your password</span>}
                                    {errors.password?.type === 'pattern' && <span className='errSpan'>Invalid Password format</span>}
                                </div>
                                <div>
                                    <label for="confirm-password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirm password</label>
                                    <input autoComplete='off' type="password" name="confirm-password" id="confirm-password" placeholder="••••••••" className={registerInput} {...register("conpassword", {
                                        required: true,
                                        pattern:
                                        {
                                            value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                                            message: 'Enter valid mail id'
                                        },
                                        validate: (val) => {
                                            if (watch('password') != val) {
                                                return "Your passwords do no match";
                                            }
                                        }
                                    })} />
                                    {errors?.conpassword?.type === 'required' && <span className="errSpan">Please enter your confirm password</span>}
                                    {errors?.conpassword?.type === 'validate' && <span className="errSpan">Your passwords do no match</span>}
                                    {errors.conpassword?.type === 'pattern' && <span className='errSpan'>Invalid Password format</span>}
                                </div>
                                <div className="flex items-start">
                                    <div className="flex items-center h-5">
                                        <input id="terms" aria-describedby="terms" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" {...register("terms", { required: true })} />
                                    </div>
                                    <div className="ml-3 text-sm">
                                        <label for="terms" className="font-light text-gray-500 dark:text-gray-300">I accept the <a className="font-medium text-primary-600 hover:underline dark:text-primary-500" href="/terms" target='_blank'>Terms and Conditions</a></label>
                                    </div>
                                </div>
                                {errors.terms && <span className="errSpan">Please accept terms and condition</span>}
                                <button type="button" className="block w-full px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple" onClick={handleSubmit(submitHandler, errorHandler)} >Create an account</button>
                                <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                                    Already have an account? <a href="/login" className="font-medium text-primary-600 hover:underline dark:text-primary-500">Login here</a>
                                </p>
                            </form>

                            <ul className="list-disc text-xs">
                                <h2><strong className='text-blue-500'>Password rules</strong></h2>
                                <li>Password should be 8 Character</li>
                                <li>Password should have one symbol</li>
                                <li>Password should have one Alphabet</li>
                            </ul>
                        </div>
                    </div> :
                        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                                <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                    Verify OTP
                                </h1>
                                <form className="space-y-4 md:space-y-6" onSubmit={e => e.preventDefault()}>
                                    <div>
                                        <OtpInput
                                            value={otpNumber}
                                            onChange={handleOtpChange}
                                            autoFocus
                                            OTPLength={6}
                                            otpType="number"
                                            disabled={false}
                                            placeholder="------"
                                        />
                                    </div>
                                    {intervalId > 0 ? (
                                        <p><span>{otpTiming}</span> seconds remaining</p>
                                    ) : (
                                        <p>Haven’t receive the code? <span><a href='javascript:void(0);' onClick={handleResendOtp}>Resend Otp</a></span></p>
                                    )}

                                    <button type="button" className="block w-full px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple" onClick={handleSubmit(submitHandler, errorHandler)} >Verify</button>

                                </form>
                            </div>
                        </div>}
                </div>
            </section >
        </React.Fragment >
    )
}

export { Registration }