import { useForm, Controller } from "react-hook-form";
import { Input, TextArea } from '../../Elements/CommonElements';

export default function Test() {
    const { register, handleSubmit, watch, formState: { errors }, control } = useForm();
    const onSubmit = data => console.log(data);

    //  console.log(watch("example")); // watch input value by passing the name of it
    //  console.log({ ...register("example") })

    return (
        /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
        <form onSubmit={handleSubmit(onSubmit)}>
            {/* register your input into the hook by invoking the "register" function */}
            {/* <Controller
                control={control}
                defaultValue="test"
                name="example"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Input onChange={onChange} />
                )}
            /> */}
            <input
                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500 mb-4"
                {...register("example", { required: true })} />
            {errors.example && <span>This field1 is required</span>}
            {/* include validation with required or other standard HTML validation rules */}
            <input {...register("exampleRequired", { required: true })} />
            {/* errors will return when field validation fails  */}
            {errors.exampleRequired && <span>This field is required</span>}

            <input type="submit" />
        </form>
    );
}