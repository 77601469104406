import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { updateUserDetailURL } from '../../../Api/ContantUrls';
import { getStaffUpdatedAction, getUsersListAction } from '../../../Store/Action';
import { setEditOptionData } from '../../../Store/Reducer';
import { FilterbyFiledAsending, FilterbyFiledDsending, FilterbyStringAsending, FilterbyStringDsending, FilterbyFiledAsendingPropName, FilterbyFiledDsendingPropName } from '../../../util/helper';
import DeleteItemPopup from '../../Popups/DeleteItemPopup';
import { DahboardMenu } from '../DahboardMenu';
import { TopHeader } from '../TopHeader';
import { buttonCSS } from '../../../util/contant';


const UsersList = () => {
    const usersListStore = useSelector(state => state?.usersListStore)
    // const { usersListStore } = store;
    const [userList, setUserList] = useState([]);
    const [orginalData, setOriginalData] = useState([]);
    const [isUpdate, setIsUpdate] = useState(false);
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [deleteData, setDeleteData] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState("");
    const [selectedId, setSelectedId] = useState("");

    const [userNameFilter, setUserNameFilter] = useState(false);
    const [userPhoneFilter, setUserPhoneFilter] = useState(false);
    const [userAgeFilter, setUserAgeFilter] = useState(false);
    const [userHospitalFilter, setUserHospitalFilter] = useState(false);
    const [userWardFilter, setUserWardFilter] = useState(false);
    const [callOnce, setCallOnce] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const editDataIds = [];

    const FilterbyTitleOrder = (a, b) => {
        if (a.name.length !== 0 && a.name[0].value && b.name.length !== 0 && b.name[0].value) {
            if (a.name[0].value.toLowerCase() < b.name[0].value.toLowerCase()) {
                return -1;
            }
            if (a.name[0].value.toLowerCase() > b.name[0].value.toLowerCase()) {
                return 1;
            }
        }
        return 0;
    }

    useEffect(() => {
        if (usersListStore.length === 0 && userList.length === 0) {
            getUsers();
        } else {
            setUserList(userList.length === 0 ? usersListStore.slice().sort(FilterbyTitleOrder) : userList);
            setOriginalData(userList.length === 0 ? usersListStore.slice().sort(FilterbyTitleOrder) : userList);
            if (usersListStore.length !== 0 && !callOnce) {
                usersListStore.slice().sort(FilterbyTitleOrder).map((val) => {
                    editDataIds.push(val?.mobile)
                })
                dispatch(setEditOptionData(editDataIds))
                setCallOnce(!callOnce);
            }
        }

    }, [usersListStore, isUpdate])

    const filterData = (val, name) => {
        let check = val;
        let filterData = [];
        if (name === 'name') {
            setUserNameFilter(!userNameFilter);
            if (check) {
                filterData = FilterbyStringAsending(orginalData, name);
            } else {
                filterData = FilterbyStringDsending(orginalData, name);
            }

        }
        if (name === 'mobile') {
            setUserPhoneFilter(!userPhoneFilter);
            if (check) {
                filterData = FilterbyFiledAsending(orginalData, name);
            } else {
                filterData = FilterbyFiledDsending(orginalData, name);
            }

        }
        if (name === 'ageGroup') {
            setUserAgeFilter(!userAgeFilter);
            if (check) {
                filterData = FilterbyFiledAsendingPropName(orginalData, name, "name");
            } else {
                filterData = FilterbyFiledDsendingPropName(orginalData, name, "name");
            }

        }
        if (name === 'hospital') {
            setUserHospitalFilter(!userHospitalFilter);
            if (check) {
                filterData = FilterbyFiledAsendingPropName(orginalData, name, "name");
            } else {
                filterData = FilterbyFiledDsendingPropName(orginalData, name, "name");
            }

        }
        if (name === 'ward') {
            setUserWardFilter(!userWardFilter);
            if (check) {
                filterData = FilterbyFiledAsendingPropName(orginalData, name, "name");
            } else {
                filterData = FilterbyFiledDsendingPropName(orginalData, name, "name");
            }

        }
        filterData.map((val) => {
            editDataIds.push(val?.mobile)
        })
        dispatch(setEditOptionData(editDataIds))
        setUserList(filterData);
        setIsUpdate(!isUpdate);
    }

    const getUsers = async () => {
        const reqObj = {
            "getLatestFirst": true,
            "pageNumber": 1,
            "numberOfRecordsPerPage": 0,
            "filter": {
                "active": true
            }
        }
        dispatch(await getUsersListAction(reqObj));
    }

    const openDelete = (data, id, index) => {
        setDeleteData(data);
        setSelectedId(id);
        setSelectedIndex(index)
        setOpenDeletePopup(true);
    }
    const closeDeletePopup = () => {
        setOpenDeletePopup(false);
    }

    const deleteGift = async () => {
        const reqObj = {
            "userId": selectedId,
            "dataToUpdate": {
                "active": false
            }
        }
        const response = await getStaffUpdatedAction(dispatch, updateUserDetailURL, reqObj);
        if (response?.status === 200 && response?.data) {
            toast.success("Success")
            const existObj = JSON.parse(JSON.stringify(userList));
            existObj.splice(selectedIndex, 1);
            setUserList(existObj);
            setIsUpdate(!isUpdate)
        } else {
            toast.error("Failed")
        }
    }
    return (
        <React.Fragment>
            <TopHeader />
            <DahboardMenu />
            {openDeletePopup && <DeleteItemPopup deleteData={deleteGift} closeModal={closeDeletePopup} modalIsOpen={openDeletePopup} isFrom="Users" data={deleteData} />}

            <div className="mt-10 h-screen" >
                <div className="
max-w-[540px] sm:max-w-[604px] md:max-w-[720px] lg:max-w-[972px]
xl:max-w-full xl:pl-24  xl:ml-40 xl:px-5 2xl:max-w-[1400px] mx-auto
flex">
                    <div className="flex flex-col h-full w-full">
                        <div className="relative sm:rounded-lg">
                            <div className="flex justify-end pb-3">
                                <div id="sticky-banner" tabindex="-1" class="fixed top-16 z-50 flex">
                                    <div class="flex items-end mx-auto">
                                        <button className={buttonCSS.addbtn} onClick={() => navigate('/dashboard/users')}>
                                            <svg className="h-6 w-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                                            </svg>
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-wrp block">
                                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 h-[500px] overflow-auto h-auto">
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0">
                                        <tr>
                                            <th scope="col" className="px-5 py-3">
                                                <div className='flex items-center'>
                                                    <p> Name</p>
                                                    <a href='javascript:void(0);' onClick={() => filterData(userNameFilter, "name")}>
                                                        {userNameFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                        </svg> :
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                            </svg>}
                                                    </a>

                                                </div>
                                            </th>
                                            <th scope="col" className="px-5 py-3">
                                                <div className='flex items-center'>
                                                    <p> Phone</p>
                                                    <a href='javascript:void(0);' onClick={() => filterData(userPhoneFilter, "mobile")}>
                                                        {userPhoneFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                        </svg> :
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                            </svg>}
                                                    </a>

                                                </div>
                                            </th>
                                            <th scope="col" className="px-5 py-3">
                                                <div className='flex items-center'>
                                                    <p> Age Group</p>
                                                    <a href='javascript:void(0);' onClick={() => filterData(userAgeFilter, "ageGroup")}>
                                                        {userAgeFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                        </svg> :
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                            </svg>}
                                                    </a>

                                                </div>
                                            </th>
                                            <th scope="col" className="px-5 py-3">
                                                <div className='flex items-center'>
                                                    <p> Hospital</p>
                                                    <a href='javascript:void(0);' onClick={() => filterData(userHospitalFilter, "hospital")}>
                                                        {userHospitalFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                        </svg> :
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                            </svg>}
                                                    </a>

                                                </div>
                                            </th>
                                            <th scope="col" className="px-5 py-3">
                                                <div className='flex items-center'>
                                                    <p> Ward</p>
                                                    <a href='javascript:void(0);' onClick={() => filterData(userWardFilter, "ward")}>
                                                        {userWardFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                        </svg> :
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                            </svg>}
                                                    </a>

                                                </div>
                                            </th>
                                            <th scope="col" className="px-5 py-3">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {userList.length !== 0 && userList.map((val, index) => {
                                            return <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                <td scope="row" className="px-5 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{val?.name[0]?.value}</td>
                                                <td scope="row" className="px-5 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{val?.mobile}</td>
                                                <td scope="row" className="px-5 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{val?.ageGroup?.name}</td>
                                                <td scope="row" className="px-5 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{val?.hospital?.name}</td>

                                                <td scope="row" className="px-5 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                    {val?.ward?.name}

                                                </td>

                                                <td>
                                                    <span><a href="javascript:void(0);" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => { navigate('/dashboard/users?id=' + val.mobile) }}>Edit</a> /&nbsp;
                                                        <a href="javascript:void(0);" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => { openDelete(val, val?._id, index) }}>Delete</a></span>
                                                </td>
                                            </tr>
                                        })}
                                        {userList.length === 0 && <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                            <th scope="row" colSpan={6} className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                No data found
                                            </th>
                                        </tr>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}

export default UsersList