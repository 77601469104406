import React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { giftUpdateURL, updateBookDetailsURL, updateDiseaseByIdURL, updateGameDetailsURL, updateMedicineURL, updateProtocolByIdURl, updateSideEffectURL, updateStaffDetailsURL, updateTreatmentByIdURL, updateUserDetailURL } from '../Api/ContantUrls';
import ChangeAlertPopup from '../components/Popups/ChangeAlertPopup';
import { getStaffUpdatedAction } from '../Store/Action';
import { getQuery } from '../util/helper';

const PrevAndNextButtons = (props) => {
    const { editOptionData, selectedEditIndex, getData, resetObj,
        reset, from, setRedirectId, setSelectedEditIndex, checkIsModified, handleSubmit, constructData } = props;
    // console.log("editOptionData---------------->", from)
    const navigation = useNavigate();
    const dispatch = useDispatch();

    const [showPopup, setShowPopup] = useState(false);


    const redirect = (id) => {
        setTimeout(() => {
            if (from === 'medicine') {
                navigation('/dashboard/medicine?id=' + id, { replace: true })
            }
            if (from === 'protocol') {
                navigation('/dashboard/protocols?id=' + id)
            }
            if (from === 'sideeffect') {
                navigation('/dashboard/addSideEffect?id=' + id, { replace: true })
            }
            if (from === 'disease') {
                navigation('/dashboard/diseasetype?id=' + id, { replace: true })
            }
            if (from === 'treatment') {
                navigation('/dashboard/treatments?id=' + id)
            }
            if (from === 'staff') {
                navigation('/dashboard/staff?id=' + id)
            }
            if (from === 'books') {
                navigation('/dashboard/books?id=' + id, { replace: true })
            }
            if (from === 'games') {
                navigation('/dashboard/games?id=' + id)
            }
            if (from === 'gift') {
                navigation('/dashboard/creategift?id=' + id)
            }
            if (from === 'users') {
                navigation('/dashboard/users?id=' + id)
            }
        }, 2000)
    }

    const nextHandler = async () => {
        if (selectedEditIndex < editOptionData.length - 1) {
            reset(resetObj)
            setRedirectId(editOptionData[selectedEditIndex + 1]);

            setSelectedEditIndex(selectedEditIndex + 1)
            await getData(editOptionData[selectedEditIndex + 1]);
            setTimeout(() => {
                redirect(editOptionData[selectedEditIndex + 1]);
            }, 500)

        } else {
            //  console.log("coming2222")
            toast.info("You are in last item")
            if (selectedEditIndex === 0) {
                setRedirectId("0");
            } else {
                setRedirectId(editOptionData[selectedEditIndex]);
            }
            setSelectedEditIndex(selectedEditIndex - 1)
            await getData(editOptionData[editOptionData.length - 1]);
            redirect(editOptionData[editOptionData.length - 1])


        }
        window.scroll(0, 0)
    }

    const DiscardChanges = async () => {
        setShowPopup(false)
        await nextHandler();

    }

    const saveChanges = async (data) => {
        setShowPopup(false)
        //   console.log("data11113333------------------>", data)
        // console.log("data1111------------------>", constructData(data))
        let response = "";
        let reqObj = "";
        let URL = "";
        if (from === 'protocol') {
            reqObj = {
                "protocolId": getQuery("id"),
                "dataToUpdate": constructData(data)
            }

            URL = updateProtocolByIdURl;
        }
        if (from === 'medicine') {
            reqObj = {
                "medicineId": getQuery("id"),
                "dataToUpdate": constructData(data)
            }
            URL = updateMedicineURL;
        }
        if (from === 'disease') {
            reqObj = {
                "diseaseTypeId": getQuery("id"),
                "dataToUpdate": constructData(data)
            }
            URL = updateDiseaseByIdURL;
        }
        if (from === 'sideeffect') {
            reqObj = {
                "sideEffectId": getQuery("id"),
                "dataToUpdate": constructData(data)
            }
            URL = updateSideEffectURL;
        }

        if (from === 'staff') {
            reqObj = {
                "staffId": getQuery("id"),
                "dataToUpdate": constructData(data)
            }
            URL = updateStaffDetailsURL;
        }
        if (from === 'books') {
            reqObj = {
                "bookId": getQuery("id"),
                "dataToUpdate": constructData(data)
            }
            URL = updateBookDetailsURL;
        }
        if (from === 'games') {
            reqObj = {
                "gameId": getQuery("id"),
                "dataToUpdate": constructData(data)
            }
            URL = updateGameDetailsURL;
        }
        if (from === 'gift') {
            reqObj = {
                "giftId": getQuery("id"),
                "dataToUpdate": constructData(data)
            }
            URL = giftUpdateURL;
        }
        if (from === 'users') {
            reqObj = {
                "giftId": getQuery("id"),
                "dataToUpdate": constructData(data)
            }
            URL = updateUserDetailURL;
        }
        if (from === 'treatment') {
            reqObj = {
                "treatmentId": getQuery("id"),
                "dataToUpdate": constructData(data)
            }
            URL = updateTreatmentByIdURL
        }
        //  console.log("reqObj------------>", reqObj);

        response = await getStaffUpdatedAction(dispatch, URL, reqObj, true);
        //  console.log("data1111response------------------>", response)
        if (response?.status === 200 && response?.data) {
            //     console.log("data1111------------------>coming")
            await nextHandler();
        } else {
            toast.error("Failed")
        }
    }

    const next = async (e) => {
        e.preventDefault();
        //   console.log("2222222------------------------>", checkIsModified())
        if (!checkIsModified()) {
            // if (selectedEditIndex < editOptionData.length - 1) {
            //     reset(resetObj)
            //     setRedirectId(editOptionData[selectedEditIndex + 1]);

            //     setSelectedEditIndex(selectedEditIndex + 1)
            //     await getData(editOptionData[selectedEditIndex + 1]);
            //     setTimeout(() => {
            //         redirect(editOptionData[selectedEditIndex + 1]);
            //     }, 500)

            // } else {
            //     console.log("coming2222")
            //     toast.info("You are in last item")
            //     if (selectedEditIndex === 0) {
            //         setRedirectId("0");
            //     } else {
            //         setRedirectId(editOptionData[selectedEditIndex]);
            //     }
            //     setSelectedEditIndex(selectedEditIndex - 1)
            //     await getData(editOptionData[editOptionData.length - 1]);
            //     redirect(editOptionData[editOptionData.length - 1])


            // }
            // window.scroll(0, 0)
            await nextHandler();
        } else {
            setShowPopup(true)
        }
    }

    const closePopup = () => {
        setShowPopup(!showPopup);
    }

    const prev = async (e) => {
        e.preventDefault();
        //    console.log("2222222------------------------>", checkIsModified())
        if (selectedEditIndex !== 0) {
            setRedirectId(editOptionData[selectedEditIndex - 1]);
            setSelectedEditIndex(selectedEditIndex + 1)
            await getData(editOptionData[selectedEditIndex - 1]);
            redirect(editOptionData[selectedEditIndex - 1]);


        } else {

            toast.info("You are in first item")
            setRedirectId(editOptionData[0]);
            setSelectedEditIndex(0)
            await getData(editOptionData[0]);
            redirect(editOptionData[0]);


        }
        window.scroll(0, 0)
    }
    return (
        <div className="bottom-0 left-0 right-0 flex justify-between w-3/4 ml-80 p-2 bg-transparent text-white mb-4" style={{ marginLeft: "0px", width: "auto" }}>
            {showPopup && <ChangeAlertPopup modalIsOpen={showPopup} closeModal={closePopup} DiscardChanges={DiscardChanges} saveChanges={saveChanges} handleSubmit={handleSubmit} />}
            <a href='javascript:void(0);' onClick={(e) => prev(e)}>

                <img className='h-20' src='../../assets/img/preview.png' alt='previousBtn' />
            </a>
            <a href='javascript:void(0);' onClick={(e) => next(e)}>

                <img className='h-20' src='../../assets/img/Next1.png' alt='previousBtn' />
            </a>
        </div>
        // <div className='fixed bottom-1/2 w-8/12' style={{ marginLeft: "-55px" }}>
        //     {/* <button className='my-8 float-right px-5 py-2 bg-purple-900 text-white text-sm font-bold tracking-wide rounded-full focus:outline-none' onClick={() => next()}>Next</button>
        //     <button className='my-8 ml-auto px-5 py-2 bg-purple-900 text-white text-sm font-bold tracking-wide rounded-full focus:outline-none' onClick={() => prev()}>Previous</button> */}
        //     <button type="button" className="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-prev onClick={() => prev()}>
        //         <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-black/30 dark:bg-gray-800/30 group-hover:bg-black/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
        //             {/* <svg className="w-4 h-4 text-white dark:text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
        //                 <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 1 1 5l4 4" />
        //             </svg> */}
        //             <img src='../../assets/img/preview.png' alt='previousBtn' />
        //             <span className="sr-only">Previous</span>
        //         </span>
        //     </button>
        //     <button type="button" className="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-next onClick={() => next()}>
        //         <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-black/30 dark:bg-gray-800/30 group-hover:bg-black/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
        //             {/* <svg className="w-4 h-4 text-white dark:text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
        //                 <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
        //             </svg> */}
        //             <img src='../../assets/img/Next1.png' alt='previousBtn' />
        //             <span className="sr-only">Next</span>
        //         </span>
        //     </button>
        // </div>
    )
}

export default PrevAndNextButtons