import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { getAllProtocolListAction, getOrderProtocolListAction, getQRCodeAction, getStaffUpdatedAction } from '../../../Store/Action';
import { setMedicineDataById, setEditOptionData, setProtocolDatabyID, setAllProtocolList, setLoading } from '../../../Store/Reducer';
import { DahboardMenu } from '../DahboardMenu';
import { TopHeader } from '../TopHeader';
import DIseaseQRCodePopup from '../Disease/DIseaseQRCodePopup';
import DeleteItemPopup from '../../Popups/DeleteItemPopup';
import { FilterbyStringAsending, FilterbyStringDsending, FilterbyFiledAsending, FilterbyFiledDsending, FilterbybooleanAsending, FilterbybooleanDsending, FilterbyFiledDsendingPropName, FilterbyFiledAsendingPropName, FilterbyFiledDsendingPropNameValue, FilterbyFiledAsendingPropNameValue } from '../../../util/helper';
import { toast } from 'react-toastify';
import { set } from 'react-hook-form';
import { buttonCSS } from '../../../util/contant';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getAllProtocalListURL, getDiseaseByIdURL } from '../../../Api/ContantUrls';
import { ThreeDots } from 'react-loader-spinner';
import { useReactToPrint } from 'react-to-print';
import { useRef } from 'react';

const ProtocolList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const allProtocolListStore = useSelector(state => state?.allProtocolListStore);
    const allProtocolCount = useSelector(state => state?.allProtocolCount);
    // const { storeOrderProtocolList, allProtocolListStore } = store;
    const [isOpenQR, setIsOpenQR] = useState(false);
    const [qrVal, setQrVal] = useState("");
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [deletePopupData, setDeletePopupData] = useState([]);
    const [protoData, setProtoData] = useState([]);
    const [callOnce, setCallOnce] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);


    const [pNameFilter, setPNameFilter] = useState(false);
    const [psNameFilter, setpSFilter] = useState(false);
    const [pLineFilter, setpLineFilter] = useState(false);
    const [pYearFilter, setpYearFilter] = useState(false);
    const [pVersionFilter, setPVersionFilter] = useState(false);
    const [pDurationFilter, setPDurationFilter] = useState(false);
    const [pMainFilter, setPMainFilter] = useState(false);
    const [pDiseaseFilter, setPDiseaseFilter] = useState(false);
    const [pContinuationOf, setPcontinutionOf] = useState(false);
    const [pConditionFilter, setpConditionFilter] = useState(false);
    const [apiCount, setapiCount] = useState(0);

    const [hasMore, setHasMore] = useState(false)
    const [pageCount, setPageCount] = useState(50)
    const [pageNumber, setPageNumber] = useState(1)

    const [qrCodes, setQRCodes] = useState([]);
    const [qrCodesArr, setQRCodesArr] = useState([]);
    const [printClicked, setPrintClicked] = useState(false);

    const editDataIds = [];
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onPrintError: () => {
            toast.error("Something went wrong, Please try again")
        }
    });

    const FilterbyTitleOrder = (a, b) => {
        if (a.protocolShortName.length !== 0 && a.protocolShortName[0].value && b.protocolShortName.length !== 0 && b.protocolShortName[0].value) {
            if (a.protocolShortName[0].value.toLowerCase() < b.protocolShortName[0].value.toLowerCase()) {
                return -1;
            }
            if (a.protocolShortName[0].value.toLowerCase() > b.protocolShortName[0].value.toLowerCase()) {
                return 1;
            }
        }
        return 0;
    }

    const arrayReverseObj = (obj) => {
        let newArray = []

        Object.keys(obj)
            .sort()
            .reverse()
            .forEach(key => {
                // console.log(key)
                newArray.push({
                    'key': key,
                    'id': obj[key].id
                })
            })

        // console.log(newArray)
        return newArray
    }

    useEffect(() => {
        if (allProtocolListStore.length === 0 && apiCount < 3 && protoData.length === 0) {
            getData();
            setapiCount(apiCount + 1)
        }
        if (allProtocolListStore.length !== 0 && protoData.length === 0) {
            let mainProtos = allProtocolListStore.filter(val => val.isMainProtocol);
            mainProtos = FilterbyFiledAsendingPropNameValue(mainProtos, "protocolShortName", "value");
            // console.log("mainProtos------------------->", mainProtos)
            let filterArray = [];
            if (allProtocolCount < allProtocolListStore.length) {
                setHasMore(false)
            } else {
                setHasMore(true)
            }

            if (mainProtos.length !== 0) {
                mainProtos.map((val1) => {
                    filterArray.push(val1)

                    let belongProtos = allProtocolListStore.filter((val) => {
                        if (val?.belongsToProtocol[0] && val?.belongsToProtocol[0]?._id) {
                            return val1?._id === val?.belongsToProtocol[0]?._id && !val.isMainProtocol // val1?._id === val?.belongsToProtocol[0]?._id && removed
                        }
                    })
                    //filterArray.push(...belongProtos)
                    // console.log("belongProtos------------------->", filterArray)
                    // let belongProtos1 = allProtocolListStore.filter((val) => {
                    //     if (val?.belongsToProtocol[0] ) {
                    //         return val1?._id !== val?.belongsToProtocol[0]?._id && !val.isMainProtocol // val1?._id === val?.belongsToProtocol[0]?._id && removed
                    //     }
                    // })
                    // console.log("belongProtos1------------------->", belongProtos1)
                    FilterbyFiledAsending(belongProtos, "diagramLineNumber").map((values) => {
                        filterArray.push(values)
                    })


                })

                let addedProtocolId = filterArray.map((proto) => { return proto._id });

                let balanceProtocol = allProtocolListStore.filter((val) => {
                    if (!addedProtocolId.includes(val._id)) {
                        return val;
                    }
                });
                FilterbyFiledAsending(balanceProtocol, "diagramLineNumber").map((values) => {
                    filterArray.push(values)
                })
                // filterArray.push(...balanceProtocol)
                // console.log('x=>', filterArray)
                // console.log('y=>', addedProtocolId)
                // console.log('z=>', balanceProtocol)
            } else {
                filterArray = FilterbyFiledAsendingPropNameValue(allProtocolListStore, "protocolShortName", "value")
            }
            setProtoData(filterArray);
            filterArray.map((val) => {
                editDataIds.push(val._id)
            })
            dispatch(setEditOptionData(editDataIds))
            setCallOnce(!callOnce)
        }
        if (qrCodes.length === protoData.length) {
            setQRCodesArr(sortQRArray(qrCodes))
            setQRCodes([])
            setIsUpdate(!isUpdate);
        }
        if (printClicked) {
            handlePrint();
            setPrintClicked(false)
        }
        document.title = 'Protocols List';
    }, [allProtocolListStore, qrCodes, isUpdate, printClicked])

    useEffect(() => () => {
        dispatch(setAllProtocolList([]))
    }, []);

    const getData = async () => {
        const reqObj = {
            "getLatestFirst": true,
            "pageNumber": pageNumber,
            "numberOfRecordsPerPage": pageCount,
            "filter": {
                "active": true
            }
        }
        dispatch(await getAllProtocolListAction(reqObj));
    }
    const selectMedicine = (id) => {
        dispatch(setProtocolDatabyID({}))
        dispatch(setMedicineDataById({}))
        navigate('/dashboard/protocols?id=' + id)
        //window.location.href = '/dashboard/protocols?id=' + id
    }

    const deletePopupOpen = (data) => {
        setDeletePopupData(data)
        setOpenDeletePopup(true);
    }

    const closeDeletePopup = () => {
        setOpenDeletePopup(false);
    }

    const openQrPopup = (val) => {
        setQrVal(val)
        setIsOpenQR(true);

    }

    const closeQrPopup = () => {
        setQrVal("")
        setIsOpenQR(false);

    }

    const filterData = (val, name) => {
        let check = val;
        let filterData = [];
        if (name === 'protocolShortName') {
            setpSFilter(!psNameFilter);
            filterData = check ? FilterbyStringAsending(protoData, name) : FilterbyStringDsending(protoData, name);
        }
        if (name === 'protocolName') {
            setPNameFilter(!pNameFilter);
            filterData = check ? FilterbyStringAsending(protoData, name) : FilterbyStringDsending(protoData, name);
        }
        if (name === 'protocolYear') {
            setpYearFilter(!pYearFilter);
            filterData = check ? FilterbyFiledAsending(protoData, name) : FilterbyFiledDsending(protoData, name);
        }
        if (name === 'protocolVersion') {
            setPVersionFilter(!pVersionFilter);
            filterData = check ? FilterbyFiledAsending(protoData, name) : FilterbyFiledDsending(protoData, name);
        }
        if (name === 'isMainProtocol') {
            setPMainFilter(!pMainFilter);
            filterData = check ? FilterbybooleanAsending(protoData, name) : FilterbybooleanDsending(protoData, name);
        }
        if (name === 'belongToDiseaseType') {
            setPDiseaseFilter(!pDiseaseFilter);
            filterData = check ? FilterbyFiledAsendingPropName(protoData, name, "diseaseType") : FilterbyFiledDsendingPropName(protoData, name, "diseaseType");
        }
        if (name === 'diagramLineNumber') {
            setpLineFilter(!pLineFilter);
            filterData = check ? FilterbyFiledAsending(protoData, name) : FilterbyFiledDsending(protoData, name);
        }
        if (name === 'conditions') {
            setpConditionFilter(!pConditionFilter);
            filterData = check ? FilterbyFiledAsendingPropNameValue(protoData, name, "code") : FilterbyFiledDsendingPropNameValue(protoData, name, "code");
        }
        if (name === 'continuationOf') {
            setPcontinutionOf(!pContinuationOf);
            filterData = check ? FilterbyFiledAsendingPropNameValue(protoData, name, "protocolName") : FilterbyFiledDsendingPropNameValue(protoData, name, "protocolName");
        }
        if (name === 'treatmentDurationDays') {
            setPDurationFilter(!pDurationFilter);
            filterData = check ? FilterbyFiledAsending(protoData, name) : FilterbyFiledDsending(protoData, name);
        }
        filterData.map((val) => {
            editDataIds.push(val?._id)
        })
        dispatch(setEditOptionData(editDataIds))
        setProtoData(filterData);
        setIsUpdate(!isUpdate);
    }

    const getInsideData = (data) => {
        try {
            let renderEle = ""
            if (data.length !== 0) {
                let filteredVal = [];
                data.map((val) => {
                    const { _id, protocolName } = val;
                    if (protocolName.length !== 0) {
                        protocolName.map((item, index) => {
                            if (index === 0) {
                                filteredVal.push(item.value)
                            }
                        })
                    }


                    //  checkval.map(val)

                })
                let checkval = [...new Set(filteredVal)];
                return checkval;
            } else {
                return [];
            }
        } catch (e) {
            console.log("EEE---------------->", e);
            return [];
        }
    }

    const fetchMoreData = () => {
        setTimeout(async () => {
            console.log("coming heree----------------------");
            const reqObj = {
                "getLatestFirst": true,
                "pageNumber": pageNumber + 1,
                "numberOfRecordsPerPage": pageCount,
                "filter": {
                    "active": true
                }
            }

            const response = await getStaffUpdatedAction(dispatch, getAllProtocalListURL, reqObj, false);
            if (response?.status === 200 && response?.data) {
                setPageNumber(pageNumber + 1)
                const { payload } = response?.data;
                const { protocolList } = payload;
                if (protocolList.length !== 0) {
                    const protoDatas = [...protoData, ...protocolList]
                    let mainProtos = protoDatas.filter(val => val.isMainProtocol);
                    mainProtos = FilterbyFiledAsendingPropNameValue(mainProtos, "protocolShortName", "value");
                    console.log("mainProtos1111------------------->", mainProtos)
                    let filterArray = [];

                    if (mainProtos.length !== 0) {
                        mainProtos.map((val1) => {
                            filterArray.push(val1)
                            let belongProtos = protoDatas.filter((val) => {
                                if (val?.belongsToProtocol[0] && val?.belongsToProtocol[0]?._id) {
                                    return val1?._id === val?.belongsToProtocol[0]?._id && !val.isMainProtocol
                                }
                            })
                            FilterbyFiledAsending(belongProtos, "diagramLineNumber").map((values) => {
                                filterArray.push(values)
                            })
                        })
                    } else {
                        filterArray = FilterbyFiledAsendingPropNameValue(protoDatas, "protocolShortName", "value")
                    }

                    setProtoData(filterArray)

                    protoDatas.map(val => {
                        editDataIds.push(val._id)
                    })
                    dispatch(setEditOptionData(editDataIds))

                    if (protocolList.length < pageCount) {
                        setHasMore(false)
                        dispatch(setAllProtocolList([]));
                    }
                } else {
                    setHasMore(false)
                }
                // setHasMore(true)

            } else {
                setProtoData(protoData);
                setHasMore(false)
                // toast.error("Failed")
            }
            setIsUpdate(!isUpdate)
        }, 500);
    }

    const getAllQRCodes = async (data) => {
        try {
            //const response = await getQRCodeAction({ text: propsAsString(qrData) });
            const qrCodeArray = []
            await data.map(async val => {
                const response = await getQRCodeAction({ text: val?._id });
                const response1 = await getStaffUpdatedAction(dispatch, getDiseaseByIdURL, { "diseaseTypeId": val?.belongToDiseaseType[0]?._id }, false);
                const { data } = response1;
                const { diseaseTypeDetails } = data?.payload;
                const { disease } = diseaseTypeDetails;
                const { payload } = response?.data;
                const { qrCodeURL } = payload;
                const { Location } = qrCodeURL;
                const arrObj = {
                    ...val,
                    code: Location,
                    name: `${val?.protocolShortName[0].value} -- ${disease[0]?.value}`
                }
                qrCodeArray.push(arrObj)
                setQRCodes([...qrCodes, ...qrCodeArray])

            })
            setIsUpdate(!isUpdate)
        } catch (e) {
            setQRCodes(qrCodes)
        }
    }

    const printPage = async () => {
        dispatch(setLoading(true))
        await getAllQRCodes(protoData);
        setTimeout(() => {
            dispatch(setLoading(false))
            // handlePrint();
            setPrintClicked(true)
        }, 2000);

    }

    const sortQRArray = (allProtocolListStore) => {
        let mainProtos = allProtocolListStore.filter(val => val.isMainProtocol);
        mainProtos = FilterbyFiledAsendingPropNameValue(mainProtos, "protocolShortName", "value");

        let filterArray = [];

        if (mainProtos.length !== 0) {
            mainProtos.map((val1) => {
                filterArray.push(val1)
                let belongProtos = allProtocolListStore.filter((val) => {
                    if (val?.belongsToProtocol[0] && val?.belongsToProtocol[0]?._id) {
                        return val1?._id === val?.belongsToProtocol[0]?._id && !val.isMainProtocol
                    }
                })
                FilterbyFiledAsending(belongProtos, "diagramLineNumber").map((values) => {
                    filterArray.push(values)
                })

                if (allProtocolListStore.length < pageCount) {
                    setHasMore(false)
                }
            })
        } else {
            filterArray = FilterbyFiledAsendingPropNameValue(allProtocolListStore, "protocolShortName", "value")
        }
        return filterArray;
    }

    const ComponentToPrint = React.forwardRef((props, ref) => {
        const { qrCodesArr } = props;
        return (
            <div ref={ref}>
                <div style={{ display: "none" }}>
                    <div class="grid grid-cols-2 gap-2" id="printScreen" ref={componentRef}>

                        {qrCodesArr.length !== 0 && qrCodesArr.map(val => {
                            return <div className='items-center'>
                                <div className="items-center justify-between p-4 border-b rounded-t dark:border-gray-600">
                                    <img src={val?.code} style={{ width: "250px", height: "250px" }} />
                                    <div className='text-start mt-4 ml-5'><strong>{val?.name}</strong></div>
                                </div>

                            </div>
                        })}
                    </div>
                </div>
            </div>
        );
    });


    return (
        <React.Fragment>
            {openDeletePopup && <DeleteItemPopup closeModal={closeDeletePopup} modalIsOpen={openDeletePopup} isFrom="protocol" data={deletePopupData} />}
            {isOpenQR && <DIseaseQRCodePopup modalIsOpen={isOpenQR} closeModal={closeQrPopup} qrData={qrVal} qrVal={qrVal?.protocolQRCode} name={qrVal?.protocolShortName[0].value} />}
            <TopHeader />
            <DahboardMenu />
            <div className="mt-10 h-full" >
                <div className="
      max-w-[540px] sm:max-w-[604px] md:max-w-[720px] lg:max-w-[972px]
      xl:max-w-full xl:pl-40  xl:ml-40 xl:px-12 2xl:max-w-[1400px] mx-auto
     flex">
                    <div className="flex w-screen flex-col ">
                        <div className="relative shadow-md sm:rounded-lg">
                            <div className="flex justify-end pb-3">
                                <div id="sticky-banner" tabindex="-1" class="fixed top-16 z-50 flex">
                                    <div class="flex items-end mx-auto">
                                        {protoData.length !== 0 && <button className={buttonCSS.addbtn + " mr-2"} onClick={(e) => printPage()}>
                                            Print QR's
                                        </button>}
                                        <button className={buttonCSS.addbtn} onClick={() => navigate('/dashboard/protocols')}>
                                            <svg className="h-6 w-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                                            </svg>
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <InfiniteScroll
                                dataLength={protoData.length}
                                next={fetchMoreData}
                                loader={protoData.length !== 0 && <ThreeDots
                                    visible={true}
                                    height="80"
                                    width="80"
                                    color="#4fa94d"
                                    radius="9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                />}
                                hasMore={hasMore}
                                endMessage={
                                    <p style={{ textAlign: "center" }}>
                                        {protoData.length !== 0 && <b className='text-blue-500'>Yay! You have seen it all</b>}
                                    </p>
                                }
                            >
                                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                        <tr>
                                            <th scope="col" className="px-6 py-3">
                                                <div className='flex items-center'>
                                                    <p> Main</p>
                                                    <a href='javascript:void(0);' onClick={() => filterData(pMainFilter, "isMainProtocol")}>
                                                        {pMainFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                        </svg> :
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                            </svg>}
                                                    </a>
                                                </div>
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                <div className='flex items-center'>
                                                    <p> Disease</p>
                                                </div>
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                <div className='flex items-center'>
                                                    <p> Line&nbsp;#</p>
                                                    <a href='javascript:void(0);' onClick={() => filterData(pLineFilter, "diagramLineNumber")}>
                                                        {pLineFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                        </svg> :
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                            </svg>}
                                                    </a>
                                                </div>
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                <div className='flex items-center'>
                                                    <p> Protocol&nbsp;Short&nbsp;Name</p>
                                                    <a href='javascript:void(0);' onClick={() => filterData(psNameFilter, "protocolShortName")}>
                                                        {psNameFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                        </svg> :
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                            </svg>}
                                                    </a>
                                                </div>
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                <div className='flex items-center'>
                                                    <p> Name</p>
                                                    <a href='javascript:void(0);' onClick={() => filterData(pNameFilter, "protocolName")}>
                                                        {pNameFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                        </svg> :
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                            </svg>}
                                                    </a>
                                                </div>
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                <div className='flex items-center'>
                                                    <p> Medicines</p>
                                                </div>
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                <div className='flex items-center'>
                                                    <p> Year</p>
                                                    <a href='javascript:void(0);' onClick={() => filterData(pYearFilter, "protocolYear")}>
                                                        {pYearFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                        </svg> :
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                            </svg>}
                                                    </a>
                                                </div>
                                            </th>

                                            <th scope="col" className="px-6 py-3">
                                                <div className='flex items-center'>
                                                    <p> Version</p>
                                                    <a href='javascript:void(0);' onClick={() => filterData(pVersionFilter, "protocolVersion")}>
                                                        {pVersionFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                        </svg> :
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                            </svg>}
                                                    </a>
                                                </div>
                                            </th>

                                            <th scope="col" className="px-6 py-3">
                                                <div className='flex items-center'>
                                                    <p> Duration</p>
                                                    <a href='javascript:void(0);' onClick={() => filterData(pDurationFilter, "treatmentDurationDays")}>
                                                        {pDurationFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                        </svg> :
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                            </svg>}
                                                    </a>
                                                </div>
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                <div className='flex items-center'>
                                                    <p> Continuation&nbsp;Of</p>
                                                </div>
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                <div className='flex items-center'>
                                                    <p> Conditions</p>
                                                    <a href='javascript:void(0);' onClick={() => filterData(pConditionFilter, "conditions")}>
                                                        {pConditionFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                        </svg> :
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                            </svg>}
                                                    </a>
                                                </div>
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {protoData.length !== 0 ? protoData.map((data, index) => {
                                            return <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                    <input type='checkbox' checked={data.isMainProtocol} />
                                                </th>
                                                <th className="px-6 py-4">
                                                    {data?.belongToDiseaseType[0]?.disease[0]?.value}
                                                </th>
                                                <th className="px-6 py-4">
                                                    {data?.diagramLineNumber}
                                                </th>
                                                <th className="px-6 py-4">
                                                    {data.protocolShortName[0].value}
                                                </th>
                                                <td className="px-6 py-4">
                                                    {data.protocolName[0].value}
                                                </td>
                                                <th className="px-6 py-4">

                                                    {data?.medicinesIncluded.map((val) => {
                                                        return val?.medicineShortName ? <p>{val?.medicineShortName[0]?.value}</p> : <p>{val?.medicineName[0]?.value}</p>
                                                    })}
                                                </th>
                                                <td className="px-6 py-4">
                                                    {data.protocolYear}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {data.protocolVersion}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {data.treatmentDurationDays}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {getInsideData(data?.continuationOf).map((val) => {
                                                        return <p>{val}</p>
                                                    })}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {data.conditions && data.conditions.map(val => <p>{val?.code}</p>)}
                                                </td>
                                                <td className="px-6 py-4">
                                                    <a href="javascript:void(0);" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => selectMedicine(data._id)}>Edit</a> /&nbsp;
                                                    <a href="javascript:void(0);" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => deletePopupOpen(data)}>Delete</a> /&nbsp;
                                                    <a href="javascript:void(0);" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => openQrPopup(data)}>QR</a>
                                                </td>
                                            </tr>
                                        }) :
                                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                <th scope="row" colSpan={15} className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                    No data found
                                                </th>
                                            </tr>
                                        }

                                    </tbody>

                                </table>
                            </InfiniteScroll>
                            {qrCodesArr.length !== 0 && <ComponentToPrint qrCodesArr={qrCodesArr} />}
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export { ProtocolList }