import React from 'react';
import { Input, TextArea } from '../../Elements/CommonElements';
import { DahboardMenu } from './DahboardMenu';
import { TopHeader } from './TopHeader';

const BlockStructure = () => {
    return (
        <React.Fragment>
            <TopHeader />
            <DahboardMenu />
            <div className="mt-10">
                <div className="
      max-w-[540px] sm:max-w-[604px] md:max-w-[720px] lg:max-w-[972px]
      xl:max-w-full xl:pl-40  xl:ml-40 xl:px-12 2xl:max-w-[1400px] mx-auto
     flex">
                    <div className="flex w-full flex-col ">
                        <div className="relative overflow-hidden mb-8">
                            <div className='rounded-t-lg overflow-hidden border-t border-l border-r border-b border-gray-400 flex justify-center p-8'>
                                <form className="w-full bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 " name='conversion'>
                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                            Protocol Short Name
                                        </label>
                                        <Input placeholder='English' id="pshortName" />

                                        <Input placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrire("ar_pshortName")} name="ar_pshortName" id="ar_pshortName" />

                                        <Input placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrireHebrew("hb_pshortName")} name="hb_pshortName" id="hb_pshortName" />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                            Version
                                        </label>
                                        <Input placeholder='English' id="version" />

                                        <Input placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrire("ar_version")} name="ar_version" id="ar_version" />

                                        <Input placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrireHebrew("hb_version")} name="hb_version" id="hb_version" />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                            Protocol Name
                                        </label>
                                        <Input placeholder='English' id="pName" />

                                        <Input placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrire("ar_pName")} name="ar_pName" id="ar_pName" />

                                        <Input placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrireHebrew("hb_pName")} name="hb_pName" id="hb_pName" />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="ar_dpd">
                                            Medicines
                                        </label>
                                        <Input placeholder='English' id="medicines" />

                                        <Input placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrire("ar_medicines")} name="ar_medicines" id="ar_medicines" />

                                        <Input placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrireHebrew("hb_medicines")} name="hb_medicines" id="hb_medicines" />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="tdd">
                                            Treatment Duration Days
                                        </label>
                                        <Input placeholder='English' id="tdd" />

                                        <Input placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrire("ar_doseUnit")} name="ar_tdd" id="ar_tdd" />

                                        <Input placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrireHebrew("hb_tdd")} name="hb_tdd" id="hb_tdd" />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                            Day Start
                                        </label>
                                        <Input placeholder='English' id="dayStart" />

                                        <Input placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrire("ar_dayStart")} name="ar_dayStart" id="ar_dayStart" />

                                        <Input placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrireHebrew("hb_dayStart")} name="hb_dayStart" id="hb_dayStart" />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                            Continuation Of
                                        </label>
                                        <Input placeholder='English' id="continuationOf" />

                                        <Input placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrire("ar_continuationOf")} name="ar_continuationOf" id="ar_continuationOf" />

                                        <Input placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrireHebrew("hb_continuationOf")} name="hb_continuationOf" id="hb_continuationOf" />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                            Conditions
                                        </label>
                                        <Input placeholder='English' id="conditions" />

                                        <Input placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrire("ar_conditions")} name="ar_conditions" id="ar_conditions" />

                                        <Input placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrireHebrew("hb_conditions")} name="hb_conditions" id="hb_conditions" />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="treatDuration">
                                            Treatment Duration
                                        </label>
                                        <Input placeholder='English' id="treatDuration" />

                                        <Input placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrire("ar_treatDuration")} name="ar_ar_treatDuration" id="ar_treatDuration" />

                                        <Input placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrireHebrew("hb_mediThrough")} name="hb_treatDuration" id="hb_treatDuration" />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="rmp">
                                            Required Medical Preparations
                                        </label>
                                        <Input placeholder='English' id="rmp" />

                                        <Input placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrire("ar_rmp")} name="ar_rmp" id="ar_rmp" />

                                        <Input placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrireHebrew("hb_rmp")} name="hb_rmp" id="hb_rmp" />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="mpsd">
                                            Medical Preparations Starting Day
                                        </label>
                                        <Input placeholder='English' id="mpsd" />

                                        <Input placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrire("ar_mpsd")} name="ar_mpsd" id="ar_mpsd" />

                                        <Input placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrireHebrew("hb_mpsd")} name="hb_mpsd" id="hb_mpsd" />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="mpsd">
                                            General Instructions
                                        </label>
                                        <TextArea placeholder='English' id="generalIns" />

                                        <TextArea placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrire("ar_generalIns")} name="ar_generalIns" id="ar_generalIns" />

                                        <TextArea placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrireHebrew("hb_generalIns")} name="hb_generalIns" id="hb_generalIns" />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="mpsd">
                                            When to Report
                                        </label>
                                        <TextArea placeholder='English' id="whenReport" />

                                        <TextArea placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrire("ar_whenReport")} name="ar_whenReport" id="ar_whenReport" />

                                        <TextArea placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrireHebrew("hb_whenReport")} name="hb_whenReport" id="hb_whenReport" />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="mpsd">
                                            Explanations
                                        </label>
                                        <TextArea placeholder='English' id="explain" />

                                        <TextArea placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrire("ar_explain")} name="ar_explain" id="ar_explain" />

                                        <TextArea placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrireHebrew("hb_explain")} name="hb_explain" id="hb_explain" />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="mpsd">
                                            Media Links
                                        </label>
                                        <TextArea placeholder='English' id="mediaLinks" />

                                        <TextArea placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrire("ar_mediaLinks")} name="ar_mediaLinks" id="ar_mediaLinks" />

                                        <TextArea placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrireHebrew("hb_mediaLinks")} name="hb_mediaLinks" id="hb_mediaLinks" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export { BlockStructure }