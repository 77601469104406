import React, { useState } from "react";

import TextField from "@mui/material/TextField";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Controller, useForm } from 'react-hook-form';
import moment from "moment";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';


const EditableTable = () => {
    const { register, handleSubmit, formState: { errors }, getValues, control, reset, setValue, setError, clearErrors } = useForm();
    const [data, setData] = useState([
        { id: 1, name: "John Doe", age: 25 },
        { id: 2, name: "Jane Smith", age: 30 },
        // ... more initial data
    ]);
    const [selectedTime, setSelectedTime] = useState(null);

    const handleTimeChange = (newTime) => {
        setSelectedTime(newTime);
    };

    const [newName, setNewName] = useState("");
    const [newAge, setNewAge] = useState("");

    const handleEdit = (id) => {
        const updatedData = data.map((item) =>
            item.id === id ? { ...item, isEditing: !item.isEditing } : item
        );
        setData(updatedData);
    };

    const handleUpdate = (id, field, value) => {
        const updatedData = data.map((item) =>
            item.id === id ? { ...item, [field]: value, isEditing: false } : item
        );
        setData(updatedData);
    };

    const handleAdd = () => {
        const newId = data.length + 1;
        const newRow = { id: newId, name: newName, age: parseInt(newAge), isEditing: false };
        setData([...data, newRow]);
        setNewName("");
        setNewAge("");
    };

    return (
        <div>
            <div>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <div className="dateAndTimePickerLightTheme">
                        <Controller
                            name="expireDate"
                            control={control}
                            render={({ field: { onChange, ref, value }, fieldState: { error } }) => (
                                <TimePicker
                                    label="24 hours"
                                    // label="Expire Time"
                                    value={value ? moment(value, "HH:mm:ss") : null}
                                    onChange={(newValue) => {
                                        onChange(moment(newValue).format("HH:mm:ss"));
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                    inputRef={ref}
                                    ampm={false}
                                />
                            )}
                            rules={{ required: 'Required' }}
                            onFocus={errors.expireDate?.ref}
                        />
                        {errors !== undefined && errors.expireDate && (
                            <span className="errorMessage">{errors.expireDate.message}</span>
                        )}
                    </div>
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DemoContainer components={['TimePicker']}>
                        <TimePicker
                            ampm={false}
                            label="With Time Clock"
                            viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                            }}
                        />
                    </DemoContainer>
                </LocalizationProvider>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Age</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row) => (
                        <tr key={row.id}>
                            <td>
                                {row.isEditing ? (
                                    <input
                                        type="text"
                                        value={row.name}
                                        onChange={(e) => handleUpdate(row.id, "name", e.target.value)}
                                    />
                                ) : (
                                    row.name
                                )}
                            </td>
                            <td>
                                {row.isEditing ? (
                                    <input
                                        type="text"
                                        value={row.age}
                                        onChange={(e) => handleUpdate(row.id, "age", parseInt(e.target.value))}
                                    />
                                ) : (
                                    row.age
                                )}
                            </td>
                            <td>
                                {row.isEditing ? (
                                    <button onClick={() => handleUpdate(row.id)}>Save</button>
                                ) : (
                                    <button onClick={() => handleEdit(row.id)}>Edit</button>
                                )}
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td>
                            <input
                                type="text"
                                placeholder="Name"
                                value={newName}
                                onChange={(e) => setNewName(e.target.value)}
                            />
                        </td>
                        <td>
                            <input
                                type="text"
                                placeholder="Age"
                                value={newAge}
                                onChange={(e) => setNewAge(e.target.value)}
                            />
                        </td>
                        <td>
                            <button onClick={handleAdd}>Add</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default EditableTable;
