import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { updateUserDetailURL } from '../../../Api/ContantUrls';
import PrevAndNextButtons from '../../../Elements/PrevAndNextButtons';
import { getAgeGroupListAction, getHospitalListAction, getWardListAction, createUserAction, getUserDetailsAction, getStaffUpdatedAction } from '../../../Store/Action';
import { buttonCSS, errorMessage, formInputClassName } from '../../../util/contant';
import { arabicLangId, englishLangId, getQuery, hebrewLangId } from '../../../util/helper';
import { DahboardMenu } from '../DahboardMenu';
import ErrorMessage from '../ErrorMessage';
import { TopHeader } from '../TopHeader';

const Users = () => {
    const { register, handleSubmit, watch, formState: { errors }, control, reset, getValues, setError, setValue } = useForm();
    //  const store = useSelector(state => state);
    // const { availableLanguages, hospitalListStore, wardListStore, ageGroupListStore, getUserDetailsStore, editOptionData } = store;
    const availableLanguages = useSelector(state => state?.availableLanguages);
    const hospitalListStore = useSelector(state => state?.hospitalListStore);
    const wardListStore = useSelector(state => state?.wardListStore);
    const ageGroupListStore = useSelector(state => state?.ageGroupListStore);
    const getUserDetailsStore = useSelector(state => state?.getUserDetailsStore);
    const editOptionData = useSelector(state => state?.editOptionData);
    const [userId, setUserId] = useState(getQuery('id'));
    const [isApicalled, setIsApiCalled] = useState(false);
    const [selectedEditIndex, setSelectedEditIndex] = useState("");
    const [pageRenderObj, setPageRenderObj] = useState({});
    const { UserError } = errorMessage;
    const dispatch = useDispatch();
    const apiReqObj = {
        "getLatestFirst": true,
        "pageNumber": 1,
        "numberOfRecordsPerPage": 0,
        "filter": {
            "active": true
        }
    }

    useEffect(() => {
        if (hospitalListStore.length === 0) {
            getAgeGroupList("hospital")
        }
        if (wardListStore.length === 0) {
            getAgeGroupList("ward")
        }
        if (ageGroupListStore.length === 0) {
            getAgeGroupList("age")
        }

        // console.log("getUserDetailsStore---------->", getUserDetailsStore)

        if (userId) {
            if (Object.keys(getUserDetailsStore).length === 0 && !isApicalled) {
                getuserDetails(userId);
                setIsApiCalled(true)
            } else {
                setSelectedEditIndex(editOptionData.indexOf(userId))
                constructPageData(getUserDetailsStore)
            }

        }

    }, [getUserDetailsStore])

    const constructPageData = data => {
        // console.log("constructdata---------->", data)
        // return;
        reset()
        const reqObj = {};
        if (Object.keys(getUserDetailsStore).length !== 0) {
            reqObj.name = data?.name[0]?.value;
            reqObj.ar_name = data?.name[1]?.value;
            reqObj.hb_name = data?.name[2]?.value;
            reqObj.hospital = data?.hospital?._id;
            reqObj.ward = data?.ward?._id;
            reqObj.ageGroup = data?.ageGroup?._id;
            reqObj.mobile = data?.mobile
        }
        setTimeout(() => {
            setPageRenderObj(reqObj)
            reset(reqObj)
        }, 50);
    }

    const getuserDetails = async (id) => {
        dispatch(await getUserDetailsAction({ mobile: id }));
    }

    const getAgeGroupList = async (from = "") => {
        if (from === 'age') {
            dispatch(await getAgeGroupListAction(apiReqObj));
        }
        if (from === 'hospital') {
            dispatch(await getHospitalListAction(apiReqObj));
        }
        if (from === 'ward') {
            dispatch(await getWardListAction(apiReqObj));
        }

    }
    const constructReqObj = (data) => {
        const reqObj = data;
        reqObj.name = [
            { language: englishLangId(availableLanguages.languageList), value: data.name },
            { language: arabicLangId(availableLanguages.languageList), value: data.ar_name },
            { language: hebrewLangId(availableLanguages.languageList), value: data.hb_name }
        ];
        return reqObj;
    }
    const submitHandler = async (data, isNew) => {
        //  console.log("data------------->", data)

        if (!getValues("ar_name") && !getValues("hb_name")) {
            setError('ar_name', { type: 'custom', message: 'Please enter the name in Arabic or Hebrew' });
            window.scroll(0, 0)
        } else {
            const reqObj = constructReqObj(data);
            if (userId) {
                const apireqObj = {
                    userId: getUserDetailsStore?._id,
                    "dataToUpdate": reqObj
                }
                const response = await getStaffUpdatedAction(dispatch, updateUserDetailURL, apireqObj);
                if (response?.status === 200 && response?.data) {
                    toast.success("User updated successfully")
                    if (isNew) {
                        window.location.href = '/dashboard/users';
                    } else {
                        window.location.href = '/dashboard/userslist';
                    }

                } else {
                    toast.error("Failed")
                }
            } else {
                dispatch(await createUserAction(reqObj, isNew))
            }
        }
    }
    const onError = (errors) => {
        window.scroll(0, 0)
        // console.log("errors------------->", errors)
    }

    const saveAndExit = (data) => {
        submitHandler(data, false)
    }

    const saveAndNew = (data) => {
        submitHandler(data, true)
    }

    const discardChanges = () => {
        if (userId) {
            constructPageData(getUserDetailsStore)
        } else {
            reset({});
        }
        window.location.href = '/dashboard/userslist';
    }

    const checkIsModified = () => {
        console.log("values are same1======================>", JSON.stringify(pageRenderObj))
        console.log("values are same2======================>", JSON.stringify(getValues()))
        if (JSON.stringify(pageRenderObj) === JSON.stringify(getValues())) {
            return false;
        } else {
            return true
        }
    }

    return (
        <React.Fragment>
            <TopHeader />
            <DahboardMenu />
            <div className="mt-10 h-screen">
                <div className="
max-w-[540px] sm:max-w-[604px] md:max-w-[720px] lg:max-w-[972px]
xl:max-w-full xl:pl-40  xl:ml-40 xl:px-12 2xl:max-w-[1400px] mx-auto
flex">
                    <div className="flex w-full flex-col ">

                        <div className="relative mb-8">
                            <ErrorMessage errors={errors} />
                            <form className="w-full bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 " name='conversion' onSubmit={handleSubmit(submitHandler, onError)}>
                                {userId &&
                                    <PrevAndNextButtons
                                        checkIsModified={checkIsModified}
                                        handleSubmit={handleSubmit}
                                        constructData={constructReqObj}
                                        setRedirectId={setUserId}
                                        editOptionData={editOptionData}
                                        setSelectedEditIndex={setSelectedEditIndex}
                                        selectedEditIndex={selectedEditIndex}
                                        getData={getuserDetails}
                                        reset={reset}
                                        resetObj={{}}
                                        from="users" />}
                                <div className="mb-4">
                                    <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                        Name
                                    </label>
                                    {/* <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="medicineName" type="text" /> */}
                                    <input className={formInputClassName} autoComplete='off' placeholder='English' id="diseaseType" name="name" {...register("name", { required: UserError.name })} />
                                    <input className={formInputClassName} autoComplete='off' placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} name="ar_name" {...register("ar_name", { required: false })} />
                                    <input className={formInputClassName} autoComplete='off' placeholder='Hebrew' name="hb_diseaseType" style={{ direction: "rtl", fontSize: "120%" }} {...register("hb_name", { required: false })} />

                                </div>
                                <div className="mb-4">
                                    <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                        Phone Number
                                    </label>
                                    {/* <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="medicineName" type="text" /> */}
                                    <input className={formInputClassName} type="number" autoComplete='off' placeholder='English' id="mobile" name="mobile" {...register("mobile", {
                                        required: UserError.phone,
                                        pattern: {
                                            value: /^(?:(?:(\+?972|\(\+?972\)|\+?\(972\))(?:\s|\.|-)?([1-9]\d?))|(0[23489]{1})|(0[57]{1}[0-9]))(?:\s|\.|-)?([^0\D]{1}\d{2}(?:\s|\.|-)?\d{4})$/,
                                            message: 'Enter valid mobile number'
                                        }
                                    })} />
                                </div>
                                <div className="mb-4">
                                    <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                        Hospital
                                    </label>
                                    <select data-te-select-init className="bg-gray-200 border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="hospital" name="hospital" {...register("hospital", { required: UserError.hospital })}>
                                        <option value="">Choose Hospital</option>
                                        {hospitalListStore.slice().reverse().map((val, index) => {
                                            return <option key={index} value={val._id}>{val.name}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="mb-4">
                                    <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                        Ward
                                    </label>
                                    <select data-te-select-init className="bg-gray-200 border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="ward" name="ward" {...register("ward", { required: UserError.ward })}>
                                        <option value="">Choose Ward</option>
                                        {wardListStore.slice().reverse().map((val, index) => {
                                            return <option key={index} value={val._id}>{val.name}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="mb-4">
                                    <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                        Age Group
                                    </label>
                                    <select data-te-select-init className="bg-gray-200 border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="ageGroup" name="ageGroup" {...register("ageGroup", { required: UserError.age })}>
                                        <option value="">Choose Hospital</option>
                                        {ageGroupListStore.slice().reverse().map((val, index) => {
                                            return <option key={index} value={val._id}>{val.name}</option>
                                        })}
                                    </select>
                                </div>

                                <div className="flex justify-between items-center mt-4">
                                    <a href='javascript:void(0);' className={buttonCSS.blue} onClick={handleSubmit((data) => saveAndExit(data), (err) => onError(err))}>Save & Exit</a>
                                    <a href='javascript:void(0);' className={buttonCSS.green} onClick={handleSubmit((data) => saveAndNew(data), (err) => onError(err))}>Save & New</a>
                                    <a href='javascript:void(0);' className={buttonCSS.red} onClick={() => discardChanges()}>Discard Changes</a>
                                </div>
                                {userId &&
                                    <PrevAndNextButtons
                                        checkIsModified={checkIsModified}
                                        handleSubmit={handleSubmit}
                                        constructData={constructReqObj}
                                        setRedirectId={setUserId}
                                        editOptionData={editOptionData}
                                        setSelectedEditIndex={setSelectedEditIndex}
                                        selectedEditIndex={selectedEditIndex}
                                        getData={getuserDetails}
                                        reset={reset}
                                        resetObj={{}}
                                        from="users" />}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Users