import React from 'react'
import Userheader from './Userheader'
import {
    Animate,
    initTE,
} from "tw-elements";
import { useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const Introduction = () => {

    useEffect(() => {
        initTE({ Animate });
    }, [])
    return (
        <div>
            <div className="bg-gray-100 h-full bg-gradient-to-r from-violet-200 to-pink-200" >
                <Userheader />
                <div className="container mx-auto py-8 px-4">
                    <div className="mb-8 h-auto">
                        <h1 className="text-3xl font-bold text-center mb-4">INTRODUCTION</h1>
                        <h2 className="text-xl text-gray-600 text-center mb-6">JOYFUL HEALING JOURNEY</h2>

                        <div className="grid grid-cols-1 md:grid-cols-1 gap-1">
                            <div className="flex justify-start space-x-4 p-5">
                                <img src="../assets/img/pageimages/unity.png" alt="Point Icon" className="w-44 h-44" />
                                <div className='flex justify-center items-center'>
                                    <h3 className="text-4xl font-bold bg-gradient-to-r from-fuchsia-500 to-indigo-900 bg-clip-text text-transparent">Welcome to our immersive Unity game designed to bring joy to hospitalized kids.</h3>
                                </div>
                            </div>
                            <div className="flex justify-start space-x-4 p-5">
                                <img src="../assets/img/pageimages/tadam.png" alt="Point Icon" className="w-44 h-44" />
                                <div className='flex justify-center items-center'>
                                    <h3 className="text-4xl font-bold bg-gradient-to-r from-fuchsia-500 to-indigo-900 bg-clip-text text-transparent">Introduce interactive 3D gameplay that introduces hospital staff, procedures, and the ward through engaging videos.</h3>

                                </div>
                            </div>
                            <div className="flex justify-start space-x-4 p-5">
                                <img src="../assets/img/pageimages/sthescope.png" alt="Point Icon" className="w-44 h-44" />
                                <div className='flex justify-center items-center'>
                                    <h3 className="text-4xl font-bold bg-gradient-to-r from-fuchsia-500 to-indigo-900 bg-clip-text text-transparent">A 3D game that helps making the hospital experience enjoyable and informative.</h3>

                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="my-8 border-t-2 border-gray-300" />


                    <div className="mb-8 h-auto"
                        data-te-animation-init
                        data-te-animation-start="onScroll"
                        // data-te-animation-on-scroll="repeat"
                        data-te-animation-reset="true"
                        data-te-animation="[zoom-in_1s_ease-in-out]">
                        <img src="../assets/img/pageimages/Challenge1.png" alt="Point Icon" className="" />

                    </div>
                    <hr className="my-8 border-t-2 border-gray-300" />


                    <div className="mb-8 h-auto"
                        data-te-animation-init
                        data-te-animation-start="onScroll"
                        data-te-animation-reset="true"
                        data-te-animation="[zoom-in_1s_ease-in-out]">
                        <h1 className="text-3xl font-bold text-center mb-4">OUR SOLUTION</h1>
                        <h2 className="text-xl text-gray-600 text-center mb-6">Empowering Kids Through Play</h2>

                        <h2 className="mb-2 text-2xl font-semibold text-gray-900 dark:text-white">Showcase key features:</h2>
                        <ul className="space-y-4 text-left text-gray-500 dark:text-gray-400 mt-7">
                            <li className="flex items-center space-x-3 rtl:space-x-reverse pt-12">
                                <svg className="flex-shrink-0 w-32 h-32 text-green-500 dark:text-green-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5" />
                                </svg>
                                <span className='font-bold text-3xl' >Engage children with interactive 3D gameplay that introduces hospital staff and provides informative videos.</span>
                            </li>
                            <li className="flex items-center space-x-3 rtl:space-x-reverse pt-12">
                                <svg className="flex-shrink-0 w-32 h-32 text-green-500 dark:text-green-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5" />
                                </svg>
                                <span className='font-bold text-3xl'>Deliver monitored and moderated content offering invaluable insights about procedures, the ward, and medical tools.</span>
                            </li>
                            <li className="flex items-center space-x-3 rtl:space-x-reverse pt-12">
                                <svg className="flex-shrink-0 w-32 h-32 text-green-500 dark:text-green-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5" />
                                </svg>
                                <span className='font-bold text-3xl'>Present gamified follow-up mechanisms and reminders on treatments and medications, creating an engaging and educational experience.</span>
                            </li>
                            {/* <li className="flex items-center space-x-3 rtl:space-x-reverse">
                                <svg className="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5" />
                                </svg>
                                <span>Premium support: <span className="font-semibold text-gray-900 dark:text-white">6 months</span></span>
                            </li> */}
                        </ul>

                    </div>
                    <hr className="my-8 border-t-2 border-gray-300" />


                    <div className="mb-8 h-auto"
                        data-te-animation-init
                        data-te-animation-start="onScroll"
                        data-te-animation-reset="true"
                        data-te-animation="[zoom-in_1s_ease-in-out]">
                        <h1 className="text-3xl font-bold text-center mb-4">FUN GAME - QUEST</h1>
                        <h2 className="text-xl text-gray-600 text-center mb-6">Integrate game challenges with enticing incentives, elevating the overall patient experience and satisfaction.</h2>


                        <Carousel autoPlay infiniteLoop>
                            <div>
                                <img src="../assets/img/pageimages/fungame1.png" />
                                {/* <p className="legend">Legend 1</p> */}
                            </div>
                            <div>
                                <img src="../assets/img/pageimages/fungame2.png" />
                                {/* <p className="legend">Legend 2</p> */}
                            </div>
                            <div>
                                <img src="../assets/img/pageimages/fungame3.png" />
                                {/* <p className="legend">Legend 3</p> */}
                            </div>
                            <div>
                                <img src="../assets/img/pageimages/fungame4.png" />
                                {/* <p className="legend">Legend 3</p> */}
                            </div>
                        </Carousel>

                        {/* <div id="default-carousel" className="relative w-full" data-carousel="slide">
                            <div className="relative h-56 overflow-hidden rounded-lg md:h-96">
                                <div className="hidden duration-700 ease-in-out" data-carousel-item>
                                    <img src="../assets/img/pageimages/fungame1.png" className="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="..." />
                                </div>
                                <div className="hidden duration-700 ease-in-out" data-carousel-item>
                                    <img src="../assets/img/pageimages/fungame2.png" className="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="..." />
                                </div>
                                <div className="hidden duration-700 ease-in-out" data-carousel-item>
                                    <img src="../assets/img/pageimages/fungame3.png" className="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="..." />
                                </div>
                                <div className="hidden duration-700 ease-in-out" data-carousel-item>
                                    <img src="../assets/img/pageimages/fungame4.png" className="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="..." />
                                </div>
                            </div>
                            <div className="absolute z-30 flex -translate-x-1/2 bottom-5 left-1/2 space-x-3 rtl:space-x-reverse">
                                <button type="button" className="w-3 h-3 rounded-full" aria-current="true" aria-label="Slide 1" data-carousel-slide-to="0"></button>
                                <button type="button" className="w-3 h-3 rounded-full" aria-current="false" aria-label="Slide 2" data-carousel-slide-to="1"></button>
                                <button type="button" className="w-3 h-3 rounded-full" aria-current="false" aria-label="Slide 3" data-carousel-slide-to="2"></button>
                                <button type="button" className="w-3 h-3 rounded-full" aria-current="false" aria-label="Slide 4" data-carousel-slide-to="3"></button>
                                <button type="button" className="w-3 h-3 rounded-full" aria-current="false" aria-label="Slide 5" data-carousel-slide-to="4"></button>
                            </div>
                            <button type="button" className="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-prev>
                                <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                                    <svg className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4" />
                                    </svg>
                                    <span className="sr-only">Previous</span>
                                </span>
                            </button>
                            <button type="button" className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-next>
                                <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                                    <svg className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                                    </svg>
                                    <span className="sr-only">Next</span>
                                </span>
                            </button>
                        </div> */}
                        <div className="grid md:grid-cols-1 lg:grid-cols-5 xl:grid-cols-5 sm:grid-cols-1 gap-1 mt-6">
                            <div>
                                <div
                                    className="block max-w-[18rem] sm:max-w-full h-full rounded-lg bg-gray-100">
                                    <div className="relative overflow-hidden bg-cover bg-no-repeat">
                                        <div className="hero container rounded-full max-w-screen-lg mx-auto bg-pink-200 w-36 h-36 mt-5 flex justify-center items-center">
                                            <img className="mx-auto h-[80%] w-[70%]" src="../assets/img/pageimages/collectcoin.png" alt="screenshot" />
                                        </div>
                                    </div>
                                    <div className="p-6">
                                        <h1 className="text-2xl font-bold text-center mb-4">Collect Coins</h1>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div
                                    className="block max-w-[18rem] sm:max-w-full h-full rounded-lg bg-gray-100">
                                    <div className="relative overflow-hidden bg-cover bg-no-repeat">
                                        <div className="hero container rounded-full max-w-screen-lg mx-auto bg-pink-200 w-36 h-36 mt-5 flex justify-center items-center">
                                            <img className="mx-auto h-[80%] w-[70%]" src="../assets/img/pageimages/solve.png" alt="screenshot" />
                                        </div>
                                    </div>
                                    <div className="p-6">
                                        <h1 className="text-2xl font-bold text-center mb-4">Solve Riddles & Challenges</h1>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div
                                    className="block max-w-[18rem] sm:max-w-full h-full rounded-lg bg-gray-100">
                                    <div className="relative overflow-hidden bg-cover bg-no-repeat">
                                        <div className="hero container rounded-full max-w-screen-lg mx-auto bg-pink-200 w-36 h-36 mt-5 flex justify-center items-center">
                                            <img className="mx-auto h-[80%] w-[70%]" src="../assets/img/pageimages/mobile.png" alt="screenshot" />
                                        </div>
                                    </div>
                                    <div className="p-6">
                                        <h1 className="text-2xl font-bold text-center mb-4">Collect Free Extra Mobile Games</h1>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div
                                    className="block max-w-[18rem] sm:max-w-full h-full rounded-lg bg-gray-100">
                                    <div className="relative overflow-hidden bg-cover bg-no-repeat">
                                        <div className="hero container rounded-full max-w-screen-lg mx-auto bg-pink-200 w-36 h-36 mt-5 flex justify-center items-center">
                                            <img className="mx-auto h-[80%] w-[70%]" src="../assets/img/pageimages/video.png" alt="screenshot" />
                                        </div>
                                    </div>
                                    <div className="p-6">
                                        <h1 className="text-2xl font-bold text-center mb-4">Meet the Staff through Videos</h1>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div
                                    className="block max-w-[18rem] sm:max-w-full h-full rounded-lg bg-gray-100">
                                    <div className="relative overflow-hidden bg-cover bg-no-repeat">
                                        <div className="hero container rounded-full max-w-screen-lg mx-auto bg-pink-200 w-36 h-36 mt-5 flex justify-center items-center">
                                            <img className="mx-auto h-[80%] w-[70%]" src="../assets/img/pageimages/gift.png" alt="screenshot" />
                                        </div>
                                    </div>
                                    <div className="p-6">
                                        <h1 className="text-2xl font-bold text-center mb-4">Incentive giftshop</h1>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                    <hr className="my-8 border-t-2 border-gray-300" />


                    <div className="mb-8 h-auto"
                        data-te-animation-init
                        data-te-animation-start="onScroll"
                        data-te-animation-reset="true"
                        data-te-animation="[zoom-in_1s_ease-in-out]">
                        <h1 className="text-3xl font-bold text-center mb-4">INFORMATIVE GAME - JOURNEY</h1>
                        <h2 className="text-xl text-gray-600 text-center mb-6">the game effectively transforms the hospitalization process into a joyful and informative journey for kids</h2>
                        <Carousel autoPlay infiniteLoop>
                            <div>
                                <img src="../assets/img/pageimages/infogame1.png" />
                                {/* <p className="legend">Legend 1</p> */}
                            </div>
                            <div>
                                <img src="../assets/img/pageimages/infogame2.png" />
                                {/* <p className="legend">Legend 2</p> */}
                            </div>
                            <div>
                                <img src="../assets/img/pageimages/infogame3.png" />
                                {/* <p className="legend">Legend 3</p> */}
                            </div>
                            <div>
                                <img src="../assets/img/pageimages/infogame4.png" />
                                {/* <p className="legend">Legend 3</p> */}
                            </div>
                        </Carousel>



                        {/* <div id="default-carousel" className="relative w-full" data-carousel="slide">
                            <div className="relative h-56 overflow-hidden rounded-lg md:h-96">
                                <div className="hidden duration-700 ease-in-out" data-carousel-item>
                                    <img src="../assets/img/pageimages/infogame1.png" className="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="..." />
                                </div>
                                <div className="hidden duration-700 ease-in-out" data-carousel-item>
                                    <img src="../assets/img/pageimages/infogame2.png" className="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="..." />
                                </div>
                                <div className="hidden duration-700 ease-in-out" data-carousel-item>
                                    <img src="../assets/img/pageimages/infogame3.png" className="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="..." />
                                </div>
                                <div className="hidden duration-700 ease-in-out" data-carousel-item>
                                    <img src="../assets/img/pageimages/infogame4.png" className="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="..." />
                                </div>
                            </div>
                            <div className="absolute z-30 flex -translate-x-1/2 bottom-5 left-1/2 space-x-3 rtl:space-x-reverse">
                                <button type="button" className="w-3 h-3 rounded-full" aria-current="true" aria-label="Slide 1" data-carousel-slide-to="0"></button>
                                <button type="button" className="w-3 h-3 rounded-full" aria-current="false" aria-label="Slide 2" data-carousel-slide-to="1"></button>
                                <button type="button" className="w-3 h-3 rounded-full" aria-current="false" aria-label="Slide 3" data-carousel-slide-to="2"></button>
                                <button type="button" className="w-3 h-3 rounded-full" aria-current="false" aria-label="Slide 4" data-carousel-slide-to="3"></button>
                                <button type="button" className="w-3 h-3 rounded-full" aria-current="false" aria-label="Slide 5" data-carousel-slide-to="4"></button>
                            </div>
                            <button type="button" className="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-prev>
                                <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                                    <svg className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4" />
                                    </svg>
                                    <span className="sr-only">Previous</span>
                                </span>
                            </button>
                            <button type="button" className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-next>
                                <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                                    <svg className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                                    </svg>
                                    <span className="sr-only">Next</span>
                                </span>
                            </button>
                        </div> */}
                        <div className="grid md:grid-cols-1 lg:grid-cols-5 xl:grid-cols-5 grid-cols-1 gap-1 mt-6">
                            <div>
                                <div
                                    className="block max-w-[18rem] sm:max-w-full h-full rounded-lg bg-gray-100">
                                    <div className="relative overflow-hidden bg-cover bg-no-repeat">
                                        <div className="hero container rounded-full max-w-screen-lg mx-auto bg-pink-200 w-36 h-36 mt-5 flex justify-center items-center">
                                            <img className="mx-auto h-[80%] w-[70%]" src="../assets/img/pageimages/collectcoin.png" alt="screenshot" />
                                        </div>
                                    </div>
                                    <div className="p-6">
                                        <h1 className="text-2xl font-bold text-center mb-4">Convert Coins to Medals & Trophies</h1>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div
                                    className="block max-w-[18rem] sm:max-w-full h-full rounded-lg bg-gray-100">
                                    <div className="relative overflow-hidden bg-cover bg-no-repeat">
                                        <div className="hero container rounded-full max-w-screen-lg mx-auto bg-pink-200 w-36 h-36 mt-5 flex justify-center items-center">
                                            <img className="mx-auto h-[80%] w-[70%]" src="../assets/img/pageimages/infogamejourney.png" alt="screenshot" />
                                        </div>
                                    </div>
                                    <div className="p-6">
                                        <h1 className="text-2xl font-bold text-center mb-4">My Journey</h1>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div
                                    className="block max-w-[18rem] sm:max-w-full h-full rounded-lg bg-gray-100">
                                    <div className="relative overflow-hidden bg-cover bg-no-repeat">
                                        <div className="hero container rounded-full max-w-screen-lg mx-auto bg-pink-200 w-36 h-36 mt-5 flex justify-center items-center">
                                            <img className="mx-auto h-[80%] w-[70%]" src="../assets/img/pageimages/infogameday.png" alt="screenshot" />
                                        </div>
                                    </div>
                                    <div className="p-6">
                                        <h1 className="text-2xl font-bold text-center mb-4">My Day</h1>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div
                                    className="block max-w-[18rem] sm:max-w-full h-full rounded-lg bg-gray-100">
                                    <div className="relative overflow-hidden bg-cover bg-no-repeat">
                                        <div className="hero container rounded-full max-w-screen-lg mx-auto bg-pink-200 w-36 h-36 mt-5 flex justify-center items-center">
                                            <img className="mx-auto h-[80%] w-[70%]" src="../assets/img/pageimages/infogameremainder.png" alt="screenshot" />
                                        </div>
                                    </div>
                                    <div className="p-6">
                                        <h1 className="text-2xl font-bold text-center mb-4">Reminders</h1>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div
                                    className="block max-w-[18rem] sm:max-w-full h-full rounded-lg bg-gray-100">
                                    <div className="relative overflow-hidden bg-cover bg-no-repeat">
                                        <div className="hero container rounded-full max-w-screen-lg mx-auto bg-pink-200 w-36 h-36 mt-5 flex justify-center items-center">
                                            <img className="mx-auto h-[80%] w-[70%]" src="../assets/img/pageimages/infogamevideo.png" alt="screenshot" />
                                        </div>
                                    </div>
                                    <div className="p-6">
                                        <h1 className="text-2xl font-bold text-center mb-4">Informative Videos</h1>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                    <hr className="my-8 border-t-2 border-gray-300" />


                    <div className="mb-8 h-auto"
                        data-te-animation-init
                        data-te-animation-start="onScroll"
                        data-te-animation-reset="true"
                        data-te-animation="[zoom-in_1s_ease-in-out]">
                        <h1 className="text-3xl font-bold text-center mb-4">BENEFITS FOR HOSPITAL & STAFF</h1>
                        <h2 className="text-xl text-gray-600 text-center mb-6">Efficient Communication, Happy Patients</h2>

                        <h2 className="mb-2 text-2xl font-semibold text-gray-900 dark:text-white">Demonstrate advantages for hospitals & staff:</h2>
                        <ul className="space-y-4 text-left text-gray-500 dark:text-gray-400 mt-7">
                            <li className="flex items-center space-x-3 rtl:space-x-reverse pt-12">
                                <svg className="flex-shrink-0 w-32 h-32 text-green-500 dark:text-green-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5" />
                                </svg>
                                <span className="font-bold text-3xl">Streamline communication with patients through the interactive game, reducing the time and effort required to explain procedures and introduce staff</span>
                            </li>
                            <li className="flex items-center space-x-3 rtl:space-x-reverse pt-12">
                                <svg className="flex-shrink-0 w-32 h-32 text-green-500 dark:text-green-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5" />
                                </svg>
                                <span className="font-bold text-3xl">Enhance patient engagement, resulting in more positive health outcomes</span>
                            </li>
                            <li className="flex items-center space-x-3 rtl:space-x-reverse pt-12">
                                <svg className="flex-shrink-0 w-32 h-32 text-green-500 dark:text-green-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5" />
                                </svg>
                                <span className="font-bold text-3xl">Integrate game challenges with enticing incentives, elevating the overall patient experience and satisfaction</span>
                            </li>
                            {/* <li className="flex items-center space-x-3 rtl:space-x-reverse">
            <svg className="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5" />
            </svg>
            <span>Premium support: <span className="font-semibold text-gray-900 dark:text-white">6 months</span></span>
        </li> */}
                        </ul>

                    </div>
                    <hr className="my-8 border-t-2 border-gray-300" />


                    <div className="mb-8 h-auto"
                        data-te-animation-init
                        data-te-animation-start="onScroll"
                        data-te-animation-reset="true"
                        data-te-animation="[zoom-in_1s_ease-in-out]">
                        <img src="../assets/img/pageimages/parentsbenefits.PNG" alt="Point Icon" className="" />

                    </div>
                    <hr className="my-8 border-t-2 border-gray-300" />


                    <div className="mb-8 h-auto"
                        data-te-animation-init
                        data-te-animation-start="onScroll"
                        data-te-animation-reset="true"
                        data-te-animation="[zoom-in_1s_ease-in-out]">
                        <h1 className="text-3xl font-bold text-center mb-4">STAFF BENEFITS</h1>
                        <h2 className="text-xl text-gray-600 text-center mb-6">Integrate game challenges with enticing incentives, elevating the overall patient experience and satisfaction.</h2>





                        <div className="grid md:grid-cols-2 lg:grid-cols-6 xl:grid-cols-6 grid-cols-2 gap-1 mt-6">
                            <div>
                                <div
                                    className="block max-w-[18rem] sm:max-w-full h-full rounded-lg bg-gray-100">
                                    <div className="p-6 h-24">
                                        <h1 className="text-lg font-bold text-center mb-4">Efficient Protocol Explanation</h1>
                                    </div>
                                    <div className="relative overflow-hidden bg-cover bg-no-repeat">
                                        <div className="hero container rounded-full max-w-screen-lg mx-auto bg-pink-900 w-36 h-36 mt-5 flex justify-center items-center">
                                            <img className="mx-auto h-[50%] w-[50%]" src="../assets/img/pageimages/staffclock.png" alt="screenshot" />
                                        </div>
                                    </div>
                                    <div className="p-6">
                                        <h1 className="text-xl font-bold text-center mb-4">Saves time by eliminating repetitive tasks</h1>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div
                                    className="block max-w-[18rem] sm:max-w-full h-full rounded-lg bg-gray-100">
                                    <div className="p-6 h-24">
                                        <h1 className="text-lg font-bold text-center mb-4">Streamlined Knowledge Transfer</h1>
                                    </div>
                                    <div className="relative overflow-hidden bg-cover bg-no-repeat">
                                        <div className="hero container rounded-full max-w-screen-lg mx-auto bg-violet-900 w-36 h-36 mt-5 flex justify-center items-center">
                                            <img className="mx-auto h-[50%] w-[50%]" src="../assets/img/pageimages/staffperson.png" alt="screenshot" />
                                        </div>
                                    </div>
                                    <div className="p-6">
                                        <h1 className="text-xl font-bold text-center mb-4">Accelerates the exchange of information from staff to patients, reducing the need for repeated patient inquiries</h1>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div
                                    className="block max-w-[18rem] sm:max-w-full h-full rounded-lg bg-gray-100">
                                    <div className="p-6 h-24">
                                        <h1 className="text-lg font-bold text-center mb-4">Reduced Workload</h1>
                                    </div>
                                    <div className="relative overflow-hidden bg-cover bg-no-repeat">
                                        <div className="hero container rounded-full max-w-screen-lg mx-auto bg-indigo-500 w-36 h-36 mt-5 flex justify-center items-center">
                                            <img className="mx-auto h-[50%] w-[50%]" src="../assets/img/pageimages/staffchart.png" alt="screenshot" />
                                        </div>
                                    </div>
                                    <div className="p-6">
                                        <h1 className="text-xl font-bold text-center mb-4">Leads to happier staff with fewer tasks to manage</h1>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div
                                    className="block max-w-[18rem] sm:max-w-full h-full rounded-lg bg-gray-100">
                                    <div className="p-6 h-24">
                                        <h1 className="text-lg font-bold text-center mb-4">Improved Staff-Patient Interaction</h1>
                                    </div>
                                    <div className="relative overflow-hidden bg-cover bg-no-repeat">
                                        <div className="hero container rounded-full max-w-screen-lg mx-auto bg-blue-500 w-36 h-36 mt-5 flex justify-center items-center">
                                            <img className="mx-auto h-[50%] w-[50%]" src="../assets/img/pageimages/staffhome.png" alt="screenshot" />
                                        </div>
                                    </div>
                                    <div className="p-6">
                                        <h1 className="text-xl font-bold text-center mb-4">Enhances communication, helping staff break the ice with patients more quickly</h1>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div
                                    className="block max-w-[18rem] sm:max-w-full h-full rounded-lg bg-gray-100">
                                    <div className="p-6 h-24">
                                        <h1 className="text-lg font-bold text-center mb-4">Medication Monitoring</h1>
                                    </div>
                                    <div className="relative overflow-hidden bg-cover bg-no-repeat">
                                        <div className="hero container rounded-full max-w-screen-lg mx-auto bg-sky-600 w-36 h-36 mt-5 flex justify-center items-center">
                                            <img className="mx-auto h-[50%] w-[50%]" src="../assets/img/pageimages/staffmedicine.png" alt="screenshot" />
                                        </div>
                                    </div>
                                    <div className="p-6">
                                        <h1 className="text-xl font-bold text-center mb-4">Facilitates tracking of patients' medication, both during day care and at home, using a gamified approach</h1>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div
                                    className="block max-w-[18rem] sm:max-w-full h-full rounded-lg bg-gray-100">
                                    <div className="p-6 h-24">
                                        <h1 className="text-lg font-bold text-center mb-4">Emotional Monitoring</h1>
                                    </div>
                                    <div className="relative overflow-hidden bg-cover bg-no-repeat">
                                        <div className="hero container rounded-full max-w-screen-lg mx-auto bg-pink-900 w-36 h-36 mt-5 flex justify-center items-center">
                                            <img className="mx-auto h-[50%] w-[50%]" src="../assets/img/pageimages/staffsmile.png" alt="screenshot" />
                                        </div>
                                    </div>
                                    <div className="p-6">
                                        <h1 className="text-xl font-bold text-center mb-4">Enables staff to monitor patients' moods, emotional tone, and sentiments through video interactions</h1>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                    <hr className="my-8 border-t-2 border-gray-300" />
                    <div className="mb-8 h-auto"
                        data-te-animation-init
                        data-te-animation-start="onScroll"
                        data-te-animation-reset="true"
                        data-te-animation="[zoom-in_1s_ease-in-out]">
                        <h1 className="text-3xl font-bold text-center mb-4">HOSPITAL BENEFITS</h1>

                        <div className="grid grid-cols-1 md:grid-cols-1 gap-1">
                            <div className="flex space-x-4 p-5">
                                <img src="../assets/img/pageimages/hospitalsavings.png" alt="Point Icon" className="w-24 h-24" />
                                <div className='flex justify-center items-center'>
                                    <h3 className="font-normal text-2xl"><strong>Time and Cost Savings:</strong> Implementation of technological solutions results in significant time and cost savings</h3>
                                </div>
                            </div>
                            <div className="flex space-x-4 p-5">
                                <img src="../assets/img/pageimages/hospitalperson.png" alt="Point Icon" className="w-24 h-24" />
                                <div className='flex justify-center items-center'>
                                    <h3 className="font-normal text-2xl"><strong>Enhanced Staff Satisfaction and Performance:</strong> Boosts staff morale, leading to improved overall performance</h3>
                                </div>
                            </div>
                            <div className="flex space-x-4 p-5">
                                <img src="../assets/img/pageimages/hospitalsmile.png" alt="Point Icon" className="w-24 h-24" />
                                <div className='flex justify-center items-center'>
                                    <h3 className="font-normal text-2xl"><strong>Increased Patient Satisfaction:</strong> Happier patients contribute to a higher hospital ranking</h3>
                                </div>
                            </div>
                            <div className="flex space-x-4 p-5">
                                <img src="../assets/img/pageimages/hospitalhome.png" alt="Point Icon" className="w-24 h-24" />
                                <div className='flex justify-center items-center'>
                                    <h3 className="font-normal text-2xl"><strong>Improved Medical Treatment:</strong> Facilitates better medical treatment for patients with enhanced data tracking and analysis</h3>
                                </div>
                            </div>
                            <div className="flex space-x-4 p-5">
                                <img src="../assets/img/pageimages/hospitaldoc.png" alt="Point Icon" className="w-24 h-24" />
                                <div className='flex justify-center items-center'>
                                    <h3 className="font-normal text-2xl"><strong>Enhanced Understanding of Patient Mental State:</strong> Provides valuable insights into patients' states of mind, easing the workload for the professional team</h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr className="my-8 border-t-2 border-gray-300" />


                    <div className="mb-8 h-auto"
                        data-te-animation-init
                        data-te-animation-start="onScroll"
                        data-te-animation-reset="true"
                        data-te-animation="[zoom-in_1s_ease-in-out]">
                        <h1 className="text-3xl font-bold text-center mb-4">TRANSFORMING PEDIATRIC CARE</h1>
                        <h2 className="text-xl text-gray-600 text-center mb-6">Join the Journey</h2>

                        {/* <h2 className="mb-2 text-lg font-semibold text-gray-900 dark:text-white">Showcase key features:</h2> */}
                        <ul className="space-y-4 text-left text-gray-500 dark:text-gray-400 mt-7">
                            <li className="flex items-center space-x-3 rtl:space-x-reverse pt-12">
                                <svg className="flex-shrink-0 w-32 h-32 text-green-500 dark:text-green-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5" />
                                </svg>
                                <span className="font-normal text-2xl">Elevate the pediatric care experience by embracing our Unity game</span>
                            </li>
                            <li className="flex items-center space-x-3 rtl:space-x-reverse pt-12">
                                <svg className="flex-shrink-0 w-32 h-32 text-green-500 dark:text-green-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5" />
                                </svg>
                                <span className="font-normal text-2xl">This innovative solution can bring unparalleled joy and valuable information to the hospital environment</span>
                            </li>
                            <li className="flex items-center space-x-3 rtl:space-x-reverse pt-12">
                                <svg className="flex-shrink-0 w-32 h-32 text-green-500 dark:text-green-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5" />
                                </svg>
                                <span className="font-normal text-2xl">Let's embark on this transformative journey together, making a positive impact on the well-being of our young patients and the efficiency of the healthcare staff</span>
                            </li>
                            {/* <li className="flex items-center space-x-3 rtl:space-x-reverse">
                                <svg className="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5" />
                                </svg>
                                <span>Premium support: <span className="font-semibold text-gray-900 dark:text-white">6 months</span></span>
                            </li> */}
                        </ul>

                    </div>
                    <hr className="my-8 border-t-2 border-gray-300" />


                    <div className="mb-8"
                        data-te-animation-init
                        data-te-animation-start="onScroll"
                        data-te-animation-reset="true"
                        data-te-animation="[zoom-in_1s_ease-in-out]">
                        <h1 className="text-3xl font-bold text-center mb-4">INCENTIVES FOR PATIENTS</h1>
                        <div className="grid grid-cols-3 mt-6">
                            <div className='p-4'>
                                <div className="block  rounded-lg bg-gray-100 h-80">
                                    <div className="relative overflow-hidden bg-cover bg-no-repeat flex justify-center items-center">
                                        <img
                                            className="rounded-t-lg"
                                            src="../assets/img/pageimages/incentivecoin.png"
                                            alt="" />
                                    </div>
                                    <div className="p-6 flex justify-center items-center">
                                        <p className="text-lg font-bold text-neutral-600 dark:text-neutral-200">
                                            Coins as positive reinforcement for medications, protocols and processes
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='p-4'>
                                <div className="block rounded-lg bg-gray-100 h-80">
                                    <div className="relative overflow-hidden bg-cover bg-no-repeat flex justify-center items-center">
                                        <img
                                            className="rounded-t-lg"
                                            src="../assets/img/pageimages/incentivemedal.png"
                                            alt="" />
                                    </div>
                                    <div className="p-6 flex justify-center items-center">
                                        <p className="text-lg font-bold text-neutral-600 dark:text-neutral-200">
                                            Convert coins to medals and trophies
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='p-4'>
                                <div
                                    className="block rounded-lg bg-gray-100 h-80">
                                    <div className="relative overflow-hidden bg-cover bg-no-repeat flex justify-center items-center">
                                        <img
                                            className="rounded-t-lg"
                                            src="../assets/img/pageimages/incentivetrophy.png"
                                            alt="" />
                                    </div>
                                    <div className="p-6 flex justify-center items-center">
                                        <p className="text-lg font-bold text-neutral-600 dark:text-neutral-200">
                                            Leaderboard with medals and trophies
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="my-8 border-t-2 border-gray-300" />
                    <div className="mb-8 h-auto"
                        data-te-animation-init
                        data-te-animation-start="onScroll"
                        data-te-animation-reset="true"
                        data-te-animation="[zoom-in_1s_ease-in-out]">
                        <h1 className="text-3xl font-bold text-center mb-4"><strong>GIFTSHOP</strong> FOR KIDS</h1>
                        <div className="flex flex-row">
                            <div className='w-1/2'>
                                <div className="grid grid-cols-2 mt-6">
                                    <div className='p-2 flex justify-end items-end'>
                                        <img src="../assets/img/pageimages/gift1.png" alt="Point Icon" className="w-14 h-14" />
                                    </div>
                                    <div className='p-2 flex justify-start items-center'>
                                        <p className="text-lg font-bold text-neutral-600 dark:text-neutral-200">
                                            Dolls - All kind, all ages
                                        </p>
                                    </div>
                                    <div className='p-2 flex justify-end items-end'>
                                        <img src="../assets/img/pageimages/gift2.png" alt="Point Icon" className="w-14 h-14" />
                                    </div>
                                    <div className='p-2 flex justify-start items-center'>
                                        <p className="text-lg font-bold text-neutral-600 dark:text-neutral-200">
                                            T-Shirts & Towels
                                        </p>
                                    </div>
                                    <div className='p-2 flex justify-end items-end'>
                                        <img src="../assets/img/pageimages/gift3.png" alt="Point Icon" className="w-14 h-14" />
                                    </div>
                                    <div className='p-2 flex justify-start items-center'>
                                        <p className="text-lg font-bold text-neutral-600 dark:text-neutral-200">
                                            Board Games
                                        </p>
                                    </div>
                                    <div className='p-2 flex justify-end items-end'>
                                        <img src="../assets/img/pageimages/gift4.png" alt="Point Icon" className="w-14 h-14" />
                                    </div>
                                    <div className='p-2 flex justify-start items-center'>
                                        <p className="text-lg font-bold text-neutral-600 dark:text-neutral-200">
                                            Sweats & Candies
                                        </p>
                                    </div>
                                    <div className='p-2 flex justify-end items-end'>
                                        <img src="../assets/img/pageimages/gift5.png" alt="Point Icon" className="w-14 h-14" />
                                    </div>
                                    <div className='p-2 flex justify-start items-center'>
                                        <p className="text-lg font-bold text-neutral-600 dark:text-neutral-200">
                                            Backpacks
                                        </p>
                                    </div>
                                    <div className='p-2 flex justify-end items-end'>
                                        <img src="../assets/img/pageimages/gift6.png" alt="Point Icon" className="w-14 h-14" />
                                    </div>
                                    <div className='p-2 flex justify-start items-center'>
                                        <p className="text-lg font-bold text-neutral-600 dark:text-neutral-200">
                                            Trending accessories

                                        </p>
                                    </div>
                                    <div className='p-2 flex justify-end items-end'>
                                        <img src="../assets/img/pageimages/gift7.png" alt="Point Icon" className="w-14 h-14" />
                                    </div>
                                    <div className='p-2 flex justify-start items-center'>
                                        <p className="text-lg font-bold text-neutral-600 dark:text-neutral-200">
                                            Computer & Tablets accessories
                                        </p>
                                    </div>
                                    <div className='p-2 flex justify-end items-end'>
                                        <img src="../assets/img/pageimages/gift8.png" alt="Point Icon" className="w-14 h-14" />
                                    </div>
                                    <div className='p-2 flex justify-start items-center'>
                                        <p className="text-lg font-bold text-neutral-600 dark:text-neutral-200">
                                            Mobile-phone accessories
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='w-1/2'>
                                <img src="../assets/img/pageimages/giftage1.png" alt="Point Icon" className='w-full' />
                            </div>
                        </div>
                        <p>* Gifts to be purchased by hospital, or donated to hospital</p>
                    </div>

                </div>

            </div>

        </div>
    )
}

export default Introduction