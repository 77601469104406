import React, { useEffect, useState } from 'react';
import { DahboardMenu } from '../DahboardMenu';
import { TopHeader } from '../TopHeader';
import { useNavigate } from 'react-router';
import { getGamesListAction, getStaffUpdatedAction } from '../../../Store/Action';
import { useDispatch, useSelector } from 'react-redux';
import DeleteItemPopup from '../../Popups/DeleteItemPopup';
import { toast } from 'react-toastify';
import { setBooksListStore, setEditOptionData, setGamesListStore } from '../../../Store/Reducer';
import { getBookListURL, getGameListURL, updateGameDetailsURL } from '../../../Api/ContantUrls';
import { FilterbyStringAsending, FilterbyStringDsending, FilterbyFiledAsending, FilterbyFiledDsending } from '../../../util/helper';
import { buttonCSS } from '../../../util/contant';
import InfiniteScroll from 'react-infinite-scroll-component';
import BooksList from '../Books/BooksList';
import { ThreeDots } from 'react-loader-spinner';


const GamesList = () => {
    const gamesListStore = useSelector(state => state?.gamesListStore)
    // const { gamesListStore } = store;
    const [gamesList, setGamesList] = useState([])
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [deleteData, setDeleteData] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState("");
    const [selectedId, setSelectedId] = useState("");
    const [isUpdate, setIsUpdate] = useState(false);
    const [orginalData, setOriginalData] = useState([]);

    const [gNameFilter, setGNameFilter] = useState(false);
    const [gDescFilter, setGDescFilter] = useState(false);
    const [callOnce, setCallOnce] = useState(false);

    const [hasMore, setHasMore] = useState(true)
    const [pageCount, setPageCount] = useState(20)
    const [pageNumber, setPageNumber] = useState(1)

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const editDataIds = [];

    const FilterbyTitleOrder = (a, b) => {
        if (a.name && b.name) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
            }
        }
        return 0;
    }

    useEffect(() => {
        //console.log("gamesListStore--------->", gamesList.length)
        if (gamesListStore.length === 0 && gamesList.length === 0) {
            getStaffs();
        }
        if (gamesListStore.length !== 0 && gamesList.length === 0) {
            setGamesList(gamesListStore.slice().sort(FilterbyTitleOrder));
            setOriginalData(gamesListStore.slice().sort(FilterbyTitleOrder));
            if (gamesListStore.length !== 0 && !callOnce) {
                gamesListStore.slice().sort(FilterbyTitleOrder).map((val) => {
                    editDataIds.push(val._id)
                })
                dispatch(setEditOptionData(editDataIds))
                setCallOnce(!callOnce);
            }
        }

    }, [gamesListStore, isUpdate])

    useEffect(() => () => {
        dispatch(setGamesListStore([]))
    }, []);
    const openDelete = (data, id, index) => {
        setDeleteData(data);
        setSelectedId(id);
        setSelectedIndex(index)
        setOpenDeletePopup(true);
    }

    const closeDeletePopup = () => {
        setOpenDeletePopup(false);
    }

    const getStaffs = async () => {
        const reqObj = {
            "getLatestFirst": true,
            "pageNumber": pageNumber,
            "numberOfRecordsPerPage": pageCount,
            "filter": {
                "active": true
            }
        }
        dispatch(await getGamesListAction(reqObj));
    }

    const deleteGift = async () => {
        const reqObj = {
            "bookId": selectedId,
            "dataToUpdate": {
                "active": false
            }
        }
        const response = await getStaffUpdatedAction(dispatch, updateGameDetailsURL, reqObj);
        if (response?.status === 200 && response?.data) {
            toast.success("Success")
            const existObj = JSON.parse(JSON.stringify(gamesList));
            // console.log("before--------->", existObj)
            // console.log("selectedIndex--------->", selectedIndex)
            // console.log("selectedIndex111--------->", typeof selectedIndex)
            existObj.splice(selectedIndex, 1);
            //console.log("after--------->", existObj)
            setGamesList(existObj);
            dispatch(setGamesListStore(existObj));
            setIsUpdate(!isUpdate)
        } else {
            toast.error("Failed")
        }
    }

    const filterData = (val, name) => {
        let check = val;
        let filterData = [];
        if (name === 'name') {
            setGNameFilter(!gNameFilter);
            if (check) {
                filterData = FilterbyFiledAsending(orginalData, name);
            } else {
                filterData = FilterbyFiledDsending(orginalData, name);
            }
        }
        if (name === 'description') {
            setGDescFilter(!gDescFilter);
            if (check) {
                filterData = FilterbyFiledAsending(orginalData, name);
            } else {
                filterData = FilterbyFiledDsending(orginalData, name);
            }
        }

        filterData.map((val) => {
            editDataIds.push(val?._id)
        })
        dispatch(setEditOptionData(editDataIds))
        setGamesList(filterData);
        setIsUpdate(!isUpdate);
    }

    const fetchMoreData = () => {
        setTimeout(async () => {
            const reqObj = {
                "getLatestFirst": true,
                "pageNumber": pageNumber + 1,
                "numberOfRecordsPerPage": pageCount,
                "filter": {
                    "active": true
                }
            }

            const response = await getStaffUpdatedAction(dispatch, getGameListURL, reqObj, false);
            if (response?.status === 200 && response?.data) {
                setPageNumber(pageNumber + 1)
                const { payload } = response?.data;
                //  const { staffList } = payload;
                if (payload?.gameList.length !== 0) {
                    const listData = gamesList.length === 0 ? gamesListStore : gamesList;
                    const gameDatas = [...listData, ...payload?.gameList]
                    setGamesList(gameDatas.slice().sort(FilterbyTitleOrder))

                    gameDatas.slice().sort(FilterbyTitleOrder).map(val => {
                        editDataIds.push(val._id)
                    })
                    dispatch(setEditOptionData(editDataIds))

                    if (payload?.gameList.length < pageCount) {
                        setHasMore(false)
                        dispatch(setGamesListStore(gameDatas.slice().sort(FilterbyTitleOrder)));
                    }
                } else {
                    setHasMore(false)
                }
                // setHasMore(true)

            } else {
                setGamesList(gamesList);
                setHasMore(false)
                // toast.error("Failed")
            }
            setIsUpdate(!isUpdate)
        }, 500);
    }

    return (
        <React.Fragment>
            <TopHeader />
            <DahboardMenu />
            {openDeletePopup && <DeleteItemPopup deleteData={deleteGift} closeModal={closeDeletePopup} modalIsOpen={openDeletePopup} isFrom="GameLevel" data={deleteData} />}

            <div className="mt-10 h-full" >
                <div className="
max-w-[540px] sm:max-w-[604px] md:max-w-[720px] lg:max-w-[972px]
xl:max-w-full xl:pl-24  xl:ml-40 xl:px-12 2xl:max-w-[1400px] mx-auto
flex">
                    <div className="flex flex-col h-full">
                        <div className="relative sm:rounded-lg">
                            <div className="flex justify-end pb-3">
                                <div id="sticky-banner" tabindex="-1" class="fixed top-16 z-50 flex">
                                    <div class="flex items-end mx-auto">
                                        <button className={buttonCSS.addbtn} onClick={() => navigate('/dashboard/games')}>
                                            <svg className="h-6 w-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                                            </svg>
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-wrp block">
                                <InfiniteScroll
                                    dataLength={gamesList.length}
                                    next={fetchMoreData}
                                    loader={gamesList.length !== 0 && <ThreeDots
                                        visible={true}
                                        height="80"
                                        width="80"
                                        color="#4fa94d"
                                        radius="9"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                    />}
                                    hasMore={hasMore}
                                    endMessage={
                                        <p style={{ textAlign: "center" }}>
                                            <b className='text-blue-500'>Yay! You have seen it all</b>
                                        </p>
                                    }
                                >
                                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 h-[500px] overflow-auto">
                                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0">
                                            <tr>
                                                <th scope="col" className="px-6 py-3">
                                                    <div className='flex items-center'>
                                                        <p> Name</p>
                                                        <a href='javascript:void(0);' onClick={() => filterData(gNameFilter, "name")}>
                                                            {gNameFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                            </svg> :
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                                </svg>}
                                                        </a>

                                                    </div>
                                                </th>
                                                <th scope="col" className="px-6 py-3">

                                                    <div className='flex items-center'>
                                                        <p> Description</p>
                                                        <a href='javascript:void(0);' onClick={() => filterData(gDescFilter, "description")}>
                                                            {gDescFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                            </svg> :
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                                </svg>}
                                                        </a>

                                                    </div>
                                                </th>

                                                <th scope="col" className="px-6 py-3">
                                                    PLAY STORE LINK
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    APPLE STORE LINK
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {gamesList.length !== 0 && gamesList.map((val, index) => {
                                                return <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                    <td scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{val?.name}</td>
                                                    <td scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-pre-wrap dark:text-white">{val?.description}</td>


                                                    <td scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-pre-wrap dark:text-white">
                                                        <a href={val.link} target="_blank">{val.link}</a>
                                                    </td>
                                                    <td scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-pre-wrap dark:text-white">
                                                        <a href={val.iosLink} target="_blank">{val.iosLink}</a>
                                                    </td>
                                                    <td>
                                                        <span><a href="javascript:void(0);" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => { navigate('/dashboard/games?id=' + val._id) }}>Edit</a> /&nbsp;
                                                            <a href="javascript:void(0);" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => { openDelete(val, val?._id, index) }}>Delete</a></span>
                                                    </td>
                                                </tr>
                                            })}
                                            {gamesList.length === 0 && <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                <th scope="row" colSpan={6} className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                    No data found
                                                </th>
                                            </tr>}
                                        </tbody>
                                    </table>
                                </InfiniteScroll>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}

export default GamesList