import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getAgeGroupListAction, getHospitalListAction, getWardListAction, getGameLevelListAction, getStaffUpdatedAction, getUpdateGameLevelDetailsAction, getStaffListAction, getBooksListAction, getGamesListAction, getAllChallegesAction, checkFileExistOrNotAction } from '../../Store/Action';
import { buttonCSS, formInputClassName } from '../../util/contant';
import { DahboardMenu } from '../backOffice/DahboardMenu';
import { TopHeader } from '../backOffice/TopHeader';
import { Accordion } from 'flowbite';
import DeleteItemPopup from '../Popups/DeleteItemPopup';
import {
    Collapse,
    Ripple,
    initTE,

} from "tw-elements";
import { useNavigate } from 'react-router';
import { setEditOptionData } from '../../Store/Reducer';
import ImagePopup from '../Popups/ImagePopup';
import { updateGameLevelDetailsURL, createGameLevelURL } from '../../Api/ContantUrls';
import { FilterbyFiledDsending, FilterbyFiledAsending, FilterbyStringAsending, FilterbyStringDsending } from '../../util/helper';
import ChallengePopup from '../Popups/ChallengePopup';
const inputClass = "block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 dark:bg-gray-700 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer";

const GameLevel = () => {
    // const store = useSelector(state => state);
    // const { ageGroupListStore, hospitalListStore, wardListStore, staffListStore, booksListStore, gamesListStore } = store;
    const ageGroupListStore = useSelector(state => state?.ageGroupListStore);
    const hospitalListStore = useSelector(state => state?.hospitalListStore);
    const wardListStore = useSelector(state => state?.wardListStore);
    const staffListStore = useSelector(state => state?.staffListStore);
    const booksListStore = useSelector(state => state?.booksListStore);
    const gamesListStore = useSelector(state => state?.gamesListStore);
    const { register, handleSubmit, watch, formState: { errors }, control, reset, setError, setValue, getValues } = useForm();
    const dispatch = useDispatch();
    const [pageData, setPageData] = useState([]);
    const navigate = useNavigate();
    // const [selectedStaffIndex, setSelectedStaffIndex] = useState("");
    // const [selectedBookIndex, setSelectedBookIndex] = useState("");
    // const [selectedGameIndex, setSelectedGameIndex] = useState("");

    const [deleteData, setDeleteData] = useState("");
    const [selectedIndex, setSelectedIndex] = useState("");
    const [selectedId, setSelectedId] = useState("");
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [deleteFrom, setDeleteFrom] = useState("GameStaff");
    const [staffAction, setStaffAction] = useState(false);
    const [bookAction, setBookAction] = useState(false);
    const [gameAction, setGameAction] = useState(false);
    const [challengeAction, setChallengeAction] = useState(false);
    const [isUpadate, setIsUpdate] = useState(false);
    const [isEdited, setIsEdited] = useState(false);
    const [staffList, setStaffList] = useState([]);
    const [booksList, setBooksList] = useState([]);
    const [gamesList, setGamesList] = useState([]);
    const [challengesList, setChallengesList] = useState([]);
    const [challengesListres, setChallengesListRes] = useState([]);
    const [callapiOnce, setCallApiOnce] = useState(false);

    const [staffListData, setStaffListData] = useState([]);
    const [bookListData, setBookListData] = useState([]);
    const [gameListData, setGameListData] = useState([]);
    const [challengesListData, setChallengesListData] = useState([]);

    const [openImagePopup, setOpenImagePopup] = useState(false);
    const [noDataFound, setNoDataFound] = useState(false);

    const [staffIdFilter, setStaffIdFilter] = useState(false);
    const [staffGIdFilter, setStaffGIdFilter] = useState(false);
    const [staffNameFilter, setStaffNameFilter] = useState(false);
    const [staffRoleFilter, setRoleNameFilter] = useState(false);
    const [staffDepFilter, setRoleDepFilter] = useState(false);

    const [bookIdFilter, setBookIdFilter] = useState(false);
    const [bookGIdFilter, setBookGIdFilter] = useState(false);
    const [bookNameFilter, setBookNameFilter] = useState(false);
    const [bookDescFilter, setBookDescFilter] = useState(false);

    const [chaGIdFilter, setChaGIdFilter] = useState(false);
    const [chaIdFilter, setChaIdFilter] = useState(false);
    const [chaTitleFilter, setChaTitleFilter] = useState(false);
    const [chaQuesFilter, setChaQuesFilter] = useState(false);
    const [chaLevelFilter, setChaLevelFilter] = useState(false);

    const [gameNameFilter, setGameNameFilter] = useState(false);
    const [gameIdFilter, setGameIdFilter] = useState(false);
    const [gameGIdFilter, setGameGIdFilter] = useState(false);
    const [gameDescFilter, setGameDescFilter] = useState(false);

    const [orginalData, setOriginalData] = useState([])

    const [sFilterClicked, setSFilterClicked] = useState(false);
    const [bFilterClicked, setBFilterClicked] = useState(false);
    const [gFilterClicked, setGFilterClicked] = useState(false);
    const [cFilterClicked, setCFilterClicked] = useState(false);

    const [openChallegespopup, setOpenChallengesPopup] = useState(false);
    const [apiCount, setapiCount] = useState(false);
    const [isEditedChallenges, setIsEditedChallenges] = useState(false);


    const [showStaffAcor, setShowStaffAcor] = useState(true);
    const [showBooksAcor, setShowBooksAcor] = useState(false);
    const [showGamesAcor, setShowGamesAcor] = useState(false);
    const [showChalAcor, setShowChalAcor] = useState(false);

    const [selectedChalengeId, setSelectedChalengeId] = useState(0);
    const [isModified, setIsModified] = useState(false);
    const [modifiedIndex, setModifiedIndex] = useState([]);
    const [modifiedName, setModifiedName] = useState("");
    const [modifiedSave, setModifiedSave] = useState(false);

    const editDataIds = [];

    const apiReqObj = {
        "getLatestFirst": true,
        "pageNumber": 1,
        "numberOfRecordsPerPage": 0,
        "filter": {
            "active": true
        }
    }

    let [challengeWindow, setchallengeWindow] = useState(null);

    const FilterbyStaffName = (a, b) => {
        // if (a.staffName.length !== 0 && a.staffName[0].value && b.staffName.length !== 0 && b.staffName[0].value) {
        //     if (a.staffName[0].value.toLowerCase() < b.staffName[0].value.toLowerCase()) {
        //         return -1;
        //     }
        //     if (a.staffName[0].value.toLowerCase() > b.staffName[0].value.toLowerCase()) {
        //         return 1;
        //     }
        // }
        // return 0;
        return a.staffId - b.staffId;
    }

    const FilterbyBookName = (a, b) => {
        return parseInt(a.bookId) - parseInt(b.bookId);
    }

    const FilterbySelected = (a, b) => {
        return (a.selected === b.selected) ? 0 : a.selected ? -1 : 1;
    }

    const FilterbyGameName = (a, b) => {
        //console.log("AAAAAAA------->", a)
        // if (a.name && b.name) {
        //     if (a.name.toLowerCase() < b.name.toLowerCase()) {
        //         return -1;
        //     }
        //     if (a.name.toLowerCase() > b.name.toLowerCase()) {
        //         return 1;
        //     }
        // }
        // return 0;
        return parseInt(a.gameId) - parseInt(b.gameId);
    }

    const filterData = (val, propName, name) => {
        let check = val;
        let filterData = [];
        //console.log("orginalData[propName]------->", orginalData[propName])
        // let orginalData = JSON.parse(JSON.stringify(pageData));
        if (propName === 'staff') {
            setSFilterClicked(true);
            if (name === 'G_staffId') {
                setStaffGIdFilter(!staffGIdFilter);
                filterData[propName] = check ? FilterbyFiledAsending(orginalData[propName], name) : FilterbyFiledDsending(orginalData[propName], name);
            }
            if (name === 'staffId') {
                setStaffIdFilter(!staffIdFilter);
                filterData[propName] = check ? FilterbyFiledAsending(orginalData[propName], name) : FilterbyFiledDsending(orginalData[propName], name);
            }
            if (name === 'staffName') {
                setStaffNameFilter(!staffNameFilter);
                filterData[propName] = check ? FilterbyStringAsending(orginalData[propName], name) : FilterbyStringDsending(orginalData[propName], name);
            }
            if (name === 'role') {
                setRoleNameFilter(!staffRoleFilter);
                filterData[propName] = check ? FilterbyFiledAsending(orginalData[propName], name) : FilterbyFiledDsending(orginalData[propName], name);

            }
            if (name === 'department') {
                setRoleDepFilter(!staffDepFilter);
                filterData[propName] = check ? FilterbyFiledAsending(orginalData[propName], name) : FilterbyFiledDsending(orginalData[propName], name);
            }
        }
        if (propName === 'books') {
            setBFilterClicked(true);
            if (name === 'G_bookId') {
                setBookGIdFilter(!bookGIdFilter);
                filterData[propName] = check ? FilterbyFiledAsending(orginalData[propName], name) : FilterbyFiledDsending(orginalData[propName], name);

            }
            if (name === 'bookId') {
                setBookIdFilter(!bookIdFilter);
                filterData[propName] = check ? FilterbyFiledAsending(orginalData[propName], name) : FilterbyFiledDsending(orginalData[propName], name);

            }
            if (name === 'bookName') {
                setBookNameFilter(!bookNameFilter);
                filterData[propName] = check ? FilterbyFiledAsending(orginalData[propName], name) : FilterbyFiledDsending(orginalData[propName], name);
            }
            if (name === 'bookDescription') {
                setBookDescFilter(!bookDescFilter);
                filterData[propName] = check ? FilterbyStringAsending(orginalData[propName], name) : FilterbyStringDsending(orginalData[propName], name);

            }
        }
        if (propName === 'games') {
            setGFilterClicked(true);
            if (name === 'G_gameId') {
                setGameGIdFilter(!gameGIdFilter);
                filterData[propName] = check ? FilterbyFiledAsending(orginalData[propName], name) : FilterbyFiledDsending(orginalData[propName], name);

            }
            if (name === 'gameId') {
                setGameIdFilter(!gameIdFilter);
                filterData[propName] = check ? FilterbyFiledAsending(orginalData[propName], name) : FilterbyFiledDsending(orginalData[propName], name);

            }
            if (name === 'name') {
                setGameNameFilter(!gameNameFilter);
                filterData[propName] = check ? FilterbyFiledAsending(orginalData[propName], name) : FilterbyFiledDsending(orginalData[propName], name);

            }
            if (name === 'description') {
                setGameDescFilter(!gameDescFilter);
                filterData[propName] = check ? FilterbyFiledAsending(orginalData[propName], name) : FilterbyFiledDsending(orginalData[propName], name);

            }
        }
        if (propName === 'challenges') {
            setCFilterClicked(true);
            if (name === 'G_challengeId') {
                setChaGIdFilter(!chaGIdFilter);
                filterData[propName] = check ? orginalData[propName].sort((a, b) => parseInt(a.challengeId) - parseInt(b.challengeId)) : orginalData[propName].sort((a, b) => parseInt(b.challengeId) - parseInt(a.challengeId));

            }
            if (name === 'challengeId') {
                setChaIdFilter(!chaIdFilter);
                filterData[propName] = check ? orginalData[propName].sort((a, b) => parseInt(a.challengeId) - parseInt(b.challengeId)) : orginalData[propName].sort((a, b) => parseInt(b.challengeId) - parseInt(a.challengeId));

            }
            if (name === 'challengeTitle') {
                setChaTitleFilter(!chaTitleFilter);
                filterData[propName] = check ? FilterbyStringAsending(orginalData[propName], name) : FilterbyStringDsending(orginalData[propName], name);

            }

            if (name === 'questionTitle') {
                setChaQuesFilter(!chaQuesFilter);
                filterData[propName] = check ? FilterbyStringAsending(orginalData[propName], name) : FilterbyStringDsending(orginalData[propName], name);

            }
            if (name === 'Level') {
                setChaLevelFilter(!chaLevelFilter);
                filterData[propName] = check ? FilterbyFiledAsending(orginalData[propName], name) : FilterbyFiledDsending(orginalData[propName], name);
            }
        }
        orginalData[propName] = filterData[propName]
        setPageData(orginalData);
        setIsUpdate(isUpadate);
    }

    useEffect(() => {
        //   initTE({ Collapse });

        //console.log("pagedata------------->", pageData)
        if (ageGroupListStore.length === 0) {
            getAgeGroupList("age")
        }
        if (hospitalListStore.length === 0) {
            getAgeGroupList("hospital")
        }
        if (wardListStore.length === 0) {
            getAgeGroupList("ward")
        }

        if (pageData.length === 0) {
            document.querySelector('#accordion-collapse').style.display = "none"
        } else {
            document.querySelector('#accordion-collapse').style.display = "block"
        }

        // const accordionItems = [
        //     {
        //         id: 'accordion-collapse-heading-1',
        //         triggerEl: document.querySelector('#accordion-collapse-heading-1'),
        //         targetEl: document.querySelector('#accordion-collapse-body-1'),
        //         active: true
        //     },
        //     {
        //         id: 'accordion-collapse-heading-2',
        //         triggerEl: document.querySelector('#accordion-collapse-heading-2'),
        //         targetEl: document.querySelector('#accordion-collapse-body-2'),
        //         active: false
        //     },
        //     {
        //         id: 'accordion-collapse-heading-3',
        //         triggerEl: document.querySelector('#accordion-collapse-heading-3'),
        //         targetEl: document.querySelector('#accordion-collapse-body-3'),
        //         active: false
        //     }
        // ];

        // const options = {
        //     alwaysOpen: true,
        //     activeClasses: 'bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white',
        //     inactiveClasses: 'text-gray-500 dark:text-gray-400',
        //     onOpen: (item) => {
        //         //  console.log('accordion item has been shown');
        //         //console.log(item);
        //     },
        //     onClose: (item) => {
        //         // console.log('accordion item has been hidden');
        //         // console.log(item);
        //     },
        //     onToggle: (item) => {
        //         // console.log('accordion item has been toggled');
        //         // console.log(item);
        //     },
        // };

        // new Accordion(accordionItems, options);
        if (!callapiOnce) {
            getStaffs();
        }
        if (staffListStore.length !== 0 && staffList.length === 0) {
            setStaffList(staffListStore.slice().sort(FilterbyStaffName));
        }


        if (booksListStore.length !== 0 && booksList.length === 0) {
            //setBooksList(booksListStore.slice().sort(FilterbyBookName));
            setBooksList(booksListStore.slice().sort(FilterbyBookName));
        }

        if (gamesListStore.length !== 0 && gamesList.length === 0) {
            setGamesList(gamesListStore.slice().sort(FilterbyGameName));

        }
        // if (challengesList.length === 0 && !callapiOnce) {
        //     getAllchallengesApi()
        // }

        //console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>isEdited----------------->", pageData)


        // console.log("gamesListStore----------------->", gamesListStore)

        // if (noDataFound) {
        //     setStaffAction(!staffAction);
        //     setBookAction(!bookAction);
        //     setGameAction(!gameAction);
        //     setChallengeAction(!challengeAction);
        // }

        if (challengeWindow !== null) {
            // window.localStorage.removeItem('challengePopup')
            // challengeWindow.close();
            // document.getElementById("myButton").click();

            var win_timer = setInterval(function () {
                if (challengeWindow.closed) {

                    challengeWindow.close();
                    let dupData = pageData;
                    if (window.localStorage.getItem('challengePopup') === 'edited') {
                        const editedData = JSON.parse(window.localStorage.getItem('editedData'));
                        // console.log("editedData2----------------->", pageData.challenges[selectedChalengeId])
                        // console.log("editedData1----------------->", editedData)
                        // console.log("editedData----------------->", { ...pageData.challenges[selectedChalengeId], ...editedData })

                        // console.log("editedData>>>>>>>----------------->", editedData)


                        document.getElementById("chatitleid" + selectedChalengeId).innerHTML = editedData?.challengeTitle[0].value;
                        document.getElementById("chaquestitleid" + selectedChalengeId).innerHTML = editedData?.questionTitle[0].value
                        document.getElementById("chaimageid" + selectedChalengeId).setAttribute("src", editedData?.challengeImage)
                        document.getElementById("chalevelid" + selectedChalengeId).innerHTML = editedData?.Level

                        // pageData.challenges[selectedChalengeId].questionTitle = editedData?.questionTitle
                        // pageData.challenges[selectedChalengeId].challengeTitle = editedData?.challengeTitle
                        // pageData.challenges[selectedChalengeId].Level = editedData?.Level
                        // pageData.challenges[selectedChalengeId].challengeImage = editedData?.challengeImage

                        //dupData = JSON.parse(JSON.stringify(pageData))
                        //dupData.challenges[selectedChalengeId] = { ...pageData.challenges[selectedChalengeId], ...editedData }
                        //  pageData.challenges = dupData.challenges;


                        //document.getElementById("myButton").click();
                        window.localStorage.removeItem('challengePopup')
                        window.localStorage.removeItem('editedData')
                    }

                    clearInterval(win_timer);
                    setchallengeWindow(null)
                    // setPageData(dupData);
                    // setOriginalData(dupData);
                    // setIsUpdate(!isUpadate);
                }
            }, 100);
        }

    }, [ageGroupListStore, pageData, isUpadate, staffListStore, openChallegespopup, isEditedChallenges, challengeWindow,
        booksListStore, gamesListStore, staffAction, sFilterClicked, bFilterClicked, gFilterClicked, cFilterClicked])


    const constructValues = (pageData, name, subName, nodata, challengesListres = null, secId = null) => {
        let staffArray = [];
        let mapArray = [];
        // console.log("staffList---------------->", staffList)
        //console.log("challengesListres---------------->", challengesListres)
        if (subName === "staff") {
            mapArray = staffList;
        }
        if (subName === "books") {
            mapArray = booksList;
        }
        if (subName === "games") {
            mapArray = gamesList;
        }
        if (subName === "challenges") {
            mapArray = challengesList.length === 0 ? challengesListres : challengesList;
        }
        mapArray.map((val, index) => {
            const values = JSON.parse(JSON.stringify(val));
            let test = [];
            //  console.log("noDataFound---------------->", pageData[subName])
            if (!nodata) {
                test = pageData[subName].filter((check) => {
                    return val._id === check[name]._id
                })
            }

            if (test.length !== 0) {
                //console.log("noDataFound---------------->", pageData[subName][index])
                values.selected = true;
                //values[secId] = pageData[subName][index][secId] ? pageData[subName][index][secId] : 0
                values[secId] = parseInt(test[0][secId]);
                values[`G_${secId}`] = parseInt(test[0][name][secId]);
            } else {
                values.selected = false;
                // values[secId] = 0
                values[`G_${secId}`] = parseInt(val[secId]);
                values[secId] = 0
            }
            if (subName === "books") {
                //  console.log("noDataFound---------------->", parseInt(val[secId]))
                //  console.log("noDataFound111---------------->", parseInt(pageData[subName][index][secId]))
            }
            // values[`G_${secId}`] = parseInt(val[secId]);
            // values[secId] = pageData[subName] && pageData[subName][index] && pageData[subName][index][secId] ? parseInt(pageData[subName][index][secId]) : 0
            //  values[`G_${secId}`] = parseInt(val[secId]);
            //  values[secId] = pageData[subName] && pageData[subName][index] && pageData[subName][index][secId] ? parseInt(pageData[subName][index][secId]) : 0

            staffArray.push(values);
        })
        // if (subName === "staff") {
        //     setStaffList(staffArray)
        // }
        // if (subName === "books") {
        //     console.log("noDataFound???????????---------------->", staffArray)
        //     setBooksList(staffArray)
        // }
        // if (subName === "games") {
        //     setGamesList(staffArray)
        // }
        // console.log("staffArray---------------->", staffArray)
        if (nodata && subName === "challenges") {
            let staffArray1 = [];
            // //pageData1.challenges = constructValues(pageData1, "challengeDetails", "challenges", nodata);
            staffArray.sort((a, b) => parseInt(a.challengeId) - parseInt(b.challengeId)).map(val => {
                const values = JSON.parse(JSON.stringify({ ...val, ...{ challengeId: 0 } }));
                values.selected = false;
                staffArray1.push(values);
            })

            // data.challenges.sort((a, b) => parseInt(a.challengeId) - parseInt(b.challengeId)).map(val => {
            //     const values = JSON.parse(JSON.stringify({ ...val?.challengeDetails, ...{ challengeId: val?.challengeId } }));
            //     values.selected = true;
            //     staffArray1.push(values);
            // })
            pageData.challenges = staffArray1;
            //  console.log("staffArray1---------------->", staffArray1)
            setChallengesList(staffArray1)
        }
        // console.log("staffArray---------------->", staffArray)
        return staffArray;
    }

    const setTableValuesnew = (data, challengesList, nodata = false) => {
        // console.log("Data----------------->", data)
        const pageData1 = JSON.parse(JSON.stringify(data));
        if (data?.staff) {
            pageData1.staff = constructValues(data, "staffDetails", "staff", nodata, null, 'staffId');
        }
        if (data?.books) {
            // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>isEdited----------------->", booksList)
            pageData1.books = constructValues(data, "bookDetails", "books", nodata, null, 'bookId').sort(FilterbyBookName);

            // let bookArray = [];

            // booksList.map((val, index) => {
            //     const values = JSON.parse(JSON.stringify(val));
            //     let test = [];
            //     if (!nodata) {
            //         test = pageData1["books"].filter((check) => {
            //             console.log("bookId----------------->", val._id)
            //             console.log("bookdetailsId----------------->", check["bookDetails"]._id)
            //             return val._id === check["bookDetails"]._id
            //         })
            //     }

            //     if (test.length !== 0) {
            //         values.selected = true;
            //         values[`bookId`] = test[0].bookId;
            //         values['G_bookId'] = test[0].bookDetails.bookId
            //     } else {
            //         //  console.log("Data111----------------->", pageData1["books"][index])
            //         values.selected = false;
            //         values[`G_bookId`] = parseInt(val["bookId"]);
            //         values['bookId'] = 0
            //     }
            //     //console.log("Data111----------------->", parseInt(val["bookId"]))
            //     // values[`G_bookId`] = parseInt(val["bookId"]);
            //     // values['bookId'] = pageData1["books"] && pageData1["books"][index] && pageData1["books"][index]["bookId"] ? parseInt(pageData1["books"][index]["bookId"]) : 0
            //     bookArray.push(values);
            // })
            // pageData1.books = bookArray;
        }
        if (data?.games) {
            pageData1.games = constructValues(data, "gameDetails", "games", nodata, null, 'gameId').sort(FilterbyGameName);
        }
        if (data?.challenges) {
            let staffArray1 = [];
            //pageData1.challenges = constructValues(pageData1, "challengeDetails", "challenges", nodata);
            challengesList.sort((a, b) => parseInt(a.challengeId) - parseInt(b.challengeId)).map(val => {
                const values = JSON.parse(JSON.stringify({ ...val, ...{ challengeId: 0, G_challengeId: 0 } }));
                values.selected = false;
                staffArray1.push(values);
            })

            data.challenges.sort((a, b) => parseInt(a.challengeId) - parseInt(b.challengeId)).map(val => {
                const values = JSON.parse(JSON.stringify({ ...val?.challengeDetails, ...{ challengeId: val?.challengeId, G_challengeId: 0 } }));
                values.selected = true;
                staffArray1.push(values);
            })
            setChallengesList(staffArray1);
            pageData1.challenges = staffArray1;
        }
        //console.log("pageData11---------------->", pageData1)
        setPageData(pageData1)
        setOriginalData(pageData1)
        setIsUpdate(!isUpadate)
    }

    const getStaffs = async () => {
        const fullArray = await Promise.all[
            dispatch(await getStaffListAction(apiReqObj)),
            dispatch(await getBooksListAction(apiReqObj)),
            dispatch(await getGamesListAction(apiReqObj))
        ];
        setCallApiOnce(true)
        //console.log("fullArray----------------->", fullArray)

    }

    const openDelete = (data, id, index, from) => {
        setDeleteFrom(from);
        if (from === 'GameStaff') {
            setDeleteData(data?.staffDetails);
        }
        if (from === 'GameBook') {
            setDeleteData(data?.bookDetails);
        }
        if (from === 'GameLevel') {
            setDeleteData(data?.gameDetails);
        }
        setSelectedId(id);
        setSelectedIndex(index)
        setOpenDeletePopup(true);
    }

    const openImage = async (url) => {
        const reqObj = { filepath: url }
        // console.log("reqObj------------>", reqObj)

        const response = await dispatch(checkFileExistOrNotAction(reqObj));
        // console.log("response------------>", response)
        if (response) {
            setDeleteData(response)
            setOpenImagePopup(true);
        } else {
            setDeleteData(response)
            setOpenImagePopup(false);
            toast.error("Couldn't find file, Please upload again")
        }


    }

    const getAgeGroupList = async (from = "") => {
        if (from === 'age') {
            dispatch(await getAgeGroupListAction(apiReqObj));
        }
        if (from === 'hospital') {
            dispatch(await getHospitalListAction(apiReqObj));
        }
        if (from === 'ward') {
            dispatch(await getWardListAction(apiReqObj));
        }

    }

    const submitHandler = async (data) => {
        try {
            setPageData([]);
            setOriginalData([]);
            setChallengesList([]);
            setBooksList([]);
            setStaffList([]);
            setGamesList([]);
            setChallengesListData([]);
            setChallengesListRes([]);
            //    setIsUpdate(!isUpadate);
            apiReqObj.filter = {
                hospital: data.hospital,
                ward: data.ward,
                level: data.level,
                ageGroup: data.age,
            }

            let response = await getGameLevelListAction(dispatch, apiReqObj);
            if (response?.status === 200 && response?.data && response?.data?.payload?.gameLevelList[0] && response?.data?.payload?.gameLevelList[0]?.staff) {
                //   setPageData(response?.data?.payload[0])
                setNoDataFound(false);
                setChallengesListRes(response?.data?.payload?.availableChallenges)
                setTableValuesnew(response?.data?.payload?.gameLevelList[0], response?.data?.payload?.availableChallenges);
                // setTableValues(response?.data?.payload?.gameLevelList[0], "staff", false);
                // setTableValues(response?.data?.payload?.gameLevelList[0], "books", false);
                // setTableValues(response?.data?.payload?.gameLevelList[0], "games", false);
                // setTableValues(response?.data?.payload?.gameLevelList[0], "challenges", false);
                setStaffAction(false);
                setBookAction(false);
                setGameAction(false);
                setChallengeAction(false);
                setIsUpdate(isUpadate);
                toast.success("Success");
            } else {
                setNoDataFound(true);
                setChallengesListRes(response?.data?.payload?.availableChallenges)
                setStaffAction(!staffAction);
                setBookAction(!bookAction);
                setGameAction(!gameAction);
                setChallengeAction(!challengeAction);
                let pageObj = {}
                pageObj.staff = constructValues([], "staffDetails", "staff", true, response?.data?.payload?.availableChallenges, "staffId")
                pageObj.books = constructValues([], "bookDetails", "books", true, response?.data?.payload?.availableChallenges, "bookId");
                pageObj.games = constructValues([], "gameDetails", "games", true, response?.data?.payload?.availableChallenges, "gameId");
                pageObj.challenges = constructValues([], "challengeDetails", "challenges", true, response?.data?.payload?.availableChallenges, "challengeId");
                let staffArray1 = [];
                pageObj.challenges.sort((a, b) => parseInt(a.challengeId) - parseInt(b.challengeId)).map(val => {
                    const values = JSON.parse(JSON.stringify(
                        {
                            ...val, ...{
                                challengeId: val?.challengeId ? parseInt(val?.challengeId) : 0,
                                G_challengeId: val?.challengeId ? parseInt(val?.challengeId) : 0
                            }
                        }));
                    values.selected = false;
                    staffArray1.push(values);
                })
                pageObj.challenges = staffArray1;

                //console.log("pageObj------------------>", pageObj)
                setPageData(pageObj);
                setOriginalData(pageObj)
                toast.error("No Data found")
                setIsUpdate(!isUpadate);
            }
            setShowStaffAcor(true);
            setShowBooksAcor(false);
            setShowGamesAcor(false);
            setShowChalAcor(false);
        } catch (e) {
            setPageData([])
            toast.error("Sorry for inconvenience ")
            console.log("ERRRR------------->", e)
        }
    }

    const onError = (errors) => {
        console.log("errors------------->", errors)
    }

    const closeDeletePopup = () => {
        setOpenDeletePopup(false);
    }

    const closeImagePopup = () => {
        setOpenImagePopup(false);
    }

    const deleteItem = async () => {
        const pageDataObj = JSON.parse(JSON.stringify(pageData));
        let reqObj = {}

        if (deleteFrom === "GameStaff") {
            pageDataObj?.staff.splice(selectedIndex, 1);
            reqObj = {
                "gameLevelId": pageData?._id,
                "dataToUpdate": {
                    "staff": pageDataObj?.staff.map((eachStaff) => {
                        return {
                            staffId: eachStaff.staffId,
                            staffDetails: eachStaff.staffDetails._id
                        }
                    })
                }
            }
        }

        if (deleteFrom === 'GameBook') {
            pageDataObj?.books.splice(selectedIndex, 1);
            reqObj = {
                "gameLevelId": pageData?._id,
                "dataToUpdate": {
                    "books": pageDataObj?.books.map((eachStaff) => {
                        return {
                            bookId: eachStaff.bookId,
                            bookDetails: eachStaff.bookDetails._id
                        }
                    })
                }
            }
        }
        if (deleteFrom === 'GameLevel') {
            pageDataObj?.games.splice(selectedIndex, 1);
            reqObj = {
                "gameLevelId": pageData?._id,
                "dataToUpdate": {
                    "games": pageDataObj?.games.map((eachStaff) => {
                        return {
                            gameId: eachStaff.gameId,
                            gameDetails: eachStaff.gameDetails._id
                        }
                    })
                }
            }
        }

        const response = await getUpdateGameLevelDetailsAction(dispatch, reqObj);
        if (response?.status === 200 && response?.data) {
            toast.success("Success")
            setPageData(pageDataObj);
            setOriginalData(pageDataObj);
            //  setIsUpdate(!isUpdate)
        } else {
            toast.error("Failed")
            setPageData(pageData);
            setOriginalData(pageData);
        }
    }

    const editItem = (from, id, index) => {
        if (from === 'staff') {
            if (pageData?.staff !== 0) {
                pageData?.staff.map((val) => {
                    editDataIds.push(val.staffDetails._id)
                })
                // console.log(">>>>>>>>>>>>>>>>>>>>>>>", editDataIds)
                dispatch(setEditOptionData(editDataIds))
            }
            navigate('/dashboard/staff?id=' + id)
        }
        if (from === "books") {
            if (pageData?.books !== 0) {
                pageData?.books.map((val) => {
                    editDataIds.push(val.bookDetails._id)
                })
                // console.log(">>>>>>>>>>>>>>>>>>>>>>>", editDataIds)
                dispatch(setEditOptionData(editDataIds))
            }
            navigate('/dashboard/books?id=' + id)
        }
        if (from === "games") {
            if (pageData?.games !== 0) {
                pageData?.games.map((val) => {
                    editDataIds.push(val.gameDetails._id)
                })
                // console.log(">>>>>>>>>>>>>>>>>>>>>>>", editDataIds)
                dispatch(setEditOptionData(editDataIds))
            }
            navigate('/dashboard/games?id=' + id)
        }
        if (from === "challenges") {
            // let challengess = [];
            // if (pageData?.challenges !== 0) {
            //     pageData?.challenges.map((val) => {
            //         challengess.push(val.challengeDetails)
            //     })
            //     console.log(">>>>>>>>>>>>>>>>>>>>>>>", challengess)
            //     dispatch(setGameLevelChallenges(challengess))
            // }
            // challengess.findIndex(val=>{
            //     return val._id = id
            // })
            // navigate('/dashboard/editChallenge?id=' + id)


            // setDeleteData('/dashboard/editChallenge?id=' + id);
            // setOpenChallengesPopup(!openChallegespopup);
            // setIsEditedChallenges(false);
            // window.closeChallengesPopup = closeChallengesPopup;
            setSelectedChalengeId(index)
            const win = window.open('/dashboard/editChallenge?id=' + id, 'targetWindow',
                `toolbar=no,
         location=no,
         status=no,
         menubar=no,
         scrollbars=yes,
         resizable=yes,
         width=2000,
         height=1000`);
            setchallengeWindow(win);
        }
    }

    const closeChallengesPopup = (check = false) => {
        // console.log("calling close popup------>", isEditedChallenges)
        setOpenChallengesPopup(!openChallegespopup)
        if (check) {
            document.getElementById("myButton").click();
        }
    }

    const checkDuplication = (data, name) => {
        const testing = data.filter((eachStaff) => {
            return eachStaff.selected === true;
        })

        let extractChallengeId = [];
        testing.map(val => {
            //  console.log("22222111--------------->", val[name])
            extractChallengeId.push(val[name])
        })
        // console.log("22222--------------->", (new Set(extractChallengeId)).size, extractChallengeId.length)
        return (new Set(extractChallengeId)).size !== extractChallengeId.length
    }

    const saveSelectedItem = async (from) => {
        setStaffAction(false);
        setBookAction(false);
        setGameAction(false);
        setChallengeAction(false)
        setModifiedSave(true);
        if (isEdited) {
            let reqObj = {}

            if (from === 'staff') {
                setStaffAction(false);
                const testing = pageData?.staff.filter((eachStaff) => {
                    return eachStaff.selected === true;
                })
                if (noDataFound) {
                    reqObj = {
                        "staff": testing.map((eachStaff, index) => {

                            return {
                                staffId: parseInt(getValues("staffId" + index)),
                                staffDetails: eachStaff._id
                            }
                        })
                    }
                } else {
                    reqObj = {
                        "gameLevelId": pageData?._id,
                        "dataToUpdate": {
                            "staff": testing.map((eachStaff, index) => {

                                return {
                                    staffId: parseInt(getValues("staffId" + index)),
                                    staffDetails: eachStaff._id
                                }
                            })
                        }
                    }
                }
                setStaffList(pageData?.staff.sort(FilterbyStaffName))
            }
            if (from === 'books') {
                setBookAction(false)
                const testing = pageData?.books.filter((eachStaff) => {
                    return eachStaff.selected === true;
                })
                if (noDataFound) {
                    reqObj = {
                        "books": testing.map((eachStaff, index) => {
                            return {
                                bookId: parseInt(getValues("bookId" + index)),
                                bookDetails: eachStaff._id
                            }
                        })
                    }
                } else {
                    reqObj = {
                        "gameLevelId": pageData?._id,
                        "dataToUpdate": {
                            "books": testing.map((eachStaff, index) => {
                                return {
                                    bookId: parseInt(getValues("bookId" + index)),
                                    bookDetails: eachStaff._id
                                }
                            })
                        }
                    }
                }
                setBooksList(pageData?.books.sort(FilterbyBookName))
            }
            if (from === 'games') {
                setGameAction(false);
                const testing = pageData?.games.filter((eachStaff) => {
                    return eachStaff.selected === true;
                })
                if (noDataFound) {
                    reqObj = {
                        "games": testing.map((eachStaff, index) => {
                            return {
                                gameId: parseInt(getValues("gameId" + index)),
                                gameDetails: eachStaff._id
                            }
                        })
                    }
                } else {
                    reqObj = {
                        "gameLevelId": pageData?._id,
                        "dataToUpdate": {
                            "games": testing.map((eachStaff, index) => {
                                return {
                                    gameId: parseInt(getValues("gameId" + index)),
                                    gameDetails: eachStaff._id
                                }
                            })
                        }
                    }
                }
                setGamesList(pageData?.games.sort(FilterbyGameName))
            }
            if (from === 'challenges') {
                const testing = pageData?.challenges.filter((eachStaff) => {
                    return eachStaff.selected === true;
                })
                if (noDataFound) {
                    reqObj = {
                        "challenges": testing.map((eachStaff, index) => {
                            // console.log("11111-------->", getValues("challengeId" + index))
                            return {
                                challengeId: parseInt(getValues("challengeId" + index)),
                                // challengesId: eachStaff.challengeId,
                                challengeDetails: eachStaff._id
                            }
                        })
                    }
                } else {
                    reqObj = {
                        "gameLevelId": pageData?._id,
                        "dataToUpdate": {
                            "challenges": testing.map((eachStaff, index) => {
                                return {
                                    challengeId: parseInt(getValues("challengeId" + index)),
                                    // challengesId: eachStaff.challengeId,
                                    challengeDetails: eachStaff._id
                                }
                            })
                        }
                    }
                }

            }
            let checkDupStaffId = false;
            let checkDupBookId = false;
            let checkDupGameId = false;
            let checkDupChalengeId = false;
            if (from === 'staff') {
                checkDupStaffId = checkDuplication(pageData?.staff, 'staffId')
                if (checkDupStaffId) {
                    setStaffAction(true)
                } else {
                    setStaffAction(false)
                }


            }
            if (from === 'books') {
                checkDupBookId = checkDuplication(pageData?.books, 'bookId')
                if (checkDupBookId) {
                    setBookAction(true)
                } else {
                    setBookAction(false)
                }


            }
            if (from === 'games') {
                checkDupGameId = checkDuplication(pageData?.games, 'gameId')
                if (checkDupGameId) {
                    setGameAction(true)
                } else {
                    setGameAction(false)
                }


            }
            if (from === 'challenges') {
                // const testing = pageData?.challenges.filter((eachStaff) => {
                //     return eachStaff.selected === true;
                // })

                // let extractChallengeId = [];
                // testing.map(val => {
                //     extractChallengeId.push(val.challengeId)
                // })
                checkDupChalengeId = checkDuplication(pageData?.challenges, 'challengeId')
                if (checkDupChalengeId) {
                    setChallengeAction(true)
                } else {
                    setChallengeAction(false)
                }


            }
            if (checkDupChalengeId) {
                toast.error("Duplicate challenge ID's are not allowed");
                return;
            } if (checkDupStaffId) {
                toast.error("Duplicate staff ID's are not allowed");
                return;
            } if (checkDupBookId) {
                toast.error("Duplicate book ID's are not allowed");
                return;
            } if (checkDupGameId) {
                toast.error("Duplicate game ID's are not allowed");
                return;
            } else {
                // return;
                let response = "";
                if (noDataFound) {
                    reqObj = {
                        ...reqObj, ...{
                            "hospital": getValues('hospital'),
                            "ward": getValues('ward'),
                            "level": parseInt(getValues('level')),
                            "ageGroup": getValues('age'),
                            "active": true
                        }
                    }
                    response = await getUpdateGameLevelDetailsAction(dispatch, createGameLevelURL, reqObj);
                } else {
                    response = await getUpdateGameLevelDetailsAction(dispatch, updateGameLevelDetailsURL, reqObj);
                }

                if (response?.status === 200 && response?.data) {

                    // document.getElementById("myButton").click();
                    // return
                    // if (from === 'challenges') {
                    //     let staffArray = [];
                    //     pageData.challenges.map((val, index) => {
                    //         const values = JSON.parse(JSON.stringify(val));
                    //         values.challengeId = getValues("challengeId" + index);

                    //         staffArray.push(values);
                    //     })
                    //     pageData.challenges = staffArray.sort((a, b) => parseInt(a.challengeId) - parseInt(b.challengeId));
                    // }
                    // if (from === 'staff') {
                    //     let staffArray = [];
                    //     console.log("staffArray pageData------------->", pageData.staff)
                    //     pageData.staff.map((val, index) => {
                    //         const values = JSON.parse(JSON.stringify(val));
                    //         values.staffId = getValues("staffId" + index);

                    //         staffArray.push(values);
                    //     })
                    //     console.log("staffArray------------->", staffArray)
                    //     pageData.staff = staffArray.sort((a, b) => parseInt(a.staffId) - parseInt(b.staffId));
                    // }
                    // if (from === 'books') {
                    //     let staffArray = [];
                    //     pageData.books.map((val, index) => {
                    //         const values = JSON.parse(JSON.stringify(val));
                    //         values.staffId = getValues("bookId" + index);

                    //         staffArray.push(values);
                    //     })
                    //     pageData.books = staffArray.sort((a, b) => parseInt(a.bookId) - parseInt(b.bookId));
                    // }
                    // if (from === 'games') {
                    //     let staffArray = [];
                    //     pageData.games.map((val, index) => {
                    //         const values = JSON.parse(JSON.stringify(val));
                    //         values.staffId = getValues("gameId" + index);

                    //         staffArray.push(values);
                    //     })
                    //     pageData.games = staffArray.sort((a, b) => parseInt(a.gameId) - parseInt(b.gameId));
                    // }

                    if (noDataFound) {
                        const { payload } = response?.data;

                        setNoDataFound(!noDataFound)
                        // setPageData(payload?.createdGameLevel);
                        // setOriginalData(payload?.createdGameLevel);

                        // setTableValuesnew(payload?.createdGameLevel, challengesList);
                        // setStaffAction(false);
                        // setBookAction(false);
                        // setGameAction(false);
                        // setChallengeAction(false);
                        document.getElementById("myButton").click();

                    } else {
                        toast.success("Success")
                        setPageData(pageData);
                        setOriginalData(pageData);
                    }


                    // setPageData(pageData);
                    // setOriginalData(pageData);
                    //  setIsUpdate(!isUpdate)
                } else {
                    toast.error("Failed")
                    //setPageData(pageData);
                }
                setIsUpdate(!isUpadate)
                //console.log("result---------->", clonePageData)
            }
        }

    }

    const selectedItem = (e, from, index, test = []) => {

        // console.log("E---------->", e.target.checked)
        // console.log("From---------->", from)
        // console.log("Index---------->", index)
        // console.log("2222222---------->", pageData[from][index])
        setIsModified(true);
        setModifiedName(from);
        setModifiedIndex([...modifiedIndex, pageData[from][index]._id])
        setIsEdited(true)
        let checkData = [];
        if (from === "staff") {
            checkData = staffListData
        }
        if (from === "books") {
            checkData = bookListData
        }
        if (from === "games") {
            checkData = gameListData
        }
        if (from === "challenges") {
            checkData = challengesListData;
        }
        if (!e.target.checked) {

            pageData[from][index].selected = false
            pageData[from][index].challengeId = parseInt(0);
            var index = checkData.indexOf(pageData[from][index]._id);
            if (index !== -1) {
                checkData.splice(index, 1);
            }
            if (from === "staff") {
                setStaffListData(checkData)
            }
            if (from === "books") {
                setBookListData(checkData)
            }
            if (from === "games") {
                setGameListData(checkData)
            }
            if (from === "challenges") {
                setChallengesListData(checkData)
            }
        } else {
            let extractChallengeId = [];
            pageData[from].map(val => {
                extractChallengeId.push(val.challengeId)
            })
            const result = extractChallengeId.sort((a, b) => a - b)

            pageData[from][index].challengeId = parseInt(pageData[from][index].challengeId) === 0 ? parseInt(result[result.length - 1]) + 1 : parseInt(pageData[from][index].challengeId);
            pageData[from][index].selected = true;
            // if (pageData[from][index].hasOwnProperty("selected")) {
            //     delete pageData[from][index].selected;
            // }
            //  const checkData = staffListData;

            if (from === "staff") {
                //  console.log(">>>>>>>>>>>>>>>>>>>>>>>>>", checkDuplication(pageData[from], 'staffId'))
                if (checkDuplication(pageData[from], 'staffId')) {
                    toast.error("Duplicate staff ID's are not allowed");
                    pageData[from][index].selected = false;
                    return;
                }
                checkData.push(pageData[from][index]._id)
                setStaffListData(checkData)
            }
            if (from === "books") {
                if (checkDuplication(pageData[from], 'bookId')) {
                    toast.error("Duplicate book ID's are not allowed");
                    pageData[from][index].selected = false;
                    return;
                }
                checkData.push(pageData[from][index]._id)
                setBookListData(checkData)
            }
            if (from === "games") {
                if (checkDuplication(pageData[from], 'gameId')) {
                    toast.error("Duplicate game ID's are not allowed");
                    pageData[from][index].selected = false;
                    return;
                }
                checkData.push(pageData[from][index]._id)
                setGameListData(checkData)
            }
            if (from === "challenges") {
                if (checkDuplication(pageData[from], 'challengeId')) {
                    toast.error("Duplicate challenge ID's are not allowed");
                    pageData[from][index].selected = false;
                    return;
                }
                checkData.push(pageData[from][index]._id)
                setChallengesListData(checkData)
            }

        }
        setIsUpdate(!isUpadate);
        // console.log("pageData.staffListData---------->", staffListData)
    }


    const getLatestArray = (from) => {
        if (from === "staff") {
            if (staffAction) {
                // console.log("sFilterClicked---------->", sFilterClicked)
                let staffData = sFilterClicked ? pageData.staff : pageData.staff.sort(FilterbySelected);
                staffData.map((value, index) => {
                    setValue(`staffId` + index, value.staffId)
                })
                //  console.log("challengeData------------>", challengeData)
                return staffData;
            } else {
                let results = pageData.staff.filter(check => {
                    return check.selected === true
                })

                return results;
            }
        }
        if (from === "books") {
            //console.log("challengeData------------>", booksList)
            if (bookAction) {
                let booksData = bFilterClicked ? pageData.books : pageData.books.sort(FilterbySelected);
                booksData.map((value, index) => {
                    setValue(`bookId` + index, value.bookId)
                })
                //  console.log("challengeData------------>", challengeData)
                return booksData;
            } else {
                let results = pageData.books.filter(check => {
                    return check.selected === true
                })
                return results;
            }
        }
        if (from === "games") {
            if (gameAction) {
                let gamesData = gFilterClicked ? pageData.games : pageData.games.sort(FilterbySelected);
                gamesData.map((value, index) => {
                    setValue(`gameId` + index, value.gameId)
                })
                //  console.log("challengeData------------>", challengeData)
                return gamesData;
            } else {
                let results = pageData.games.filter(check => {
                    return check.selected === true
                })
                return results;
            }
        }
        if (from === "challenges") {
            if (challengeAction) {
                // console.log("pageData------------>", pageData)
                let challengeData = false ? pageData.challenges : pageData.challenges.sort(FilterbySelected);
                challengeData.map((value, index) => {
                    setValue(`challengeId` + index, value.challengeId)
                })
                //  console.log("challengeData------------>", challengeData)
                return challengeData;
            } else {
                let results = pageData.challenges.filter(check => {
                    return check.selected === true
                })
                return results;
            }
        }
    }

    const resetData = (from) => {
        setSFilterClicked(false);
        setBFilterClicked(false);
        setGFilterClicked(false);
        setCFilterClicked(false);

        setStaffAction(false);
        setBookAction(false);
        setGameAction(false);
        setChallengeAction(false);
        if (isModified && !modifiedSave) {
            let obj = Array.from(new Set(modifiedIndex));
            obj.map(val => {
                const filterIndex = pageData[modifiedName].findIndex(values => values._id === val);
                pageData[modifiedName][filterIndex].selected = false;
            })
            // for (const property in obj) {
            //     console.log(`${property}: ${obj[property]}`);
            //     pageData[modifiedName][obj[property]].selected = false;
            // }

        }
        setIsModified(false);
        setModifiedIndex([]);
        setModifiedName("");
        setModifiedSave(false)
        // setPageData(pageData);
        // setIsUpdate(!isUpadate);
        if (from === 'staff') {
            // setBFilterClicked(false);
            // setGFilterClicked(false);
            // setCFilterClicked(false);
            setShowStaffAcor(!showStaffAcor);
            setShowBooksAcor(false);
            setShowGamesAcor(false);
            setShowChalAcor(false);
        }
        if (from === 'books') {
            // setBFilterClicked(false);
            // setGFilterClicked(false);
            // setCFilterClicked(false);
            setShowStaffAcor(false);
            setShowBooksAcor(!showBooksAcor);
            setShowGamesAcor(false);
            setShowChalAcor(false);
        }
        if (from === 'games') {
            // setBFilterClicked(false);
            // setGFilterClicked(false);
            // setCFilterClicked(false);
            setShowStaffAcor(false);
            setShowBooksAcor(false);
            setShowGamesAcor(!showGamesAcor);
            setShowChalAcor(false);
        }
        if (from === 'challenge') {
            // setBFilterClicked(false);
            // setGFilterClicked(false);
            // setCFilterClicked(false);
            setShowStaffAcor(false);
            setShowBooksAcor(false);
            setShowGamesAcor(false);
            setShowChalAcor(!showChalAcor);
        }
        setIsUpdate(!isUpadate)
    }

    const onclickHandler = () => {

    }

    const setChallengeId = (from, e, index) => {
        // console.log("e--------->", e.target.name)
        setIsModified(true);
        setModifiedName(from);

        setValue(e.target.name, e.target.value)
        if (from === 'staff') {
            pageData["staff"][index].staffId = parseInt(e.target.value);
            setModifiedIndex([...modifiedIndex, pageData["staff"][index]._id])
        }
        if (from === 'books') {
            pageData["books"][index].bookId = parseInt(e.target.value);
            setModifiedIndex([...modifiedIndex, pageData["books"][index]._id])
        }
        if (from === 'games') {
            pageData["games"][index].gameId = parseInt(e.target.value);
            setModifiedIndex([...modifiedIndex, pageData["games"][index]._id])
        }
        if (from === 'challenges') {
            pageData["challenges"][index].challengeId = parseInt(e.target.value);
            setModifiedIndex([...modifiedIndex, pageData["challenges"][index]._id])
        }

        //setIsEdited(true)

        // let existData = JSON.parse(JSON.stringify(pageData));
        // existData.challenges[index].challengeId = e.target.value
        // setPageData(existData);
        // setIsUpdate(!isUpadate);

    }

    return (
        <React.Fragment>
            <TopHeader />
            <DahboardMenu />
            {openImagePopup && <ImagePopup deleteData={deleteData} closeModal={closeImagePopup} modalIsOpen={openImagePopup} data={deleteData} />}
            {openChallegespopup && <ChallengePopup deleteData={deleteData} closeModal={closeChallengesPopup} modalIsOpen={openChallegespopup} data={deleteData} isEditedChallenges={isEditedChallenges} setIsEditedChallenges={setIsEditedChallenges} />}
            <div className="mt-10">
                <div className="
  max-w-[540px] sm:max-w-[604px] md:max-w-[720px] lg:max-w-[972px]
  xl:max-w-full xl:pl-28  xl:ml-40 xl:px-12 2xl:max-w-[1400px] mx-auto
 flex">

                    <div className="flex w-full flex-col ">

                        <div className="relative mb-8">
                            <form className="w-full bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 " name='conversion' onSubmit={handleSubmit(submitHandler, onError)}>
                                <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                                    <div>
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                            Hospital
                                        </label>
                                        <select data-te-select-init className="bg-gray-200 border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="hospital" name="hospital" {...register("hospital", { required: true })}>
                                            <option value="">Choose Hospital</option>
                                            {hospitalListStore.slice().reverse().map((val, index) => {
                                                return <option key={index} value={val._id}>{val.name}</option>
                                            })}
                                        </select>
                                        {errors.hospital && <span className='errSpan'>Required</span>}
                                    </div>
                                    <div>
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                            Ward
                                        </label>
                                        <select data-te-select-init className="bg-gray-200 border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="ward" name="ward" {...register("ward", { required: true })}>
                                            <option value="">Choose Ward</option>
                                            {wardListStore.slice().reverse().map((val, index) => {
                                                return <option key={index} value={val._id}>{val.name}</option>
                                            })}
                                        </select>
                                        {errors.ward && <span className='errSpan'>Required</span>}
                                    </div>
                                    <div>
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                            Age Group
                                        </label>
                                        <select data-te-select-init className="bg-gray-200 border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="age" name="age" {...register("age", { required: true })}>
                                            <option value="">Choose Age Group</option>
                                            {ageGroupListStore.slice().reverse().map((val, index) => {
                                                return <option key={index} value={val._id}>{val.name}</option>
                                            })}
                                        </select>
                                        {errors.age && <span className='errSpan'>Required</span>}
                                    </div>
                                    <div>
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                            Level
                                        </label>
                                        <select data-te-select-init className="bg-gray-200 border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="level" name="level" {...register("level", { required: true })}>
                                            <option value="">Choose Level</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                        </select>
                                        {errors.level && <span className='errSpan'>Required</span>}
                                    </div>
                                </div>

                                <div className="flex justify-between items-center mt-4">
                                    <a href='javascript:void(0);' className={buttonCSS.red} onClick={() => { reset(); setPageData([]) }} >Clear</a>
                                    <a href='javascript:void(0);' id="myButton" className={buttonCSS.blue} onClick={handleSubmit((data) => submitHandler(data), (err) => onError(err))}>Set</a>


                                </div>
                                {/* <div className="mb-4">
                                    <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                        Name
                                    </label>
                                    <input className={formInputClassName} autoComplete='off' placeholder='Name' id="name" name="name" {...register("name")} />
                                </div> */}
                            </form>



                            <div id="accordion-collapse" data-accordion="collapse">
                                <h2 id="accordion-collapse-heading-1">
                                    <button type="button" onClick={() => resetData("staff")} className="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                        <span><strong>Staffs Details</strong></span><strong className='text-blue-500' style={{ marginRight: "71%" }}>Total:{pageData.length !== 0 && getLatestArray("staff").length !== 0 ? getLatestArray("staff").length : 0}</strong>
                                        <svg data-accordion-icon className="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                        </svg>
                                    </button>
                                </h2>
                                <div id="accordion-collapse-body-1" className={showStaffAcor ? "" : "hidden"} aria-labelledby="accordion-collapse-heading-1">
                                    <div className="p-5 relative max-h-[750px] h-auto overflow-auto border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">

                                        {pageData.length !== 0 ? <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                                            <div className="flex justify-end">
                                                {/* <button className="bg-transparent hover:bg-transparent text-black font-bold py-2 px-4 rounded-full flex items-center" onClick={() => { navigate('/dashboard/staff') }}>
                                                    <svg className="h-6 w-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                                                    </svg>
                                                    Add
                                                </button> */}
                                                {staffAction ? <a href='javascript:void(0);' className="bg-purple-900 hover:bg-purple-900 text-white font-bold mb-2 py-2 px-4 rounded" onClick={() => { saveSelectedItem("staff") }} >Save</a> :
                                                    <a href='javascript:void(0);' className="bg-purple-900 hover:bg-purple-900 text-white font-bold mb-2 py-2 px-4 rounded" onClick={() => { setStaffAction(!staffAction) }} >Edit & Select</a>}
                                            </div>
                                            <table className="w-full text-xs text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400  top-0 sticky">
                                                    <tr>
                                                        <th scope="col" className="px-6 py-3">

                                                            <div className='flex items-center'>
                                                                <p> Game&nbsp;Staff&nbsp;ID</p>
                                                                <a href='javascript:void(0);' onClick={() => filterData(staffIdFilter, "staff", "staffId")}>
                                                                    {staffIdFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                                    </svg> :
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                                        </svg>}
                                                                </a>

                                                            </div>
                                                        </th>

                                                        <th scope="col" className="px-6 py-3">

                                                            <div className='flex items-center'>
                                                                <p> Staff&nbsp;ID</p>
                                                                <a href='javascript:void(0);' onClick={() => filterData(staffGIdFilter, "staff", "G_staffId")}>
                                                                    {staffGIdFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                                    </svg> :
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                                        </svg>}
                                                                </a>

                                                            </div>
                                                        </th>
                                                        <th scope="col" className="px-6 py-3">
                                                            <div className='flex items-center'>
                                                                <p> Staff&nbsp;Name</p>
                                                                <a href='javascript:void(0);' onClick={() => filterData(staffNameFilter, "staff", "staffName")}>
                                                                    {staffNameFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                                    </svg> :
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                                        </svg>}
                                                                </a>

                                                            </div>
                                                        </th>
                                                        <th scope="col" className="px-6 py-3">
                                                            <div className='flex items-center'>
                                                                <p> Role</p>
                                                                <a href='javascript:void(0);' onClick={() => filterData(staffRoleFilter, "staff", "role")}>
                                                                    {staffRoleFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                                    </svg> :
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                                        </svg>}
                                                                </a>

                                                            </div>
                                                        </th>
                                                        <th scope="col" className="px-6 py-3 whitespace-pre-wrap">

                                                            <div className='flex items-center'>
                                                                <p> Department</p>
                                                                <a href='javascript:void(0);' onClick={() => filterData(staffDepFilter, "staff", "department")}>
                                                                    {staffDepFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                                    </svg> :
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                                        </svg>}
                                                                </a>

                                                            </div>
                                                        </th>
                                                        <th scope="col" colSpan={staffAction ? "1" : "2"} className="px-6 py-3">
                                                            Video Link
                                                        </th>
                                                        <th scope="col" className="px-6 py-3">
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {pageData?.staff && getLatestArray("staff").map((val, index) => {
                                                        //const { staffDetails } = val;
                                                        //console.log("val------------------>", val)
                                                        return <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                                            <th className="px-6 py-4 w-auto font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                                {staffAction ?
                                                                    <input autoComplete='off'
                                                                        type="number" {...register('staffId' + index, { onChange: e => { setChallengeId("staff", e, index); setIsEdited(true) } })} id="staffId" className="w-14 p-2 text-center border-2 border-black [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none" placeholder=" " /> : val?.staffId}
                                                            </th>
                                                            <td className="px-6 py-4">
                                                                {val?.G_staffId}
                                                            </td>
                                                            <td className="px-6 py-4">
                                                                {val?.staffName[0]?.value}
                                                            </td>
                                                            <td className="px-6 py-4">
                                                                {val?.role}
                                                            </td>
                                                            <th className="px-6 py-4 w-auto font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                                {val?.department}
                                                            </th>


                                                            <td colSpan={staffAction ? "1" : "2"} className="px-6 py-4">
                                                                <a href={val?.videoLink} target="_blank">{val?.videoLink}</a>
                                                            </td>
                                                            <td className="px-6 py-4">
                                                                {/* <a href="javascript:void(0);" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => editItem("staff", staffDetails?._id)}>Edit</a>/&nbsp;
                                                                <a href="javascript:void(0);" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => { openDelete(val, val?._id, index, "GameStaff") }}>Delete</a> */}
                                                                {staffAction && <div class="flex items-center mb-4">
                                                                    <input id="default-checkbox" onChange={e => selectedItem(e, "staff", index, pageData)} type="checkbox" checked={val?.selected} value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                </div>}
                                                            </td>
                                                        </tr>

                                                    })}
                                                    {getLatestArray("staff").length === 0 && <tr className="bg-white border-b text-lg dark:bg-gray-800 dark:border-gray-700">
                                                        <th scope="row" colSpan={8} className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                            Please select and add staffs
                                                        </th>
                                                    </tr>}

                                                </tbody>
                                            </table>
                                        </div> : <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                            <th scope="row" colSpan={4} className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                No data found
                                            </th>
                                        </tr>}
                                    </div>
                                </div>
                                <h2 id="accordion-collapse-heading-2">
                                    <button type="button" onClick={() => resetData("books")} className="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-2" aria-expanded="false" aria-controls="accordion-collapse-body-2">
                                        <span><strong>Books Details</strong></span><strong className='text-blue-500' style={{ marginRight: "71%" }}>Total:{pageData.length !== 0 && getLatestArray("books").length !== 0 ? getLatestArray("books").length : 0}</strong>
                                        <svg data-accordion-icon className="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                        </svg>
                                    </button>

                                </h2>
                                <div id="accordion-collapse-body-2" className={showBooksAcor ? "" : "hidden"} aria-labelledby="accordion-collapse-heading-2">
                                    <div className="p-5 border relative max-h-[750px] h-auto overflow-auto border-b-0 border-gray-200 dark:border-gray-700">
                                        {pageData.length !== 0 ? <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                                            <div className="flex justify-end">
                                                {/* <button className="bg-transparent hover:bg-transparent text-black font-bold py-2 px-4 rounded-full flex items-center" onClick={() => { navigate('/dashboard/books') }}>
                                                    <svg className="h-6 w-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                                                    </svg>
                                                    Add
                                                </button> */}
                                                {bookAction ? <a href='javascript:void(0);' className="bg-purple-900 hover:bg-purple-900 text-white font-bold mb-2 py-2 px-4 rounded" onClick={() => { saveSelectedItem("books") }} >Save</a> :
                                                    <a href='javascript:void(0);' className="bg-purple-900 hover:bg-purple-900 text-white font-bold mb-2 py-2 px-4 rounded" onClick={() => { setBookAction(!bookAction) }} >Edit & Select</a>}
                                            </div>
                                            <table className="w-full text-xs text-left rtl:text-right text-gray-500 dark:text-gray-400 overflow-auto top-0 sticky">
                                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                                    <tr>
                                                        <th scope="col" className="px-6 py-3">

                                                            <div className='flex items-center'>
                                                                <p>Game&nbsp;Book&nbsp;ID</p>
                                                                <a href='javascript:void(0);' onClick={() => filterData(bookIdFilter, "books", "bookId")}>
                                                                    {bookIdFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                                    </svg> :
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                                        </svg>}
                                                                </a>

                                                            </div>
                                                        </th>
                                                        <th scope="col" className="px-6 py-3">

                                                            <div className='flex items-center'>
                                                                <p>Book&nbsp;ID</p>
                                                                <a href='javascript:void(0);' onClick={() => filterData(bookGIdFilter, "books", "G_bookId")}>
                                                                    {bookGIdFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                                    </svg> :
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                                        </svg>}
                                                                </a>

                                                            </div>
                                                        </th>
                                                        <th scope="col" className="px-6 py-3">
                                                            <div className='flex items-center'>
                                                                <p>Name</p>
                                                                <a href='javascript:void(0);' onClick={() => filterData(bookNameFilter, "books", "bookName")}>
                                                                    {bookNameFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                                    </svg> :
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                                        </svg>}
                                                                </a>

                                                            </div>
                                                        </th>
                                                        <th scope="col" className="px-6 py-3">

                                                            <div className='flex items-center'>
                                                                <p>Description</p>
                                                                <a href='javascript:void(0);' onClick={() => filterData(bookDescFilter, "books", "bookDescription")}>
                                                                    {bookDescFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                                    </svg> :
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                                        </svg>}
                                                                </a>

                                                            </div>
                                                        </th>
                                                        <th scope="col" className="px-6 py-3">
                                                            Video Link
                                                        </th>
                                                        <th scope="col" className="px-6 py-3">
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {pageData?.books && getLatestArray("books").map((val, index) => {
                                                        // const { bookDetails } = val;
                                                        //console.log("val------------------->", pageData?.books)
                                                        return <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                                {bookAction ?
                                                                    <input autoComplete='off'
                                                                        type="number" {...register('bookId' + index, { onChange: e => { setChallengeId("books", e, index); setIsEdited(true) } })} id="challengeId" className="w-14 p-2 text-center border-2 border-black [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none" placeholder=" " /> : val?.bookId}
                                                            </th>
                                                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                                {val?.G_bookId}
                                                            </th>
                                                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                                {val?.bookName[0]?.value}
                                                            </th>
                                                            <td className="px-6 py-4">
                                                                {val?.bookDescription[0]?.value}
                                                            </td>
                                                            <td className="px-6 py-4">
                                                                <a href={val?.videoLink} target="_blank">{val?.videoLink}</a>
                                                            </td>
                                                            <td className="px-6 py-4">
                                                                {/* <a href="javascript:void(0);" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => { editItem("books", bookDetails?._id) }}>Edit</a>/&nbsp;
                                                                <a href="javascript:void(0);" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => { openDelete(val, val?._id, index, "GameBook") }}>Delete</a> */}
                                                                {bookAction && <div class="flex items-center mb-4">
                                                                    <input id="default-checkbox" onChange={e => selectedItem(e, "books", index)} type="checkbox" checked={val?.selected} value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                </div>}
                                                            </td>
                                                        </tr>

                                                    })}

                                                    {getLatestArray("books").length === 0 && <tr className="bg-white text-lg border-b dark:bg-gray-800 dark:border-gray-700">
                                                        <th scope="row" colSpan={7} className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                            Please select and add books
                                                        </th>
                                                    </tr>}
                                                </tbody>
                                            </table>
                                        </div> : <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                            <th scope="row" colSpan={4} className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                No data found
                                            </th>
                                        </tr>}
                                    </div>
                                </div>
                                <h2 id="accordion-collapse-heading-3">
                                    <button type="button" onClick={() => resetData("games")} className="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-3" aria-expanded="false" aria-controls="accordion-collapse-body-3">
                                        <span><strong> Games Details</strong></span><strong className='text-blue-500' style={{ marginRight: "71%" }}>Total:{pageData.length !== 0 && getLatestArray("games").length !== 0 ? getLatestArray("games").length : 0}</strong>
                                        <svg data-accordion-icon className="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                        </svg>
                                    </button>

                                </h2>
                                <div id="accordion-collapse-body-3" className={showGamesAcor ? "" : "hidden"} aria-labelledby="accordion-collapse-heading-3">
                                    {pageData.length !== 0 ? <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
                                        <div className="flex justify-end">
                                            {/* <button className="bg-transparent hover:bg-transparent text-black font-bold py-2 px-4 rounded-full flex items-center" onClick={() => { navigate('/dashboard/games') }}>
                                                <svg className="h-6 w-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                                                </svg>
                                                Add
                                            </button> */}
                                            {gameAction ? <a href='javascript:void(0);' className="bg-purple-900 hover:bg-purple-900 text-white font-bold mb-2 py-2 px-4 rounded" onClick={() => { saveSelectedItem("games") }} >Save</a> :
                                                <a href='javascript:void(0);' className="bg-purple-900 hover:bg-purple-900 text-white font-bold mb-2 py-2 px-4 rounded" onClick={() => { setGameAction(!gameAction) }} >Edit & Select</a>}
                                        </div>
                                        <div className="relative max-h-[750px] h-auto overflow-auto shadow-md sm:rounded-lg">
                                            <table className="w-full text-xs text-left rtl:text-right text-gray-500 dark:text-gray-400  max-h-[750px] h-auto overflow-auto">
                                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0">
                                                    <tr>
                                                        <th className="px-6 py-3 break-normal whitespace-pre-wrap w-[15%]">
                                                            <div className='flex items-center'>
                                                                <p>Game&nbsp;GameId</p>
                                                                <a href='javascript:void(0);' onClick={() => filterData(gameIdFilter, "games", "gameId")}>
                                                                    {gameIdFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                                    </svg> :
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                                        </svg>}
                                                                </a>

                                                            </div>
                                                        </th>
                                                        <th className="px-6 py-3 break-normal whitespace-pre-wrap w-[15%]">
                                                            <div className='flex items-center'>
                                                                <p>GameId</p>
                                                                <a href='javascript:void(0);' onClick={() => filterData(gameGIdFilter, "games", "G_gameId")}>
                                                                    {gameGIdFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                                    </svg> :
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                                        </svg>}
                                                                </a>

                                                            </div>
                                                        </th>
                                                        <th className="px-6 py-3 break-normal whitespace-pre-wrap w-[15%]">
                                                            <div className='flex items-center'>
                                                                <p>Name</p>
                                                                <a href='javascript:void(0);' onClick={() => filterData(gameNameFilter, "games", "name")}>
                                                                    {gameNameFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                                    </svg> :
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                                        </svg>}
                                                                </a>

                                                            </div>
                                                        </th>
                                                        <th className="px-6 py-3 break-normal whitespace-pre-wrap w-[35%]">

                                                            <div className='flex items-center'>
                                                                <p>Description</p>
                                                                <a href='javascript:void(0);' onClick={() => filterData(gameDescFilter, "games", "description")}>
                                                                    {gameDescFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                                    </svg> :
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                                        </svg>}
                                                                </a>

                                                            </div>
                                                        </th>
                                                        <th className="px-6 py-3">
                                                            Video Link
                                                        </th>
                                                        <th className="px-6 py-3">
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {pageData?.games && getLatestArray("games").map((val, index) => {
                                                        // console.log("val------------>", val)
                                                        //const { gameDetails } = val;
                                                        return <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 break-normal whitespace-pre-wrap dark:text-white">
                                                                {gameAction ?
                                                                    <input autoComplete='off'
                                                                        type="number" {...register('gameId' + index, { onChange: e => { setChallengeId("games", e, index); setIsEdited(true) } })} id="challengeId" className="w-14 p-2 text-center border-2 border-black [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none" placeholder=" " /> : val?.gameId}
                                                            </th>
                                                            <th className="px-6 py-4">
                                                                {val?.G_gameId}
                                                            </th>
                                                            <th className="px-6 py-4">
                                                                {val?.name}
                                                            </th>
                                                            <td className="px-6 py-4">
                                                                {val?.description}
                                                            </td>
                                                            <td className="px-6 py-4">
                                                                <a href={val?.link} target="_blank">{val?.link}</a>
                                                            </td>
                                                            <td className="px-6 py-4">
                                                                {/* <a href="javascript:void(0);" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => { editItem("games", gameDetails?._id) }}>Edit</a>/&nbsp;
                                                                <a href="javascript:void(0);" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => { openDelete(val, val?._id, index, "GameLevel") }}>Delete</a> */}
                                                                {gameAction && <div class="flex items-center mb-4">
                                                                    <input id="default-checkbox" onChange={e => selectedItem(e, "games", index)} type="checkbox" checked={val?.selected} value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                </div>}
                                                            </td>
                                                        </tr>

                                                    })}
                                                    {getLatestArray("games").length === 0 && <tr className="bg-white text-lg border-b dark:bg-gray-800 dark:border-gray-700">
                                                        <th scope="row" colSpan={8} className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                            Please select and add games
                                                        </th>
                                                    </tr>}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div> :
                                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                            <th scope="row" colSpan={4} className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                No data found
                                            </th>
                                        </tr>}
                                </div>
                                <h2 id="accordion-collapse-heading-4">
                                    <button type="button" onClick={() => resetData("challenge")} className="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" data-accordion-target="#accordion-collapse-body-4" aria-expanded="false" aria-controls="accordion-collapse-body-3">
                                        <span><strong> Challenges Details</strong></span><strong className='text-blue-500' style={{ marginRight: "73%" }}>Total:{pageData.length !== 0 && getLatestArray("challenges").length !== 0 ? getLatestArray("challenges").length : 0}</strong>
                                        <svg data-accordion-icon className="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5" />
                                        </svg>
                                    </button>
                                </h2>
                                <div id="accordion-collapse-body-4" className={showChalAcor ? "" : "hidden"} aria-labelledby="accordion-collapse-heading-4">
                                    {pageData.length !== 0 ? <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
                                        <div className="flex justify-end">
                                            {/* <button className="bg-transparent hover:bg-transparent text-black font-bold py-2 px-4 rounded-full flex items-center" onClick={() => { navigate('/dashboard/addchallenge') }}>
                                                <svg className="h-6 w-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                                                </svg>
                                                Add
                                            </button> */}
                                            {challengeAction ? <a href='javascript:void(0);' className="bg-purple-900 hover:bg-purple-900 text-white font-bold mb-2 py-2 px-4 rounded" onClick={() => { saveSelectedItem("challenges") }} >Save</a> :
                                                <a href='javascript:void(0);' className="bg-purple-900 hover:bg-purple-900 text-white font-bold mb-2 py-2 px-4 rounded" onClick={() => { setChallengeAction(!challengeAction) }} >Edit & Select</a>}
                                        </div>
                                        <div className="relative max-h-[750px] h-auto overflow-auto shadow-md sm:rounded-lg">
                                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0">
                                                    <tr>
                                                        <th scope="col" className="px-6 py-3">
                                                            <div className='flex items-center'>
                                                                <p>Game ChallengeId</p>
                                                                <a href='javascript:void(0);' onClick={() => filterData(chaIdFilter, "challenges", "challengeId")}>
                                                                    {chaIdFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                                    </svg> :
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                                        </svg>}
                                                                </a>

                                                            </div>
                                                        </th>
                                                        <th scope="col" className="px-6 py-3">
                                                            <div className='flex items-center'>
                                                                <p>ChallengeId</p>
                                                                <a href='javascript:void(0);' onClick={() => filterData(chaGIdFilter, "challenges", "G_challengeId")}>
                                                                    {chaGIdFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                                    </svg> :
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                                        </svg>}
                                                                </a>

                                                            </div>
                                                        </th>
                                                        <th scope="col" className="px-6 py-3">
                                                            <div className='flex items-center'>
                                                                <p>Title</p>
                                                                <a href='javascript:void(0);' onClick={() => filterData(chaTitleFilter, "challenges", "challengeTitle")}>
                                                                    {chaTitleFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                                    </svg> :
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                                        </svg>}
                                                                </a>

                                                            </div>
                                                        </th>
                                                        <th scope="col" className="px-6 py-3">

                                                            <div className='flex items-center'>
                                                                <p>Question</p>
                                                                <a href='javascript:void(0);' onClick={() => filterData(chaQuesFilter, "challenges", "questionTitle")}>
                                                                    {chaQuesFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                                    </svg> :
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                                        </svg>}
                                                                </a>

                                                            </div>
                                                        </th>
                                                        <th scope="col" className="px-6 py-3">
                                                            Image
                                                        </th>
                                                        <th scope="col" className="px-6 py-3">

                                                            <div className='flex items-center'>
                                                                <p>Level</p>
                                                                <a href='javascript:void(0);' onClick={() => filterData(chaLevelFilter, "challenges", "Level")}>
                                                                    {chaLevelFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                                    </svg> :
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                                        </svg>}
                                                                </a>

                                                            </div>
                                                        </th>
                                                        <th scope="col" className="px-6 py-3">
                                                            Action
                                                        </th>
                                                        <th scope="col" className="px-6 py-3">
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {pageData?.challenges && getLatestArray("challenges").map((val, index) => {
                                                        // console.log("val-------------->", val.challengeId)
                                                        //const { challengeDetails } = val;
                                                        return <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                                {/* {val?.challengeId} */}
                                                                {challengeAction ?
                                                                    <input autoComplete='off'
                                                                        type="number" {...register('challengeId' + index, { onChange: e => { setChallengeId("challenges", e, index); setIsEdited(true) } })} id="challengeId" className="w-14 p-2 text-center border-2 border-black [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none" placeholder=" " /> : val?.challengeId}
                                                            </th>
                                                            <th scope="row" className="px-6 py-4 font-medium" id={`chatitleid` + index}>
                                                                {val?.G_challengeId}
                                                            </th>
                                                            <th scope="row" className="px-6 py-4 font-medium" id={`chatitleid` + index}>
                                                                {val?.challengeTitle[0]?.value}
                                                            </th>
                                                            <td className="px-6 py-4" id={`chaquestitleid` + index}>
                                                                {val?.questionTitle[0]?.value}
                                                            </td>
                                                            <td className="px-6 py-4">
                                                                {val?.challengeImage && <div class="bg-indigo-300">
                                                                    <a href='javascript:void(0);' onClick={() => openImage(val?.challengeImage)}><img id={`chaimageid` + index} src={val?.challengeImage} class="object-cover h-7 w-10" /></a>
                                                                </div>}
                                                            </td>
                                                            <td className="px-6 py-4" id={`chalevelid` + index}>
                                                                {val?.Level}
                                                            </td>
                                                            <td className="px-6 py-4">
                                                                <a href="javascript:void(0);" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => { editItem("challenges", val?._id, index) }}>Edit</a>
                                                            </td>
                                                            <td className="px-6 py-4">
                                                                {/* <a href="javascript:void(0);" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => { editItem("challenges", challengeDetails?._id) }}>Edit</a>/&nbsp;
                                                                <a href="javascript:void(0);" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => { openDelete(val, val?._id, index, "GameLevel") }}>Delete</a> */}
                                                                {challengeAction && <div class="flex items-center mb-4">
                                                                    <input id="default-checkbox" onChange={e => selectedItem(e, "challenges", index)} type="checkbox" checked={val.selected} value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                                </div>}
                                                            </td>
                                                        </tr>
                                                    })}
                                                    {getLatestArray("challenges").length === 0 && <tr className="bg-white text-lg border-b dark:bg-gray-800 dark:border-gray-700">
                                                        <th scope="row" colSpan={8} className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                            Please select and add challenges
                                                        </th>
                                                    </tr>}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div> :
                                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                            <th scope="row" colSpan={4} className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                No data found
                                            </th>
                                        </tr>}
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default GameLevel