import React from 'react'
import Userheader from './Userheader'

const HomePage = () => {
    return (
        <div>
            {/* <div className="bg-gray-100">
                <div className="bg-black p-8 shadow-lg flex">
                    <div className="w-11/12 p-2 bg-black"><img src="../assets/img/TadamLogoSide.png" alt="Image" className="mx-auto" /></div>
                    <div className="w-1/2 p-2 bg-black"><div>login</div></div>
                </div>
            </div> */}
            {/* <div className="bg-gray-100 p-9">
                <div className="bg-black p-8 shadow-lg flex">
                    <div className="md:w-11/12 p-2 bg-black h-1/6">
                        <img src="../assets/img/TadamLogoSide.png" alt="Image" className="mx-auto" />
                    </div>
                    <div className="md:w-1/6 p-2 bg-black flex items-center justify-center">
                        <div className="text-center">
                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
                                Centered Button
                            </button>
                        </div>
                        <div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="bg-gray-100 bg-gradient-to-r from-violet-200 to-pink-200 h-screen">
                {/* <div className="bg-black p-8 shadow-lg flex flex-col md:flex-row p-9">
                    <div className="md:w-11/12 p-2 bg-black h-1/6">
                        <img src="../assets/img/TadamLogoSide.png" alt="Image" className="" />
                    </div>

                    <div className="md:w-1/6 p-2 bg-black flex items-center justify-center">
                        <div className="text-center">
                            <a className="bg-yellow-400 text-violet-600 font-bold py-2 px-4 rounded-full" href='/login'>
                                Login
                            </a>
                        </div>
                        <div>

                        </div>
                    </div>
                </div> */}
                <Userheader />
                <div className="shadow-lg flex flex-col md:flex-row">
                    <div className="md:w-1/2 p-2  h-1/6">
                        <img src="../assets/img/Homepage6.png" alt="Image" className="mx-auto" />
                    </div>

                    <div className="md:w-1/2 p-2  flex items-start">
                        <div className="text-start">
                            <h1 className="text-3xl font-extrabold dark:text-white">Welcome to TaDam Backend!</h1>
                            <br></br>
                            <br></br>
                            <p className="text-gray-700 text-lg font-medium">
                                <strong>TaDam</strong> is more than just a name; it's a meaningful fusion of elements that perfectly
                                encapsulates  the  essence  of  your  app  and  its  mission.  The  wordplay  in  "TaDam"
                                stems  from  two  crucial  components:  "Ta"  (cell)  and  "Dam"  (blood),  creating  a
                                connection  to  the  medical  context  of  leukemia.  Beyond  its  medical  significance,
                                "TaDam"  resonates  with  celebration  –  the  sound  itself  is  joyful  and  exciting.  This
                                mirrors the experience you're crafting for the young patients, turning their journey
                                into one of hope and positivity.
                            </p>
                            <br></br>
                            <p className="text-gray-700 text-lg font-medium">
                                <strong>
                                    Slogan: Learning, Laughter, Life: Where Joy Meets Knowledge
                                </strong>
                            </p>
                            <br></br>
                            <p className="text-gray-700 text-lg font-medium">
                                The  slogan  effortlessly  echoes  and  enhances  the  app's  name.  It's  a  harmonious
                                blend of emotions and concepts that embody the app's purpose. <strong>Learning</strong> signifies
                                the  educational  aspect,  offering  knowledge  about  treatments  and  protocols.
                                <strong>Laughter</strong> embodies the entertainment and joy that come from engaging activities
                                and  games.  <strong>Life</strong>  emphasizes  the  broader  perspective,  focusing  on  overall  well-
                                being. The phrase "<strong>Where Joy Meets Knowledge</strong>" beautifully brings together the
                                idea that while medical knowledge is essential, the journey should also be filled with
                                happiness and positivity.
                            </p>
                            <br></br>
                            <p className="text-gray-700 text-lg font-medium">
                                In this powerful synergy of the name "TaDam" and the slogan "Learning, Laughter,
                                Life: Where Joy Meets Knowledge," this solution not only provides vital information
                                but also wraps it in an atmosphere of empowerment, positivity, and a celebration
                                of life.
                            </p>
                            <br></br>
                            <p className="text-gray-700 text-lg font-medium">
                                We're here to make patients & families journey a little brighter.
                                They will explore, play, and embrace the power of positivity as they navigate their
                                path to healing.
                            </p>
                            <br></br>
                            {/* <p className="text-gray-700 text-lg font-medium">
                                Contact Us
                                <br></br>
                                Email: <a href="mailto:support@hip-it.com" className="text-blue-500 hover:underline">support@hip-it.com</a>
                                <br></br>
                                Phone: <a href="tel:+972543977794" className="text-blue-500 hover:underline">+972-54-3977794</a>
                            </p> */}
                        </div>
                        <div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export { HomePage }
