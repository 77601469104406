import React from 'react'

const Input = (props) => {
  //console.log("props------------>", props)
  const { name, id, onChange, onBlur, ref, defaultValue, register, onKeyUp, style, value } = props
  return (

    // <input
    //   autoComplete='off'
    //   name={name}
    //   id={id}
    //   onChange={onChange}
    //   onBlur={onBlur}
    //   onKeyUp={onKeyUp}
    //   ref={ref}
    //   defaultValue={defaultValue}
    //   register={register}
    //   style={style}
    //   value={value}
    //   className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500 mb-4" type="text" />
    <input
      autoComplete='off'
      {...props}
      className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500 mb-4" />
  )
}

const TextArea = (props) => {
  return (
    <textarea  {...props} className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500 mb-4" type="text" />
  )
}


export { Input, TextArea }