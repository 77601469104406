import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { getAllDiseasesAction, getStaffUpdatedAction } from '../../../Store/Action';
import { setMedicineDataById, setEditOptionData, setAllDiseases } from '../../../Store/Reducer';
import { FilterbyStringAsending, FilterbyStringDsending, FilterbyFiledAsending, FilterbyFiledDsending } from '../../../util/helper';
import DeleteItemPopup from '../../Popups/DeleteItemPopup';
import { DahboardMenu } from '../DahboardMenu';
import { TopHeader } from '../TopHeader';
import DIseaseQRCodePopup from './DIseaseQRCodePopup';
import { buttonCSS } from '../../../util/contant';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getAllDiseaseURL } from '../../../Api/ContantUrls';
import { ThreeDots } from 'react-loader-spinner';

const DiseaseList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const allDiseaseStore = useSelector(state => state?.allDiseaseStore);
    //const { allDiseaseStore } = store;
    const [diseaseList, setDiseaseList] = useState([]);
    const [isOpenQR, setIsOpenQR] = useState(false);
    const [qrVal, setQrVal] = useState("");
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [deleteData, setDeleteData] = useState([]);
    const [callOnce, setCallOnce] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);

    const [dTypeFilter, setDtypeFilter] = useState(false);
    const [dNameFilter, setDnameFilter] = useState(false);
    const [dDiagnosisFilter, setDdiagnosisFilter] = useState(false);
    const [dYearFilter, setDYearFilter] = useState(false);
    const [dVersionFilter, setDVersionFilter] = useState(false);


    const [hasMore, setHasMore] = useState(true)
    const [pageCount, setPageCount] = useState(20)
    const [pageNumber, setPageNumber] = useState(1)


    const editDataIds = [];

    useEffect(() => {
        if (allDiseaseStore.length === 0) {
            getData();
        }
        document.title = 'Diseases list';

        if (allDiseaseStore.length !== 0 && !callOnce) {
            setDiseaseList(allDiseaseStore)
            allDiseaseStore.map((val) => {
                editDataIds.push(val._id)
            })
            dispatch(setEditOptionData(editDataIds))
            setCallOnce(!callOnce)
            if (allDiseaseStore.length < pageCount) {
                setHasMore(false)
            }
        }
    }, [isOpenQR, qrVal, allDiseaseStore, isUpdate])
    useEffect(() => () => {
        dispatch(setAllDiseases([]))
    }, []);
    const getData = async () => {
        const reqObj = {
            "getLatestFirst": true,
            "pageNumber": pageNumber,
            "numberOfRecordsPerPage": pageCount,
            "filter": {
                "active": true
            }
        }
        dispatch(await getAllDiseasesAction(reqObj));
    }

    const selectMedicine = (id) => {
        dispatch(setMedicineDataById({}))
        navigate('/dashboard/diseasetype?id=' + id)
    }

    const openDelete = (data) => {
        setDeleteData(data);
        setOpenDeletePopup(true);
    }

    const closeDeletePopup = () => {
        setOpenDeletePopup(false);
    }

    const openQrPopup = (val) => {
        setQrVal(val)
        setIsOpenQR(true);

    }

    const closeQrPopup = () => {
        setQrVal("")
        setIsOpenQR(false);

    }

    const fetchMoreData = () => {
        setTimeout(async () => {
            const reqObj = {
                "getLatestFirst": true,
                "pageNumber": pageNumber + 1,
                "numberOfRecordsPerPage": pageCount,
                "filter": {
                    "active": true
                }
            }

            const response = await getStaffUpdatedAction(dispatch, getAllDiseaseURL, reqObj, false);
            if (response?.status === 200 && response?.data) {
                setPageNumber(pageNumber + 1)
                const { payload } = response?.data;
                const { diseaseTypeList } = payload;
                if (diseaseTypeList.length !== 0) {
                    const protoDatas = [...diseaseList, ...diseaseTypeList]
                    setDiseaseList(protoDatas)

                    protoDatas.map(val => {
                        editDataIds.push(val._id)
                    })
                    dispatch(setEditOptionData(editDataIds))

                    if (diseaseTypeList.length < pageCount) {
                        setHasMore(false)
                        dispatch(setAllDiseases(diseaseList));
                    }
                } else {
                    setHasMore(false)
                }
                // setHasMore(true)

            } else {
                setDiseaseList(diseaseList);
                setHasMore(false)
                // toast.error("Failed")
            }
            setIsUpdate(!isUpdate)
        }, 500);
    }

    const filterData = (val, name) => {
        let check = val;
        let filterData = [];
        if (name === 'diseaseType') {
            setDtypeFilter(!dTypeFilter);
            filterData = check ? FilterbyStringAsending(diseaseList, name) : FilterbyStringDsending(diseaseList, name);
        }
        if (name === 'disease') {
            setDnameFilter(!dNameFilter);
            filterData = check ? FilterbyStringAsending(diseaseList, name) : FilterbyStringDsending(diseaseList, name);
        }
        if (name === 'diagnosis') {
            setDdiagnosisFilter(!dDiagnosisFilter);
            filterData = check ? FilterbyStringAsending(diseaseList, name) : FilterbyStringDsending(diseaseList, name);
        }
        if (name === 'year') {
            setDYearFilter(!dYearFilter);
            filterData = check ? FilterbyFiledAsending(diseaseList, name) : FilterbyFiledDsending(diseaseList, name);
        }
        if (name === 'version') {
            setDVersionFilter(!dVersionFilter);
            filterData = check ? FilterbyFiledAsending(diseaseList, name) : FilterbyFiledDsending(diseaseList, name);
        }
        filterData.map((val) => {
            editDataIds.push(val?._id)
        })
        dispatch(setEditOptionData(editDataIds))
        setDiseaseList(filterData);
        setIsUpdate(!isUpdate);
    }

    return (
        <React.Fragment>
            {isOpenQR && <DIseaseQRCodePopup modalIsOpen={isOpenQR} qrData={qrVal} closeModal={closeQrPopup} qrVal={qrVal.diseaseQRCode} name={qrVal.disease[0].value} />}
            {openDeletePopup && <DeleteItemPopup closeModal={closeDeletePopup} modalIsOpen={openDeletePopup} isFrom="disease" data={deleteData} />}
            <TopHeader />
            <DahboardMenu />
            <div className="mt-10 h-screen" >
                <div className="
      max-w-[540px] sm:max-w-[604px] md:max-w-[720px] lg:max-w-[972px]
      xl:max-w-full xl:pl-40  xl:ml-40 xl:px-12 2xl:max-w-[1400px] mx-auto
     flex">
                    <div className="flex w-full flex-col ">
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                            <div className="flex justify-end pb-3">
                                <div id="sticky-banner" tabindex="-1" class="fixed top-16 z-50 flex">
                                    <div class="flex items-end mx-auto">
                                        <button className={buttonCSS.addbtn} onClick={() => navigate('/dashboard/diseasetype')}>
                                            <svg className="h-6 w-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                                            </svg>
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <InfiniteScroll
                                dataLength={diseaseList.length}
                                next={fetchMoreData}
                                loader={diseaseList.length !== 0 && <ThreeDots
                                    visible={true}
                                    height="80"
                                    width="80"
                                    color="#4fa94d"
                                    radius="9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                />}
                                hasMore={hasMore}
                                endMessage={
                                    <p style={{ textAlign: "center" }}>
                                        <b className='text-blue-500'>Yay! You have seen it all</b>
                                    </p>
                                }
                            >
                                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                        <tr>
                                            <th scope="col" className="px-6 py-3">
                                                <div className='flex items-center'>
                                                    <p> Disease Type</p>
                                                    <a href='javascript:void(0);' onClick={() => filterData(dTypeFilter, "diseaseType")}>
                                                        {dTypeFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                        </svg> :
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                            </svg>}
                                                    </a>
                                                </div>
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                <div className='flex items-center'>
                                                    <p> Disease</p>
                                                    <a href='javascript:void(0);' onClick={() => filterData(dNameFilter, "disease")}>
                                                        {dNameFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                        </svg> :
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                            </svg>}
                                                    </a>
                                                </div>
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                <div className='flex items-center'>
                                                    <p> Diagnosis</p>
                                                    <a href='javascript:void(0);' onClick={() => filterData(dDiagnosisFilter, "diagnosis")}>
                                                        {dDiagnosisFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                        </svg> :
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                            </svg>}
                                                    </a>
                                                </div>
                                            </th>

                                            <th scope="col" className="px-6 py-3">
                                                <div className='flex items-center'>
                                                    <p> Year</p>
                                                    <a href='javascript:void(0);' onClick={() => filterData(dYearFilter, "year")}>
                                                        {dYearFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                        </svg> :
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                            </svg>}
                                                    </a>
                                                </div>
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                <div className='flex items-center'>
                                                    <p> Version</p>
                                                    <a href='javascript:void(0);' onClick={() => filterData(dVersionFilter, "version")}>
                                                        {dVersionFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                        </svg> :
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                            </svg>}
                                                    </a>
                                                </div>
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {diseaseList.length !== 0 ? diseaseList.map((data, index) => {
                                            return <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                    {data.diseaseType[0].value}
                                                </th>
                                                <td className="px-6 py-4">
                                                    {data.disease[0].value}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {data.diagnosis[0].value}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {data.year}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {data.version}
                                                </td>
                                                <td className="px-6 py-4">
                                                    <a href="javascript:void(0);" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => selectMedicine(data._id)}>Edit</a> /&nbsp;
                                                    <a href="javascript:void(0);" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => openDelete(data)}>Delete</a> /&nbsp;
                                                    <a href="javascript:void(0);" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => openQrPopup(data)}>Scan</a>
                                                </td>
                                            </tr>
                                        }) :
                                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                <th scope="row" colSpan={6} className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                    No data found
                                                </th>
                                            </tr>
                                        }

                                    </tbody>
                                </table>
                            </InfiniteScroll>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export { DiseaseList }