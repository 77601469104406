import logo from './logo.svg';
import './App.css';
import * as ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {
  Sidenav,
  Dropdown,
  Ripple,
  initTE,
} from "tw-elements";
import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { Vortex } from 'react-loader-spinner'

import Main from './components/backOffice/Main';
import { Home } from './components/backOffice/Home';
import { Login } from './components/Common/Login';
import { DahboardMenu } from './components/backOffice/DahboardMenu';
import { Medicine } from './components/backOffice/MedicinePage/Medicine';
import { Header } from './components/backOffice/Header';
import { TopHeader } from './components/backOffice/TopHeader';
import { DiseaseType } from './components/backOffice/Disease/DiseaseType';
import { Protocols } from './components/backOffice/Protocol/Protocols';
import { Registration } from './components/Common/Registration';
import { Treatment } from './components/backOffice/Treatment/Treatment';
import { BlockStructure } from './components/backOffice/BlockStructure';
import { UserHome } from './components/Common/UserHome';
import Test from './components/backOffice/Test';
import AboutUs from './components/Common/AboutUs';
import Services from './components/Common/Services';
import Contact from './components/Common/Contact';
import EditableTable from './components/backOffice/TableComponent';
import Redux from './components/backOffice/Redux';
import { getLanguageList } from './Store/Action';
import { useDispatch, useSelector } from 'react-redux';
import MedicineList from './components/backOffice/MedicineList/MedicineList';
import { MedicineView } from './components/backOffice/MedicinePage/MedicineView';
import { HomePage } from './components/Common/HomePage';
import { AddSideEffect } from './components/backOffice/SideEffect/AddSideEffect';
import { DiseaseList } from './components/backOffice/Disease/DiseaseList';
import { SideEffectList } from './components/backOffice/SideEffect/SideEffectList';
import { ProtocolList } from './components/backOffice/Protocol/ProtocolList';
import TreatmentList from './components/backOffice/Treatment/TreatmentList';
import { Profile } from './components/backOffice/settings/Profile/Profile';
import { AddChallenges } from './components/backOffice/Challenges/AddChallenges';
import { Diagram } from './components/backOffice/ProtoColDiagram/Diagram';
import ForgotPassword from './components/Common/ForgotPassword';
import CreatePassword from './components/Common/CreatePassword';
import TermsAndConditions from './components/Common/TermsAndConditions';
import { availableLanguages as storeLanguages } from './Store/Reducer';
import { getCookie } from './util/helper';
import { EditChallenges } from './components/backOffice/Challenges/EditChallenges';
import { MedicineListNew } from './components/backOffice/MedicineList/MedicineListNew';
import ProtectedRoute from './ProductRoute';
import GiftList from './components/backOffice/Gifts/GiftList';
import CreateGift from './components/backOffice/Gifts/CreateGift';
import GameLevel from './components/GameLevel/GameLevel';
import Staff from './components/backOffice/Staff/Staff';
import Books from './components/backOffice/Books/Books';
import Games from './components/backOffice/Games/Games';
import StaffList from './components/backOffice/Staff/StaffList';
import BooksList from './components/backOffice/Books/BooksList';
import GamesList from './components/backOffice/Games/GamesList';
import Users from './components/backOffice/Users/Users';
import UsersList from './components/backOffice/Users/UsersList';
import Introduction from './components/Common/Introduction';


function App() {
  const [currentLocation, setCurrentLocation] = useState(window.location.pathname);
  const isloading = useSelector(state => state?.isloading);
  const availableLanguages = useSelector(state => state?.availableLanguages);
  const dispatch = useDispatch();
  useEffect(() => {

    setCurrentLocation(window.location.pathname);
    const burger = document.querySelectorAll('.navbar-burger');
    const menu = document.querySelectorAll('.navbar-menu');

    if (burger.length && menu.length) {
      for (var i = 0; i < burger.length; i++) {
        burger[i].addEventListener('click', function () {
          for (var j = 0; j < menu.length; j++) {
            menu[j].classList.toggle('hidden');
          }
        });
      }
    }

    // close
    const close = document.querySelectorAll('.navbar-close');
    const backdrop = document.querySelectorAll('.navbar-backdrop');

    if (close.length) {
      for (var i = 0; i < close.length; i++) {
        close[i].addEventListener('click', function () {
          for (var j = 0; j < menu.length; j++) {
            menu[j].classList.toggle('hidden');
          }
        });
      }
    }

    if (backdrop.length) {
      for (var i = 0; i < backdrop.length; i++) {
        backdrop[i].addEventListener('click', function () {
          for (var j = 0; j < menu.length; j++) {
            menu[j].classList.toggle('hidden');
          }
        });
      }
    }

    if (Object.keys(availableLanguages).length === 0 && !getCookie("avilableLanguages")) {
      dispatch(getLanguageList());
    }
    if (getCookie("avilableLanguages")) {
      dispatch(storeLanguages(JSON.parse(getCookie("avilableLanguages"))));
    }

    // });
  }, [isloading])

  return (
    <div className="App bg-gradient-to-l h-full from-cyan-200 dark:via-red-500 dark:to-lime-500 dark:bg-slate-800">
      {/* {isloading && <Vortex
        visible={true}
        height="80"
        width="80"
        ariaLabel="vortex-loading"
        wrapperStyle={{}}
        wrapperClass="vortex-wrapper"
        colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}
      />} */}

      {isloading && <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
        {/* <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div> */}
        <Vortex
          visible={true}
          height="80"
          width="80"
          ariaLabel="vortex-loading"
          wrapperStyle={{}}
          wrapperClass="vortex-wrapper"
          colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}
        />
      </div>}

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Router>


        <Routes>


          <Route path='dashboard'>
            <Route index path="" element={<ProtectedRoute><Home /></ProtectedRoute>} />
            <Route path="check" element={<Home />} />
            <Route path="medicine/:id?" element={<ProtectedRoute><Medicine /></ProtectedRoute>} />
            <Route path="diseasetype/:id?" element={<ProtectedRoute><DiseaseType /></ProtectedRoute>} />
            <Route path="protocollist" element={<ProtectedRoute><ProtocolList /></ProtectedRoute>} />
            <Route path="protocols/:id?" element={<ProtectedRoute><Protocols /></ProtectedRoute>} />
            <Route path="treatments/:id?" element={<ProtectedRoute><Treatment /></ProtectedRoute>} />
            <Route path="treatmentlist" element={<ProtectedRoute><TreatmentList /></ProtectedRoute>} />
            <Route path="blockStructure" element={<ProtectedRoute><BlockStructure /></ProtectedRoute>} />
            <Route path="test" element={<Test />} />
            <Route path="medicinelist" element={<ProtectedRoute><MedicineListNew /></ProtectedRoute>} />
            <Route path="medicinelistnew" element={<ProtectedRoute><MedicineListNew /></ProtectedRoute>} />
            <Route path="medicineView/:id?" element={<ProtectedRoute><MedicineView /></ProtectedRoute>} />
            <Route path="addSideEffect/:id?" element={<ProtectedRoute><AddSideEffect /></ProtectedRoute>} />
            <Route path="diseaselist" element={<ProtectedRoute><DiseaseList /></ProtectedRoute>} />
            <Route path="sideeffectlist" element={<ProtectedRoute><SideEffectList /></ProtectedRoute>} />
            <Route path="profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
            <Route path="addChallenge" element={<ProtectedRoute><AddChallenges /></ProtectedRoute>} />
            <Route path="editChallenge" element={<ProtectedRoute><EditChallenges /></ProtectedRoute>} />
            <Route path="diagram" element={<ProtectedRoute><Diagram /></ProtectedRoute>} />
            <Route path="gifts" element={<ProtectedRoute><GiftList /></ProtectedRoute>} />
            <Route path="creategift/:id?" element={<ProtectedRoute><CreateGift /></ProtectedRoute>} />
            <Route path="gamelevel" element={<ProtectedRoute><GameLevel /></ProtectedRoute>} />
            <Route path="staff" element={<ProtectedRoute><Staff /></ProtectedRoute>} />
            <Route path="books" element={<ProtectedRoute><Books /></ProtectedRoute>} />
            <Route path="games" element={<ProtectedRoute><Games /></ProtectedRoute>} />
            <Route path="staffslist" element={<ProtectedRoute><StaffList /></ProtectedRoute>} />
            <Route path="bookslist" element={<ProtectedRoute><BooksList /></ProtectedRoute>} />
            <Route path="gameslist" element={<ProtectedRoute><GamesList /></ProtectedRoute>} />
            <Route path="users" element={<ProtectedRoute><Users /></ProtectedRoute>} />
            <Route path="usersList" element={<ProtectedRoute><UsersList /></ProtectedRoute>} />
          </Route>

          {/* </div>
            </div>
          </div> */}

          {/* </Routes> */}


          {/* </BrowserRouter>
      <BrowserRouter> */}
          {/* <Routes> */}
          <Route index path="/intro" element={<Introduction />} />
          <Route index path="/" element={<HomePage />} />
          <Route index path="/" element={<UserHome />} />
          <Route index path="/edit" element={<EditableTable />} />
          <Route index path="/aboutus" element={<AboutUs />} />
          <Route index path="/services" element={<Services />} />
          <Route index path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/reduxTest" element={<Redux />} />
          <Route path="/createpassword/:id?" element={<CreatePassword />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/terms" element={<TermsAndConditions />} />
        </Routes>
      </Router>

    </div>
  );
}

export default App;
