import axios from "axios";
import { getCookie } from "../util/helper";
import { toast } from "react-toastify";
axios.defaults.baseURL = 'https://e9pngd4lgg.execute-api.ap-south-1.amazonaws.com';
axios.defaults.headers.common['Authorization'] = `Bearer ${getCookie('token')}`;
//axios.defaults.timeout = 10000;
// axios.defaults.headers.common['Content-Type'] = 'application/json';
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.common['Access-Control-Allow-Headers'] = '*';


const headers = { headers: { "Content-Type": "application/json" } };
const httpGet = async (url) => {
    try {
        const response = await axios.get(url, headers);
        return response;
    } catch (error) {
        if (error.response?.data?.message) {
            return false;
        } else {
            return error;
        }
    }

}

const httpPost = async (url, params) => {
    try {
        const response = await axios.post(url, params, headers)
        //  console.log(response);
        return response;
    } catch (error) {
        if (error.response?.data?.message) {
            toast.error(error.response?.data?.message);
            return false;
        } else {
            return error;
        }
    }
}

const httpPut = async (url, params) => {
    try {
        const response = await axios.post(url, params, headers)
        return response;
    } catch (error) {
        if (error.response?.data?.message) {
            toast.error(error.response?.data?.message);
            return false;
        } else {
            return error;
        }
    }
}

export { httpGet, httpPost, httpPut }