
import { httpGet, httpPost, httpPut } from '../Api/httpProtocol';
import {
    getLanguageURL, createMedicineURL, createAdminURL, getAdminRolesURL, adminLoginURL,
    createSideEffectURL, sideEffectListURL, getSideEffectByIdURL, getAllMedicineURL, updateSideEffectURL,
    getMedicineDetailsByIdURL, updateMedicineURL, createDiseaseURL, getAllDiseaseURL, getDiseaseByIdURL,
    updateDiseaseByIdURL, createProtocolURL, getAllMedicalConditionsURL, getAllProtocalListURL, getProtocolsByIdURL,
    updateProtocolByIdURl, createtreatmentURL, getAlltreatmentListURL, gettreatmentByIdURL, updateTreatmentByIdURL,
    getAdminDetailsURL, updateAdminDetailsURL, getOrderProtocolListURL, uploadFileURL, createChallengesURL, forgotPasswordURL,
    createPasswordURL, getChallengeListURL, updateChallengeDetailsURL, getTitleOrderURL, qrCodeURL, giftListURL, giftUpdateURL,
    getAgeGroupListURL, createGiftURL, getGiftDetailsURL, getHospitalListURL, getWardListURL, getGameLevelListURL,
    updateStaffDetailsURL, getStaffDetailsURL, createStaffURL, updateGameLevelDetailsURL, createBookURL, getBookDetailsURL,
    updateBookDetailsURL, createGameURL, getGameDetailsURL, getStaffListURL, getBookListURL, getGameListURL, signedFileURL,
    createUserURL, getUserListURL, getUserDetailURL, createGameLevelURL, checkFileExistURL
} from '../Api/ContantUrls'
import Reducer, {
    increment, availableLanguages, medicineCreated, adminRoleList, adminLogin, createAdmins,
    setLoading, addsideEffect, sideEffectList, sideEffectListByIds, setAllmedicine, updateSideEffects,
    setMedicineDataById, setupdateMedicine, setAllDiseases, setDiseaseDataById, setAllMedicalCondition,
    setAllProtocolList, setAllMainProtocolList, setProtocolDatabyID, setAllTreatmentList, setTreatmentById,
    updateTreatmentSucess, setOrderProtocolList, setChallengesUpdate, setTitleorder, setGiftListStore, setageGroupListStore,
    sethospitalListStore, setwardListStore, setStaffDetailsStore, setBookDetailsStore, setGameDetailsStore, setStaffListStore,
    setBooksListStore, setGamesListStore, setUsersListStore, setUserDetailsStore, setTotalListCountStore,
    setAllProtocolCount
} from './Reducer';
import { toast } from 'react-toastify';
import { setCookie } from '../util/helper';
import { async } from 'q';

export const testIncreament = (arg) => {
    return (dispatch, getState, api) => {
        dispatch(increment());
    }

}

export const getLanguageList = () => {
    return async (dispatch) => {
        try {
            dispatch(setLoading(true))
            const reqObj = {
                "getLatestFirst": true,
                "pageNumber": 1,
                "numberOfRecordsPerPage": 0
            };
            const response = await httpPost(getLanguageURL, reqObj);
            const { payload } = response?.data;
            setCookie("avilableLanguages", JSON.stringify(payload));
            dispatch(availableLanguages(payload));
            dispatch(setLoading(false))
        } catch (error) {
            dispatch(setLoading(false))
            dispatch(availableLanguages({}))
        }
    }
}


export const addMedicine = (reqObj, reset) => {
    return async (dispatch) => {
        try {
            dispatch(setLoading(true))
            const response = await httpPost(createMedicineURL, reqObj);
            //  const { payload } = response?.data;
            if (response?.status === 200 && response?.data) {
                dispatch(medicineCreated({ update: true }))
                toast.success("Medicine created successfully");
                reset();
                if (reqObj.isNew) {
                    window.location.href = '/dashboard/medicine'
                } else {
                    window.location.href = '/dashboard/medicinelist'
                }
            } else {
                dispatch(medicineCreated({ update: false }));
                toast.error("Medicine created failed or medicine already exist");
            }
            dispatch(setLoading(false))
        } catch (err) {
            dispatch(medicineCreated({ update: false }))
            dispatch(setLoading(false))
        }
    }

}

export const adminRoleListcall = () => {
    return async (dispatch) => {
        try {
            dispatch(setLoading(true))
            const reqObj = {
                "getLatestFirst": true,
                "pageNumber": 1,
                "numberOfRecordsPerPage": 0
            }
            const response = await httpPost(getAdminRolesURL, reqObj);
            const { payload } = response?.data;
            if (payload.adminRoleList) {
                dispatch(adminRoleList(payload.adminRoleList))
            } else {
                dispatch(adminRoleList({}))
            }
            dispatch(setLoading(false))
        } catch (err) {
            dispatch(adminRoleList({}));
            dispatch(setLoading(false))
        }
    }
}

export const createAdmin = (reqObj) => {
    return async (dispatch) => {
        try {
            dispatch(setLoading(true))
            const response = await httpPost(createAdminURL, reqObj);

            // const { payload } = response?.data;
            if (response?.status === 200) {
                dispatch(createAdmins(true))
                toast.success("Please signin with your credentials")
                setTimeout(() => {
                    window.location.href = "/login"
                }, 100)
            } else {
                dispatch(createAdmins(false));
                toast.error(response && response.data && response.data.message ? response.data.message : "Email or phone aleady exist")
            }
            dispatch(setLoading(false))
        } catch (err) {
            dispatch(createAdmins(false))
            dispatch(setLoading(false))
            toast.error("Internal server error")
        }
    }
}

export const forgotPasswordAction = (reqObj) => {

    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await httpPost(forgotPasswordURL, reqObj);
        try {
            if (response?.status === 200 && response?.data) {
                toast.success("Please check your email to reset your password");
                setTimeout(() => {
                    window.location.href = "/login";
                }, 3000)

            } else {
                toast.error("Email not found")
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error(response.message ? response.message : "Internal server error")
            dispatch(setLoading(false))
        }
    }
}

export const createPasswordAction = (reqObj) => {

    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await httpPost(createPasswordURL, reqObj);
        try {
            if (response?.status === 200 && response?.data) {
                toast.success("Password created successfully. Please login");
                setTimeout(() => {
                    window.location.href = "/login";
                }, 3000)

            } else {
                toast.error("Failed to create the password")
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error(response.message ? response.message : "Internal server error")
            dispatch(setLoading(false))
        }
    }
}

export const getAdminDetailsByIdAction = (reqObj) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await httpPost(getAdminDetailsURL, reqObj);
        try {
            if (response?.status === 200 && response?.data) {
                const { payload } = response?.data;
                dispatch(adminLogin({ details: payload.adminDetails, success: true }));
            } else {
                toast.error("Admin not found")
                dispatch(adminLogin({ details: {}, success: false }));
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error(response.message)
            dispatch(adminLogin({ details: {}, success: false }));
            dispatch(setLoading(false))
        }
    }
}

export const updateAdminDetailsByIdAction = (reqObj) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await httpPost(updateAdminDetailsURL, reqObj);
        try {
            if (response?.data?.success) {
                toast.success("Profile update successfully")
                window.location.href = '/dashboard/profile'
            } else {
                toast.error("Profile update failed")
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error(response.message)
        }
    }
}

export const adminLogincall = (reqObj) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await httpPost(adminLoginURL, reqObj);
        try {
            if (response?.status === 200 && response?.data) {
                const { payload } = response?.data;
                const { token } = payload;
                setCookie('token', token)
                setCookie('adminId', payload?.adminDetails?._id)
                setCookie('adminName', payload?.adminDetails?.name)
                dispatch(adminLogin({ details: payload.adminDetails, success: true }));
            } else {
                //  toast.error("Admin not found")
                dispatch(adminLogin({ details: {}, success: false }));
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error(response.message)
            dispatch(adminLogin({ details: {}, success: false }));
            dispatch(setLoading(false))
        }
    }
}

export const addSideEffect = async (reqObj, isfromMedicinePage) => {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        try {
            const response = await httpPost(createSideEffectURL, reqObj);
            if (response?.status === 200 && response?.data) {
                toast.success("SideEffect created Sucessfully");
                dispatch(addsideEffect(true));
                if (isfromMedicinePage) {
                    window.history.back();
                } else {
                    if (reqObj.isNew) {
                        window.location.href = '/dashboard/addSideEffect';
                    } else {
                        window.location.href = '/dashboard/sideeffectlist';
                    }
                }
            } else {
                dispatch(addsideEffect(false));
                toast.error("Side effect already exist")
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error(err.message)
            dispatch(addsideEffect(false));
            dispatch(setLoading(false))
        }
    }
}

export const getsideEffectList = (reqObj) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await httpPost(sideEffectListURL, reqObj);
        try {
            if (response?.status === 200 && response?.data) {
                const { payload } = response?.data;
                dispatch(sideEffectList(payload.medicineSideEffectList));
            } else {
                dispatch(sideEffectList([]));
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error(response.message)
            dispatch(sideEffectList([]));
            dispatch(setLoading(false))
        }
    }
}

export const getsideEffectListById = async (reqObj) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await httpPost(getSideEffectByIdURL, reqObj);
        try {
            if (response?.status === 200 && response?.data) {

                const { payload } = response?.data;
                dispatch(sideEffectListByIds(payload.medicineSideEffectDetails));
            } else {
                dispatch(sideEffectListByIds({}));
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error(response.message)
            dispatch(sideEffectListByIds({}));
            dispatch(setLoading(false))
        }
    }
}

export const getAllMedicine = async (reqObj) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await httpPost(getAllMedicineURL, reqObj);
        try {
            if (response?.status === 200 && response?.data) {
                const { payload } = response?.data;
                dispatch(setAllmedicine(payload.medicineList));
                dispatch(setTotalListCountStore(payload?.totalMedicineList))
            } else {
                toast.error(response?.data?.message)
                dispatch(setAllmedicine([]));
                dispatch(0)
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error(response.message)
            dispatch(setAllmedicine([]));
            dispatch(0)
            dispatch(setLoading(false))
        }
    }
}

export const getMedicineByID = async (reqObj) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await httpPost(getMedicineDetailsByIdURL, reqObj);
        try {
            if (response?.status === 200 && response?.data) {

                const { payload } = response?.data;
                dispatch(setMedicineDataById(payload.medicineDetails));
            } else {
                dispatch(setMedicineDataById({}));
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error(response.message)
            dispatch(setMedicineDataById({}));
            dispatch(setLoading(false))
        }
    }
}

export const getUpdateSideEffect = async (reqObj, isfromMedicinePage) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await httpPost(updateSideEffectURL, reqObj);
        try {
            if (response?.status === 200 && response?.data) {
                //const { payload } = response?.data;
                dispatch(updateSideEffects(true));
                toast.success("SideEffect updated Sucessfully");
                if (isfromMedicinePage) {
                    window.history.back();
                } else {
                    if (reqObj.isNew) {
                        window.location.href = '/dashboard/addSideEffect';
                    } else {
                        window.location.href = '/dashboard/sideeffectlist';
                    }
                }

            } else {
                dispatch(updateSideEffects(false));
                toast.error("SideEffect already exist");
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error("Internal server error");
            dispatch(updateSideEffects(false));
            dispatch(setLoading(false))
        }
    }
}

export const getUpdateTreatmentIdFromtable = async (reqObj, dispatch) => {
    try {
        dispatch(setLoading(true))
        const response = await httpPost(updateTreatmentByIdURL, reqObj);
        dispatch(setLoading(false))
        return response;

    } catch (err) {
        toast.error("Internal server error");
        return {};
    }
}

export const getUpdateTreatmentId = async (reqObj, isreDirect) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await httpPost(updateTreatmentByIdURL, reqObj);
        try {
            if (response?.status === 200 && response?.data) {
                //const { payload } = response?.data;
                dispatch(updateTreatmentSucess(true));
                toast.success("Treatment updated Sucessfully");
                if (isreDirect) {
                    setTimeout(() => {
                        window.location.href = '/dashboard/medicinelist';
                    }, 2000)
                } else if (!isreDirect) {
                    setTimeout(() => {
                        window.location.href = '/dashboard/treatments';
                    }, 2000)
                }
            } else {
                dispatch(updateTreatmentSucess(false));
                toast.error("Treatment already exist");
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error("Internal server error");
            dispatch(updateTreatmentSucess(false));
            dispatch(setLoading(false))
        }
    }
}

export const getMedicineUpdated = async (reqObj, isNew) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await httpPost(updateMedicineURL, reqObj);
        try {
            if (response?.status === 200 && response?.data) {
                //const { payload } = response?.data;
                toast.success("Updated Successfully")
                dispatch(setupdateMedicine(true));
                if (isNew) {
                    window.location.href = '/dashboard/medicine'
                } else {
                    window.location.href = '/dashboard/medicinelist'
                }

            } else {
                toast.error("Update Failed")
                dispatch(setupdateMedicine(false));
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error(response.message)
            dispatch(setupdateMedicine(false));
            dispatch(setLoading(false))
        }
    }
}

export const createStaffAction = async (reqObj) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await httpPost(createStaffURL, reqObj);
        try {
            if (response?.status === 200 && response?.data) {
                //const { payload } = response?.data;
                toast.success("Updated Successfully")
                if (reqObj.isNew) {
                    window.location.href = '/dashboard/staff'
                } else {
                    window.location.href = '/dashboard/staffslist'
                }

            } else {
                toast.error("Update Failed")
                dispatch(setupdateMedicine(false));
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error(response.message)
            dispatch(setLoading(false))
        }
    }
}

export const createDiseases = async (reqObj, reset) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await httpPost(createDiseaseURL, reqObj);
        try {
            if (response?.status === 200 && response?.data) {
                //const { payload } = response?.data;
                toast.success("Created Successfully");
                setTimeout(() => {
                    if (reqObj.isNew) {
                        window.location.href = '/dashboard/diseasetype'
                    } else {
                        window.location.href = '/dashboard/diseaselist'
                    }
                }, 10);

            } else {
                toast.error("Failed to create")
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error(response.message);
        }
    }
}

export const getAllDiseasesAction = async (reqObj) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await httpPost(getAllDiseaseURL, reqObj);
        try {
            if (response?.status === 200 && response?.data) {
                const { payload } = response?.data;
                dispatch(setAllDiseases(payload.diseaseTypeList));
            } else {
                dispatch(setAllDiseases([]));
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error(response.message)
            dispatch(setAllDiseases([]));
            dispatch(setLoading(false))
        }
    }
}

export const getAllDiseasesbyId = async (reqObj) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await httpPost(getDiseaseByIdURL, reqObj);
        try {
            if (response?.status === 200 && response?.data) {
                const { payload } = response?.data;
                dispatch(setDiseaseDataById(payload.diseaseTypeDetails));
            } else {
                dispatch(setDiseaseDataById({}));
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error(response.message)
            dispatch(setDiseaseDataById({}));
            dispatch(setLoading(false))
        }
    }
}

export const updateDiseaseByID = async (reqObj) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await httpPost(updateDiseaseByIdURL, reqObj);
        try {
            if (response?.status === 200 && response?.data) {
                const { payload } = response?.data;
                toast.success("Disease updated successfully ");
                if (reqObj.isNew) {
                    window.location.href = '/dashboard/diseasetype';
                } else {
                    window.location.href = '/dashboard/diseaselist';
                }

            } else {
                toast.error("Disease updated failed")
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error(response.message)
            dispatch(setLoading(false))
        }
    }
}

export const createProtocolAction = (reqObj) => {
    return async (dispatch) => {
        dispatch(setLoading(true))

        try {
            const response = await httpPost(createProtocolURL, reqObj);
            if (response?.status === 200 && response?.data) {
                toast.success("Protocol created successfully ");
                if (reqObj.isNew) {
                    window.location.href = '/dashboard/protocols';
                } else {
                    window.location.href = '/dashboard/protocollist';
                }
            } else {
                toast.error(response?.data?.message)
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error("Internal server error")
            dispatch(setLoading(false))
        }
    }
}

export const createTreatmentFromtable = async (reqObj, dispatch) => {
    //   return async (dispatch) => {
    dispatch(setLoading(true))

    try {
        const response = await httpPost(createtreatmentURL, reqObj);
        if (response?.status === 200 && response?.data) {
            const { payload } = response?.data;
            toast.success("Treatment created successfully ");
            dispatch(setLoading(false))
            return payload?.createdTreatment;
        } else {
            toast.error(response?.data?.message)
            dispatch(setLoading(false))
            return {};
        }

    } catch (err) {
        toast.error("Internal server error")
        dispatch(setLoading(false))
        return {};
    }
    //}
}


export const createTreatmentAction = (reqObj, isreDirect) => {
    return async (dispatch) => {
        dispatch(setLoading(true))

        try {
            const response = await httpPost(createtreatmentURL, reqObj);
            if (response?.status === 200 && response?.data) {
                toast.success("Treatment created successfully ");
                if (isreDirect) {
                    setTimeout(() => {
                        window.location.href = '/dashboard/medicinelist';
                    }, 2000)
                } else {
                    setTimeout(() => {
                        window.location.href = '/dashboard/treatments';
                    }, 2000)
                }


            } else {
                toast.error(response?.data?.message)
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error("Internal server error")
            dispatch(setLoading(false))
        }
    }
}

export const getAllTreatmentListAction = async (reqObj) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await httpPost(getAlltreatmentListURL, reqObj);
        try {
            if (response?.status === 200 && response?.data) {
                const { payload } = response?.data;
                dispatch(setAllTreatmentList(payload.treatmentList));
            } else {
                dispatch(setAllTreatmentList([]));
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error(response.message)
            dispatch(setAllTreatmentList([]));
            dispatch(setLoading(false))
        }
    }
}

export const getAllTreatmentbyId = async (reqObj) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await httpPost(gettreatmentByIdURL, reqObj);
        try {
            if (response?.status === 200 && response?.data) {
                const { payload } = response?.data;
                dispatch(setTreatmentById(payload.treatmentDetails));
            } else {
                dispatch(setTreatmentById([]));
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error(response.message)
            dispatch(setTreatmentById([]));
            dispatch(setLoading(false))
        }
    }
}

export const getAllMedicalConditionAction = async (reqObj) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await httpPost(getAllMedicalConditionsURL, reqObj);
        try {
            if (response?.status === 200 && response?.data) {
                const { payload } = response?.data;
                dispatch(setAllMedicalCondition(payload.medicalConditionList));
            } else {
                dispatch(setAllMedicalCondition([]));
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error(response.message)
            dispatch(setAllMedicalCondition([]));
            dispatch(setLoading(false))
        }
    }
}

export const getAllProtocolListAction = async (reqObj) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await httpPost(getAllProtocalListURL, reqObj);
        try {
            if (response?.status === 200 && response?.data) {
                const { payload } = response?.data;
                dispatch(setAllProtocolCount(payload.totalProtocolList))
                dispatch(setAllProtocolList(payload.protocolList));
            } else {
                dispatch(setAllProtocolList([]));
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error(response.message)
            dispatch(setAllProtocolList([]));
            dispatch(setLoading(false))
        }
    }
}

export const getOrderProtocolListAction = async (reqObj) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await httpPost(getOrderProtocolListURL, reqObj);
        try {
            if (response?.status === 200 && response?.data) {
                const { payload } = response?.data;
                dispatch(setOrderProtocolList(payload.finalProtocolList));
            } else {
                dispatch(setOrderProtocolList([]));
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error(response.message)
            dispatch(setOrderProtocolList([]));
            dispatch(setLoading(false))
        }
    }
}
export const getAllMainProtocolListAction = async (reqObj) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await httpPost(getAllProtocalListURL, reqObj);
        try {
            if (response?.status === 200 && response?.data) {
                const { payload } = response?.data;
                dispatch(setAllMainProtocolList(payload.protocolList));
            } else {
                dispatch(setAllMainProtocolList([]));
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error(response.message)
            dispatch(setAllMainProtocolList([]));
            dispatch(setLoading(false))
        }
    }
}

export const actionForPrevAndNext = async (dispatch, url, reqObj, key = "") => {

    dispatch(setLoading(true))
    const response = await httpPost(url, reqObj);
    try {
        if (response?.status === 200 && response?.data) {
            const { payload } = response?.data;
            dispatch(setLoading(false))
            return key ? payload[key] : payload;
        } else {
            dispatch(setLoading(false))
            return [];
        }

    } catch (err) {
        dispatch(setLoading(false))
        return [];
    }
}

export const getAllProtocolByIdAction = (reqObj) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await httpPost(getProtocolsByIdURL, reqObj);
        try {
            if (response?.status === 200 && response?.data) {
                const { payload } = response?.data;
                dispatch(setProtocolDatabyID(payload));
            } else {
                dispatch(setProtocolDatabyID({}));
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error(response.message)
            dispatch(setProtocolDatabyID({}));
            dispatch(setLoading(false))
        }
    }
}

export const getAllChallegesAction = async (dispatch, reqObj) => {
    // return async (dispatch) => {

    try {
        dispatch(setLoading(true))
        const response = await httpPost(getChallengeListURL, reqObj);
        if (response?.status === 200 && response?.data) {
            const { payload } = response?.data;
            dispatch(setLoading(false))
            return payload.challengeList;
        } else {
            // dispatch(setAllChallengesList([]));
            dispatch(setLoading(false))
            return [];
        }

    } catch (err) {
        toast.error("Failed")
        //dispatch(setAllChallengesList([]));
        dispatch(setLoading(false))
        return [];
    }
    // }
}

export const updateProtocolByID = async (reqObj) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await httpPost(updateProtocolByIdURl, reqObj);
        try {
            if (response?.status === 200 && response?.data) {
                const { payload } = response?.data;
                toast.success("Disease updated successfully ");
                if (reqObj.isNew) {
                    window.location.href = '/dashboard/protocols';
                }
                if (reqObj.isNew === false) {
                    window.location.href = '/dashboard/protocollist';
                }
            } else {
                toast.error("Disease updated failed")
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error(response.message)
            dispatch(setLoading(false))
        }
    }
}
export const createChellangesAction = async (reqObj) => {
    return async (dispatch) => {

        try {
            dispatch(setLoading(true))
            const response = await httpPost(createChallengesURL, reqObj);
            if (response?.status === 200 && response?.data) {
                const message = response?.data?.message
                toast.success(message ? message : "Challenge Created Successfully")
                setTimeout(() => {
                    window.location.href = '/dashboard/editChallenge'

                }, 1000);
            } else {
                toast.error("Failed")
            }
            dispatch(setLoading(false))
        } catch (e) {
            toast.error("Failed")
            dispatch(setLoading(false))
        }
    }
}

export const getTitleOrderAction = async () => {
    return async (dispatch) => {

        try {
            dispatch(setLoading(true))
            const response = await httpGet(getTitleOrderURL);
            if (response?.status === 200 && response?.data) {
                const { data } = response?.data;
                dispatch(setTitleorder(data))
            } else {
                dispatch(setTitleorder([]))
            }
            dispatch(setLoading(false))
        } catch (e) {
            dispatch(setTitleorder([]))
            dispatch(setLoading(false))
        }
    }
}

export const uploadSignedFile = async (url, reqObj, uheader) => {
    const response = await httpPut(url, reqObj, uheader);
    return response;
}

export const getSignedFileUrl = async (reqObj) => {
    const response = await httpPost(signedFileURL, reqObj);
    return response;
}

export const uploadFileAction = async (reqObj) => {
    const response = await httpPost(uploadFileURL, reqObj);
    return response;
}

export const getQRCodeAction = async (reqObj) => {
    const response = await httpPost(qrCodeURL, reqObj);
    return response;
}

export const getGiftDetailsAction = async (dispatch, reqObj) => {
    dispatch(setLoading(true))
    const response = await httpPost(getGiftDetailsURL, reqObj);
    dispatch(setLoading(false))
    return response;
}

export const getGameLevelListAction = async (dispatch, reqObj) => {
    dispatch(setLoading(true))
    const response = await httpPost(getGameLevelListURL, reqObj);
    dispatch(setLoading(false))
    return response;
}

export const getGiftUpatedAction = async (dispatch, reqObj) => {
    dispatch(setLoading(true))
    const response = await httpPost(giftUpdateURL, reqObj);
    dispatch(setLoading(false))
    return response;
}

export const getUpdateGameLevelDetailsAction = async (dispatch, url, reqObj) => {
    dispatch(setLoading(true))
    const response = await httpPost(url, reqObj);
    dispatch(setLoading(false))
    return response;
}

export const getStaffUpdatedAction = async (dispatch, url, reqObj, showloader = true) => {
    if (showloader) {
        dispatch(setLoading(true))
    }
    const response = await httpPost(url, reqObj);
    if (showloader) {
        dispatch(setLoading(false))
    }
    return response;
}

export const getQRCodeImageAction = async (URL) => {
    const response = await httpGet(URL);
    return response;
}

export const updateChallengeAPI = async (dispatch, reqObj) => {

    try {
        dispatch(setLoading(true))
        const response = await httpPost(updateChallengeDetailsURL, reqObj);
        dispatch(setLoading(false))
        return response;
    } catch (e) {
        dispatch(setLoading(false))
        return {}
    }
}

export const updateChallengesAction = async (reqObj) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await updateChallengeAPI(dispatch, reqObj);
        try {
            if (response?.status === 200 && response?.data) {
                const { message } = response?.data;
                toast.success(message);
                dispatch(setChallengesUpdate(true));

            } else {
                toast.error("Challenges updated failed")
                dispatch(setChallengesUpdate(false));
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error(response.message)
            dispatch(setLoading(false));
            dispatch(setChallengesUpdate(false));
        }
    }
}

export const getGiftListAction = async (reqObj) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await httpPost(giftListURL, reqObj);
        try {
            if (response?.status === 200 && response?.data) {
                const { payload } = response?.data;
                toast.success("Success");
                dispatch(setGiftListStore(payload?.giftList));

            } else {
                toast.error("Failed")
                dispatch(setGiftListStore([]));
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error("Failed")
            dispatch(setLoading(false));
            dispatch(setGiftListStore([]));
        }
    }
}

export const getStaffListAction = async (reqObj) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await httpPost(getStaffListURL, reqObj);
        try {
            if (response?.status === 200 && response?.data) {
                const { payload } = response?.data;
                dispatch(setStaffListStore(payload?.staffList));

            } else {
                toast.error("Failed")
                dispatch(setStaffListStore([]));
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error("Failed")
            dispatch(setLoading(false));
            dispatch(setStaffListStore([]));
        }
    }
}

export const getBooksListAction = async (reqObj) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await httpPost(getBookListURL, reqObj);
        try {
            if (response?.status === 200 && response?.data) {
                const { payload } = response?.data;
                dispatch(setBooksListStore(payload?.bookList));

            } else {
                toast.error("Failed")
                dispatch(setBooksListStore([]));
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error("Failed")
            dispatch(setLoading(false));
            dispatch(setBooksListStore([]));
        }
    }
}

export const getGamesListAction = async (reqObj) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await httpPost(getGameListURL, reqObj);
        try {
            if (response?.status === 200 && response?.data) {
                const { payload } = response?.data;
                dispatch(setGamesListStore(payload?.gameList));

            } else {
                toast.error("Failed")
                dispatch(setGamesListStore([]));
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error("Failed")
            dispatch(setLoading(false));
            dispatch(setGamesListStore([]));
        }
    }
}


export const getAgeGroupListAction = async (reqObj) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await httpPost(getAgeGroupListURL, reqObj);
        try {
            if (response?.status === 200 && response?.data) {
                const { payload } = response?.data;
                dispatch(setageGroupListStore(payload?.ageGroupList));

            } else {
                toast.error("Failed")
                dispatch(setageGroupListStore([]));
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error("Failed")
            dispatch(setLoading(false));
            dispatch(setageGroupListStore([]));
        }
    }
}

export const getHospitalListAction = async (reqObj) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await httpPost(getHospitalListURL, reqObj);
        try {
            if (response?.status === 200 && response?.data) {
                const { payload } = response?.data;
                // toast.success("Success");
                dispatch(sethospitalListStore(payload?.hospitalList));

            } else {
                toast.error("Failed")
                dispatch(sethospitalListStore([]));
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error("Failed")
            dispatch(setLoading(false));
            dispatch(sethospitalListStore([]));
        }
    }
}

export const getWardListAction = async (reqObj) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await httpPost(getWardListURL, reqObj);
        try {
            if (response?.status === 200 && response?.data) {
                const { payload } = response?.data;
                dispatch(setwardListStore(payload?.wardList));

            } else {
                toast.error("Failed")
                dispatch(setwardListStore([]));
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error("Failed")
            dispatch(setLoading(false));
            dispatch(setwardListStore([]));
        }
    }
}

export const getStaffDetailsAction = async (reqObj) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await httpPost(getStaffDetailsURL, reqObj);
        try {
            if (response?.status === 200 && response?.data) {
                const { payload } = response?.data;
                dispatch(setStaffDetailsStore(payload?.staffDetails));

            } else {
                toast.error("Failed")
                dispatch(setStaffDetailsStore({}));
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error("Failed")
            dispatch(setLoading(false));
            dispatch(setStaffDetailsStore({}));
        }
    }
}

export const getBookDetailsAction = async (reqObj) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await httpPost(getBookDetailsURL, reqObj);
        try {
            if (response?.status === 200 && response?.data) {
                const { payload } = response?.data;
                // toast.success("Success");
                dispatch(setBookDetailsStore(payload?.bookDetails));

            } else {
                toast.error("Failed")
                dispatch(setBookDetailsStore({}));
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error("Failed")
            dispatch(setLoading(false));
            dispatch(setBookDetailsStore({}));
        }
    }
}


export const createGiftAction = async (reqObj, isreDirect) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await httpPost(createGiftURL, reqObj);
        try {
            if (response?.status === 200 && response?.data) {
                //const { payload } = response?.data;
                toast.success("Gift updated Sucessfully");
                if (isreDirect) {
                    setTimeout(() => {
                        window.location.href = '/dashboard/gifts';
                    }, 2000)
                } else if (!isreDirect) {
                    setTimeout(() => {
                        window.location.href = '/dashboard/creategift';
                    }, 2000)
                }
            } else {
                toast.error("Gift already exist");
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error("Internal server error");
            dispatch(setLoading(false))
        }
    }
}

export const createBookAction = async (reqObj, isreDirect) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await httpPost(createBookURL, reqObj);
        try {
            if (response?.status === 200 && response?.data) {
                //const { payload } = response?.data;
                toast.success("Books updated Sucessfully");
                if (isreDirect) {
                    setTimeout(() => {
                        window.location.href = '/dashboard/bookslist';
                    }, 2000)
                } else if (!isreDirect) {
                    setTimeout(() => {
                        window.location.href = '/dashboard/books';
                    }, 2000)
                }
            } else {
                toast.error("Books updated failed");
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error("Internal server error");
            dispatch(setLoading(false))
        }
    }
}

export const createGameAction = async (reqObj, isreDirect) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await httpPost(createGameURL, reqObj);
        try {
            if (response?.status === 200 && response?.data) {
                //const { payload } = response?.data;
                toast.success("Game updated Sucessfully");
                if (isreDirect) {
                    setTimeout(() => {
                        window.location.href = '/dashboard/gameslist';
                    }, 2000)
                } else if (!isreDirect) {
                    setTimeout(() => {
                        window.location.href = '/dashboard/games';
                    }, 2000)
                }
            } else {
                toast.error("Game updated failed");
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error("Internal server error");
            dispatch(setLoading(false))
        }
    }
}

export const getGameDetailsAction = async (reqObj) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await httpPost(getGameDetailsURL, reqObj);
        try {
            if (response?.status === 200 && response?.data) {
                const { payload } = response?.data;
                // toast.success("Success");
                dispatch(setGameDetailsStore(payload?.gameDetails));

            } else {
                toast.error("Failed")
                dispatch(setGameDetailsStore({}));
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error("Failed")
            dispatch(setLoading(false));
            dispatch(setGameDetailsStore({}));
        }
    }
}

export const createUserAction = async (reqObj, isreDirect) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await httpPost(createUserURL, reqObj);
        try {
            if (response?.status === 200 && response?.data) {
                //const { payload } = response?.data;
                toast.success("User updated Sucessfully");
                if (isreDirect) {
                    setTimeout(() => {
                        window.location.href = '/dashboard/users';
                    }, 2000)
                } else if (!isreDirect) {
                    setTimeout(() => {
                        window.location.href = '/dashboard/userslist';
                    }, 2000)
                }
            } else {
                toast.error("Users updated failed");
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error("Internal server error");
            dispatch(setLoading(false))
        }
    }
}

export const getUsersListAction = async (reqObj) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await httpPost(getUserListURL, reqObj);
        try {
            if (response?.status === 200 && response?.data) {
                const { payload } = response?.data;
                //toast.success("Success");
                dispatch(setUsersListStore(payload?.userList));

            } else {
                toast.error("Failed")
                dispatch(setUsersListStore([]));
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error("Failed")
            dispatch(setLoading(false));
            dispatch(setUsersListStore([]));
        }
    }
}

export const getUserDetailsAction = async (reqObj) => {
    return async (dispatch) => {
        dispatch(setLoading(true))
        const response = await httpPost(getUserDetailURL, reqObj);
        try {
            if (response?.status === 200 && response?.data) {
                const { payload } = response?.data;
                // toast.success("Success");
                dispatch(setUserDetailsStore(payload?.userDetails));

            } else {
                toast.error("Failed")
                dispatch(setUserDetailsStore({}));
            }
            dispatch(setLoading(false))
        } catch (err) {
            toast.error("Failed")
            dispatch(setLoading(false));
            dispatch(setUserDetailsStore({}));
        }
    }
}


export const checkFileExistOrNotAction = (reqObj, showLoader = true) => {
    return async (dispatch) => {
        if (showLoader) {
            dispatch(setLoading(true))
        }
        try {
            const response = await httpPost(checkFileExistURL, reqObj);

            if (response?.status === 200 && response?.data) {
                const { payload } = response?.data;
                dispatch(setLoading(false))
                return payload.doesFileExist ? reqObj?.filepath : "";
            } else {
                dispatch(setLoading(false))
                return "";
            }

        } catch (err) {
            dispatch(setLoading(false))
            return "";
        }
    }
}