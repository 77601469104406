import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { getMedicineUpdated, updateProtocolByID, updateDiseaseByID, getUpdateTreatmentId, getUpdateSideEffect } from '../../Store/Action';

const


    DeleteItemPopup = (props) => {
        const { modalIsOpen, closeModal, isFrom, data, deleteData } = props;
        const [header, setheader] = useState("");
        const [subHeader, setSubHeader] = useState("");
        const dispatch = useDispatch();
        useEffect(() => {
            if (isFrom === 'medicine') {
                setheader(data?.medicineName[0]?.value);
                setSubHeader(data?.medicineDescription[0]?.value)
            }
            if (isFrom === 'protocol') {
                setheader(data?.protocolName[0]?.value);
                setSubHeader(data?.protocolShortName[0]?.value)
            }
            if (isFrom === 'disease') {
                setheader(data?.disease[0]?.value);
                setSubHeader(data?.diseaseType[0]?.value)
            }
            if (isFrom === 'treatment') {
                setheader(data?.instructionsForMedicine[0]?.value);
                setSubHeader(data?.explanation[0]?.value)
            }
            if (isFrom === 'sideeffect') {
                setheader(data?.sideEffectName[0]?.value);
                setSubHeader(data?.sideEffectDescription[0]?.value)
            }
            if (isFrom === 'challenges') {
                setheader(data?.challengeTitle[0]?.value);
                setSubHeader(data?.questionTitle[0]?.value)
            }
            if (isFrom === 'gift') {
                setheader(data?.name);
                setSubHeader(data?.coins)
            }
            if (isFrom === 'GameStaff') {
                setheader(data?.staffName[0]?.value);
                setSubHeader(data?.department)
            }
            if (isFrom === 'GameBook') {
                setheader(data?.bookName[0]?.value);
                setSubHeader(data?.bookDescription[0]?.value)
            }
            if (isFrom === 'GameLevel') {
                setheader(data?.description);
                setSubHeader(data?.name)
            }
            if (isFrom === 'Users') {
                setheader(data?.name[0]?.value);
                setSubHeader(data?.ageGroup?.name)
            }
        }, [])

        const deleteItem = async (e) => {
            e.preventDefault();
            const reqId = {}
            if (isFrom === "medicine") {
                reqId.medicineId = data._id;
            }
            if (isFrom === 'protocol') {
                reqId.protocolId = data._id;
            }
            if (isFrom === 'disease') {
                reqId.diseaseTypeId = data._id;
            }
            if (isFrom === 'treatment') {
                reqId.treatmentId = data._id;
            }
            if (isFrom === 'sideeffect') {
                reqId.sideEffectId = data._id
            }
            const reqObjval = {
                ...reqId,
                dataToUpdate: {
                    active: false
                }
            }
            if (isFrom === "medicine") {
                dispatch(await getMedicineUpdated(reqObjval));
                //  return false;
            }
            if (isFrom === 'protocol') {
                dispatch(await updateProtocolByID(reqObjval));
                //   return false;
            }
            if (isFrom === 'disease') {
                dispatch(await updateDiseaseByID(reqObjval));
                // return false;
            }
            if (isFrom === "sideeffect") {
                dispatch(await getUpdateSideEffect(reqObjval))
            }
            if (isFrom === 'treatment') {
                //dispatch(await getUpdateTreatmentId(reqObjval));
                //  return false;
                deleteData(reqObjval)
            }
            if (isFrom === 'challenges') {
                deleteData(e)
            }
            if (isFrom === 'gift') {
                deleteData()
            }
            if (isFrom === 'GameStaff' || isFrom === 'GameBook' || isFrom === 'GameLevel' || isFrom === 'Users') {
                deleteData()
            }
            //window.location.reload();
            closeModal();
        }
        const customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                padding: '0px',
                transform: 'translate(-50%, -50%)',
            },
        };
        return (
            <div>
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Example Modal"
                    style={customStyles}
                    ariaHideApp={false}
                >
                    <div id="defaultModal">
                        <div className="relative w-full max-w-2xl max-h-full">
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                        Are you sure you want to delete?
                                    </h3>
                                    <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="defaultModal" onClick={() => closeModal()}>
                                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                        </svg>
                                        <span className="sr-only">Close modal</span>
                                    </button>
                                </div>
                                {isFrom !== 'treatment' ? <div className="p-6 space-y-6">
                                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                                        {(data?.medicineShortName && data?.medicineShortName[0]) && <span><strong className='text-lg'>{data?.medicineShortName[0].value}</strong><br></br></span>}
                                        <strong>{header}</strong>
                                    </p>
                                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                                        <strong> {subHeader}</strong>
                                    </p>
                                </div> :
                                    <div className="p-6 space-y-6">
                                        <p><strong>Day Count:</strong> <span>{data?.dayCount}</span></p>
                                        <p><strong>Condition:</strong> <span>{data?.medicalCondition[0]?.value}</span></p>
                                        <p><strong>Dose Time:</strong> <span>{data?.doseTimes}</span></p>
                                    </div>}
                                <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                                    <button data-modal-hide="defaultModal" type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" onClick={(e) => deleteItem(e)}>Yes</button>
                                    <button data-modal-hide="defaultModal" type="button" className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600" onClick={() => closeModal()}>No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal >
            </div >
        )
    }

export default DeleteItemPopup