export const englishLangId = langArray => {
    const returnData = langArray.find(o => o.name === 'English');
    return returnData._id;
}
export const arabicLangId = langArray => {
    const returnData = langArray.find(o => o.name === 'Arabic');
    return returnData._id;
}
export const hebrewLangId = langArray => {
    const returnData = langArray.find(o => o.name === 'Hebrew');
    return returnData._id;
}

export const setCookie = (name, value, options = {}) => {

    options = {
        path: '/',
        // add other defaults here if necessary
        ...options
    };

    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }

    document.cookie = updatedCookie;
}

export const getCookie = (name) => {
    // eslint-disable-next-line
    let matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

export const clearCookie = (name) => {
    setCookie(name, "", {
        'max-age': -1
    });
}

export const getQuery = (name) => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params.get(name);
}

export const FilterbyStringAsending = (data, name) => {
    return data.slice().sort((a, b) => {
        if (a[name] && b[name]) {
            if (a[name][0].value.toLowerCase() < b[name][0].value.toLowerCase()) {
                return -1;
            }
            if (a[name][0].value.toLowerCase() > b[name][0].value.toLowerCase()) {
                return 1;
            }
        }
        return 0;
    })
}

export const FilterbyStringDsending = (data, name) => {
    return data.slice().sort((a, b) => {
        if (a[name] && b[name]) {
            if (a[name][0].value.toLowerCase() > b[name][0].value.toLowerCase()) {
                return -1;
            }
            if (a[name][0].value.toLowerCase() < b[name][0].value.toLowerCase()) {
                return 1;
            }
        }
        return 0;
    })
}

export const FilterbybooleanAsending = (data, name) => {
    return data.slice().sort((a, b) => {
        return a[name] - b[name];
    })
}

export const FilterbybooleanDsending = (data, name) => {
    return data.slice().sort((a, b) => {
        return b[name] - a[name];
    })
}

export const FilterbyFiledAsending = (data, name) => {
    return data.slice().sort((a, b) => {
        if (typeof a[name] === 'number') {
            return a[name] - b[name];

        } else {
            if (a[name] && b[name]) {
                if (a[name] < b[name]) {
                    return -1;
                }
                if (a[name] > b[name]) {
                    return 1;
                }
            }
            return 0;
        }


    })
}

export const FilterbyFiledDsending = (data, name) => {
    return data.slice().sort((a, b) => {
        //  console.log("a----------------------->", typeof a[name] === 'number')
        if (typeof a[name] === 'number') {
            return b[name] - a[name];

        } else {
            if (a[name] && b[name]) {
                if (a[name] > b[name]) {
                    return -1;
                }
                if (a[name] < b[name]) {
                    return 1;
                }
            }
            return 0;
        }
    })
}

export const FilterbyFiledAsendingPropName = (data, name, propName) => {
    return data.slice().sort((a, b) => {
        if (a[name][propName] && b[name][propName]) {
            if (a[name][propName] < b[name][propName]) {
                return -1;
            }
            if (a[name][propName] > b[name][propName]) {
                return 1;
            }
        }
        return 0;
    })
}

export const FilterbyFiledDsendingPropName = (data, name, propName) => {
    return data.slice().sort((a, b) => {
        console.log("a----------------------->", a[name])
        if (a[name][propName] && b[name][propName]) {
            if (a[name][propName] > b[name][propName]) {
                return -1;
            }
            if (a[name][propName] < b[name][propName]) {
                return 1;
            }
        }
        return 0;
    })
}

export const FilterbyFiledAsendingPropNameValue = (data, name, propName) => {
    return data.slice().sort((a, b) => {
        if (a[name] && a[name][0] && a[name][0][propName] && b[name][0] && b[name][0][propName]) {
            //  console.log("a----------------------->", a[name][0][propName])
            if (a[name][0][propName] < b[name][0][propName]) {
                return -1;
            }
            if (a[name][0][propName] > b[name][0][propName]) {
                return 1;
            }
        }
        return 0;
    })
}

export const FilterbyFiledDsendingPropNameValue = (data, name, propName) => {
    return data.slice().sort((a, b) => {

        if (a[name] && a[name][0] && a[name][0][propName] && b[name][0] && b[name][0][propName]) {
            //   console.log("a----------------------->", a[name][0][propName])
            if (a[name][0][propName] > b[name][0][propName]) {
                return -1;
            }
            if (a[name][0][propName] < b[name][0][propName]) {
                return 1;
            }
        }
        return 0;
    })
}
