import React from 'react'
import { useEffect } from 'react'
import Userheader from './Userheader'

const AboutUs = () => {
    useEffect(() => {
        document.body.setAttribute("style", "overflow:hidden")
    }, [])
    return (
        <div>
            <div className='bg-gradient-to-r from-violet-200 to-pink-200 h-full z-50'>
                <Userheader />
                <div className="">
                    {/* <div className="bg-transparent shadow-lg rounded-lg overflow-hidden">
                        <div className="p-6"> */}
                    {/* <h1 className="text-2xl font-semibold mb-2">About Us</h1> */}
                    <iframe src='https://www.easyhelp.net/about-us/#primary' className='min-h-screen  w-full absolute top-20 left-0 right-0 bottom-0' height="100%" width="100%" title="Tadam"></iframe>
                    {/* </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default AboutUs