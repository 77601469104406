import React, { useEffect, useRef, useState } from 'react';
import { TopHeader } from '../TopHeader';
import { DahboardMenu } from '../DahboardMenu';
import { Controller, useForm } from 'react-hook-form';
import { challengeTypeOption, userTargetOption, questionTypeOption, userLevelOption, questionTimeOption, answerCountOption, answerTypeOption, buttonCSS } from '../../../util/contant';
import { createChellangesAction, uploadFileAction, getAllChallegesAction, updateChallengesAction, updateChallengeAPI } from '../../../Store/Action';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { englishLangId, arabicLangId, hebrewLangId, getQuery } from '../../../util/helper';
import { setChallengesUpdate, setLoading } from '../../../Store/Reducer';
import DeleteItemPopup from '../../Popups/DeleteItemPopup';
import TitleOrder from './TitleOrder';

const style = {
    control: base => ({
        ...base,
        border: 0,
        ["border-bottom"]: "1px solid #EAECEE",
        // This line disable the blue border
        boxShadow: "none",
    })
};


const inputClass = "block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 dark:bg-gray-700 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer";

const labelClass = "absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4";
const uploadDivClass = 'h-12 flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600';
const uploadLabelClass = 'flex flex-col items-center justify-center w-full h-14 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600';

const EditChallenges = () => {
    //const store = useSelector(state => state);
    //const { allChallengesList, availableLanguages, challengesUpdated } = store;
    const availableLanguages = useSelector(state => state?.availableLanguages);
    const challengesUpdated = useSelector(state => state?.challengesUpdated);
    const { control, formState: { errors }, handleSubmit, register, setValue, clearErrors, getValues, reset, unregister } = useForm();
    const [questionType, setQuestionType] = useState('Image');
    const [answerCount, setAnswerCount] = useState(4);
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [deletePopupData, setDeletePopupData] = useState([]);
    const [answerImageURL, setAnswerImageURL] = useState({});
    const [answerType, setAnswerType] = useState('Text');
    const [isRefresh, setRefresh] = useState(false);
    const [challenges, setChallenges] = useState([]);
    const [ids, setIds] = useState([])
    const [quesList, setQuesList] = useState([]);
    const [selectedQus, setSelectedQues] = useState(0);
    const [isApiCalled, setApiCalled] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [openTitleOrder, setOpenTitleOrder] = useState(false);
    const [openQuestions, setOpenQuestions] = useState(false);
    const [challengeId, setChallengeId] = useState(getQuery("id"));
    const [apicount, setApiCount] = useState(0);
    const [showOnce, setShowOnce] = useState(false);

    const navigate = useNavigate();
    const videoRef = useRef();
    const audioRef = useRef();
    const dispatch = useDispatch();

    const FilterbyTitle = (a, b) => {
        if (a.questionTitle[0].value < b.questionTitle[0].value) {
            return -1;
        }
        if (a.questionTitle[0].value > b.questionTitle[0].value) {
            return 1;
        }
        return 0;
    }

    const FilterbyTitleOrder = (a, b) => {
        if (a.titleOrder < b.titleOrder) {
            return -1;
        }
        if (a.titleOrder > b.titleOrder) {
            return 1;
        }
        return 0;
    }
    const FilterbychallengeOrder = (a, b) => {
        if (a.challengeOrder < b.challengeOrder) {
            return -1;
        }
        if (a.challengeOrder > b.challengeOrder) {
            return 1;
        }
        return 0;
    }

    const getChallengesList = async (update = false) => {
        const response = await getAllchallengesApi();
        let filterData = response.sort(FilterbyTitleOrder)
        filterData = response.sort(FilterbychallengeOrder)

        if (!update) {
            setChallenges(filterData);
            getChallengeIds(filterData);
        } else {
            setDefaultValues(filterData[selectedIndex]);
            setChallenges(filterData);
            quesList[selectedIndex].title = response[selectedIndex].questionTitle;
        }
    }

    const closeTitleOrder = () => {
        setOpenTitleOrder(false)
    }

    const deleteChallenges = async (e) => {
        e.preventDefault();
        const reqObj = {
            "challengeId": challenges[selectedIndex]._id,
            "dataToUpdate": { active: false }
        }
        const response = await updateChallengeAPI(dispatch, reqObj);
        if (response?.status === 200 && response?.data) {
            toast.success("Success")
            const challenge = JSON.parse(JSON.stringify(challenges));
            challenge.splice(selectedIndex, 1);
            setChallenges(challenge);
            getChallengeIds(challenge);
        } else {
            toast.error("Failed")
        }


    }

    useEffect(() => {
        videoRef.current?.load();
        audioRef.current?.load();

        let getChallenges = async () => {
            if (apicount < 3) {
                getChallengesList();
                setApiCalled(true)
                videoRef.current?.load();
                audioRef.current?.load();
                setApiCount(apicount + 1)
            } else {
                toast.error("Service not available")
            }

        }
        if (challenges.length === 0) {
            getChallenges();
        }

        if (challengesUpdated) {
            //getChallengesList(true);
            dispatch(setChallengesUpdate(false))
            window.localStorage.setItem('challengePopup', "edited")
        }

        if (challengeId) {
            setOpenQuestions(true);
        }
        if (challengeId && challenges.length !== 0) {
            const selectFindIndex = challenges.findIndex(val => val._id === challengeId);
            setDefaultValues(challenges[selectFindIndex]);
            setSelectedIndex(selectFindIndex);
            setTimeout(() => {
                if (document.querySelector("#ques" + selectFindIndex)) {
                    document.querySelector("#ques" + selectFindIndex).scrollIntoView();
                    setShowOnce(true)
                }
            }, 1000);

        }

    }, [isRefresh, challengesUpdated])

    const getChallengeIds = (data) => {
        try {
            let challageIds = [];
            let quesNames = [];
            for (var i = 0; i < data.length; i++) {
                if (i === 0 && selectedIndex === 0) {
                    setSelectedQues(data[i])
                    setDefaultValues(data[i]);
                    setSelectedIndex(i)
                } else {
                    setSelectedQues(data[selectedIndex])
                    setDefaultValues(data[selectedIndex]);
                    setSelectedIndex(selectedIndex)
                }
                let ques = {};
                const keys = Object.keys(data[i]);
                if (data[i]['_id']) {
                    challageIds.push(data[i]['_id']['$oid'])
                }
                if (data[i]['questionTitle']) {
                    ques.index = i;
                    ques.title = data[i]['questionTitle'];
                    ques.challengeOrder = data[i]['challengeOrder'];
                    ques.titleOrder = data[i]['titleOrder'];
                }
                quesNames.push(ques);
            }
            setQuesList(quesNames)
            setIds(challageIds);
            setRefresh(!isRefresh);

        } catch (e) {
            console.log("ERRROR-------->", e)
        }
    }
    const getAllchallengesApi = async () => {
        try {
            const reqObj = {
                "getLatestFirst": true,
                "pageNumber": 1,
                "numberOfRecordsPerPage": 1000,
                "filter": {
                    "active": true
                }
            }
            return await getAllChallegesAction(dispatch, reqObj);
        } catch (e) {
            return []
        }
    }

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const setTextPropFromApi = (data, val) => {
        unregister("answer" + val);
        unregister("answerAr" + val);
        unregister("answerHb" + val);
        if (data[0] && data[0].value) {
            setValue("answer" + val, data[0].value ? data[0].value : "");
        }
        if (data[1] && data[1].value) {
            setValue("answerAr" + val, data[1].value ? data[1].value : "");
        }
        if (data[2] && data[2].value) {
            setValue("answerHb" + val, data[2].value ? data[2].value : "");
        }
    }

    const setImagePropFromApi = (data, val) => {
        unregister("answer" + val)
        setValue("answer" + val + "Image", data.value);
        let img = data.value;
        let ansImageUrl = answerImageURL;
        ansImageUrl["answer" + val + "Image"] = img
        setAnswerImageURL(ansImageUrl);
    }

    const setDefaultValues = (data) => {
        try {
            setQuestionType(data.questionType)
            setAnswerType(data.answersType)
            setAnswerCount(data.answersCount);
            setValue("challengeType", data.challengeType);
            setValue("challengeTitle", data?.challengeTitle[0]?.value)
            setValue("challengeTitle_Ar", data?.challengeTitle[0]?.value)
            setValue("challengeTitle_Hb", data?.challengeTitle[0]?.value)
            setValue("userTarget", "All")
            setValue("questionType", data.questionType);

            setValue("userLevel", data.Level);

            setValue("questionTime", data.secondsToAnswer);
            setValue("coinsToEarn", data.coinsToEarn);
            setValue("titleOrder", data.titleOrder);
            setValue("challengeOrder", data.challengeOrder);

            setValue("answerType", data.answersType);
            setValue("answerCount", data.answersCount);
            setValue("correctAnswer", data.correctAnswer);
            setValue("imageQuestionContent", data?.questionTitle[0].value);
            setValue("imageQuestionContentAr", data?.questionTitle[1]?.value);
            setValue("imageQuestionContentHb", data?.questionTitle[2]?.value);


            if (data.challengeType !== 'Text') {
                let ansImageUrl = answerImageURL;
                ansImageUrl.challengeImage = data.challengeImage

                setAnswerImageURL(ansImageUrl);
                setValue("challengeImage", data.challengeImage);
            }

            if (data.Answer1) {
                if (data.answersType !== 'Text') {
                    setImagePropFromApi(data.Answer1[0], 1)

                } else {
                    setTextPropFromApi(data.Answer1, 1);
                }
            }
            if (data.Answer2) {
                if (data.answersType !== 'Text') {
                    setImagePropFromApi(data.Answer2[0], 2)

                } else {
                    setTextPropFromApi(data.Answer2, 2);
                }
            }
            if (data.Answer3) {
                if (data.answersType !== 'Text') {
                    setImagePropFromApi(data.Answer3[0], 3)

                } else {
                    setTextPropFromApi(data.Answer3, 3);
                }
            }
            if (data.Answer4) {
                if (data.answersType !== 'Text') {
                    setImagePropFromApi(data.Answer4[0], 4)

                } else {
                    setTextPropFromApi(data.Answer4, 4);
                }
            }
            if (data.Answer5) {
                if (data.answersType !== 'Text') {
                    setImagePropFromApi(data.Answer5[0], 5)

                } else {
                    setTextPropFromApi(data.Answer5, 5);
                }
            }
            if (data.Answer6) {
                if (data.answersType !== 'Text') {
                    setImagePropFromApi(data.Answer6[0], 6)

                } else {
                    setTextPropFromApi(data.Answer6, 6);
                }
            }
            if (data.Answer7) {
                if (data.answersType !== 'Text') {
                    setImagePropFromApi(data.Answer7[0], 7)

                } else {
                    setTextPropFromApi(data.Answer7, 7);
                }
            }
            if (data.Answer8) {
                if (data.answersType !== 'Text') {
                    setImagePropFromApi(data.Answer8[0], 8)

                } else {
                    setTextPropFromApi(data.Answer8, 8);
                }
            }



        } catch (e) {
            console.log("EEE------------------>", e)
        }
    }

    const editQuestion = (e, index) => {
        e.preventDefault();
        clearErrors();
        setSelectedIndex(index)
        setSelectedQues(challenges[index])
        setDefaultValues(challenges[index])
        setRefresh(!isRefresh)
    }

    const AnswerFileUpload = async (file) => {
        let content = new FormData();
        content.append('fileKey', file);
        const fileKey = await uploadFileAction(content);
        return fileKey;
    }


    const constructParams = async (data, submitData) => {

        submitData.challengeImage = answerImageURL.challengeImage;
        if (answerType === 'Text') {
            for (let i = 0; i < answerCount; i++) {
                const newIndex = i + 1;
                submitData[`Answer` + newIndex] = [
                    { language: englishLangId(availableLanguages.languageList), value: data[`answer` + newIndex] },
                    { language: arabicLangId(availableLanguages.languageList), value: data[`answerAr` + newIndex] },
                    { language: hebrewLangId(availableLanguages.languageList), value: data[`answerHb` + newIndex] }
                ]
            }
        } else {
            for (let i = 0; i < answerCount; i++) {
                const newIndex = i + 1;
                submitData[`Answer` + newIndex] = [
                    { language: englishLangId(availableLanguages.languageList), value: answerImageURL[`answer` + newIndex + 'Image'] }
                ]
            }
        }
        if (data.challengeTitle) {
            submitData.challengeTitle = [
                { language: englishLangId(availableLanguages.languageList), value: data.challengeTitle },
                { language: arabicLangId(availableLanguages.languageList), value: data.challengeTitle_Ar },
                { language: hebrewLangId(availableLanguages.languageList), value: data.challengeTitle_Hb }
            ]
        }
        if (data.imageQuestionContent) {
            submitData.questionTitle = [
                { language: englishLangId(availableLanguages.languageList), value: data.imageQuestionContent },
                { language: arabicLangId(availableLanguages.languageList), value: data.imageQuestionContentAr },
                { language: hebrewLangId(availableLanguages.languageList), value: data.imageQuestionContentHb }
            ]
        }

        return submitData;
    }

    const submit = async (values) => {
        try {
            const { answerCount, answerType,
                challengeOrder, challengeTitle, challengeType, coinsToEarn, correctAnswer, imageQuestionContent,
                questionTime, questionType, titleOrder, userLevel, userTarget } = values;
            let isValidCorrectAnswer = false
            if (answerType === 'Text') {
                isValidCorrectAnswer = values['answer' + correctAnswer] === ""
            }
            if (isValidCorrectAnswer) {
                toast.error(`Please update answer as per correct answer`);
            } else {
                let submitData = {
                    challengeType: challengeType,
                    titleOrder: "1", //titleOrder,
                    Level: userLevel,
                    challengeOrder: "1", //challengeOrder,
                    questionType: questionType,
                    answersType: answerType,
                    answersCount: answerCount,
                    secondsToAnswer: questionTime,
                    correctAnswer: correctAnswer,
                    userTarget: userTarget,
                    coinsToEarn: coinsToEarn,

                }
                submitData = await constructParams(values, submitData)
                const reqObj = {
                    "challengeId": challenges[selectedIndex]._id,
                    "dataToUpdate": submitData
                }
                if (challengeId) {
                    window.localStorage.setItem("editedData", JSON.stringify({
                        questionTitle: submitData.questionTitle,
                        challengeTitle: submitData.challengeTitle,
                        Level: submitData.Level,
                        challengeImage: submitData.challengeImage
                    }))
                }
                setSelectedIndex(selectedIndex);
                quesList[selectedIndex].title = submitData.questionTitle;
                challenges[selectedIndex] = { ...challenges[selectedIndex], ...submitData };
                dispatch(await updateChallengesAction(reqObj));
            }
        } catch (error) {
            console.log("EEEE0==========>", error)
            toast.error("Failed");
        }
    }
    const PlaceHolder = ["first", "second", "third", "fourth", "fifth", "sixth", "seventh", "eighth"];

    const checkFileType = (file) => {
        if (file.type.match('image.*'))
            return 'Image';

        if (file.type.match('video.*'))
            return 'Video';

        if (file.type.match('audio.*'))
            return 'Audio';

        return 'other';
    }

    const uploadFilesToAws = async (file, id) => {
        let ansImageUrl = answerImageURL;
        try {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async () => {
                const base64File = reader.result.split(',')[1];
                dispatch(setLoading(true))
                const response = await uploadFileAction({
                    fileContent: base64File,
                    contentType: file.type
                })

                const responseBody = response;
                const { payload } = responseBody?.data;
                const { fileUploadResponse } = payload;
                const { Location } = fileUploadResponse;

                if (Location) {
                    ansImageUrl[id] = Location;
                    setAnswerImageURL(ansImageUrl);
                    setRefresh(!isRefresh)
                } else {
                    toast.error("Upload failed")
                    ansImageUrl[id] = "";
                    setAnswerImageURL(ansImageUrl);
                    setRefresh(!isRefresh)
                }
                dispatch(setLoading(false))
            }
        } catch (e) {
            toast.error("Upload failed")
            dispatch(setLoading(false))
            ansImageUrl[id] = "";
            setAnswerImageURL(ansImageUrl);
            setRefresh(!isRefresh)
        }
    }

    const handleFileUpload = async (file, id) => {
        try {
            const fileType = checkFileType(file)
            if (fileType === questionType) {
                await uploadFilesToAws(file, id);
            } else {
                await uploadFilesToAws(file, id);
                if (id === 'challengeImage') {
                    reset({ id: "" });
                    toast.error("Please upload file as per the question type");
                }
            }

        } catch (error) {
        }
    }
    const handleQuesType = (val) => {
        setQuestionType(val)
        if (answerImageURL.challengeImage) {
            answerImageURL.challengeImage = ""
        }
    }

    const handleAnswerCount = (val) => {
        setAnswerCount(val);
        setRefresh(!isRefresh)




    }

    const ShowAnswers = () => {
        let answerHtml = [];
        let spaceStyle = { 2: 6, 3: 4, 4: 3, 5: 4, 6: 4, 7: 4, 8: 4 }
        for (let i = 0; i < answerCount; i++) {
            let index = i + 1;
            let inputValidataion = "Required"
            if (answerType === 'Text' && answerCount > 3 && i === answerCount - 1) {
                inputValidataion = false;
            }
            if (answerType === 'Text') {
                answerHtml.push(
                    <div>
                        <div>
                            <div className="relative">
                                <input autoComplete='off' type="text" name={`answer` + index} id={`answer` + index}
                                    className={inputClass} placeholder={`Your ${PlaceHolder[i]} answer here (English)`}
                                    {...register(`answer` + index, { required: inputValidataion, onChange: e => setValue(e.target.name, e.target.value) })} />
                            </div>
                            {errors[`answer` + index] && <span className='errSpan'>Required</span>}
                            <div className="relative">
                                <input autoComplete='off' type="text" name={`answerAr` + index} id={`answerAr` + index} style={{ direction: "rtl" }}
                                    className={inputClass} placeholder={`Your ${PlaceHolder[i]} answer here (Arabic)`}
                                    {...register(`answerAr` + index, { required: inputValidataion, onChange: e => setValue(e.target.name, e.target.value) })} />
                            </div>
                            {errors[`answerAr` + index] && <span className='errSpan'>Required</span>}
                            <div className="relative">
                                <input autoComplete='off' type="text" name={`answerHb` + index} id={`answerHb` + index} style={{ direction: "rtl" }}
                                    className={inputClass} placeholder={`Your ${PlaceHolder[i]} answer here(Hebrew)`}
                                    {...register(`answerHb` + index, { required: inputValidataion, onChange: e => setValue(e.target.name, e.target.value) })} />
                            </div>
                            {errors[`answerHb` + index] && <span className='errSpan'>Required</span>}
                        </div>
                        {index === 4 && <br></br>}
                    </div>)
            } else if (answerType === 'Image') {
                answerHtml.push(
                    <div>
                        <label>Answer {index}</label>
                        <div className={uploadDivClass + ` ${answerImageURL[`answer${index}Image`] ? 'h-auto p-2' : 'h-12 p-2'}`}>
                            <img src={answerImageURL[`answer${index}Image`]} style={{ height: answerImageURL[`answer${index}Image`] ? "75px" : "0px" }} />
                        </div>
                        <div className="flex items-center justify-center w-full">
                            <label for={`answer${index}Image`} className={uploadLabelClass}>
                                <div className="flex flex-col items-center justify-center">
                                    <svg className="w-5 h-5  text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                    </svg>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Upload your image</span></p>
                                </div>
                                <Controller
                                    control={control}
                                    name={`answer${index}Image`}
                                    rules={{ required: true }}
                                    render={({ field: { onChange, ref } }) => (
                                        <input ref={ref} onChange={(e) => { onChange(e.target.files[0], handleFileUpload(e.target.files[0], e.target.id)) }} accept="image/*, video/*,.mp3,audio/*" id={`answer${index}Image`} type="file" className="hidden" />
                                    )}
                                />
                            </label>

                        </div>
                        {errors[`answer${index}Image`] && <span className='errSpan'>Required</span>}
                    </div>)
            }
        }
        return answerHtml;

    }

    const previous = (e) => {
        e.preventDefault();
        clearErrors();
        if (selectedIndex === 0) {
            toast.info("No more previous questions")
        } else {
            if (e.target.name !== 'next') {
                const prevIndex = selectedIndex - 1;
                setDefaultValues(challenges[prevIndex])
                setSelectedQues(challenges[prevIndex])
                setSelectedIndex(prevIndex)
            }
        }


    }
    const next = (e) => {
        e.preventDefault();
        clearErrors();
        if (selectedIndex === challenges.length - 1) {
            toast.info("No more next questions")
        } else {
            const nextIndex = selectedIndex + 1;
            setDefaultValues(challenges[nextIndex])
            setSelectedQues(challenges[nextIndex])
            setSelectedIndex(nextIndex)
        }

    }

    const deletePopupOpen = (data) => {
        setDeletePopupData(data)
        setOpenDeletePopup(true);
    }

    const closeDeletePopup = () => {
        setOpenDeletePopup(false);
    }


    return (
        <React.Fragment>
            {!challengeId && <TopHeader />}
            <DahboardMenu />
            {openTitleOrder && <TitleOrder open={openTitleOrder} close={closeTitleOrder} />}
            {openDeletePopup && <DeleteItemPopup closeModal={closeDeletePopup} modalIsOpen={openDeletePopup} isFrom="challenges" deleteData={deleteChallenges} data={deletePopupData} />}

            <div>

            </div>

            <div className="mt-2 h-full w-full" >
                <div className="justify-between max-w-[540px] sm:max-w-[604px] md:max-w-[720px] lg:max-w-[972px]
xl:max-w-full xl:pl-28 h-full xl:ml-40 xl:px-6 2xl:max-w-[initial] mx-auto
flex">
                    {/* <button data-drawer-target="default-sidebar" data-drawer-toggle="default-sidebar" aria-controls="default-sidebar" type="button" className="inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg  hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                        <span className="sr-only">Open sidebar</span>
                        <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                        </svg>
                    </button> */}
                    <div className="text-center">
                        <button onClick={() => setOpenQuestions(!openQuestions)} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" type="button">
                            {openQuestions ? "Hide Questions" : "Show Questions"}
                        </button>
                    </div>
                    <button className={buttonCSS.addbtn} style={{ "height": "39px" }} onClick={() => navigate('/dashboard/addchallenge')}>
                        <svg className="h-6 w-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                        </svg>
                        Add
                    </button>
                </div>
                <div className="
max-w-[540px] sm:max-w-[604px] md:max-w-[720px] lg:max-w-[972px]
xl:max-w-full xl:pl-20 h-full xl:ml-40 xl:px-12 2xl:max-w-[initial] mx-auto
flex">

                    <div className="flex">
                        {openQuestions && <div className='z-40'>
                            <aside id="default-sidebar" className="z-40 w-72 h-screen p-4 overflow-y-auto  bg-white w-80 dark:bg-gray-800" aria-label="Sidebar">
                                <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
                                    <ul id="quesul" className="space-y-2 font-light text-sm">
                                        {quesList.map((val, index) => {
                                            return <li id={`ques${index}`}>
                                                <a onClick={(e) => editQuestion(e, val.index)} href="javascript:void(0);" className="flex items-center pb-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                                                    <span style={{ color: selectedIndex === val.index ? "#FF6600" : "#000" }} key={"cOrder" + val.index}><span className='font-bold'>{val.title[0].value}&nbsp;</span>

                                                    </span>
                                                </a>
                                            </li>
                                        })}
                                    </ul>
                                </div>
                            </aside>
                        </div>}
                        <div className={"flex absolute" + (openQuestions ? " pl-80" : " pl-10")} style={{ "width": "80%" }}>
                            <form className="w-full bg-white" id="challengeForm" name='conversion' onSubmit={(e) => e.preventDefault()}>
                                <div className="bg-white">
                                    <div className="flex flex-wrap xl:space-x-2 pl-2">
                                        <div className="grid grid-cols-1 md:grid-cols-3 gap-3 w-full">
                                            <div className="">
                                                <div className="">
                                                    <div className="relative p-2 mt-3">

                                                        <select id="underline_select" name='challengeType' {...register('challengeType', { required: true })} className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer">
                                                            <option value=""></option>
                                                            {challengeTypeOption.map((val, index) => {
                                                                return <option selected={index === 0 ? true : false} value={val.value}>{val.label}</option>
                                                            })}
                                                        </select>
                                                        <label for="challengeType" className={labelClass}>Challenge Type<span className='text-red-600'>*</span></label>
                                                    </div>
                                                    {errors.challengeType && <span className='errSpan'>Required</span>}
                                                </div>

                                            </div>
                                            <div className=" ">
                                                <div className=" p-2">
                                                    <div className="relative p-2 mt-1">
                                                        <select disabled={true} id="userTarget" {...register('userTarget', { required: true })} name="userTarget" className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer">
                                                            <option value=""></option>
                                                            {userTargetOption.map((val, index) => {
                                                                return <option selected={index === 0 ? true : false} value={val.value}>{val.label}</option>
                                                            })}
                                                        </select>
                                                        <label for="userTarget" className={labelClass}>User Target<span className='text-red-600'>*</span></label>
                                                    </div>
                                                    {errors.userTarget && <span className='errSpan'>Required</span>}
                                                </div>
                                            </div>
                                            <div className="  ">
                                                <div className=" p-2">
                                                    <div className="relative p-2 mt-1">
                                                        <select id="questionType" {...register('questionType', { required: true })} onChange={e => handleQuesType(e.target.value)} name="questionType" className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer">
                                                            <option value=""></option>
                                                            {questionTypeOption.map((val, index) => {
                                                                return <option selected={index === 1 ? true : false} value={val.value}>{val.label}</option>
                                                            })}
                                                        </select>
                                                        <label for="questionType" className={labelClass}>Question Type<span className='text-red-600'>*</span></label>
                                                    </div>
                                                    {errors.questionType && <span className='errSpan'>Required</span>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap md:flex-nowrap">
                                        <div className="float-left pl-2 w-full">
                                            {questionType !== 'Text' && <div className="grid grid-cols-1 md:grid-cols-3 gap-3 w-full">
                                                <div className="w-full ">
                                                    <div className=" p-2">
                                                        <div className="relative">
                                                            <input autoComplete='off' type="text" {...register('imageQuestionContent', { required: true })} name="imageQuestionContent" id="imageQuestionContent" className={inputClass} placeholder=" " />
                                                            <label for="imageQuestionContent" className={labelClass}>Text for the Image(English)</label>
                                                        </div>
                                                        {errors.imageQuestionContent && <span className='errSpan'>Required</span>}
                                                    </div>
                                                </div>
                                                <div className="w-full ">
                                                    <div className=" p-2">
                                                        <div className="relative">
                                                            <input autoComplete='off' type="text" style={{ direction: "rtl" }}  {...register('imageQuestionContentAr', { required: true })} name="imageQuestionContentAr" id="imageQuestionContentAr" className={inputClass} placeholder=" " />
                                                            <label for="imageQuestionContentAr" className={labelClass}>Text for the Image(Arabic)</label>
                                                        </div>
                                                        {errors.imageQuestionContentAr && <span className='errSpan'>Required</span>}
                                                    </div>
                                                </div>
                                                <div className="w-full ">
                                                    <div className=" p-2">
                                                        <div className="relative">
                                                            <input autoComplete='off' type="text" style={{ direction: "rtl" }}  {...register('imageQuestionContentHb', { required: true })} name="imageQuestionContentHb" id="imageQuestionContentHb" className={inputClass} placeholder=" " />
                                                            <label for="imageQuestionContentHb" className={labelClass}>Text for the Image(Hebrew)</label>
                                                        </div>
                                                        {errors.imageQuestionContentHb && <span className='errSpan'>Required</span>}
                                                    </div>
                                                </div>
                                            </div>}

                                            <div className="grid grid-cols-1 md:grid-cols-3 gap-3 w-full">
                                                <div className="w-full ">
                                                    <div className=" p-2">
                                                        <div className="relative">
                                                            <input autoComplete='off' type="text" name="challengeTitle" id="challengeTitle" {...register('challengeTitle', { required: true })} className={inputClass} placeholder=" " />
                                                            <label for="challengeTitle" className={labelClass}>Challenge Title(English)<span className='text-red-600'>*</span></label>
                                                        </div>
                                                        {errors.challengeTitle && <span className='errSpan'>Required</span>}
                                                    </div>
                                                </div>
                                                <div className="w-full ">
                                                    <div className=" p-2">
                                                        <div className="relative">
                                                            <input autoComplete='off' style={{ direction: "rtl" }} type="text" name="challengeTitle_Ar" id="challengeTitle_Ar" {...register('challengeTitle_Ar', { required: true })} className={inputClass} placeholder=" " />
                                                            <label for="challengeTitle_Ar" className={labelClass}>Challenge Title(Arabic)<span className='text-red-600'>*</span></label>
                                                        </div>
                                                        {errors.challengeTitle_Ar && <span className='errSpan'>Required</span>}
                                                    </div>
                                                </div>
                                                <div className="w-full ">
                                                    <div className=" p-2">
                                                        <div className="relative">
                                                            <input autoComplete='off' style={{ direction: "rtl" }} type="text" name="challengeTitle_Hb" id="challengeTitle_Hb" {...register('challengeTitle_Hb', { required: true })} className={inputClass} placeholder=" " />
                                                            <label for="challengeTitle_Hb" className={labelClass}>Challenge Title(Hebrew)<span className='text-red-600'>*</span></label>
                                                        </div>
                                                        {errors.challengeTitle_Hb && <span className='errSpan'>Required</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-1 md:grid-cols-3 gap-3 w-full">
                                                <div className="w-full ">
                                                    <div className="mb-2 p-2">
                                                        <div className="relative p-2 mt-1">
                                                            <select id="userLevel" {...register('userLevel', { required: true })} name="userLevel" className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer">
                                                                <option value=""></option>
                                                                {userLevelOption.map((val, index) => {
                                                                    return <option selected={index === 0 ? true : false} value={val.value}>{val.label}</option>
                                                                })}
                                                            </select>
                                                            <label for="userLevel" className={labelClass}>Level<span className='text-red-600'>*</span></label>
                                                        </div>
                                                        {errors.userLevel && <span className='errSpan'>Required</span>}
                                                    </div>
                                                </div>
                                                <div className="w-full  ">
                                                    <div className="mb-2 p-2">
                                                        <div className="relative p-2 mt-1">
                                                            <select id="questionTime" {...register('questionTime', { required: true })} name="questionTime" className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer">
                                                                <option value=""></option>
                                                                {questionTimeOption.map((val, index) => {
                                                                    return <option selected={index === 0 ? true : false} value={val.value}>{val.label}</option>
                                                                })}
                                                            </select>
                                                            <label for="questionTime" className={labelClass}>Question Time<span className='text-red-600'>*</span></label>
                                                        </div>
                                                        {errors.questionTime && <span className='errSpan'>Required</span>}
                                                    </div>
                                                </div>
                                                <div className="w-full  ">
                                                    <div className="mb-2 p-2">
                                                        <div className="relative">
                                                            <input autoComplete='off' type="text" {...register('coinsToEarn', { required: true })} id="coinsToEarn" className={inputClass} placeholder=" " required />
                                                            <label for="coinsToEarn" className={labelClass}>Coins To Earn<span className='text-red-600'>*</span></label>
                                                        </div>
                                                        {errors.coinsToEarn && <span className='errSpan'>Required</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-1 md:grid-cols-3 gap-3 w-full">
                                                <div className="w-full  ">
                                                    <div className="">
                                                        <div className="relative p-2 mt-1">
                                                            <select id="answerType" {...register('answerType', { required: true, onChange: e => setAnswerType(e.target.value) })} name="answerType" className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer">
                                                                <option value=""></option>
                                                                {answerTypeOption.map((val, index) => {
                                                                    return <option selected={index === 1 ? true : false} value={val.value}>{val.label}</option>
                                                                })}
                                                            </select>
                                                            <label for="answerType" className={labelClass}>Answer Type<span className='text-red-600'>*</span></label>
                                                        </div>
                                                        {errors.answerType && <span className='errSpan'>Required</span>}
                                                    </div>
                                                </div>
                                                <div className="w-full  ">
                                                    <div className="">
                                                        <div className="relative p-2 mt-1">
                                                            <select id="answerCount" {...register('answerCount', { required: true })} onChange={e => handleAnswerCount(e.target.value)} name="answerCount" className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer">
                                                                <option value=""></option>
                                                                {answerCountOption.map((val, index) => {
                                                                    return <option selected={answerCount == val.value ? true : false} value={val.value}>{val.label}</option>
                                                                })}
                                                            </select>
                                                            <label for="answerCount" className={labelClass}>Answer Count<span className='text-red-600'>*</span></label>
                                                        </div>
                                                        {errors.answerCount && <span className='errSpan'>Required</span>}
                                                    </div>
                                                </div>
                                                <div className="w-full  ">
                                                    <div className="">
                                                        <div className="relative">
                                                            <input autoComplete='off' type="text" {...register('correctAnswer', { required: true })} id="correctAnswer" className={inputClass} placeholder=" " required />
                                                            <label for="correctAnswer" className={labelClass}>Correct Answer<span className='text-red-600'>*</span></label>
                                                        </div>
                                                        {errors.correctAnswer && <span className='errSpan'>Required</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="float-right w-full md:w-1/3">

                                            {questionType === 'Text' ? <div className=" p-2 h-56 border border-dashed mr-5 bg-slate-100">
                                                <div className="relative">
                                                    <textarea type="text" {...register('imageQuestionContent', { required: true })} id="correctAnswer" className={inputClass + " h-14"} placeholder=" " />
                                                    <label for="imageQuestionContent" className={labelClass}>Challenge question (English)<span className='text-red-600'>*</span></label>
                                                </div>
                                                {errors.imageQuestionContent && <span className='errSpan'>Required</span>}
                                                <br></br>
                                                <div className="relative">
                                                    <textarea type="text" {...register('imageQuestionContentAr', { required: true })} id="correctAnswer" className={inputClass + " h-14"} placeholder=" " />
                                                    <label for="imageQuestionContentAr" className={labelClass}>Challenge question (Arabic)<span className='text-red-600'>*</span></label>
                                                </div>
                                                {errors.imageQuestionContentAr && <span className='errSpan'>Required</span>}
                                                <br></br>
                                                <div className="relative">
                                                    <textarea type="text" {...register('imageQuestionContentHb', { required: true })} id="correctAnswer" className={inputClass + " h-14"} placeholder=" " />
                                                    <label for="imageQuestionContentHb" className={labelClass}>Challenge question (Hebrew)<span className='text-red-600'>*</span></label>
                                                </div>
                                                {errors.imageQuestionContentHb && <span className='errSpan'>Required</span>}
                                            </div> :
                                                <div>

                                                    <div className="flex items-center justify-center w-full">
                                                        <label for="challengeImage" className="flex flex-col h-64 items-center justify-center w-96 mr-7 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                                                {!answerImageURL.challengeImage ? <svg className="w-8 h-8  text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                                                </svg> :
                                                                    <div className='p-5' style={{ maxWidth: "100%" }}>
                                                                        {questionType === 'Image' && <img height={"150"} width={"200"} src={answerImageURL.challengeImage} alt="" id="representativeImage" />}
                                                                        {(questionType === 'Video' && answerImageURL.challengeImage) && <video width="320" height="240" controls autoPlay={true} muted playsInline ref={videoRef}>
                                                                            <source src={answerImageURL.challengeImage} type="video/mp4" />
                                                                            Your browser does not support the video tag.
                                                                        </video>}
                                                                        {(questionType === 'Audio' && answerImageURL.challengeImage) && <audio controls ref={audioRef}>
                                                                            <source src={answerImageURL.challengeImage} type="audio/mp3" />
                                                                            Your browser does not support the audio tag.
                                                                        </audio>}

                                                                    </div>}
                                                                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Upload your image</span></p>
                                                            </div>
                                                            <Controller
                                                                control={control}
                                                                name="challengeImage"
                                                                rules={{ required: true }}
                                                                render={({ field: { onChange, ref } }) => (
                                                                    <input accept="image/*, video/*,.mp3,audio/*" ref={ref} onChange={(e) => onChange(e.target.files[0], handleFileUpload(e.target.files[0], e.target.id))}
                                                                        id="challengeImage" type="file" className="hidden" />
                                                                )}
                                                            />
                                                        </label>

                                                    </div>
                                                    {errors.challengeImage && <span className='errSpan'>Required</span>}
                                                </div>
                                            }

                                        </div>
                                    </div>
                                    <div className='rounded ring-2 ring-gray-300 dark:ring-gray-500 m-5 p-5'>

                                        <div className={`grid grid-cols-1 md:grid-cols-${answerCount < 4 ? answerCount : 4} gap-5 xl:space-x-5 w-full mt-1`}>
                                            <ShowAnswers />
                                        </div>
                                    </div>
                                </div>
                                <div className="grid grid-cols-4 gap-4 p-2">
                                    <div>
                                        <button onClick={(e) => previous(e)} className="bg-yellow-500 text-white px-4 py-2 rounded-md">Previous</button>
                                    </div>
                                    <div className='text-center'>
                                        <button onClick={(e) => deletePopupOpen(challenges[selectedIndex])} className="bg-blue-500 text-white px-4 py-2 rounded-md">Delete</button>
                                    </div>
                                    <div className='text-center'>
                                        <button onClick={handleSubmit(submit)} className="bg-blue-500 text-white px-4 py-2 rounded-md">Update</button>
                                    </div>
                                    <div className='text-end'>
                                        <button onClick={(e) => next(e)} className="bg-yellow-500 text-white px-4 py-2 rounded-md">Next</button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div >
            </div >
        </React.Fragment>
    )
}

export { EditChallenges }