import React, { useEffect, useState } from 'react';
import _ from 'underscore';

const ErrorMessage = (props) => {
    const { errors, setFormErrors } = props;
    if (document.querySelector('#errDiv')) {
        document.querySelector('#errDiv').classList.remove('hidden')
    }
    let errval = [];
    _.findKey(errors, function (value, key) {
        if (value && value.message) {
            errval.push(value.message)
        } else {
            errval.push(errors)
        }
    });
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            {(errval.length !== 0) ? <div id="errDiv" className="flex p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                <svg className="flex-shrink-0 inline w-4 h-4 mr-3 mt-[2px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                </svg>
                <span className="sr-only">Danger</span>
                <span className="absolute top-0 bottom-0 right-0 px-4 py-3" onClick={() => document.querySelector('#errDiv').classList.add('hidden')}>
                    <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
                </span>
                <div>
                    <span className="font-medium">Ensure that these requirements are met:</span>
                    <ul className="mt-1.5 ml-4 list-disc list-inside">
                        {errval.map((val, index) => <li>{val}</li>)}
                    </ul>
                </div>
            </div> : null}
        </div>
    )
}

export default ErrorMessage