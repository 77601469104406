import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { getAllTreatmentListAction } from '../../../Store/Action';
import { setEditOptionData, setMedicineDataById } from '../../../Store/Reducer';
import DeleteItemPopup from '../../Popups/DeleteItemPopup';
import { DahboardMenu } from '../DahboardMenu';
import { TopHeader } from '../TopHeader';

const TreatmentList = () => {
    const store = useSelector(state => state);
    const { allTreatmentListStore, editOptionData } = store;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isOpenQR, setIsOpenQR] = useState(false);
    const [qrVal, setQrVal] = useState("");
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [deletePopupData, setDeletePopupData] = useState([]);
    const treatDataIds = [];

    useEffect(() => {
        if (allTreatmentListStore.length === 0) {
            getData();
        }
        if (allTreatmentListStore.length !== 0) {
            allTreatmentListStore.map((val) => {
                treatDataIds.push(val._id)
            })
            dispatch(setEditOptionData(treatDataIds))
        }
        document.title = 'Protocols List';
    }, [allTreatmentListStore])


    const getData = async () => {
        const reqObj = {
            "getLatestFirst": true,
            "pageNumber": 1,
            "numberOfRecordsPerPage": 0,
            "filter": {
                "active": true
            }
        }
        dispatch(await getAllTreatmentListAction(reqObj));
    }

    const selectMedicine = (id) => {
        dispatch(setMedicineDataById({}))
        navigate('/dashboard/treatments?id=' + id, { state: { id: 1, name: 'sabaoon' } })
        //window.location.href = '/dashboard/protocols?id=' + id
    }

    const deletePopupOpen = (data) => {
        setDeletePopupData(data)
        setOpenDeletePopup(true);
    }

    const closeDeletePopup = () => {
        setOpenDeletePopup(false);
    }

    const openQrPopup = (val) => {
        setQrVal(val)
        setIsOpenQR(true);

    }

    const closeQrPopup = () => {
        setQrVal("")
        setIsOpenQR(false);

    }
    return (
        <React.Fragment>
            {openDeletePopup && <DeleteItemPopup closeModal={closeDeletePopup} modalIsOpen={openDeletePopup} isFrom="treatment" data={deletePopupData} />}
            <TopHeader />
            <DahboardMenu />
            <div className="mt-10 h-screen" >
                <div className="
      max-w-[540px] sm:max-w-[604px] md:max-w-[720px] lg:max-w-[972px]
      xl:max-w-full xl:pl-40  xl:ml-40 xl:px-12 2xl:max-w-[1400px] mx-auto
     flex">
                    <div className="flex w-full flex-col ">
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                            <div className="flex justify-end">
                                <button className="bg-transparent hover:bg-transparent text-black font-bold py-2 px-4 rounded-full flex items-center" onClick={() => navigate('/dashboard/treatments')}>
                                    <svg className="h-6 w-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                                    </svg>
                                    Add
                                </button>
                            </div>
                            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                    <tr>
                                        <th scope="col" className="px-6 py-3">
                                            Treatment Explanation
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Treatment Instruction
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Treatment Duration
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allTreatmentListStore.length !== 0 ? allTreatmentListStore.map((data, index) => {
                                        return <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                            <td className="px-6 py-4">
                                                {data.explanation[0].value}
                                            </td>
                                            <td className="px-6 py-4">
                                                {data.instructionsForMedicine.length !== 0 ? data.instructionsForMedicine[0].value : ""}
                                            </td>
                                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                {data.treatmentDuration}
                                            </th>
                                            <td className="px-6 py-4">
                                                <a href="javascript:void(0);" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => selectMedicine(data._id)}>Edit</a> /&nbsp;
                                                <a href="javascript:void(0);" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => deletePopupOpen(data)}>Delete</a>
                                                {/* <a href="javascript:void(0);" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => openQrPopup(data?.protocolQRCode)}>Scan</a> */}
                                            </td>
                                        </tr>
                                    }) :
                                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                            <th scope="row" colSpan={5} className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                No data found
                                            </th>
                                        </tr>
                                    }

                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default TreatmentList