import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { getsideEffectList, getsideEffectListById, getStaffUpdatedAction } from '../../../Store/Action';
import { fromMedicinePage, setMedicineDataById, isfromsideEffectList, setEditOptionData, sideEffectList as sideEffectListAction } from '../../../Store/Reducer';
import { DahboardMenu } from '../DahboardMenu';
import { TopHeader } from '../TopHeader';
import DeleteItemPopup from '../../Popups/DeleteItemPopup';
import { FilterbyStringAsending, FilterbyStringDsending } from '../../../util/helper';
import { buttonCSS } from '../../../util/contant';
import InfiniteScroll from 'react-infinite-scroll-component';
import { sideEffectListURL } from '../../../Api/ContantUrls';
import { ThreeDots } from 'react-loader-spinner';

const SideEffectList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const sideEffectListdata = useSelector(state => state?.sideEffectListdata);
    //const { sideEffectListdata } = store;
    const [isOpenQR, setIsOpenQR] = useState(false);
    const [qrVal, setQrVal] = useState("");
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [deletePopupData, setDeletePopupData] = useState([]);
    const [sideEffectList, setSideEffectList] = useState([]);
    const [callOnce, setCallOnce] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);

    const [sideNameFilter, setSideNameFilter] = useState(false);
    const [sideDescFilter, setSideDescFilter] = useState(false);
    const [sideTreatFilter, setSideTreatFilter] = useState(false);

    const [hasMore, setHasMore] = useState(true)
    const [pageCount, setPageCount] = useState(20)
    const [pageNumber, setPageNumber] = useState(1)

    const editDataIds = [];
    useEffect(() => {
        document.title = 'Side Effects List';
        if (sideEffectListdata.length === 0 && sideEffectList.length === 0) {
            const reqObj = {
                "getLatestFirst": true,
                "pageNumber": pageNumber,
                "numberOfRecordsPerPage": pageCount,
                "filter": {
                    "active": true
                }
            }
            dispatch(getsideEffectList(reqObj));
        }
        if (sideEffectListdata.length !== 0 && !callOnce && sideEffectList.length === 0) {
            setSideEffectList(sideEffectListdata);
            sideEffectListdata.map((val) => {
                editDataIds.push(val._id)
            })
            dispatch(setEditOptionData(editDataIds))
            setCallOnce(!callOnce)
            if (sideEffectListdata.length < pageCount) {
                setHasMore(false)
            }
        }
    }, [isOpenQR, qrVal, sideEffectListdata, isUpdate])
    useEffect(() => () => {
        dispatch(sideEffectListAction([]))
    }, []);
    const editsideEffect = async (id) => {
        // console.log("1111119999999999999999")
        const reqObj = {
            "sideEffectId": id
        }
        dispatch(await getsideEffectListById(reqObj));
        dispatch(isfromsideEffectList(true))
        // dispatch(sideEffectListByIds({}));
        setTimeout(() => {
            navigate('/dashboard/addSideEffect?id=' + id)
        }, 1000);

    }

    const deletePopupOpen = (data) => {
        setDeletePopupData(data)
        setOpenDeletePopup(true);
    }

    const closeDeletePopup = () => {
        setOpenDeletePopup(false);
    }

    const filterData = (val, name) => {
        let check = val;
        let filterData = [];
        if (name === 'sideEffectName') {
            setSideNameFilter(!sideNameFilter);
            filterData = check ? FilterbyStringAsending(sideEffectList, name) : FilterbyStringDsending(sideEffectList, name);
        }
        if (name === 'sideEffectDescription') {
            setSideDescFilter(!sideDescFilter);
            filterData = check ? FilterbyStringAsending(sideEffectList, name) : FilterbyStringDsending(sideEffectList, name);
        }
        if (name === 'sideEffectTreatment') {
            setSideTreatFilter(!sideTreatFilter);
            filterData = check ? FilterbyStringAsending(sideEffectList, name) : FilterbyStringDsending(sideEffectList, name);
        }
        filterData.map((val) => {
            editDataIds.push(val?._id)
        })
        dispatch(setEditOptionData(editDataIds))
        setSideEffectList(filterData);
        setIsUpdate(!isUpdate);
    }

    const fetchMoreData = () => {
        setTimeout(async () => {
            const reqObj = {
                "getLatestFirst": true,
                "pageNumber": pageNumber + 1,
                "numberOfRecordsPerPage": pageCount,
                "filter": {
                    "active": true
                }
            }

            const response = await getStaffUpdatedAction(dispatch, sideEffectListURL, reqObj, false);
            if (response?.status === 200 && response?.data) {
                setPageNumber(pageNumber + 1)
                const { payload } = response?.data;
                const { medicineSideEffectList } = payload;
                if (medicineSideEffectList.length !== 0) {
                    const protoDatas = [...sideEffectList, ...medicineSideEffectList]
                    setSideEffectList(protoDatas)

                    protoDatas.map(val => {
                        editDataIds.push(val._id)
                    })
                    dispatch(setEditOptionData(editDataIds))

                    if (medicineSideEffectList.length < pageCount) {
                        setHasMore(false)
                        dispatch(sideEffectListAction(sideEffectList));
                    }
                } else {
                    setHasMore(false)
                }
                // setHasMore(true)

            } else {
                setSideEffectList(sideEffectList);
                setHasMore(false)
                // toast.error("Failed")
            }
            setIsUpdate(!isUpdate)
        }, 500);
    }

    return (
        <React.Fragment>
            <TopHeader />
            <DahboardMenu />
            <div className="mt-10 h-full" >
                {openDeletePopup && <DeleteItemPopup closeModal={closeDeletePopup} modalIsOpen={openDeletePopup} isFrom="sideeffect" data={deletePopupData} />}
                <div className="
      max-w-[540px] sm:max-w-[604px] md:max-w-[720px] lg:max-w-[972px]
      xl:max-w-full xl:pl-40  xl:ml-40 xl:px-12 2xl:max-w-[1400px] mx-auto
     flex">
                    <div className="flex w-full flex-col ">
                        <div className="flex justify-end pb-3">
                            <div id="sticky-banner" tabindex="-1" class="fixed top-16 z-50 flex">
                                <div class="flex items-end mx-auto">
                                    <button className={buttonCSS.addbtn} onClick={() => { dispatch(isfromsideEffectList(true)); navigate('/dashboard/addSideEffect') }}>
                                        <svg className="h-6 w-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                                        </svg>
                                        Add
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                            <InfiniteScroll
                                dataLength={sideEffectList.length}
                                next={fetchMoreData}
                                loader={sideEffectList.length !== 0 && <ThreeDots
                                    visible={true}
                                    height="80"
                                    width="80"
                                    color="#4fa94d"
                                    radius="9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                />}
                                hasMore={hasMore}
                                endMessage={
                                    <p style={{ textAlign: "center" }}>
                                        <b className='text-blue-500'>Yay! You have seen it all</b>
                                    </p>
                                }
                            >
                                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                        <tr>
                                            <th scope="col" className="px-6 py-4">
                                                <div className='flex items-center'>
                                                    <p> Name</p>
                                                    <a href='javascript:void(0);' onClick={() => filterData(sideNameFilter, "sideEffectName")}>
                                                        {sideNameFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                        </svg> :
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                            </svg>}
                                                    </a>
                                                </div>
                                            </th>
                                            <th scope="col" className="px-6 py-4">
                                                <div className='flex items-center'>
                                                    <p> Description</p>
                                                    <a href='javascript:void(0);' onClick={() => filterData(sideDescFilter, "sideEffectDescription")}>
                                                        {sideDescFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                        </svg> :
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                            </svg>}
                                                    </a>
                                                </div>
                                            </th>
                                            <th scope="col" className="px-6 py-4">
                                                <div className='flex items-center'>
                                                    <p> Treatment</p>
                                                    <a href='javascript:void(0);' onClick={() => filterData(sideTreatFilter, "sideEffectTreatment")}>
                                                        {sideTreatFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                        </svg> :
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                            </svg>}
                                                    </a>
                                                </div>
                                            </th>
                                            <th scope="col" className="px-6 py-4">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sideEffectList.length !== 0 ? sideEffectList.map((data, index) => {
                                            return <tr
                                                className="border-b border-secondary-200 bg-secondary-100 text-neutral-800" key={"sideTable" + index}>
                                                <td className="whitespace-nowrap px-6 py-4 font-medium text-ellipsis overflow-hidden">
                                                    {data.sideEffectName[0].value}
                                                </td>
                                                <td className="px-6 py-4 break-words flex-wrap">{data.sideEffectDescription[0].value}</td>
                                                <td className="px-6 py-4 break-words">{data.sideEffectTreatment[0].value}</td>
                                                <td className="px-6 py-4">
                                                    <a href="javascript:void(0);" onClick={() => editsideEffect(data._id)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
                                                        <span>Edit</span>
                                                    </a>
                                                    &nbsp;/&nbsp;
                                                    <a href="javascript:void(0);" onClick={() => deletePopupOpen(data)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
                                                        <span>Delete</span>
                                                    </a>
                                                </td>
                                            </tr>
                                        }) :
                                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                <th scope="row" colSpan={4} className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                    No data found
                                                </th>
                                            </tr>
                                        }

                                    </tbody>
                                </table>
                            </InfiniteScroll>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export { SideEffectList }