import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { formInputClassName, errorMessage, buttonCSS } from '../../../util/contant';
import { DahboardMenu } from '../DahboardMenu';
import { TopHeader } from '../TopHeader';
import { getAllMedicine, getAllMedicalConditionAction, createTreatmentAction, getAllTreatmentbyId, getUpdateTreatmentId, actionForPrevAndNext } from '../../../Store/Action';
import { useDispatch, useSelector } from 'react-redux';
import ErrorMessage from '../ErrorMessage';
import { getQuery, englishLangId, arabicLangId, hebrewLangId, setCookie } from '../../../util/helper';
import PrevAndNextButtons from '../../../Elements/PrevAndNextButtons';
import { useLocation } from 'react-router-dom';
import { gettreatmentByIdURL } from '../../../Api/ContantUrls';

const Treatment = () => {
    const store = useSelector(state => state);
    const { allMedicineStore, allConditionStore, availableLanguages, storeTreatmentListById, editOptiontreatmentData, storeMedicineListById } = store;
    const { treatments } = errorMessage;
    const { register, formState: { errors }, handleSubmit, reset, setValue, getValues } = useForm();
    const dispatch = useDispatch();
    const [medicineData, setMedicineData] = useState([]);
    const [medicineConditionData, setMedicineConditionData] = useState([]);
    const [isupdate, setIsUpdate] = useState(false);
    const [isCalled, setIsCalled] = useState(false);
    const [isCalledlist, setIsCalledlist] = useState(false);
    const [treatmentId, setTreatmentId] = useState(getQuery('id'));
    const [selectedEditIndex, setSelectedEditIndex] = useState("");
    const [callTriggered, setCallTriggered] = useState(false)
    const location = useLocation();
    const [pageRenderObj, setPageRenderObj] = useState({});

    const addMedicineId = getQuery('add');

    const resetObj = {}
    //const treatmentId = getQuery('id')
    useEffect(() => {

        const { state } = location;
        // console.log("editOptiontreatmentData--------------->", state)
        document.title = 'Treatments';
        setTreatmentId(getQuery('id'));
        if (state && state.id) {
            setCookie("selectedMedicine", state.id)
        }
        if (medicineData.length === 0 && !isCalledlist) {
            setIsCalledlist(true);
            getMedicineData();

        }
        if (allMedicineStore.length !== 0 && medicineData.length === 0) {
            extractMedicineData();
        }

        if (medicineConditionData.length === 0 && !isCalled) {
            getMedicineConditionData();
            setIsCalled(true);
        }
        if (allConditionStore.length !== 0 && medicineConditionData.length === 0) {
            extractMedicineConditionData();
        }
        if (treatmentId && !callTriggered) {
            getDataById(treatmentId);
        }

        if (storeTreatmentListById && Object.keys(storeTreatmentListById).length !== 0 && Object.keys(pageRenderObj).length === 0) {
            constructpageData(storeTreatmentListById);
        }

        if (treatmentId && editOptiontreatmentData.length !== 0) {
            setSelectedEditIndex(editOptiontreatmentData.indexOf(treatmentId))

        }

        if (addMedicineId && allMedicineStore.length !== 0) {
            const medinameIndex = allMedicineStore.findIndex((val) => {

                return val._id === addMedicineId;
            })
            // setValue("medicineName", { label: allMedicineStore[medinameIndex].medicineName[0].value, value: allMedicineStore[medinameIndex].medicineName[0]._id })
            setValue("medicineName", allMedicineStore[medinameIndex]._id)
        }



    }, [isupdate, allMedicineStore, allConditionStore, storeTreatmentListById, callTriggered, selectedEditIndex, editOptiontreatmentData])

    const prevAndNextHandler = async (id) => {
        const reqObj = {
            "treatmentId": id
        }
        const response = await actionForPrevAndNext(dispatch, gettreatmentByIdURL, reqObj, "treatmentDetails");
        constructpageData(response);
        //console.log("chhhhhhhhhhhhh->", await actionForPrevAndNext(dispatch, getProtocolsByIdURL, reqObj))
    }

    const constructpageData = (data) => {
        const reqObj = {};
        reqObj.medicineName = data?.medicine;
        reqObj.treatmedicineCondition = data?.medicalCondition[0]?.value;
        reqObj.ar_treatmedicineCondition = data?.medicalCondition[1]?.value;
        reqObj.hb_treatmedicineCondition = data?.medicalCondition[2]?.value;
        //reqObj.medicineThrough = data?.medicineThrough?._id;
        reqObj.dayCount = data.dayCount;
        reqObj.dpd = data?.dosePerDay;
        // reqObj.ar_dpd = data?.dosePerDay[1]?.value;
        // reqObj.hb_dpd = data?.dosePerDay[2]?.value;
        reqObj.doseUnit = data?.doseUnit[0]?.value;
        reqObj.ar_doseUnit = data?.doseUnit[1]?.value;
        reqObj.hb_doseUnit = data?.doseUnit[2]?.value;
        reqObj.doseTimes = data.doseTimes;
        reqObj.dosePerTime = data.dosePerTime;
        reqObj.treatDuration = data.treatmentDuration;
        reqObj.explanation = data?.explanation[0]?.value;
        reqObj.ar_explanation = data?.explanation[1]?.value;
        reqObj.hb_explanation = data?.explanation[2]?.value;
        reqObj.mediIns = data?.instructionsForMedicine[0]?.value;
        reqObj.ar_mediIns = data?.instructionsForMedicine[1]?.value;
        reqObj.hb_mediIns = data?.instructionsForMedicine[2]?.value;

        reqObj.routeOfAdminEN = data?.routeOfAdministration[0]?.value;
        reqObj.routeOfAdminAR = data?.routeOfAdministration[1]?.value;
        reqObj.routeOfAdminHB = data?.routeOfAdministration[2]?.value;

        reqObj.treatDuration = data?.treatmentDuration[0]?.value;
        reqObj.ar_treatDuration = data?.treatmentDuration[1]?.value;
        reqObj.hb_treatDuration = data?.treatmentDuration[2]?.value;

        reqObj.comments = data.comments[0]?.value;
        reqObj.ar_comments = data.comments[1]?.value;
        reqObj.hb_comments = data.comments[2]?.value;



        reqObj.linkMedia = data.linkMedia ? data.linkMedia : "";

        setPageRenderObj(reqObj);
        reset(reqObj);
    }


    const getDataById = async (treatmentId) => {
        const reqObj = { treatmentId: treatmentId }
        dispatch(await getAllTreatmentbyId(reqObj))
        setCallTriggered(true);
    }

    const extractMedicineData = () => {
        let medicineArray = medicineData;
        allMedicineStore.map((item, index) => {
            medicineArray.push({ value: item._id, label: item.medicineName[0].value })

        })
        setMedicineData(medicineArray)
        setIsUpdate(!isupdate)
    }

    const extractMedicineConditionData = () => {
        let medicineArray = medicineConditionData;
        allConditionStore.map((item, index) => {
            medicineArray.push({ value: item._id, label: item.name })

        })
        setMedicineConditionData(medicineArray)
        setIsUpdate(!isupdate)
    }

    const getMedicineData = async () => {
        const reqObj = {
            "getLatestFirst": true,
            "pageNumber": 1,
            "numberOfRecordsPerPage": 0,
            "filter": {
                "active": true
            }
        }
        dispatch(await getAllMedicine(reqObj));
    }

    const getMedicineConditionData = async () => {
        const reqObj = {
            "getLatestFirst": true,
            "pageNumber": 1,
            "numberOfRecordsPerPage": 0,
            "filter": {
                "active": true
            }
        }
        dispatch(await getAllMedicalConditionAction(reqObj));
    }
    const setPageValues = (val, pageData, engName, arName, hbName) => {
        if (val.language._id === englishLangId(availableLanguages.languageList)) {
            pageData[engName] = val.value;
        }
        if (val.language._id === arabicLangId(availableLanguages.languageList)) {
            pageData[arName] = val.value;
        }
        if (val.language._id === hebrewLangId(availableLanguages.languageList)) {
            pageData[hbName] = val.value;
        }
    }

    const constructData = (data) => {

        const reqObj = {};
        reqObj.medicine = data.medicineName;
        // reqObj.medicalCondition = data.treatmedicineCondition ? data.treatmedicineCondition : "No Condition";
        //   reqObj.medicineThrough = data.medicineThrough;
        reqObj.dayCount = data.dayCount;
        reqObj.dosePerDay = data.dpd;
        reqObj.mediaToLink = data.linkMedia;
        reqObj.comments = data.comments;
        reqObj.doseTimes = data.doseTimes;
        reqObj.dosePerTime = data.dosePerTime;
        //reqObj.treatmentDuration = data.treatDuration;
        // reqObj.comments = data.comments;

        reqObj.treatmentDuration = [
            { language: availableLanguages.languageList.find(o => o.name === 'English')._id, value: data.treatDuration },
            { language: availableLanguages.languageList.find(o => o.name === 'Arabic')._id, value: data.ar_treatDuration },
            { language: availableLanguages.languageList.find(o => o.name === 'Hebrew')._id, value: data.hb_treatDuration }
        ];
        reqObj.comments = [
            { language: availableLanguages.languageList.find(o => o.name === 'English')._id, value: data.comments },
            { language: availableLanguages.languageList.find(o => o.name === 'Arabic')._id, value: data.ar_comments },
            { language: availableLanguages.languageList.find(o => o.name === 'Hebrew')._id, value: data.hb_comments }
        ];

        reqObj.medicalCondition = [
            { language: availableLanguages.languageList.find(o => o.name === 'English')._id, value: data.treatmedicineCondition },
            { language: availableLanguages.languageList.find(o => o.name === 'Arabic')._id, value: data.ar_treatmedicineCondition },
            { language: availableLanguages.languageList.find(o => o.name === 'Hebrew')._id, value: data.hb_treatmedicineCondition }
        ];

        reqObj.doseUnit = [
            { language: availableLanguages.languageList.find(o => o.name === 'English')._id, value: data.doseUnit },
            { language: availableLanguages.languageList.find(o => o.name === 'Arabic')._id, value: data.ar_doseUnit },
            { language: availableLanguages.languageList.find(o => o.name === 'Hebrew')._id, value: data.hb_doseUnit }
        ];

        // reqObj.dosePerDay = [
        //     { language: availableLanguages.languageList.find(o => o.name === 'English')._id, value: data.dpd },
        //     { language: availableLanguages.languageList.find(o => o.name === 'Arabic')._id, value: data.ar_dpd },
        //     { language: availableLanguages.languageList.find(o => o.name === 'Hebrew')._id, value: data.hb_dpd }
        // ];

        reqObj.instructionsForMedicine = [
            { language: availableLanguages.languageList.find(o => o.name === 'English')._id, value: data.mediIns },
            { language: availableLanguages.languageList.find(o => o.name === 'Arabic')._id, value: data.ar_mediIns },
            { language: availableLanguages.languageList.find(o => o.name === 'Hebrew')._id, value: data.hb_mediIns }
        ];
        reqObj.explanation = [
            { language: availableLanguages.languageList.find(o => o.name === 'English')._id, value: data.explanation },
            { language: availableLanguages.languageList.find(o => o.name === 'Arabic')._id, value: data.ar_explanation },
            { language: availableLanguages.languageList.find(o => o.name === 'Hebrew')._id, value: data.hb_explanation }
        ]
        reqObj.routeOfAdministration = [
            { language: availableLanguages.languageList.find(o => o.name === 'English')._id, value: data.routeOfAdminEN },
            { language: availableLanguages.languageList.find(o => o.name === 'Arabic')._id, value: data.routeOfAdminAR },
            { language: availableLanguages.languageList.find(o => o.name === 'Hebrew')._id, value: data.routeOfAdminHB }
        ]
        return reqObj;
    }


    const SubmitEvententHandler = async (data, isreDirect) => {

        if (!treatmentId) {
            //  return;
            dispatch(createTreatmentAction(constructData(data), isreDirect));
        } else {
            const reqObj = {
                "treatmentId": treatmentId,
                "dataToUpdate": {
                    ...constructData(data)
                }
            }

            dispatch(await getUpdateTreatmentId(reqObj, isreDirect))
        }
        // if (isreDirect) {
        //     setTimeout(() => {
        //         window.location.href = '/dashboard/treatmentlist';
        //     }, 2000)
        // } else {
        //     setTimeout(() => {
        //         window.location.href = '/dashboard/treatments';
        //     }, 2000)
        // }

    }

    const onError = (error) => {
        window.scroll(0, 0)
    }

    const saveAndNew = (data) => {
        SubmitEvententHandler(data, false);
    }

    const saveAndExit = (data) => {
        SubmitEvententHandler(data, true);
    }
    const discardChanges = () => {
        // reset(resetObj);
        if (treatmentId) {
            constructpageData(storeTreatmentListById)
        } else {
            reset(resetObj);
        }
        window.location.href = '/dashboard/medicinelist';
    }

    const checkIsModified = () => {
        if (JSON.stringify(pageRenderObj) === JSON.stringify(getValues())) {
            return false;
        } else {
            return true
        }
    }

    return (
        <React.Fragment>
            <TopHeader />
            <DahboardMenu />
            <div className="mt-10">
                <div className="
      max-w-[540px] sm:max-w-[604px] md:max-w-[720px] lg:max-w-[972px]
      xl:max-w-full xl:pl-40  xl:ml-40 xl:px-12 2xl:max-w-[1400px] mx-auto
     flex">
                    <div className="flex w-full flex-col ">
                        <div className="relative overflow-hidden mb-8">
                            <ErrorMessage errors={errors} />
                            <div className='rounded-t-lg overflow-hidden border-t border-l border-r border-b border-gray-400 flex justify-center p-8'>
                                <form className="w-full bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 " name='conversion' onSubmit={handleSubmit(SubmitEvententHandler, onError)}>
                                    {treatmentId &&
                                        <PrevAndNextButtons
                                            checkIsModified={checkIsModified}
                                            handleSubmit={handleSubmit}
                                            constructData={constructData}
                                            editOptionData={editOptiontreatmentData}
                                            selectedEditIndex={selectedEditIndex}
                                            getData={prevAndNextHandler}
                                            reset={reset}
                                            resetObj={{}}
                                            from="treatment"
                                            setRedirectId={setTreatmentId}
                                            setSelectedEditIndex={setSelectedEditIndex} />}
                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                            Medicine
                                        </label>
                                        <select data-te-select-init disabled={addMedicineId} className="bg-gray-200 border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="treatmedicine" {...register("medicineName", { required: addMedicineId ? false : treatments.medicine })} >
                                            <option value="">Choose one</option>
                                            {medicineData.map((item, index) => {
                                                return <option selected={addMedicineId === item.value} value={item.value}>{item.label}</option>
                                            })}
                                        </select>
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                            Day Count
                                        </label>
                                        <input type={"number"} className={formInputClassName} autoComplete='off' placeholder='English' id="dayCount" {...register("dayCount", { required: treatments.dayCount })} />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                            Medicine Condition
                                        </label>
                                        <input className={formInputClassName} autoComplete='off' placeholder='Dose per day' id="treatmedicineCondition"  {...register("treatmedicineCondition")} />
                                        <input className={formInputClassName} autoComplete='off' placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrire("ar_treatmedicineCondition")} name="ar_treatmedicineCondition" id="ar_treatmedicineCondition" {...register("ar_treatmedicineCondition")} />

                                        <input className={formInputClassName} autoComplete='off' placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrireHebrew("hb_treatmedicineCondition")} name="hb_treatmedicineCondition" id="hb_treatmedicineCondition" {...register("hb_treatmedicineCondition")} />

                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="ar_dpd">
                                            Dose per day
                                        </label>
                                        <input type="number" className={formInputClassName} autoComplete='off' placeholder='Dose per day' id="dpd"  {...register("dpd", { required: treatments.dosePerDay })} />


                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                            Dose Unit
                                        </label>
                                        <input type="text" className={formInputClassName} autoComplete='off' placeholder='English' id="doseUnit" {...register("doseUnit", { required: treatments.doseUnit })} />

                                        <input className={formInputClassName} autoComplete='off' placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrire("ar_doseUnit")} name="ar_doseUnit" id="ar_doseUnit" {...register("ar_doseUnit", { required: treatments.ar_doseUnit })} />

                                        <input className={formInputClassName} autoComplete='off' placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrireHebrew("hb_doseUnit")} name="hb_doseUnit" id="hb_doseUnit" {...register("hb_doseUnit", { required: treatments.hb_doseUnit })} />
                                    </div>
                                    <div className="mb-4">

                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="timeOfTreatment">
                                            Route of administration
                                        </label>
                                        <div className="grid grid-cols-3 gap-3">
                                            <div>
                                                <select data-te-select-init className="bg-gray-200 border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="routeOfAdminEN" {...register("routeOfAdminEN", { required: treatments.routeOfAdminEN })}>
                                                    <option value="">Choose admin</option>
                                                    <option value="IV - Central vein - Infusion">IV - Central vein - Infusion</option>
                                                    <option value="PO - Oral pill/Syrup">PO - Oral pill/Syrup</option>
                                                    <option value="LP - Injection to liquefy the spinal cord">LP - Injection to liquefy the spinal cord</option>
                                                    <option value="SC - Subcutaneous injection">SC - Subcutaneous injection</option>
                                                    <option value="LB - Leaking from a bag">LB - Leaking from a bag</option>
                                                </select>
                                            </div>

                                            <div>
                                                <select data-te-select-init className="bg-gray-200 border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="routeOfAdminAR" {...register("routeOfAdminAR", { required: treatments.routeOfAdminAR })}>
                                                    <option value="">اختر المشرف</option>
                                                    <option value="IV - الوريد المركزي - التسريب">IV - الوريد المركزي - التسريب</option>
                                                    <option value="PO - حبوب منع الحمل عن طريق الفم / شراب">PO - حبوب منع الحمل عن طريق الفم / شراب</option>
                                                    <option value="LP - حقنة لتسييل الحبل الشوكي">LP - حقنة لتسييل الحبل الشوكي
                                                    </option>
                                                    <option value="SC - الحقن تحت الجلد">SC - الحقن تحت الجلد
                                                    </option>
                                                    <option value="SC - تسرب من الحقيبة">LB - تسرب من الحقيبة</option>
                                                </select>
                                            </div>
                                            <div>
                                                <select data-te-select-init className="bg-gray-200 border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="routeOfAdminHB"  {...register("routeOfAdminHB", { required: treatments.routeOfAdminHB })}>
                                                    <option value="">בחר מנהל</option>
                                                    <option value="IV - צנטר וריד - הזלפה">IV - צנטר וריד - הזלפה</option>
                                                    <option value="PO - כדור/סירופ לפה">PO - כדור/סירופ לפה</option>
                                                    <option value="LP - בזריקה לנזול חוט השדרה">LP - בזריקה לנזול חוט השדרה</option>
                                                    <option value="SC - הזרקה תת עורית">SC - הזרקה תת עורית</option>
                                                    <option value="SC - הדלפה משקית">LB - הדלפה משקית</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                            Dose Times
                                        </label>
                                        <select data-te-select-init className="bg-gray-200 border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="doseTimes" {...register("doseTimes", { required: treatments.doseTimes })}>
                                            <option value="">Choose one</option>
                                            <option value="Morning">Morning</option>
                                            <option value="Noon">Noon</option>
                                            <option value="Evening">Evening</option>
                                            <option value="Night">Night</option>
                                        </select>
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                            Recommended Dose per Time
                                        </label>
                                        <input className={formInputClassName} type="number" autoComplete='off' placeholder='English' id="dosePerTime" {...register("dosePerTime", { required: treatments.dosePerTime })} />
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                            Treatment Duration
                                        </label>
                                        <input className={formInputClassName} type="text" autoComplete='off' placeholder='English' id="treatDuration" {...register("treatDuration", { required: treatments.treatmentDuration })} />

                                        <input className={formInputClassName} autoComplete='off' placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrire("ar_treatDuration")} name="ar_mediThrough" id="ar_treatDuration" {...register("ar_treatDuration", { required: treatments.ar_treatmentDuration })} />

                                        <input className={formInputClassName} autoComplete='off' placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrireHebrew("hb_mediIns")} name="hb_treatDuration" id="hb_treatDuration" {...register("hb_treatDuration", { required: treatments.hb_treatmentDuration })} />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                            Medicine Instruction
                                        </label>
                                        <input className={formInputClassName} autoComplete='off' placeholder='English' id="mediIns" {...register("mediIns", { required: treatments.en_mediIns })} />

                                        <input className={formInputClassName} autoComplete='off' placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrire("ar_mediIns")} name="ar_mediIns" id="ar_mediIns" {...register("ar_mediIns", { required: treatments.ar_mediIns })} />

                                        <input className={formInputClassName} autoComplete='off' placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrireHebrew("hb_mediIns")} name="hb_mediIns" id="hb_mediIns" {...register("hb_mediIns", { required: treatments.hb_mediIns })} />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                            Explanation
                                        </label>
                                        <textarea className={formInputClassName} placeholder='English' id="explanation" {...register("explanation", { required: treatments.en_explanation })} />

                                        <textarea className={formInputClassName} placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrire("ar_explanation")} name="ar_explanation" id="ar_explanation" {...register("ar_explanation", { required: treatments.ar_explanation })} />

                                        <textarea className={formInputClassName} placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrireHebrew("hb_explanation")} name="hb_explanation" id="hb_explanation" {...register("hb_explanation", { required: treatments.hb_explanation })} />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="supportiveCare">
                                            Link To Media
                                        </label>

                                        <input className={formInputClassName} autoComplete='off' placeholder='English' id="sideTreatment" name="sideTreatment" {...register("linkMedia")} />

                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="supportiveCare">
                                            Comments
                                        </label>

                                        <input className={formInputClassName} autoComplete='off' placeholder='English' id="comments" name="comments" {...register("comments")} />
                                        <input className={formInputClassName} autoComplete='off' placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} name="ar_comments" id="ar_comments" {...register("ar_comments")} />

                                        <input className={formInputClassName} autoComplete='off' placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }} name="hb_comments" id="hb_comments" {...register("hb_comments")} />

                                    </div>
                                    <div className="flex justify-between items-center mb-4">
                                        <a href='javascript:void(0);' className={buttonCSS.blue} onClick={handleSubmit((data) => saveAndExit(data), (err) => onError(err))}>Save & Exit</a>
                                        <a href='javascript:void(0);' className={buttonCSS.green} onClick={handleSubmit((data) => saveAndNew(data), (err) => onError(err))}>Save & New</a>
                                        <a href='javascript:void(0);' className={buttonCSS.red} onClick={() => discardChanges()}>Discard Changes</a>
                                    </div>
                                    {treatmentId &&
                                        <PrevAndNextButtons
                                            checkIsModified={checkIsModified}
                                            handleSubmit={handleSubmit}
                                            constructData={constructData}
                                            editOptionData={editOptiontreatmentData}
                                            selectedEditIndex={selectedEditIndex}
                                            getData={prevAndNextHandler}
                                            reset={reset}
                                            resetObj={{}}
                                            from="treatment"
                                            setRedirectId={setTreatmentId}
                                            setSelectedEditIndex={setSelectedEditIndex} />}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export { Treatment }