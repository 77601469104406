import React from 'react';
import { useEffect } from 'react';
import Modal from 'react-modal';

const ChallengePopup = (props) => {
    const { modalIsOpen, closeModal, deleteData, isEditedChallenges, setIsEditedChallenges } = props;

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            padding: '0px',
            transform: 'translate(-50%, -50%)',
        },
    };

    useEffect(() => {
        window.addEventListener('message', (event) => {
            // identify correctness of message from iframe
            if (event.data === '<YourMessageToParentWindow>') {
                // console.log("Listner working fine---------");
                setIsEditedChallenges(true)
                closeModal(true);
            }
        });
    }, [])
    return (
        <div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Example Modal"
                style={{ content: { overflow: "hidden" } }}
                ariaHideApp={false}
            >

                <div style={{ textAlign: "end" }}>
                    <button type="button" className="text-gray-400 bg-black hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="defaultModal" id="challengeClose" onClick={() => closeModal(false)}>
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                </div>
                <iframe height={"100%"} width={"100%"}
                    style={{ height: "100%", width: "120%", margin: "auto" }} src={deleteData + "&#challengeForm"} />
            </Modal >
        </div >
    )
}

export default ChallengePopup