import React, { useEffect, useState } from 'react'
import { DahboardMenu } from '../DahboardMenu'
import { TopHeader } from '../TopHeader';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { getAllMedicine, getStaffUpdatedAction } from '../../../Store/Action';
import { setMedicineDataById, setEditOptionData, setAllmedicine } from '../../../Store/Reducer';
import MedicineTable from './MedicineTable';
import { clearCookie, getCookie } from '../../../util/helper';
import { buttonCSS } from '../../../util/contant';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getAllMedicineURL } from '../../../Api/ContantUrls';

import { ThreeDots } from 'react-loader-spinner';
import { toast } from 'react-toastify';

const MedicineListNew = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const allMedicineStore = useSelector(state => state?.allMedicineStore);
    const totalListCountStore = useSelector(state => state?.allMedicineStore);
    // const { allMedicineStore } = store;
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [popupData, setPopupData] = useState("");
    const [medicineData, setMedicineData] = useState([]);
    const [isRefresh, setIsRefresh] = useState(false);
    const [selectedData, setSelectedData] = useState([]);
    const [processData, setProcessData] = useState([]);
    const [apicalled, setapicalled] = useState(false);
    const [tratmentLength, settreatmentLength] = useState(0);
    const [isAddTreatment, setIsAddTreatment] = useState(false);
    const [hasMore, setHasMore] = useState(true)
    const [pageCount, setPageCount] = useState(20)
    const [pageNumber, setPageNumber] = useState(1)

    const [searchWord, setSearchWord] = useState("");
    const [dubsearchWord, setDupSearchWord] = useState("");
    const [resetData, setResetData] = useState([]);

    const editDataIds = [];
    const mediDupData = [];

    const FilterbyTitleOrder = (a, b) => {
        if (a.createdAt < b.createdAt) {
            return -1;
        }
        if (a.createdAt > b.createdAt) {
            return 1;
        }
        return 0;
    }

    useEffect(() => {
        // initTE({ Collapse });
        document.title = 'Medicines List';
        if (allMedicineStore.length === 0) {
            getdata();
            setapicalled(true)
        }


        if (allMedicineStore.length !== 0 && medicineData.length === 0 && !dubsearchWord) {
            setPageNumber(1);
            const filterData = allMedicineStore;
            filterData.map((val) => {
                const selectedMedicine = getCookie("selectedMedicine")
                let item = JSON.parse(JSON.stringify(val))
                if (selectedMedicine === val._id) {
                    item.showTopDiv = true;
                    item.showMedicine = true;
                    if (getCookie("fromMedicine")) {
                        item.showTreatment = false;

                    } else {
                        item.showTreatment = true;
                        clearCookie("fromMedicine")
                    }
                    clearCookie("selectedMedicine")
                } else {
                    item.showTopDiv = false;
                    item.showMedicine = false;
                    item.showTreatment = false;
                }
                mediDupData.push(item);
                editDataIds.push(val._id)
            })
            if (getCookie("searchWord")) {
                setSearchWord(getCookie("searchWord"));
                setDupSearchWord(getCookie("searchWord"));
            }
            const filterMediData = getCookie("searchWord") ? FilterSearchWord(mediDupData, getCookie("searchWord")) : mediDupData

            setMedicineData(filterMediData);
            setResetData(mediDupData)
            dispatch(setEditOptionData(editDataIds))
            if (allMedicineStore.length < pageCount) {
                setHasMore(false)
            }
            const options = {
                alwaysOpen: false,
                activeClasses: 'bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white',
                inactiveClasses: 'text-gray-500 dark:text-gray-400',
                onOpen: (item) => {
                    //  console.log('accordion item has been shown');
                    //console.log(item);
                },
                onClose: (item) => {
                    // console.log('accordion item has been hidden');
                    // console.log(item);
                },
                onToggle: (item) => {
                    // console.log('accordion item has been toggled');
                    // console.log(item);
                },
            };

        }
    }, [allMedicineStore, medicineData, isRefresh, searchWord])

    useEffect(() => () => {
        dispatch(setAllmedicine([]))
    }, []);

    const getdata = async () => {
        const reqObj = {
            "getLatestFirst": false,
            "pageNumber": pageNumber,
            "numberOfRecordsPerPage": pageCount,
            "filter": {
                "active": true
            }
        }
        dispatch(await getAllMedicine(reqObj));
        // setPageNumber(pageNumber + 1)
    }
    const selectMedicine = (id) => {
        dispatch(setMedicineDataById({}))
        navigate('/dashboard/medicine?id=' + id, { state: { id: id } })

    }
    const closePopup = () => {
        setOpenDeletePopup(false)
    }
    const deleteMedicine = (data) => {
        setPopupData(data);
        setOpenDeletePopup(true)
    }

    const gotomedicineView = () => {

    }

    const showMedicineDetails = (index) => {
        const mediData = medicineData;
        mediData.map((val, ind) => {
            if (ind !== index) {
                mediData[ind].showTopDiv = false;
                mediData[ind].showMedicine = false;
            }
        })
        if (mediData[index].showMedicine) {
            mediData[index].showMedicine = false;
            mediData[index].showTopDiv = false;
        } else {
            mediData[index].showTopDiv = true;
            mediData[index].showMedicine = true;
        }

        mediData[index].showTreatment = false;
        setMedicineData(mediData)
        setResetData(mediData)
        setIsRefresh(!isRefresh);
    }

    const constructData = (id) => {
        const reqObj = {};
        reqObj.medicine = id;
        reqObj.dayCount = "";
        reqObj.dpd = "";
        reqObj.linkMedia = "";
        // reqObj.comments = data.comments;
        reqObj.doseTimes = "";
        reqObj.dosePerTime = "";
        //reqObj.treatmentDuration = data.treatDuration;
        // reqObj.comments = data.comments;
        reqObj.treatDuration = "";
        reqObj.ar_treatDuration = "";
        reqObj.hb_treatDuration = "";

        reqObj.comments = "";
        reqObj.ar_comments = "";
        reqObj.hb_comments = "";

        reqObj.treatmedicineCondition = "";
        reqObj.ar_treatmedicineCondition = "";
        reqObj.hb_treatmedicineCondition = "";

        reqObj.doseUnit = "";
        reqObj.ar_doseUnit = "";
        reqObj.hb_doseUnit = "";


        reqObj.mediIns = "";
        reqObj.ar_mediIns = "";
        reqObj.hb_mediIns = "";

        reqObj.explanation = "";
        reqObj.ar_explanation = "";
        reqObj.hb_explanation = "";

        reqObj.routeOfAdminEN = "";
        reqObj.routeOfAdminAR = "";
        reqObj.routeOfAdminHB = "";
        reqObj.new = true;

        return reqObj;
    }

    const showTreatmentDetails = (index, show = false) => {
        const mediData = medicineData;
        setSelectedData([])
        mediData.map((val, ind) => {
            if (ind !== index) {
                mediData[ind].showTopDiv = false;
                mediData[ind].showTreatment = false;
                mediData[ind].showMedicine = false;
            }
        })

        mediData[index].showTopDiv = true;
        if (mediData[index].showTreatment) {
            mediData[index].showTreatment = false;
            //  mediData[index].showTopDiv = false;
            mediData[index].showMedicine = true;

        } else {
            mediData[index].showTreatment = true;
            mediData[index].showTopDiv = true;
            mediData[index].showMedicine = true;
        }


        setMedicineData(mediData)
        setResetData(mediData)
        setIsRefresh(!isRefresh);
    }

    const duplicateData = (val) => {
        console.log("val--------------->", val)
    }


    const addTreatment = (index) => {
        //  showTreatmentDetails(index, true)
        const mediData = medicineData;
        mediData.map((val, ind) => {
            if (ind === index) {
                mediData[ind].showTopDiv = true;
                mediData[ind].showTreatment = true;
                mediData[ind].showTopDiv = true;
            } else {
                mediData[ind].showTopDiv = false;
                mediData[ind].showTreatment = false;
                mediData[ind].showTopDiv = false;
            }
        })
        const existtreatments = processData.length === 0 ? mediData[index].treatments : processData;
        const existData = JSON.parse(JSON.stringify(selectedData));
        if (existData.length === 0) {
            settreatmentLength(mediData[index].treatments.length)
        }
        existData.push(constructData(mediData[index]._id));
        setSelectedData(existData)
        setIsAddTreatment(true);
        setMedicineData(mediData)
        setResetData(mediData)
        setIsRefresh(true);
    }

    const fetchMoreData = () => {
        setTimeout(async () => {
            if (medicineData.length !== 0) {
                const reqObj = {
                    "getLatestFirst": false,
                    "pageNumber": pageNumber + 1,
                    "numberOfRecordsPerPage": pageCount,
                    "filter": {
                        "active": true
                    }
                }

                const response = await getStaffUpdatedAction(dispatch, getAllMedicineURL, reqObj, false);
                if (response?.status === 200 && response?.data) {
                    setPageNumber(pageNumber + 1)
                    const { payload } = response?.data;
                    const { medicineList } = payload;
                    if (medicineList.length !== 0) {
                        const filData = dubsearchWord ? resetData : medicineData;
                        const mediData = [...filData, ...medicineList];
                        const filterMediData = dubsearchWord ? FilterSearchWord(mediData, dubsearchWord) : mediData;
                        setMedicineData(filterMediData)
                        setResetData([...resetData, ...medicineList])
                        filterMediData.map(val => {
                            editDataIds.push(val._id)
                        })
                        dispatch(setEditOptionData(editDataIds))

                        if (medicineList.length < pageCount) {
                            setHasMore(false);



                        }
                    } else {
                        setHasMore(false)
                    }
                    // setHasMore(true)

                } else {
                    setMedicineData(medicineData);
                    setResetData(medicineData)
                    setHasMore(false)
                    // toast.error("Failed")
                }
                setIsRefresh(!isRefresh)
            }
        }, 500);
    }

    const FilterSearchWord = (resetData, searchWord) => {
        const searchVal = searchWord.toLowerCase();
        return resetData.filter((val) => {
            return val?.medicineName[0].value.toLowerCase().includes(searchVal) || val?.medicineShortName[0].value.toLowerCase().includes(searchVal)
        })
    }

    const SearchItem = () => {
        if (searchWord.trim().length !== 0) {
            const filteredData = FilterSearchWord(resetData, searchWord)
            setMedicineData(filteredData)
            // setSearchWord("")
        } else {
            setSearchWord("")
            document.getElementById("table-search").focus();
            toast.error("Please enter search word")
        }
    }

    const ResetSearch = () => {
        setSearchWord("")
        if (getCookie("searchWord")) {
            clearCookie("searchWord");
        }
        toast.success("Data Restored")
        setDupSearchWord("")
        setMedicineData(resetData)
    }

    const EnterKeyHandler = (e) => {
        if (e.key === 'Enter') {
            console.log('do validate', e.target.value);
            SearchItem()
        }
    }

    return (
        <React.Fragment>

            <TopHeader />
            <DahboardMenu />
            <div className="mt-10 h-full" >
                <div className="
  max-w-[540px] sm:max-w-[604px] md:max-w-[720px] lg:max-w-[972px]
  xl:max-w-full xl:pl-40  xl:ml-40 xl:px-12 2xl:max-w-[screen] mx-auto
 flex">
                    <div className="flex w-full flex-col ">
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                            <div className="flex justify-end">
                                <div id="sticky-banner" tabindex="-1" class="fixed w-[79%] top-16 z-50 flex mr-5">
                                    <div class="flex  justify-between w-full">
                                        <div>
                                            <div class="grid grid-cols-2 gap-2">
                                                <div>
                                                    <div class="relative mr-3">
                                                        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                                            <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd"
                                                                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                                                    clip-rule="evenodd"></path>
                                                            </svg>
                                                        </div>
                                                        <input type="text" value={searchWord} onKeyDown={(e) => EnterKeyHandler(e)} onChange={(e) => { setSearchWord(e.target.value); setDupSearchWord(e.target.value) }} id="table-search" autoComplete='off' class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-64 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Medicine Name or Short Name" />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='flex items-start'>
                                                        <button className={buttonCSS.addbtn + ' h-8 mr-3 mt-1'} onClick={() => SearchItem()}>
                                                            Search
                                                        </button>

                                                        <button className={buttonCSS.addbtn + ' h-8 mr-3 mt-1'} onClick={() => ResetSearch()}>
                                                            Reset
                                                        </button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div>
                                            <button className={buttonCSS.addbtn} onClick={() => navigate('/dashboard/medicine')}>
                                                <svg className="h-6 w-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                                                </svg>
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="exampleWrapper" className="p-5 mb-5" >
                                <div id="sideeffect" data-accordion="collapse">
                                    <InfiniteScroll
                                        dataLength={medicineData.length}
                                        next={fetchMoreData}
                                        hasMore={hasMore}
                                        loader={(medicineData.length !== 0) && <ThreeDots
                                            visible={true}
                                            height="80"
                                            width="80"
                                            color="#4fa94d"
                                            radius="9"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                        />}
                                        endMessage={
                                            <p style={{ textAlign: "center" }}>
                                                <b className='text-blue-500'>Yay! You have seen it all</b>
                                            </p>
                                        }
                                    >
                                        {medicineData.length !== 0 ? medicineData.map((medidata, index) => {
                                            const newIndex = index + 1;
                                            return <section className="col-span-10 col-start-2 border border-dashed border-blue-700 mb-1 p-2" key={"sidesec" + index} >
                                                <h2 id={`sideeffect-heading-${newIndex}`} key={"sideDiv" + index}>
                                                    <button onClick={() => showMedicineDetails(index)} type="button" className="overflow-auto flex items-center justify-between w-full font-medium text-left text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800" data-accordion-target={`#sideeffect-body-` + newIndex} aria-expanded={newIndex === 0 ? 'true' : 'false'} aria-controls={`sideeffect-body-${newIndex}`}>
                                                        <table className="table-auto overflow-auto w-full text-sm text-left text-gray-500 dark:text-gray-400" id={"meditable" + medidata?._id}>
                                                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                                                <tr>
                                                                    <th scope="col" className="px-6 py-3">
                                                                        Short&nbsp;Name
                                                                    </th>
                                                                    <th scope="col" className="px-6 py-3">
                                                                        Medicine Name
                                                                    </th>
                                                                    <th scope="col" className="px-6 py-3">
                                                                        Medicine&nbsp;Description
                                                                    </th>
                                                                    <th scope="col" className="px-6 py-3">
                                                                        General&nbsp;Dose&nbsp;
                                                                    </th>
                                                                    <th scope="col" className="px-6 py-3">
                                                                        Maximum&nbsp;Dose&nbsp;
                                                                    </th>
                                                                    <th scope="col" className="px-6 py-3">
                                                                        Day&nbsp;
                                                                    </th>
                                                                    <th scope="col" className="px-6 py-3">
                                                                        Dose&nbsp;per&nbsp;day
                                                                    </th>
                                                                    <th scope="col" className="px-6 py-3">
                                                                        Route of Administration&nbsp;
                                                                    </th>
                                                                    {/* <th scope="col" className="px-6 py-3">

                                                                </th> */}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                                    <td scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white" style={{ width: "100px" }}>
                                                                        <p style={{ width: "inherit" }}>{medidata.medicineShortName.length !== 0 ? medidata.medicineShortName[0].value : ""}</p>
                                                                    </td>
                                                                    <td scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white" style={{ width: "100px" }}>
                                                                        <p style={{ width: "inherit" }}>{medidata.medicineName[0].value}</p>
                                                                    </td>
                                                                    <td className="px-6 py-4" style={{ width: "300px" }}>
                                                                        <p style={{ width: "inherit" }}>{medidata.medicineDescription[0].value}</p>
                                                                    </td>
                                                                    <td className="px-6 py-4" style={{ width: "300px" }}>
                                                                        <p style={{ width: "inherit" }}> {medidata.generalDose[0].value}</p>
                                                                    </td>
                                                                    <td className="px-6 py-4" style={{ width: "300px" }}>
                                                                        <p style={{ width: "inherit" }}>{medidata.maximumDose[0].value}</p>
                                                                    </td>
                                                                    <td className="px-6 py-4" style={{ width: "300px" }}>
                                                                        <p style={{ width: "inherit" }}>{medidata.day[0].value}</p>
                                                                    </td>
                                                                    <td className="px-6 py-4" style={{ width: "300px" }}>
                                                                        <p style={{ width: "inherit" }}>{medidata.dosePerDay}</p>
                                                                    </td>
                                                                    <td className="px-6 py-4" style={{ width: "300px" }}>
                                                                        <p style={{ width: "inherit" }}>{medidata.routeOfAdministration[0].value}</p>
                                                                    </td>
                                                                    {/* <td className="px-6 py-4">
                                                                    <button type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Details</button>
                                                                </td> */}
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </button>

                                                </h2>

                                                {medidata.showTopDiv && <div id={`sideeffect-body-${newIndex}`} aria-labelledby={`sideeffect-heading-${newIndex}`} key={"sidebody" + index}>
                                                    <div className="flex flex-col">

                                                        <MedicineTable dubsearchWord={dubsearchWord} medicineData={medicineData} medidata={medidata} tratmentLength={tratmentLength} selectedData={selectedData} setSelectedData={setSelectedData} processData={processData} setProcessData={setProcessData} setIsAddTreatment={setIsAddTreatment} isAddTreatment={isAddTreatment} />

                                                    </div>
                                                </div>}
                                                <div className="flex">
                                                    {medidata.showMedicine && <div className="w-1/2">
                                                        <button type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" onClick={() => showTreatmentDetails(index)}>{medidata.showTreatment ? "Hide Treatment" : "Show Treatment"}</button>
                                                        <button type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" onClick={() => addTreatment(index)}>+ Add Treatment</button>

                                                    </div>}

                                                </div>

                                            </section>
                                        }) :
                                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                <th scope="row" colSpan={4} className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                    No data found
                                                </th>
                                            </tr>
                                        }
                                    </InfiniteScroll>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}

export { MedicineListNew }