export const getLanguageURL = '/language/getLanguageList';
export const createMedicineURL = '/medicine/createMedicine';
export const getAllMedicineURL = '/medicine/getMedicineList';
export const getMedicineDetailsByIdURL = '/medicine/getMedicineDetails';
export const updateMedicineURL = '/medicine/updateMedicineDetails';

export const getAdminRolesURL = '/adminRole/getAdminRoleList';
export const createAdminURL = '/admin/createAdmin';
export const adminLoginURL = '/admin/loginAdmin';
export const getAdminDetailsURL = '/admin/getAdminDetails';
export const updateAdminDetailsURL = '/admin/updateAdminDetails';
export const forgotPasswordURL = '/admin/forgotPasswordAdmin';
export const createPasswordURL = '/admin/createAdminPassword';

export const createSideEffectURL = '/medicineSideEffect/createMedicineSideEffect';
export const sideEffectListURL = '/medicineSideEffect/getMedicineSideEffectList';
export const getSideEffectByIdURL = '/medicineSideEffect/getMedicineSideEffectDetails';
export const updateSideEffectURL = '/medicineSideEffect/updateMedicineSideEffectDetails';


export const createDiseaseURL = '/diseaseType/createDiseaseType';
export const getAllDiseaseURL = '/diseaseType/getDiseaseTypeList';
export const getDiseaseByIdURL = '/diseaseType/getDiseaseTypeDetails';
export const updateDiseaseByIdURL = '/diseaseType/updateDiseaseTypeDetails';

export const createProtocolURL = '/protocol/createProtocol';
export const getAllProtocalListURL = '/protocol/getProtocolList';
export const getProtocolsByIdURL = '/protocol/getProtocolDetails';
export const updateProtocolByIdURl = '/protocol/updateProtocolDetails';
export const getOrderProtocolListURL = '/protocol/getProtocolListOrdered';


export const getAllMedicalConditionsURL = '/medicalCondition/getMedicalConditionList';

export const createtreatmentURL = '/treatment/createTreatment';
export const getAlltreatmentListURL = '/treatment/getTreatmentList';
export const gettreatmentByIdURL = '/treatment/getTreatmentDetails';
export const updateTreatmentByIdURL = '/treatment/updateTreatmentDetails';

export const createChallengesURL = "/challenge/createChallenge";
export const getChallengesDetailsURL = "/challenge/getChallengeDetails";
export const updateChallengeDetailsURL = "/challenge/updateChallengeDetails";
export const getChallengeListURL = "/challenge/getChallengeList";
export const uploadFileURL = "https://e9pngd4lgg.execute-api.ap-south-1.amazonaws.com/Upload/fileUpload";
export const signedFileURL = '/Upload/getPresignedUrl';

export const checkFileExistURL = '/Upload/checkFileExists';

export const getSignedURL = 'https://api.jobsgaar.com/v1/common/get/signedUrl'

export const getTitleOrderURL = 'https://sepapi1.jobsgaar.com/v1/common/getTitleOrder';

export const qrCodeURL = '/qr/createQR';

export const createGiftURL = '/gift/createGift';
export const giftListURL = '/gift/getGiftList';
export const giftUpdateURL = '/gift/updateGiftDetails';
export const getGiftDetailsURL = '/gift/getGiftDetails';

export const getAgeGroupListURL = '/ageGroup/getAgeGroupList';
export const getHospitalListURL = '/hospital/getHospitalList';
export const getWardListURL = '/ward/getWardList';

export const createStaffURL = '/staff/createStaff';
export const getStaffDetailsURL = '/staff/getStaffDetails';
export const updateStaffDetailsURL = '/staff/updateStaffDetails';
export const getStaffListURL = '/staff/getStaffList';

export const createGameLevelURL = '/gameLevel/createGameLevel';
export const getGameLevelListURL = '/gameLevel/getGameLevelList';
export const updateGameLevelDetailsURL = '/gameLevel/updateGameLevelDetails';

export const createBookURL = '/book/createBook';
export const getBookDetailsURL = '/book/getBookDetails';
export const updateBookDetailsURL = '/book/updateBookDetails';
export const getBookListURL = '/book/getBookList';

export const createGameURL = '/game/createGame';
export const getGameDetailsURL = '/game/getGameDetails';
export const updateGameDetailsURL = '/game/updateGameDetails';
export const getGameListURL = '/game/getGameList';


export const createUserURL = '/user/createUser';
export const getUserDetailURL = '/user/getUserDetails';
export const updateUserDetailURL = '/user/updateUserDetails';
export const getUserListURL = '/user/getUserList';