import React, { useEffect, useState } from 'react';
import { Input } from '../../../Elements/CommonElements';
import { useForm, Controller } from 'react-hook-form';
import { DahboardMenu } from '../DahboardMenu';
import ErrorMessage from '../ErrorMessage';
import { TopHeader } from '../TopHeader';
import { buttonCSS, errorMessage, formInputClassName } from '../../../util/contant';
import { createDiseases, getAllDiseasesbyId, updateDiseaseByID, getAllDiseasesAction, actionForPrevAndNext } from '../../../Store/Action';
import { useNavigate } from 'react-router-dom';
import QRCode from "react-qr-code";
import { getQuery, englishLangId, arabicLangId, hebrewLangId } from '../../../util/helper';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'underscore';
import PrevAndNextButtons from '../../../Elements/PrevAndNextButtons';
import { getDiseaseByIdURL } from '../../../Api/ContantUrls';

export const DiseaseType = () => {
    const { diseaseType } = errorMessage;
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const { register, handleSubmit, watch, formState: { errors }, control, reset, setError, getValues } = useForm();
    const store = useSelector(state => state);
    const { storeDiseaseListById, availableLanguages, editOptionData } = store;
    const [formErrors, setFormErrors] = useState({})
    const [thisYear, selectedYear] = useState((new Date()).getFullYear());
    const minOffset = 0;
    const maxOffset = 60;
    const [diseaseId, setdiseaseId] = useState(getQuery('id'));
    const [selectedEditIndex, setSelectedEditIndex] = useState("");
    const resetObj = {
        version: "",
        year: "",
        diseaseType: "",
        ar_diseaseType: "",
        hb_diseaseType: "",
        disease: "",
        ar_disease: "",
        hb_disease: ""
    };

    const [pageRenderObj, setPageRenderObj] = useState({});

    useEffect(() => {
        document.title = 'Diseases';
        if (diseaseId && Object.keys(storeDiseaseListById).length === 0) {
            getDiseasData(diseaseId);
        }
        if (diseaseId) {
            constructData(storeDiseaseListById)
        } else {
            reset(resetObj);

        }


        if (diseaseId && editOptionData.length !== 0) {
            setSelectedEditIndex(editOptionData.indexOf(diseaseId))

        }

    }, [storeDiseaseListById, diseaseId, selectedEditIndex])

    const prevAndNextHandler = async (id) => {
        const reqObj = {
            "diseaseTypeId": id
        }
        const response = await actionForPrevAndNext(dispatch, getDiseaseByIdURL, reqObj, "diseaseTypeDetails");
        constructData(response);
    }

    const getDiseasData = async (diseaseId) => {
        const reqObj = {
            "diseaseTypeId": diseaseId
        }
        dispatch(await getAllDiseasesbyId(reqObj))

    }
    const setPageValues = (val, pageData, engName, arName, hbName) => {
        if (val.language._id === englishLangId(availableLanguages.languageList)) {
            pageData[engName] = val.value;
        }
        if (val.language._id === arabicLangId(availableLanguages.languageList)) {
            pageData[arName] = val.value;
        }
        if (val.language._id === hebrewLangId(availableLanguages.languageList)) {
            pageData[hbName] = val.value;
        }

    }
    const constructData = (data) => {
        let pageData = {};
        if (Object.keys(data).length !== 0) {

            for (let key in data) {
                let filterdata = _.values(data[key]);
                pageData.version = data.version
                pageData.year = data.year
                pageData.linkMedia = data?.mediaToLink
                if (Array.isArray(filterdata)) {
                    filterdata.map((val) => {
                        if (key === 'diseaseType') {
                            setPageValues(val, pageData, 'diseaseType', 'ar_diseaseType', 'hb_diseaseType')
                        }
                        if (key === 'disease') {
                            setPageValues(val, pageData, 'disease', 'ar_disease', 'hb_disease')
                        }
                        if (key === 'diagnosis') {
                            pageData.diagnosis = data.diagnosis[0].value
                        }

                    })
                }

            }
            setPageRenderObj(pageData)
            reset(pageData)
        }
    }

    const getYearOptions = () => {
        const options = [];

        for (let i = minOffset; i <= maxOffset; i++) {
            const year = thisYear + i;
            options.push(<option value={year}>{year}</option>);

        }
        return options;
    }
    const constructParams = (data, isNew) => {
        const reqObj = {};
        reqObj.diseaseType = [
            {
                language: englishLangId(availableLanguages.languageList),
                value: data.diseaseType
            },
            {
                language: arabicLangId(availableLanguages.languageList),
                value: data.ar_diseaseType
            },
            {
                language: hebrewLangId(availableLanguages.languageList),
                value: data.hb_diseaseType
            }
        ];
        reqObj.disease = [
            {
                language: englishLangId(availableLanguages.languageList),
                value: data.disease
            },
            {
                language: arabicLangId(availableLanguages.languageList),
                value: data.ar_disease
            },
            {
                language: hebrewLangId(availableLanguages.languageList),
                value: data.hb_disease
            }
        ];
        reqObj.diagnosis = [
            {
                language: englishLangId(availableLanguages.languageList),
                value: data.diagnosis
            }
        ];
        reqObj.mediaToLink = data.linkMedia;
        reqObj.year = data.year;
        reqObj.version = data.version;
        reqObj.diseaseQRCode =
            `Disease Type:${data.diseaseType}  
            Disease:${data.disease} 
            Diagnosis:${data.diagnosis} 
            Year:${data.year} 
            Version:${data.version}`

        reqObj.active = true;
        return reqObj;

    }
    const submitHandler = async (data, isNew = null) => {
        setFormErrors({});
        const reqObj = constructParams(data, isNew);
        if (diseaseId) {
            dispatch(await updateDiseaseByID({
                "diseaseTypeId": diseaseId,
                "dataToUpdate": reqObj,
                isNew
            }))
        } else {
            reqObj.isNew = isNew;
            dispatch(await createDiseases(reqObj, reset));
            const reqObjj = {
                "getLatestFirst": true,
                "pageNumber": 1,
                "numberOfRecordsPerPage": 0
            }
            dispatch(await getAllDiseasesAction(reqObjj));
        }
        return false;
    }

    const onError = (errors) => {
        setFormErrors(errors);
        window.scrollTo(0, 0);
    }

    const saveAndExit = (data) => {
        submitHandler(data, false)
    }
    const saveAndNew = (data) => {
        submitHandler(data, true);
    }
    const discardChanges = () => {
        if (diseaseId) {
            constructData(storeDiseaseListById);
        } else {
            reset(resetObj);
        }
        window.location.href = '/dashboard/diseaselist';
    }

    const checkIsModified = () => {
        if (JSON.stringify(pageRenderObj) === JSON.stringify(getValues())) {
            return false;
        } else {
            return true
        }
    }

    return (
        <React.Fragment>
            <TopHeader />
            <DahboardMenu />
            <div className="mt-10">
                <div className="
      max-w-[540px] sm:max-w-[604px] md:max-w-[720px] lg:max-w-[972px]
      xl:max-w-full xl:pl-40  xl:ml-40 xl:px-12 2xl:max-w-[1400px] mx-auto
     flex">
                    <div className="flex w-full flex-col ">
                        <div className="relative overflow-hidden mb-8">
                            <ErrorMessage errors={errors} setFormErrors={setFormErrors} />

                            <div className='rounded-t-lg overflow-hidden border-t border-l border-r border-b border-gray-400 flex justify-center p-8'>
                                <form className="w-full bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 " name='conversion' onSubmit={handleSubmit(submitHandler, onError)}>
                                    {diseaseId &&
                                        <PrevAndNextButtons
                                            checkIsModified={checkIsModified}
                                            handleSubmit={handleSubmit}
                                            constructData={constructParams}
                                            setRedirectId={setdiseaseId}
                                            editOptionData={editOptionData}
                                            setSelectedEditIndex={setSelectedEditIndex}
                                            selectedEditIndex={selectedEditIndex}
                                            getData={prevAndNextHandler}
                                            reset={reset}
                                            resetObj={{}}
                                            from="disease" />}
                                    <div className="mb-4">

                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                            Disease
                                        </label>
                                        <input className={formInputClassName} autoComplete='off' placeholder='English' id="disease" name="disease" {...register("disease", { required: diseaseType.en_disease })} />
                                        <input className={formInputClassName} autoComplete='off' placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrire("ar_disease")} name="ar_disease" {...register("ar_disease", { required: diseaseType.ar_disease })} />
                                        <input className={formInputClassName} autoComplete='off' placeholder='Hebrew' name="hb_disease" style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrireHebrew("hb_disease")} {...register("hb_disease", { required: diseaseType.ar_disease })} />

                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                            Disease Type
                                        </label>
                                        <input className={formInputClassName} autoComplete='off' placeholder='English' id="diseaseType" name="diseaseType" {...register("diseaseType", { required: diseaseType.en_diseaseType })} />
                                        <input className={formInputClassName} autoComplete='off' placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrire("ar_diseaseType")} name="ar_diseaseType" {...register("ar_diseaseType", { required: diseaseType.ar_diseaseType })} />
                                        <input className={formInputClassName} autoComplete='off' placeholder='Hebrew' name="hb_diseaseType" style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrireHebrew("hb_diseaseType")} {...register("hb_diseaseType", { required: diseaseType.ar_diseaseType })} />

                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="generalDose">
                                            Diagnosis
                                        </label>
                                        <select data-te-select-init className="bg-gray-200 border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="diagnosis" name="diagnosis" {...register("diagnosis", { required: diseaseType.ar_disease })}>
                                            <option value="">Choose one</option>
                                            <option value="AIEOP-BFM">AIEOP-BFM ALL (pB-ALL)</option>
                                        </select>

                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="year">
                                            Year
                                        </label>
                                        <input type={'number'} maxLength="4" className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                            onInput={(e) => (e.target.value.length > e.target.maxLength) ? e.target.value = e.target.value.slice(0, e.target.maxLength) : ""}
                                            autoComplete='off' placeholder='English' id="year" name='year' {...register("year", { required: diseaseType.year })} />

                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4">
                                            Version
                                        </label>
                                        <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                                            id="version" autoComplete='off' placeholder='Version' type="text" name="version" {...register("version", { required: diseaseType.version })} />

                                    </div>
                                    <div className="mb-4">
                                        <div className="mb-8">
                                            <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="supportiveCare">
                                                Link To Media
                                            </label>

                                            <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" autoComplete='off' placeholder='English' id=" linkMedia" name="linkMedia" {...register("linkMedia")} />

                                        </div>
                                    </div>
                                    <div className="flex justify-between items-center mb-4">
                                        <a href='javascript:void(0);' className={buttonCSS.blue} onClick={handleSubmit((data) => saveAndExit(data), (err) => onError(err))}>Save & Exit</a>
                                        <a href='javascript:void(0);' className={buttonCSS.green} onClick={handleSubmit((data) => saveAndNew(data), (err) => onError(err))}>Save & New</a>
                                        <a href='javascript:void(0);' className={buttonCSS.red} onClick={() => discardChanges()}>Discard Changes</a>
                                    </div>
                                    {diseaseId && <PrevAndNextButtons
                                        checkIsModified={() => checkIsModified()}
                                        handleSubmit={handleSubmit}
                                        constructData={constructParams}
                                        setRedirectId={setdiseaseId}
                                        editOptionData={editOptionData}
                                        setSelectedEditIndex={setSelectedEditIndex}
                                        selectedEditIndex={selectedEditIndex}
                                        getData={prevAndNextHandler}
                                        reset={reset}
                                        resetObj={{}}
                                        from="disease" />}
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
