import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { DahboardMenu } from '../DahboardMenu';
import { TopHeader } from '../TopHeader';
import { buttonCSS, errorMessage, formInputClassName } from '../../../util/contant';
import { getQuery } from '../../../util/helper';
import { openEditor, ReactProfile } from "react-profile";
import "react-profile/themes/dark.min.css";
import { useDispatch, useSelector } from 'react-redux';
import { createGiftAction, getAgeGroupListAction, uploadFileAction, getGiftDetailsAction, getGiftUpatedAction } from '../../../Store/Action';
import ErrorMessage from '../ErrorMessage';
import { setLoading } from '../../../Store/Reducer';
import { toast } from 'react-toastify';
import PrevAndNextButtons from '../../../Elements/PrevAndNextButtons';
import ConfirmPopup from '../../Popups/ConfirmPopup';

const CreateGift = () => {
    const { giftError } = errorMessage;
    const store = useSelector(state => state);
    // console.log("store------------>", store)
    const { ageGroupListStore, editOptionData } = store;
    const { register, handleSubmit, watch, formState: { errors }, getValues, reset, setError, setValue } = useForm();
    const [openUploadPopup, setOpenUploadPopup] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");
    const [giftId, setGiftId] = useState(getQuery('id'));
    const [openEdit, setOpenEdit] = useState(false);
    const [editedImage, setEditedImage] = useState("");
    const [formErrors, setFormErrors] = useState({})
    const [giftData, setGiftData] = useState({});
    const [selectedEditIndex, setSelectedEditIndex] = useState("");
    const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
    const dispatch = useDispatch();
    const [pageRenderObj, setPageRenderObj] = useState({});

    useEffect(() => {
        // console.log("GiftId-------------------->", giftId)
        // console.log("giftData-------------------->", giftData)
        // console.log("ageGroupListStore------------>", ageGroupListStore)
        // console.log("openEdit------------>", openEdit)
        // setTimeout(async () => {
        //     if (selectedImage) {
        //         await openEditor({ src: URL.createObjectURL(selectedImage) });
        //     }
        // }, 500);
        setTimeout(() => {

            // setTimeout(() => {
            //console.log("selectedImage11------------>", selectedImage)
            if (document.querySelector(".rp-editor")) {

                //  console.log("cominggggggg")
                document.querySelector(".rp-editor").style.zIndex = '555555'
                document.querySelector(".rp-editor").style.left = '11%'
                document.querySelector(".rp-editor").style.width = '89%'


            }
            // }, 1000);
        }, 500);
        //document.querySelector(".rp-navigation")
        if (ageGroupListStore.length === 0) {
            getAgeGroupList()
        }
        if (giftId) {
            if (Object.keys(giftData).length === 0) {
                getGiftDetailsById(giftId);
            } else {
                setSelectedEditIndex(editOptionData.indexOf(giftId))
                //  constructPageData(giftData)
            }

        }
    }, [selectedImage, document.querySelector(".rp-editor"), ageGroupListStore, openEdit, giftData, selectedEditIndex])

    const constructPageData = (giftData) => {
        reset({});
        setSelectedImage("")
        setEditedImage("")
        const pageData = {
            name: giftData?.name,
            code: giftData?.code,
            ageGroup: giftData?.ageGroup,
            coins: giftData?.coins,
            giftImage: giftData?.image
        }
        // console.log("response?.data222222------------>", giftData?.ageGroup)
        setValue("ageGroup", giftData?.ageGroup)
        setSelectedImage(giftData?.image)
        setEditedImage(giftData?.image)
        setTimeout(() => {
            setPageRenderObj(pageData)
            reset(pageData)
        }, 100);

    }

    const getGiftDetailsById = async (id) => {
        const reqObj = {
            giftId: id
        }
        const response = await getGiftDetailsAction(dispatch, reqObj);
        // console.log("response?.data111------------>", response)
        if (response?.status === 200 && response?.data) {
            //  console.log("response?.data------------>", response?.data)
            const { payload } = response?.data;
            setGiftData(payload?.giftDetails)
            constructPageData(payload?.giftDetails)
        } else {
            setGiftData({})
        }

    }

    const getAgeGroupList = async () => {
        const reqObj = {
            "getLatestFirst": true,
            "pageNumber": 1,
            "numberOfRecordsPerPage": 0,
            "filter": {
                "active": true
            }
        }
        dispatch(await getAgeGroupListAction(reqObj));
    }

    const uploadGiftImage = async e => {
        setSelectedImage("")
        const file = e.target.files[0]
        //  var tmppath = URL.createObjectURL(file);
        // console.log("File------------>", file)
        setSelectedImage(file)
        //await openEditor({ src: URL.createObjectURL(file) });
        setOpenUploadPopup(false)
        setOpenEdit(true)
        //await openEditor({ src: "./your-image.jpg" });
    }

    const editPreviewImage = () => {
        // console.log("checkkkkkkkkkkkkkkkkkkkk")
        setSelectedImage(selectedImage ? selectedImage : giftData?.image)

        setTimeout(() => {
            setOpenUploadPopup(false)
            setOpenEdit(true)
        }, 100)

    }

    const DoneEdit1 = async (data) => {
        // console.log("data------------>", ReactProfile)
        // console.log("1111----->", await data.getBlob())
        // console.log("2222----->", await data.getCanvas())
        // console.log("3333----->", await data.getDataURL())
        // console.log("4444----->", await data.getImageFromBlob())
        // console.log("5555----->", await data.getInferedMimetype())
        //setSelectedImage("")
        //  openEditor({ onDone: true });
        // return true;
        // setEditedImage(await data.getDataURL());
        await uploadFilesToAws(await data.getDataURL())
        setOpenEdit(false)
        // setValue("giftImage", "")
        return false;
    }
    function dataURItoBlob(dataURI) {
        try {
            // convert base64/URLEncoded data component to raw binary data held in a string
            var byteString;
            if (dataURI.split(',')[0].indexOf('base64') >= 0)
                byteString = atob(dataURI.split(',')[1]);
            else
                byteString = unescape(dataURI.split(',')[1]);

            // separate out the mime component
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

            // write the bytes of the string to a typed array
            var ia = new Uint8Array(byteString.length);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            return new Blob([ia], { type: mimeString });
        } catch (e) {
            console.log("ERRRR------->", e)
        }
    }

    const uploadFilesToAws = async (file) => {

        try {
            const originalFile = dataURItoBlob(file);
            // console.log("originalFile------->", originalFile)
            const reader = new FileReader();
            reader.readAsDataURL(originalFile);
            reader.onload = async () => {

                const base64File = reader.result.split(',')[1];
                dispatch(setLoading(true))
                const response = await uploadFileAction({
                    fileContent: base64File,
                    contentType: originalFile.type
                })

                const responseBody = response;
                // console.log("responseBody------->", responseBody)
                const { payload } = responseBody?.data;
                const { fileUploadResponse } = payload;
                const { Location } = fileUploadResponse;
                //  console.log("responseBody Location------->", Location)
                dispatch(setLoading(false))
                if (Location) {
                    setValue("giftImage", Location)
                    setEditedImage(Location)
                } else {
                    toast.error("Upload failed")
                    setEditedImage("")
                }

            }
            //  console.log("readerreader----------->", reader)
        } catch (e) {
            //  console.log("EEEEE----------->", e)
            toast.error("Upload failed")
            dispatch(setLoading(false))
            setEditedImage("")
        }

    }

    const constructReqObj = (data) => {
        const reqObj = {
            "name": data?.name,
            "code": data?.code,
            "image": editedImage,
            "coins": data?.coins,
            "ageGroup": data?.ageGroup,
            "active": true
        }
        return reqObj;
    }

    const constructParams = async (data, isNew = null) => {
        //console.log("editedImageeditedImage----------->", editedImage)
        //const res = await uploadFilesToAws(editedImage)
        //   console.log("resresresresres----------->", res)
        const reqObj = constructReqObj(data)
        if (giftId) {
            const reqObjct = {
                "giftId": giftId,
                "dataToUpdate": reqObj
            }
            const response = await getGiftUpatedAction(dispatch, reqObjct);
            if (response?.status === 200 && response?.data) {

                toast.success("Gift updated successfully")
                if (isNew) {
                    window.location.href = '/dashboard/gifts';
                } else {
                    // setSelectedEditIndex(selectedEditIndex + 1)
                    window.location.href = '/dashboard/creategift';
                }
            } else {
                toast.error('Gift updated failed')
            }
        } else {
            dispatch(await createGiftAction(reqObj, isNew))
        }

    }

    const submitHandler = (data, isNew = null) => {
        // console.log("data------------->", data)
        if (!selectedImage) {
            setError('giftImage', { type: 'custom', message: 'Please select image' });
        } else {
            setFormErrors({});
            constructParams(data, isNew)
            return false;
        }
    }

    const onError = (errors) => {
        // console.log("errors------------->", errors)
        setFormErrors(errors);
        window.scrollTo(0, 0);
    }

    const onCacelEdit = () => {
        setValue("giftImage", "")
        setOpenEdit(false)
        setSelectedImage("")
    }

    const TestComponent = () => {
        return <ReactProfile openEditor={openEdit} onCancel={() => onCacelEdit()} src={giftId ? selectedImage : window.URL.createObjectURL(selectedImage)} onDone={async data => await DoneEdit1(data)} />
    }

    const closePreview = () => {
        setValue("giftImage", "")
        setEditedImage("");
        setOpenConfirmPopup(false)

    }

    const saveAndExit = (data) => {
        submitHandler(data, true)
    }
    const saveAndNew = (data) => {
        submitHandler(data, false);
    }
    const discardChanges = () => {
        if (giftId) {
            constructPageData();
        } else {
            reset({});
        }
        window.location.href = '/dashboard/gifts';
    }

    const closeConfirm = () => {
        setOpenConfirmPopup(false)
    }

    const checkIsModified = () => {
        console.log("values are same1======================>", JSON.stringify(pageRenderObj))
        console.log("values are same2======================>", JSON.stringify(getValues()))
        if (JSON.stringify(pageRenderObj) === JSON.stringify(getValues())) {
            return false;
        } else {
            return true
        }
    }

    return (
        <React.Fragment>
            <TopHeader />
            <DahboardMenu />
            {openEdit && <div className="flex items-center justify-center w-full">
                <TestComponent />
            </div>}
            {openConfirmPopup && <ConfirmPopup modalIsOpen={openConfirmPopup} closeModal={closeConfirm} deleteData={closePreview} />}
            {openUploadPopup && <div id="default-modall" tabIndex="-1" className={`fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full justify-center items-center flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80  inset-0 ${openUploadPopup ? "" : "hidden"}`} role="dialog" aria-modal="true">
                <div className="relative w-full max-w-2xl max-h-full">

                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

                        <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                            <button type="button" onClick={() => setOpenUploadPopup(false)} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modall">
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        {selectedImage === "" && <div className="flex items-center justify-center w-full">
                            <label for="dropzone-file" className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                    <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                    </svg>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span></p>
                                </div>
                                <input id="dropzone-file" type="file" className="hidden" onChange={e => uploadGiftImage(e)} />
                            </label>
                        </div>}
                    </div>
                </div>
            </div>}

            <div className="mt-10">
                <div className="
  max-w-[540px] sm:max-w-[604px] md:max-w-[720px] lg:max-w-[972px]
  xl:max-w-full xl:pl-40  xl:ml-40 xl:px-12 2xl:max-w-[1400px] mx-auto
 flex h-screen">

                    <div className="flex w-full h-screen flex-col ">

                        <div className="relative h-screen mb-8">
                            <ErrorMessage errors={errors} setFormErrors={setFormErrors} />
                            <form className="w-full bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 " name='conversion' onSubmit={handleSubmit(submitHandler, onError)}>
                                {giftId && <PrevAndNextButtons
                                    checkIsModified={checkIsModified}
                                    handleSubmit={handleSubmit}
                                    constructData={constructReqObj}
                                    setRedirectId={setGiftId}
                                    editOptionData={editOptionData}
                                    setSelectedEditIndex={setSelectedEditIndex}
                                    selectedEditIndex={selectedEditIndex}
                                    getData={getGiftDetailsById}
                                    reset={reset}
                                    resetObj={{}}
                                    from="gift" />}
                                <div className="mb-4">
                                    <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                        Name
                                    </label>
                                    <input className={formInputClassName} autoComplete='off' placeholder='Name' id="name" name="name" {...register("name", { required: giftError.name })} />
                                </div>
                                <div className="mb-4">
                                    <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                        Code
                                    </label>
                                    <input className={formInputClassName} autoComplete='off' placeholder='Code' id="code" name="code" {...register("code", { required: giftError.code })} />
                                </div>
                                <div className="mb-4">
                                    <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                        Age Group
                                    </label>
                                    <select data-te-select-init className="bg-gray-200 border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="ageGroup" name="ageGroup" {...register("ageGroup", { required: giftError.ageGroup })}>
                                        <option value="">Choose one</option>
                                        {ageGroupListStore.slice().reverse().map((val) => {
                                            return <option value={val._id}>{val.name}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="mb-4">
                                    <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                        Coins
                                    </label>
                                    <input className={formInputClassName} type="number" autoComplete='off' placeholder='Coins' id="coins" name="coins" {...register("coins", { required: giftError.coins })} />
                                </div>
                                <div className="mb-4">
                                    <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                        Image
                                    </label>
                                    <input id="giftimage-file" accept="image/png, image/gif, image/jpeg" type="file" className="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" {...register("giftImage")} onChange={e => uploadGiftImage(e)} />
                                </div>
                                {editedImage && <div className="mb-4">
                                    <h3 className='text-2xl'>Preview</h3>
                                    <div className="relative w-1/2">

                                        <img src={editedImage} alt="Your Image" className="w-full h-1/2" />
                                        <div className="absolute top-0 left-0 p-2">
                                            <i className="fas fa-icon-1 text-white">
                                                <button type="button" className=" bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" onClick={() => setOpenConfirmPopup(true)}>
                                                    <span className="sr-only">Close menu</span>
                                                    <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                    </svg>
                                                </button>
                                            </i>
                                        </div>
                                        <div className="absolute top-0 right-0 p-2">
                                            <i className="fas fa-icon-2 text-white">
                                                <button type="button" className=" bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" onClick={() => editPreviewImage()}>
                                                    <span className="sr-only">Close menu</span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                                    </svg>
                                                </button>
                                            </i>
                                        </div>
                                    </div>

                                </div>}
                                <div className="flex justify-between items-center mt-4">
                                    <a href='javascript:void(0);' className={buttonCSS.blue} onClick={handleSubmit((data) => saveAndExit(data), (err) => onError(err))}>Save & Exit</a>
                                    <a href='javascript:void(0);' className={buttonCSS.green} onClick={handleSubmit((data) => saveAndNew(data), (err) => onError(err))}>Save & New</a>
                                    <a href='javascript:void(0);' className={buttonCSS.red} onClick={() => { discardChanges() }}>Discard Changes</a>
                                </div>
                                {giftId &&
                                    <PrevAndNextButtons
                                        checkIsModified={checkIsModified}
                                        handleSubmit={handleSubmit}
                                        constructData={constructReqObj}
                                        setRedirectId={setGiftId}
                                        editOptionData={editOptionData}
                                        setSelectedEditIndex={setSelectedEditIndex}
                                        selectedEditIndex={selectedEditIndex}
                                        getData={getGiftDetailsById}
                                        reset={reset}
                                        resetObj={{}}
                                        from="gift" />
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default CreateGift