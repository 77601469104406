import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { DahboardMenu } from '../DahboardMenu';
import ErrorMessage from '../ErrorMessage';
import { TopHeader } from '../TopHeader';
import { buttonCSS, errorMessage, formInputClassName } from '../../../util/contant';
import '../../../Store/Action'
import { useDispatch, useSelector } from 'react-redux';
import { Accordion } from 'flowbite';
import { SideEffectTable } from './SideEffectTable';
import AlertMessage from './AlertMessage';
import _ from 'underscore';
// import { Select, initTE } from "tw-elements";
import {
    Collapse,
    Ripple,
    initTE,

} from "tw-elements";
import { addMedicine, getsideEffectList, getsideEffectListById, getMedicineByID, getMedicineUpdated, getAllMedicine, actionForPrevAndNext } from '../../../Store/Action';
import { toast } from 'react-toastify';
import { fromMedicinePage, medicineCreated, sideEffectList, sideEffectListByIds, saveMedicineData, addsideEffect } from '../../../Store/Reducer';
import { useLocation, useNavigate } from 'react-router-dom';
import { TreatAndInsComponent } from './TreatAndInsComponent';
import { getQuery, englishLangId, arabicLangId, hebrewLangId } from '../../../util/helper';
import PrevAndNextButtons from '../../../Elements/PrevAndNextButtons';
import { getMedicineDetailsByIdURL } from '../../../Api/ContantUrls';
import { httpPost } from '../../../Api/httpProtocol';

export const Medicine = () => {
    const store = useSelector(state => state);
    const dispatch = useDispatch();
    const { availableLanguages, isMedicineAdded, sideEffectListdata, medicineData, storeMedicineListById, editOptionData, isSideEffectAdded } = store;
    const { medicinePage } = errorMessage;
    const { register, handleSubmit, watch, formState: { errors }, getValues, setError, reset, } = useForm();
    const [submitCalled, setSubmitCalled] = useState(false);
    const [formErrors, setFormErrors] = useState({})
    const [tiTableSL, setTiTableSL] = useState(1);
    const [tiArray, setTiArray] = useState({});
    const [tiArabicArray, setTiArabicArray] = useState({});
    const [tiHebArray, setTiHebArray] = useState({});
    const [isupdate, setIsUpdate] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState("");
    const [ismedicineAdded, setIsmedicineAdded] = useState(isMedicineAdded)
    const [selectedSideEffects, setSelectedSideEffects] = useState([]);
    const [pageState, setPageState] = useState({});
    const [selectedEditIndex, setSelectedEditIndex] = useState("");
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [treatTableSelectedIndex, setTreatTableSelectedIndex] = useState("");
    const navigation = useNavigate();
    //  const medicineId = getQuery('id')
    const [medicineId, setMedicineId] = useState(getQuery('id'));
    const [pageRenderObj, setPageRenderObj] = useState({});
    const [adminMediTypes, setAdminMediTypes] = useState({});

    const resetObj = {
        eng_mediname: "",
        ar_mediName: "",
        hb_mediName: "",

        eng_smediname: "",
        ar_smediName: "",
        hb_smediName: "",

        eng_mediDesc: "",
        ar_mediDesc: "",
        hb_mediDesc: "",

        eng_generalDose: "",
        ar_generalDose: "",
        hb_generalDose: "",

        maxDose: "",
        ar_maxDose: "",
        hb_maxDose: "",

        method: "",
        ar_method: "",
        hb_method: "",

        doseperday: "",

        tot: "",
        ar_tot: "",
        hb_tot: "",

        insForMedi: "",
        ar_insForMedi: "",
        hb_insForMedi: "",

        generalExplain: "",
        ar_generalExplain: "",
        hb_generalExplain: "",

        supportiveCare: "",
        ar_supportiveCare: "",
        hb_supportiveCare: "",
    }

    useEffect(() => {
        const getAdminTypes = async () => {
            const response = await httpPost("/adminMedicineType/getAdminMedicineType", {});
            const { data } = response;
            const { payload } = data;
            const { medicineTypes } = payload;
            if (Object.keys(adminMediTypes).length === 0) {
                setAdminMediTypes(medicineTypes);
            }
            console.log("response------------>", response)
        }
        getAdminTypes();
    }, [])


    useEffect(() => {
        initTE({ Collapse });
        setFormErrors(errors);
        dispatch(fromMedicinePage(false));
        document.title = 'Medicines';
        const accordionItems = [
            {
                id: 'accordion-collapse-heading-1',
                triggerEl: document.querySelector('#accordion-collapse-heading-1'),
                targetEl: document.querySelector('#accordion-collapse-body-1'),
                active: true
            },
            {
                id: 'accordion-collapse-heading-2',
                triggerEl: document.querySelector('#accordion-collapse-heading-2'),
                targetEl: document.querySelector('#accordion-collapse-body-2'),
                active: false
            },
            {
                id: 'accordion-collapse-heading-3',
                triggerEl: document.querySelector('#accordion-collapse-heading-3'),
                targetEl: document.querySelector('#accordion-collapse-body-3'),
                active: false
            }
        ];

        const options = {
            alwaysOpen: true,
            activeClasses: 'bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white',
            inactiveClasses: 'text-gray-500 dark:text-gray-400',
            onOpen: (item) => {
                //  console.log('accordion item has been shown');
                //console.log(item);
            },
            onClose: (item) => {
                // console.log('accordion item has been hidden');
                // console.log(item);
            },
            onToggle: (item) => {
                // console.log('accordion item has been toggled');
                // console.log(item);
            },
        };

        setTimeout(() => {
            try {
                const accordionSideEffectItems = [];

                sideEffectListdata.map((data, index) => {
                    const newIndex = index + 1;
                    //  data.sideEffectName.map((val, ind) => {
                    accordionSideEffectItems.push(
                        {
                            id: 'sideeffect-heading-' + newIndex,
                            triggerEl: document.querySelector('#sideeffect-heading-' + newIndex),
                            targetEl: document.querySelector('#sideeffect-body-' + newIndex),
                            active: false
                        }
                    )
                    // })
                });
                new Accordion(accordionSideEffectItems, options);
                //sideAccordin.toggle('sideeffect-heading-1')
            } catch (e) {
                console.log('EEE--------------->', e)
            }
        }, 10);

        const accordion = new Accordion(accordionItems, options);


        // sideEffectListdata.map((data, index) => {
        //     //    data.sideEffectName.map((val, ind) => {
        //     accordionsideEffect.toggle('sideeffect-heading-' + index);
        //     //    })
        // });

        // open accordion item based on id
        accordion.toggle('accordion-collapse-heading-2');
        // accordionsideEffect.toggle('sideeffect-heading-1');

        // close accordion item based on id
        accordion.close('accordion-collapse-heading-2');
        //   accordionsideEffect.close('sideeffect-heading-2');

        // toggle visibility of item based on id
        accordion.close('accordion-collapse-heading-3');
        // accordionsideEffect.close('sideeffect-heading-3');
        if (isMedicineAdded && medicineId) {
            setTiArray({});
            setTiArabicArray({});
            setTiHebArray({});
            setSelectedSideEffects([]);
            reset();
            toast.success("Medicine added successfully");
            dispatch(medicineCreated({ update: false }))
        }

        if (sideEffectListdata.length === 0 || (isSideEffectAdded || isMedicineAdded)) {
            const reqObj = {
                "getLatestFirst": true,
                "pageNumber": 1,
                "numberOfRecordsPerPage": 0
            }
            dispatch(getsideEffectList(reqObj));
            dispatch(medicineCreated({ update: false }))
            dispatch(addsideEffect(false));
            //dispatch(fromMedicinePage(false))
        }

        if (medicineId && editOptionData.length !== 0) {
            setSelectedEditIndex(editOptionData.indexOf(medicineId))

        }

        if (medicineId && storeMedicineListById && Object.keys(storeMedicineListById).length === 0) {
            //  dispatch(setMedicineDataById({}))
            getMedicineData(medicineId);
        } else {
            if (medicineData) {
                reset(medicineData)
            } else {
                reset(resetObj);
            }
            setTiArray({});
            setTiArabicArray({});
            setTiHebArray({});
            setSelectedSideEffects([]);

        }
        if (medicineId && storeMedicineListById && Object.keys(storeMedicineListById).length !== 0) {
            construtPageData(storeMedicineListById);
        }

    }, [sideEffectListdata, isMedicineAdded, storeMedicineListById, medicineId, selectedEditIndex, editOptionData, medicineId])


    useEffect(() => {
        if (medicineId) {
            //  dispatch(setMedicineDataById({}))
            getMedicineData(medicineId);
        }
    }, [])

    const getMedicineData = async (medicineId) => {
        const reqObj = {
            medicineId
        }
        dispatch(await getMedicineByID(reqObj))
    }

    const setPageValues = (val, pageData, engName, arName, hbName) => {
        if (val.language._id === englishLangId(availableLanguages.languageList)) {
            pageData[engName] = val.value;
        }
        if (val.language._id === arabicLangId(availableLanguages.languageList)) {
            pageData[arName] = val.value;
        }
        if (val.language._id === hebrewLangId(availableLanguages.languageList)) {
            pageData[hbName] = val.value;
        }
    }

    const extractTI = (data, beforeArray, duringArray, afterArray) => {

        //  data.map((val, ind) => {
        let resultEngArry = {};
        let resultArArry = {}
        let resultHbArry = {}
        for (let key in data) {
            // if (key === "beforeMedicine") {
            let beforeengVal = data["beforeMedicine"] && data["beforeMedicine"][0] && data["beforeMedicine"][0].value ? data["beforeMedicine"][0].value : [];
            let duringenVal = data["duringMedicine"] && data["duringMedicine"][0] && data["duringMedicine"][0].value ? data["duringMedicine"][0].value : [];
            let afterengVal = data["afterMedicine"] && data["afterMedicine"][0] && data["afterMedicine"][0].value ? data["afterMedicine"][0].value : [];


            for (let i = 0; i < beforeengVal.length; i++) {
                resultEngArry[i] = [beforeengVal[i], duringenVal[i], afterengVal[i]];
            }

            let before1engVal = data["beforeMedicine"] && data["beforeMedicine"][1] && data["beforeMedicine"][1].value ? data["beforeMedicine"][1].value : [];
            let during1enVal = data["duringMedicine"] && data["duringMedicine"][1] && data["duringMedicine"][1].value ? data["duringMedicine"][1].value : [];
            let after1engVal = data["duringMedicine"] && data["afterMedicine"][1] && data["afterMedicine"][1].value ? data["afterMedicine"][1].value : [];


            for (let i = 0; i < before1engVal.length; i++) {
                resultArArry[i] = [before1engVal[i], during1enVal[i], after1engVal[i]];
            }

            let before2engVal = data["beforeMedicine"] && data["beforeMedicine"][2] && data["beforeMedicine"][2].value ? data["beforeMedicine"][2].value : [];
            let during2enVal = data["duringMedicine"] && data["duringMedicine"][2] && data["duringMedicine"][2].value ? data["duringMedicine"][2].value : [];
            let after2engVal = data["afterMedicine"] && data["afterMedicine"][2] && data["afterMedicine"][2].value ? data["afterMedicine"][2].value : [];


            for (let i = 0; i < before2engVal.length; i++) {
                resultHbArry[i] = [before2engVal[i], during2enVal[i], after2engVal[i]];
            }
            // }


            //})
        }
        setTiArray(resultEngArry);
        setTiArabicArray(resultArArry);
        setTiHebArray(resultHbArry);
    }
    const construtPageData = (data) => {
        //let test = _.pluck(data, "medicineName")
        reset();
        let mediIndex = 1;

        let pageData = {};
        for (let key in data) {
            let filterdata = _.values(data[key]);

            if (key === 'treatmentAndInstructions') {

                extractTI(data[key])
                // _.find(data[key], function (item) {
                //console.log("item--------------->", item)
                // let beforeArray = {}
                // let duringArray = {}
                // let afterArray = {}
                // extractTI(data[key], beforeArray, duringArray, afterArray);
                // extractTI(data[key], beforeArray, duringArray, afterArray)
                // extractTI(data[key], beforeArray, duringArray, afterArray)
                // console.log("item--------------->", beforeArray)
                //setTiArray(beforeArray)
                //setTiArabicArray(duringArray);
                //setTiHebArray(afterArray)
                //return test;
                //return item.language === englishLangId(availableLanguages.language);
                //  });


            }
            pageData.doseperday = data.dosePerDay;
            pageData.linkMedia = data.mediaToLink

            //   pageData.sideEffects = data.sideEffects;
            if (Array.isArray(filterdata)) {
                filterdata.map((val) => {
                    if (key === 'medicineShortName') {
                        setPageValues(val, pageData, 'eng_smediname', 'ar_smediName', 'hb_smediName');
                    }
                    if (key === 'medicineName') {
                        setPageValues(val, pageData, 'eng_mediname', 'ar_mediName', 'hb_mediName');
                    }
                    if (key === 'medicineDescription') {
                        setPageValues(val, pageData, 'eng_mediDesc', 'ar_mediDesc', 'hb_mediDesc');
                    }
                    if (key === 'generalDose') {
                        setPageValues(val, pageData, 'eng_generalDose', 'ar_generalDose', 'hb_generalDose');
                    }
                    if (key === 'maximumDose') {
                        setPageValues(val, pageData, 'maxDose', 'ar_maxDose', 'hb_maxDose');
                    }
                    if (key === 'day') {
                        setPageValues(val, pageData, 'method', 'ar_method', 'hb_method');
                    }
                    if (key === 'timeOfTreatment') {
                        setPageValues(val, pageData, 'tot', 'ar_tot', 'hb_tot');
                    }
                    if (key === 'instructionsForMedicine') {
                        setPageValues(val, pageData, 'insForMedi', 'ar_insForMedi', 'hb_insForMedi');
                    }
                    if (key === 'generalExplanation') {
                        setPageValues(val, pageData, 'generalExplain', 'ar_generalExplain', 'hb_generalExplain');
                    }
                    if (key === 'supportiveCareOrRequiredFollowUp') {
                        setPageValues(val, pageData, 'supportiveCare', 'ar_supportiveCare', 'hb_supportiveCare');
                    }
                    if (key === 'routeOfAdministration') {
                        setPageValues(val, pageData, 'routeOfAdminEN', 'routeOfAdminAR', 'routeOfAdminHB');
                    }
                    if (key === 'sideEffects') {
                        let sideIds = [];
                        filterdata.map((val) => {
                            sideIds.push(val._id)
                        })

                        setSelectedSideEffects(sideIds);
                        //setPageValues(val, pageData, 'supportiveCare', 'ar_supportiveCare', 'hb_supportiveCare');
                        pageData.sideEffects = sideIds;
                    }

                })
            }

        }
        setPageRenderObj(pageData)
        setPageState(pageData);
        reset(pageData)
    }

    const constructReqObj = (obj) => {
        const reqObj = {};
        let arabicObj = availableLanguages.languageList.find(o => o.name === 'Arabic');
        let hebrewObj = availableLanguages.languageList.find(o => o.name === 'Hebrew');
        let englishObj = availableLanguages.languageList.find(o => o.name === 'English');
        let langObj = {};
        let dietObj = {};
        langObj['Arabic'] = arabicObj?._id;
        langObj['Hebrew'] = hebrewObj?._id;
        langObj['English'] = englishObj?._id;

        let Before = []
        let During = []
        let After = []
        if (tiArray !== {}) {
            Before.push({ language: englishObj?._id, value: constructTreatmentInstruct(tiArray, englishObj?._id).beforeMedi })
            Before.push({ language: arabicObj?._id, value: constructTreatmentInstruct(tiArabicArray, arabicObj?._id).beforeMedi })
            Before.push({ language: hebrewObj?._id, value: constructTreatmentInstruct(tiHebArray, hebrewObj?._id).beforeMedi })
            dietObj.tiEnglish = Before
        }
        if (tiArabicArray !== {}) {
            During.push({ language: englishObj?._id, value: constructTreatmentInstruct(tiArray, englishObj?._id).duringMedi })
            During.push({ language: arabicObj?._id, value: constructTreatmentInstruct(tiArabicArray, arabicObj?._id).duringMedi })
            During.push({ language: hebrewObj?._id, value: constructTreatmentInstruct(tiHebArray, hebrewObj?._id).duringMedi })
            dietObj.tiArabic = During;
        }
        if (tiHebArray !== {}) {
            After.push({ language: englishObj?._id, value: constructTreatmentInstruct(tiArray, englishObj?._id).afterMedi })
            After.push({ language: arabicObj?._id, value: constructTreatmentInstruct(tiArabicArray, arabicObj?._id).afterMedi })
            After.push({ language: hebrewObj?._id, value: constructTreatmentInstruct(tiHebArray, hebrewObj?._id).afterMedi })
            dietObj.tiHebrew = After;
        }

        reqObj.medicineName = [
            { language: langObj.English, value: obj.eng_mediname },
            { language: langObj.Arabic, value: obj.ar_mediName },
            { language: langObj.Hebrew, value: obj.hb_mediName }
        ];
        reqObj.medicineShortName = [
            { language: langObj.English, value: obj.eng_smediname },
            { language: langObj.Arabic, value: obj.ar_smediName },
            { language: langObj.Hebrew, value: obj.hb_smediName }
        ];
        reqObj.medicineDescription = [
            { language: langObj.English, value: obj.eng_mediDesc },
            { language: langObj.Arabic, value: obj.ar_mediDesc },
            { language: langObj.Hebrew, value: obj.hb_mediDesc }
        ];
        reqObj.generalDose = [
            { language: langObj.English, value: obj.eng_mediDesc },
            { language: langObj.Arabic, value: obj.ar_mediDesc },
            { language: langObj.Hebrew, value: obj.hb_mediDesc }
        ];
        reqObj.maximumDose = [
            { language: langObj.English, value: obj.maxDose },
            { language: langObj.Arabic, value: obj.ar_maxDose },
            { language: langObj.Hebrew, value: obj.hb_maxDose }
        ];
        reqObj.day = [
            { language: langObj.English, value: obj.method },
            { language: langObj.Arabic, value: obj.ar_method },
            { language: langObj.Hebrew, value: obj.hb_method }
        ];
        reqObj.dosePerDay = parseInt(obj.doseperday)
        reqObj.timeOfTreatment = [
            { language: langObj.English, value: obj.tot },
            { language: langObj.Arabic, value: obj.ar_tot },
            { language: langObj.Hebrew, value: obj.hb_tot }
        ];
        reqObj.instructionsForMedicine = [
            { language: langObj.English, value: obj.insForMedi },
            { language: langObj.Arabic, value: obj.ar_insForMedi },
            { language: langObj.Hebrew, value: obj.hb_insForMedi }
        ];
        reqObj.treatmentAndInstructions = {
            beforeMedicine: dietObj.tiEnglish,
            duringMedicine: dietObj.tiArabic,
            afterMedicine: dietObj.tiHebrew
        };
        reqObj.generalExplanation = [
            { language: langObj.English, value: obj.generalExplain },
            { language: langObj.Arabic, value: obj.ar_generalExplain },
            { language: langObj.Hebrew, value: obj.hb_generalExplain }
        ];
        reqObj.supportiveCareOrRequiredFollowUp = [
            { language: langObj.English, value: obj.supportiveCare },
            { language: langObj.Arabic, value: obj.ar_supportiveCare },
            { language: langObj.Hebrew, value: obj.hb_supportiveCare }
        ];
        reqObj.sideEffects = selectedSideEffects;
        reqObj.active = true;
        reqObj.mediaToLink = obj.linkMedia;
        reqObj.routeOfAdministration = [
            { language: langObj.English, value: obj.routeOfAdminEN },
            { language: langObj.Arabic, value: obj.routeOfAdminAR },
            { language: langObj.Hebrew, value: obj.routeOfAdminHB }
        ];
        return reqObj;
    }


    const constructParams = async (obj, isNew = null) => {
        const reqObj = constructReqObj(obj);

        //return;
        if (medicineId) {
            const reqObjval = {
                medicineId,
                dataToUpdate: {
                    ...reqObj
                }
            }
            dispatch(await getMedicineUpdated(reqObjval, isNew))
        } else {
            dispatch(addMedicine(reqObj, reset));
        }

    }

    const constructTreatmentInstruct = (obj, id) => {
        let tiEngObj = [];
        let beforeMedi = [];
        let duringMedi = [];
        let afterMedi = [];

        if (obj !== {}) {
            Object.keys(obj).map(function (key, index) {
                beforeMedi.push(obj[key][0])
                duringMedi.push(obj[key][1])
                afterMedi.push(obj[key][2])
            });
        }
        return { beforeMedi, duringMedi, afterMedi };
    }
    const submitHandler = (data, isNew = null) => {
        try {
            setFormErrors({});
            constructParams(data, isNew);
            return false;
            //   }
        } catch (err) {
            toast.error("Please try again");
        }
    }

    const onError = (errors) => {
        setFormErrors(errors);
        setSubmitCalled(true);
        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 0);
    }

    const deleteTreatment = (index, lang) => {
        setTreatTableSelectedIndex(`${index} - ${lang}`)
        if (lang === 'english' && tiArray.length !== 0 && tiArray[index]) {
            setAlertMessage(tiArray[index].toString())
        } if (lang === 'arabic' && tiArabicArray.length !== 0 && tiArabicArray[index]) {
            setAlertMessage(tiArabicArray[index].toString())
        } if (lang === 'hebrew' && tiHebArray.length !== 0 && tiHebArray[index]) {
            setAlertMessage(tiHebArray[index].toString())
        }
        setOpenAlert(!openAlert);

    }

    const deleteRow = () => {


        const getValues = treatTableSelectedIndex.split('-');
        const index = parseInt(getValues[0].trim());
        const lang = getValues[1].trim();
        if (lang === 'english') {
            delete tiArray[index]
            setTiArray(tiArray);
        } else if (lang === 'arabic') {
            delete tiArabicArray[index]
            setTiArabicArray(tiArabicArray);
        } else {
            delete tiHebArray[index]
            setTiHebArray(tiHebArray);
        }
        setOpenAlert(!openAlert);
        document.querySelector("#BM" + lang).value = "";
        document.querySelector("#DM" + lang).value = "";
        document.querySelector("#AM" + lang).value = "";
        setIsUpdate(!isupdate);
    }

    const editRow = (index, lang) => {

        setTimeout(() => {
            document.querySelector("#BM" + lang).value = lang === 'english' ? tiArray[index][0] : lang === 'arabic' ? tiArabicArray[index][0] : tiHebArray[index][0];
            document.querySelector("#DM" + lang).value = lang === 'english' ? tiArray[index][1] : lang === 'arabic' ? tiArabicArray[index][1] : tiHebArray[index][1];
            document.querySelector("#AM" + lang).value = lang === 'english' ? tiArray[index][2] : lang === 'arabic' ? tiArabicArray[index][2] : tiHebArray[index][2];
        }, 100);

        setSelectedIndex(index);
        document.querySelector("#BM" + lang).focus();
        document.querySelector("#BM" + lang).focus();
        document.querySelector("#BM" + lang).focus();
        setIsUpdate(!isupdate);
    }

    const saveTI = (e, lang) => {
        let inputHeader = document.querySelector("#addTreatMent" + lang);
        let insObj = [];
        if (inputHeader) {
            for (let i = 0; i < inputHeader.cells.length; i++) {
                if (inputHeader.cells[i].children[0]) {
                    //insObj[inputHeader.cells[i].children[0].name] = inputHeader.cells[i].children[0].value;
                    if (selectedIndex === "" && inputHeader.cells[i].children[0].value) {
                        insObj.push(inputHeader.cells[i].children[0].value ? inputHeader.cells[i].children[0].value : "");
                    } else {
                        insObj.push(inputHeader.cells[i].children[0].value ? inputHeader.cells[i].children[0].value : "");
                        //return false;
                    }
                }
            }
            let check = "";
            insObj.map(val => {
                check += val
            })
            if (check) {
                let test = lang === 'english' ? tiArray : lang === 'arabic' ? tiArabicArray : tiHebArray;
                if (selectedIndex === "") {
                    if (insObj.length !== 0) {
                        let insertIndex = lang === 'english' ? Object.keys(tiArray).length + 1 : lang === 'arabic' ? Object.keys(tiArabicArray).length + 1 : Object.keys(tiHebArray).length + 1;
                        test[insertIndex] = insObj;
                    }

                    if (lang === 'english') {
                        setTiArray(test);
                    }
                    if (lang === 'arabic') {
                        setTiArabicArray(test)
                    }
                    if (lang === 'hebrew') {
                        setTiHebArray(test)
                    }

                    setTiTableSL(tiTableSL + 1);
                    setIsUpdate(!isupdate)
                } else {

                    if (lang === 'english') {
                        tiArray[selectedIndex] = insObj;
                        setTiArray(test);
                    }
                    if (lang === 'arabic') {
                        tiArabicArray[selectedIndex] = insObj;
                        setTiArabicArray(test)
                    }
                    if (lang === 'hebrew') {
                        tiHebArray[selectedIndex] = insObj;
                        setTiHebArray(test)
                    }
                    // setTiArray(tiArray);
                    setSelectedIndex("");
                    setIsUpdate(!isupdate)
                }
                document.querySelector("#BM" + lang).value = "";
                document.querySelector("#DM" + lang).value = "";
                document.querySelector("#AM" + lang).value = "";
            } else {
                toast.error("Please enter values")
            }

        }

        // setTiTableSL(tiTableSL + 1);
    }


    const selectSideEffect = (id, index) => {
        const arryVal = selectedSideEffects;
        var checkDub = arryVal.includes(id);
        const found = arryVal.some(r => r.includes(id))
        if (checkDub) {
            var index = arryVal.indexOf(id);
            if (index !== -1) {
                arryVal.splice(index, 1);
            }
        } else {
            arryVal.push(id);
        }
        setSelectedSideEffects(arryVal)
        setIsUpdate(!isupdate);
    }

    const editsideEffect = async (id) => {
        const reqObj = {
            "sideEffectId": id
        }
        dispatch(await getsideEffectListById(reqObj));
        dispatch(fromMedicinePage(true))
        // dispatch(sideEffectListByIds({}));
        setTimeout(() => {
            navigation('/dashboard/addSideEffect?id=' + id)
        }, 1000);

    }

    const gotoSideEffectpage = () => {
        dispatch(saveMedicineData(getValues()))
        dispatch(sideEffectListByIds({}))
        dispatch(fromMedicinePage(true))
        navigation('/dashboard/addSideEffect')
    }

    const next = () => {
        if (selectedEditIndex < editOptionData.length - 1) {
            reset(resetObj)
            getMedicineData(editOptionData[selectedEditIndex + 1]);
            setTimeout(() => {
                navigation('/dashboard/medicine?id=' + editOptionData[selectedEditIndex + 1])
            }, 1000)

        } else {
            getMedicineData(editOptionData[editOptionData.length - 1]);
            navigation('/dashboard/medicine?id=' + editOptionData[editOptionData.length - 1])
        }
        window.scroll(0, 0)
    }

    const prev = () => {
        if (selectedEditIndex !== 0) {
            getMedicineData(editOptionData[selectedEditIndex - 1]);
            setTimeout(() => {
                navigation('/dashboard/medicine?id=' + editOptionData[selectedEditIndex - 1])
            }, 1000)
        } else {
            getMedicineData(editOptionData[0]);
            navigation('/dashboard/medicine?id=' + editOptionData[0])
        }
        window.scroll(0, 0)
    }

    const saveAndNew = (data) => {
        submitHandler(data, true);
    }

    const saveAndExit = (data) => {
        submitHandler(data);
    }
    const discardChanges = () => {
        // reset(resetObj);
        if (medicineId) {
            construtPageData(storeMedicineListById)
        } else {
            reset(resetObj);
            // navigation('/dashboard/medicine')
        }
        window.location.href = '/dashboard/medicinelist'
    }

    const prevAndNextHandler = async (id) => {
        const reqObj = {
            "medicineId": id
        }
        const response = await actionForPrevAndNext(dispatch, getMedicineDetailsByIdURL, reqObj, "medicineDetails");
        construtPageData(response);
    }

    const checkIsModified = () => {
        if (JSON.stringify(pageRenderObj) === JSON.stringify(getValues())) {
            return false;
        } else {
            return true
        }
    }

    return (
        <React.Fragment>

            <TopHeader />
            <DahboardMenu />
            <AlertMessage modalIsOpen={openAlert} closeModal={deleteTreatment} alertMessage={alertMessage} deleteRow={deleteRow} />
            <div className="mt-10">
                <div className="
      max-w-[540px] sm:max-w-[604px] md:max-w-[720px] lg:max-w-[972px]
      xl:max-w-full xl:pl-40  xl:ml-40 xl:px-12 2xl:max-w-[1400px] mx-auto
     flex">
                    <div className="flex w-full flex-col ">
                        <div className="relative overflow-hidden mb-8">
                            <ErrorMessage errors={errors} setFormErrors={setFormErrors} />

                            <div className='rounded-t-lg overflow-hidden border-t border-l border-r border-b border-gray-400 flex justify-center p-8'>
                                <form className="w-full h-3/4 bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 " name='conversion' onSubmit={handleSubmit(submitHandler, onError)}>
                                    {medicineId &&
                                        <PrevAndNextButtons
                                            checkIsModified={checkIsModified}
                                            handleSubmit={handleSubmit}
                                            constructData={constructReqObj}
                                            setSelectedEditIndex={setSelectedEditIndex}
                                            setRedirectId={setMedicineId}
                                            editOptionData={editOptionData}
                                            selectedEditIndex={selectedEditIndex}
                                            getData={prevAndNextHandler}
                                            reset={reset}
                                            resetObj={resetObj}
                                            from="medicine" />}
                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                            Medicine Name
                                        </label>
                                        <input className={formInputClassName} autoComplete='off' placeholder='English' id="medicineName" name="eng_mediname" {...register("eng_mediname", { required: medicinePage.en_mediName })} />
                                        <input className={formInputClassName} autoComplete='off' placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrire("ar_mediName")} name="ar_mediName" id="medicineName" {...register("ar_mediName", { required: medicinePage.ar_mediName })} />
                                        <input className={formInputClassName} autoComplete='off' placeholder='Hebrew' name="hb_mediName" style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrireHebrew("hb_mediName")} id="medicineName"  {...register("hb_mediName", { required: medicinePage.hb_mediName })} />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                            Medicine Short Name
                                        </label>
                                        <input className={formInputClassName} autoComplete='off' placeholder='English' id="medicinesName" name="eng_smediname" {...register("eng_smediname", { required: medicinePage.en_smediName })} />
                                        <input className={formInputClassName} autoComplete='off' placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrire("ar_mediName")} name="ar_smediName" id="medicineArsName" {...register("ar_smediName", { required: medicinePage.ar_smediName })} />
                                        <input className={formInputClassName} autoComplete='off' placeholder='Hebrew' name="hb_mediName" style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrireHebrew("hb_smediName")} id="medicineHbSName"  {...register("hb_smediName", { required: medicinePage.hb_smediName })} />
                                    </div>
                                    <div className="mb-4">

                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineDesc">
                                            Medicine Description
                                        </label>

                                        <textarea className={formInputClassName} autoComplete='off' placeholder='English' id="medicineDesc" name="eng_mediDesc" {...register("eng_mediDesc", { required: medicinePage.en_mediDesc })} />
                                        <textarea className={formInputClassName} autoComplete='off' placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} name="ar_mediDesc" onKeyUp={() => window.transcrire("ar_mediDesc")} {...register("ar_mediDesc", { required: medicinePage.ar_mediDesc })} />
                                        <textarea className={formInputClassName} autoComplete='off' placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }} name="hb_mediDesc" onKeyUp={() => window.transcrireHebrew("hb_mediDesc")} {...register("hb_mediDesc", { required: medicinePage.hb_mediDesc })} />
                                    </div>
                                    <div className="mb-4">

                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="generalDose">
                                            General Dose
                                        </label>

                                        <textarea className={formInputClassName} autoComplete='off' placeholder='English' id="generalDose" name="eng_generalDose" {...register("eng_generalDose", { required: medicinePage.en_generalDose })} />

                                        <textarea className={formInputClassName} autoComplete='off' placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrire("ar_generalDose")} name="ar_generalDose" id="ar_generalDose" {...register("ar_generalDose", { required: medicinePage.ar_generalDose })} />

                                        <textarea className={formInputClassName} autoComplete='off' placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrireHebrew("hb_generalDose")} name="hb_generalDose" id="hb_generalDose" {...register("hb_generalDose", { required: medicinePage.hb_generalDose })} />

                                    </div>
                                    <div className="mb-4">

                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="maximumDose">
                                            Maximum Dose
                                        </label>

                                        <textarea className={formInputClassName} autoComplete='off' placeholder='English' id="maxDose" name="maxDose" {...register("maxDose", { required: medicinePage.en_maxiDose })} />

                                        <textarea className={formInputClassName} autoComplete='off' placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrire("ar_maxDose")} name="ar_maxDose" id="ar_maxDose" {...register("ar_maxDose", { required: medicinePage.ar_maxiDose })} />

                                        <textarea className={formInputClassName} autoComplete='off' placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrireHebrew("hb_maxDose")} name="hb_maxDose" id="hb_maxDose" {...register("hb_maxDose", { required: medicinePage.hb_maxiDose })} />

                                    </div>
                                    <div className="mb-4">

                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="method">
                                            Day
                                        </label>

                                        <input className={formInputClassName} autoComplete='off' placeholder='English' id="method" name="method" {...register("method", { required: medicinePage.en_method })} />

                                        <input className={formInputClassName} autoComplete='off' placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrire("ar_method")} name="ar_method" id="ar_method" {...register("ar_method", { required: medicinePage.ar_method })} />

                                        <input className={formInputClassName} autoComplete='off' placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrireHebrew("hb_method")} name="hb_method" id="hb_method" {...register("hb_method", { required: medicinePage.hb_method })} />

                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="generalDose">
                                            Dose Per Day
                                        </label>
                                        <select data-te-select-init className="bg-gray-200 border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="doseperday" name="doseperday" {...register("doseperday", { required: medicinePage.doseperday })}>
                                            <option value="">Choose dose per day</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="2">3</option>
                                            <option value="2">4</option>
                                            <option value="2">5</option>
                                            <option value="2">6</option>
                                        </select>

                                    </div>
                                    <div className="mb-4">

                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="timeOfTreatment">
                                            Route of administration
                                        </label>
                                        <div className="grid grid-cols-3 gap-3">
                                            <div>
                                                <select data-te-select-init className="bg-gray-200 border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="routeOfAdminEN" name="routeOfAdminEN" {...register("routeOfAdminEN", { required: medicinePage.routeOfAdminEN })}>
                                                    <option value="">Choose admin</option>
                                                    {console.log("adminMediTypes---->", adminMediTypes)}
                                                    {adminMediTypes && adminMediTypes[0] && adminMediTypes[0].english && adminMediTypes[0].english.map((val, key) => {
                                                        return <option key={"english" + key} value={val}>{val}</option>
                                                    })}
                                                </select>
                                            </div>

                                            <div>
                                                <select data-te-select-init className="bg-gray-200 border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="routeOfAdminAR" name="routeOfAdminAR" {...register("routeOfAdminAR", { required: medicinePage.routeOfAdminAR })}>
                                                    <option value="">اختر المشرف</option>
                                                    {adminMediTypes && adminMediTypes[0] && adminMediTypes[0].english && adminMediTypes[0].arabic.map((val, key) => {
                                                        return <option key={"english" + key} value={val}>{val}</option>
                                                    })}
                                                </select>
                                            </div>
                                            <div>
                                                <select data-te-select-init className="bg-gray-200 border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="routeOfAdminHB" name="routeOfAdminHB" {...register("routeOfAdminHB", { required: medicinePage.routeOfAdminHB })}>
                                                    <option value="">בחר מנהל</option>
                                                    {adminMediTypes && adminMediTypes[0] && adminMediTypes[0].english && adminMediTypes[0].hebrew.map((val, key) => {
                                                        return <option key={"english" + key} value={val}>{val}</option>
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-4">

                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="timeOfTreatment">
                                            Time Of Treatment
                                        </label>

                                        <input className={formInputClassName} autoComplete='off' placeholder='English' id="tot" name="tot" {...register("tot", { required: medicinePage.en_tot })} type="text" />

                                        <input className={formInputClassName} autoComplete='off' placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrire("ar_tot")} name="ar_tot" id="ar_tot" {...register("ar_tot", { required: medicinePage.ar_tot })} />

                                        <input className={formInputClassName} autoComplete='off' placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrireHebrew("hb_tot")} name="hb_tot" id="hb_tot" {...register("hb_tot", { required: medicinePage.hb_tot })} />
                                    </div>
                                    <div className="mb-4">

                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="instructMedicine">
                                            Instructions For Medicine
                                        </label>

                                        <input className={formInputClassName} autoComplete='off' placeholder='English' id="insForMedi" name="insForMedi" {...register("insForMedi", { required: medicinePage.en_insForMedi })} />

                                        <input className={formInputClassName} autoComplete='off' placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrire("ar_insForMedi")} name="ar_insForMedi" id="ar_insForMedi" {...register("ar_insForMedi", { required: medicinePage.ar_insForMedi })} />

                                        <input className={formInputClassName} autoComplete='off' placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrireHebrew("hb_insForMedi")} name="hb_insForMedi" id="hb_insForMedi" {...register("hb_insForMedi", { required: medicinePage.hb_insForMedi })} />
                                    </div>
                                    <div className="mb-4">

                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="treatmentIns">
                                            Treatment and Instructions
                                        </label>
                                        <div id="exampleWrapper" className="p-5 mb-5">
                                            <div id="accordion-collapse" data-accordion="open">
                                                <h2 id="accordion-collapse-heading-1">
                                                    <button type="button" className="flex items-center justify-between w-full p-5 font-medium text-left border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-800 text-gray-500 dark:text-gray-400" data-accordion-target="#accordion-collapse-body-1" aria-expanded="false" aria-controls="accordion-collapse-body-1">
                                                        <span>Treatment and Instruction in English</span>
                                                        <svg data-accordion-icon="" className="w-3 h-3 shrink-0 rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" stroke-width="2" d="M9 5 5 1 1 5"></path>
                                                        </svg>
                                                    </button>
                                                </h2>
                                                <div id="accordion-collapse-body-1" className="hidden" aria-labelledby="accordion-collapse-heading-1">
                                                    <TreatAndInsComponent lang="english" saveTI={saveTI} tiArray={tiArray} tiArabicArray={tiArabicArray} tiHebArray={tiHebArray} deleteRow={deleteRow} deleteTreatment={deleteTreatment} editRow={editRow} />
                                                </div>
                                                <h2 id="accordion-collapse-heading-2">
                                                    <button type="button" className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800" data-accordion-target="#accordion-collapse-body-2" aria-expanded="false" aria-controls="accordion-collapse-body-2">
                                                        <span>Treatment and Instruction in Arabic</span>
                                                        <svg data-accordion-icon="" className="w-3 h-3 shrink-0 rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" stroke-width="2" d="M9 5 5 1 1 5"></path>
                                                        </svg>
                                                    </button>
                                                </h2>
                                                <div id="accordion-collapse-body-2" className="hidden" aria-labelledby="accordion-collapse-heading-2">
                                                    <TreatAndInsComponent lang="arabic" saveTI={saveTI} tiArray={tiArray} tiArabicArray={tiArabicArray} tiHebArray={tiHebArray} deleteRow={deleteRow} deleteTreatment={deleteTreatment} editRow={editRow} />
                                                </div>
                                                <h2 id="accordion-collapse-heading-3">
                                                    <button type="button" className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800" data-accordion-target="#accordion-collapse-body-3" aria-expanded="false" aria-controls="accordion-collapse-body-3">
                                                        <span>Treatment and Instruction in Hebrew</span>
                                                        <svg data-accordion-icon="" className="w-3 h-3 shrink-0 rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" stroke-width="2" d="M9 5 5 1 1 5"></path>
                                                        </svg>
                                                    </button>
                                                </h2>
                                                <div id="accordion-collapse-body-3" className="hidden" aria-labelledby="accordion-collapse-heading-3">
                                                    <TreatAndInsComponent lang="hebrew" saveTI={saveTI} tiArray={tiArray} tiArabicArray={tiArabicArray} tiHebArray={tiHebArray} deleteRow={deleteRow} deleteTreatment={deleteTreatment} editRow={editRow} />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="mb-4">

                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="generalExplain">
                                            General Explanation
                                        </label>
                                        <input className={formInputClassName} autoComplete='off' placeholder='English' id="generalExplain" name="generalExplain" {...register("generalExplain", { required: medicinePage.en_generalExplain })} />

                                        <input className={formInputClassName} autoComplete='off' placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrire("ar_generalExplain")} name="ar_generalExplain" id="ar_generalExplain" {...register("ar_generalExplain", { required: medicinePage.ar_generalExplain })} />

                                        <input className={formInputClassName} autoComplete='off' placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrireHebrew("hb_generalExplain")} name="hb_generalExplain" id="hb_generalExplain" {...register("hb_generalExplain", { required: medicinePage.hb_generalExplain })} />

                                    </div>
                                    <div className="mb-4">

                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="supportiveCare">
                                            Supportive Care/Required Follow up
                                        </label>

                                        <input className={formInputClassName} autoComplete='off' placeholder='English' id="supportiveCare" name="supportiveCare" {...register("supportiveCare", { required: medicinePage.en_followup })} />

                                        <input className={formInputClassName} autoComplete='off' placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrire("ar_supportiveCare")} name="ar_supportiveCare" id="ar_supportiveCare" {...register("ar_supportiveCare", { required: medicinePage.ar_followup })} />

                                        <input className={formInputClassName} autoComplete='off' placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrireHebrew("hb_supportiveCare")} name="hb_supportiveCare" id="hb_supportiveCare" {...register("hb_supportiveCare", { required: medicinePage.hb_followup })} />

                                    </div>
                                    <h3 className="mb-4"><strong>Side Effects:-</strong></h3>
                                    <button
                                        type="button"
                                        className="inline-block rounded border-2 border-primary px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                                        onClick={() => gotoSideEffectpage()}
                                        data-te-ripple-init>
                                        Add SideEffect
                                    </button>
                                    <div id="exampleWrapper" className="p-5 mb-5" style={{ height: "10%", overflow: "auto" }}>
                                        {sideEffectListdata.length !== 0 && <SideEffectTable sideEffectListdata={sideEffectListdata} pageData={selectedSideEffects} medicineId={medicineId} selectSideEffect={selectSideEffect} editsideEffect={editsideEffect} selectedSideEffects={selectedSideEffects} />}
                                    </div>
                                    <div className="mb-4">
                                        <div className="mb-8">
                                            <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="supportiveCare">
                                                Link To Media
                                            </label>

                                            <input className={formInputClassName} autoComplete='off' placeholder='English' id="sideTreatment" name="sideTreatment" {...register("linkMedia")} />

                                        </div>
                                    </div>

                                    <div id="sticky-bottom" class="mt-8 text-center sticky-bottom">
                                        <div className="flex justify-between items-center mb-4">
                                            <a href='javascript:void(0);' className={buttonCSS.blue} onClick={handleSubmit((data) => saveAndExit(data), (err) => onError(err))}>Save & Exit</a>
                                            <a href='javascript:void(0);' className={buttonCSS.green} onClick={handleSubmit((data) => saveAndNew(data), (err) => onError(err))}>Save & New</a>
                                            <a href='javascript:void(0);' className={buttonCSS.red} onClick={() => discardChanges()}>Discard Changes</a>
                                        </div>
                                    </div>
                                    {medicineId &&
                                        <PrevAndNextButtons
                                            checkIsModified={checkIsModified}
                                            handleSubmit={handleSubmit}
                                            constructData={constructReqObj}
                                            setSelectedEditIndex={setSelectedEditIndex}
                                            setRedirectId={setMedicineId}
                                            editOptionData={editOptionData}
                                            selectedEditIndex={selectedEditIndex}
                                            getData={prevAndNextHandler}
                                            reset={reset}
                                            resetObj={resetObj}
                                            from="medicine" />}
                                </form>

                            </div >
                        </div >

                    </div>

                </div>

            </div >
        </React.Fragment >
    )
}
