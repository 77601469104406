import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    countNumber: 0,
    availableLanguages: {},
    isMedicineAdded: false,
    adminRoleList: [],
    isAdminCreated: false,
    isLoginSuccess: false,
    loginDetails: {},
    isloading: false,
    isSideEffectAdded: false,
    sideEffectListdata: [],
    sideEffectListById: {},
    isfromMedicinePage: false,
    allMedicineStore: [],
    storeupdateSideEffects: false,
    fetched: false,
    storeMedicineListById: {},
    storeIsMedicineupdated: false,
    allDiseaseStore: [],
    storeDiseaseListById: {},
    medicineData: {},
    isFromSideeffectList: false,
    allConditionStore: [],
    allProtocolListStore: [],
    allMainProtocolListStore: [],
    storeProtocolListById: {},
    editOptionData: [],
    editOptiontreatmentData: [],
    allTreatmentListStore: [],
    storeTreatmentListById: [],
    updateTreatment: false,
    storeOrderProtocolList: [],
    allChallengesList: [],
    challengesUpdated: false,
    storeTitleOrder: [],
    giftListStore: [],
    ageGroupListStore: [],
    hospitalListStore: [],
    wardListStore: [],
    getStaffDetailsStore: {},
    bookDetailStore: {},
    gameDetailsStore: {},
    staffListStore: [],
    booksListStore: [],
    gamesListStore: [],
    usersListStore: [],
    getUserDetailsStore: {},
    totalListCountStore: 0,
    allProtocolCount: 0
}

export const appReducer = createSlice({
    name: 'backOffice',
    initialState,
    reducers: {
        increment: (state) => {
            state.countNumber += 1
        },
        decrement: (state) => {
            state.countNumber -= 1
        },
        incrementByAmount: (state, action) => {
            state.countNumber += action.payload
        },
        availableLanguages: (state, action) => {

            state.availableLanguages = action.payload
        },
        medicineCreated: (state, action) => {
            state.isMedicineAdded = action.payload.update
        },
        adminRoleList: (state, action) => {
            //  console.log("action.payload------->", action.payload)
            state.adminRoleList = action.payload
        },
        createAdmins: (state, action) => {
            //  console.log("action.payload------->", action.payload)
            state.isAdminCreated = action.payload
        },
        adminLogin: (state, action) => {
            //  console.log("action.payload------->", action.payload)
            state.loginDetails = action.payload.details;
            state.isLoginSuccess = action.payload.success;
        },
        setLoading: (state, action) => {
            state.isloading = action.payload
        },
        addsideEffect: (state, action) => {
            state.isSideEffectAdded = action.payload
        },
        sideEffectList: (state, action) => {
            state.sideEffectListdata = action.payload
        },
        sideEffectListByIds: (state, action) => {
            // console.log("test1111------------------>", action.payload)
            state.sideEffectListById = action.payload;
            state.fetched = true;
        },
        fromMedicinePage: (state, action) => {

            state.isfromMedicinePage = action.payload
        },
        setAllmedicine: (state, action) => {
            state.allMedicineStore = action.payload
        },
        updateSideEffects: (state, action) => {
            state.storeupdateSideEffects = action.payload
        },
        setMedicineDataById: (state, action) => {
            state.storeMedicineListById = action.payload
        },
        setupdateMedicine: (state, action) => {
            state.storeIsMedicineupdated = action.payload
        },
        setAllDiseases: (state, action) => {
            state.allDiseaseStore = action.payload
        },
        setDiseaseDataById: (state, action) => {
            state.storeDiseaseListById = action.payload
        },
        saveMedicineData: (state, action) => {
            state.medicineData = action.payload
        },
        isfromsideEffectList: (state, action) => {
            state.isFromSideeffectList = action.payload
        },
        setAllMedicalCondition: (state, action) => {
            state.allConditionStore = action.payload
        },
        setAllProtocolList: (state, action) => {
            state.allProtocolListStore = action.payload
        },
        setAllProtocolCount: (state, action) => {
            state.allProtocolCount = action.payload
        },
        setAllMainProtocolList: (state, action) => {
            state.allMainProtocolListStore = action.payload
        },
        setProtocolDatabyID: (state, action) => {
            state.storeProtocolListById = action.payload
        },
        setEditOptionData: (state, action) => {

            state.editOptionData = action.payload
        },
        setEditOptionTreatmentData: (state, action) => {

            state.editOptiontreatmentData = action.payload
        },
        setAllTreatmentList: (state, action) => {
            state.allTreatmentListStore = action.payload
        },
        setTreatmentById: (state, action) => {
            state.storeTreatmentListById = action.payload
        },
        updateTreatmentSucess: (state, action) => {
            state.updateTreatment = action.payload
        },
        setOrderProtocolList: (state, action) => {
            state.storeOrderProtocolList = action.payload
        },
        setAllChallengesList: (state, action) => {
            state.allChallengesList = action.payload
        },
        setChallengesUpdate: (state, action) => {
            state.challengesUpdated = action.payload
        },
        setTitleorder: (state, action) => {
            state.storeTitleOrder = action.payload
        },
        setGiftListStore: (state, action) => {
            state.giftListStore = action.payload
        },
        setageGroupListStore: (state, action) => {
            state.ageGroupListStore = action.payload
        },
        sethospitalListStore: (state, action) => {
            state.hospitalListStore = action.payload
        },
        setwardListStore: (state, action) => {
            state.wardListStore = action.payload
        },
        setStaffDetailsStore: (state, action) => {
            state.getStaffDetailsStore = action.payload
        },
        setBookDetailsStore: (state, action) => {
            state.bookDetailStore = action.payload
        },
        setGameDetailsStore: (state, action) => {
            state.gameDetailsStore = action.payload
        },
        setStaffListStore: (state, action) => {
            state.staffListStore = action.payload
        },
        setBooksListStore: (state, action) => {
            state.booksListStore = action.payload
        },
        setGamesListStore: (state, action) => {
            state.gamesListStore = action.payload
        },
        setUsersListStore: (state, action) => {
            state.usersListStore = action.payload
        },
        setUserDetailsStore: (state, action) => {
            state.getUserDetailsStore = action.payload
        },
        setTotalListCountStore: (state, action) => {
            state.totalListCountStore = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    increment, decrement, incrementByAmount, availableLanguages, medicineCreated,
    userRoleList, adminRoleList, createAdmins, adminLogin, setLoading, addsideEffect,
    sideEffectList, sideEffectListByIds, fromMedicinePage, setAllmedicine, updateSideEffects,
    setMedicineDataById, setupdateMedicine, setAllDiseases, setDiseaseDataById, saveMedicineData,
    isfromsideEffectList, setAllMedicalCondition, setAllProtocolList, setAllProtocolCount, setAllMainProtocolList,
    setProtocolDatabyID, setEditOptionData, setAllTreatmentList, setTreatmentById, updateTreatmentSucess,
    setOrderProtocolList, setAllChallengesList, setEditOptionTreatmentData, setChallengesUpdate, setTitleorder,
    setGiftListStore, setageGroupListStore, sethospitalListStore, setwardListStore, setStaffDetailsStore,
    setBookDetailsStore, setGameDetailsStore, setStaffListStore, setBooksListStore, setGamesListStore, setUsersListStore,
    setUserDetailsStore, setTotalListCountStore
} = appReducer.actions

export default appReducer.reducer