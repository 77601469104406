import { formInputClassName } from "../../../util/contant";

export const TreatAndInsComponent = (props) => {
    const { lang, saveTI, tiArray, tiArabicArray, tiHebArray, deleteRow, deleteTreatment, editRow } = props;
    let placeHolder = 'English';
    let tableArray = tiArray;
    let keyUpEvent = undefined;
    if (lang === 'arabic') {
        placeHolder = 'Arabic';
        tableArray = tiArabicArray;
        keyUpEvent = name => window.transcrire(name);
    }
    if (lang === 'hebrew') {
        placeHolder = 'Hebrew';
        tableArray = tiHebArray;
        keyUpEvent = name => window.transcrireHebrew(name);
    }
    return <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700">
        <table className="table-auto py-2 mb-5" id="tiTable">
            <thead className='text-sm text-gray-500'>
                <tr>
                    <th>&nbsp;</th>
                    <th>Before Medicine</th>
                    <th>During Medicine</th>
                    <th>After Medicine</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody id="treatmentTable">
                <tr id={"addTreatMent" + lang} className='bg-white border-b'>
                    <td>#</td>
                    <td><input placeholder={placeHolder} autoComplete='off' style={{ direction: lang === 'english' ? 'ltr' : 'rtl' }} type="text" id={"BM" + lang} name={"BM" + lang} className={formInputClassName} onKeyUp={(e) => lang === 'english' ? undefined : keyUpEvent(e.target.name)} /></td>
                    <td><input placeholder={placeHolder} autoComplete='off' style={{ direction: lang === 'english' ? 'ltr' : 'rtl' }} type="text" id={"DM" + lang} name={"DM" + lang} className={formInputClassName} onKeyUp={(e) => lang === 'english' ? undefined : keyUpEvent(e.target.name)} /></td>
                    <td><input placeholder={placeHolder} autoComplete='off' style={{ direction: lang === 'english' ? 'ltr' : 'rtl' }} type="text" id={"AM" + lang} name={"AM" + lang} className={formInputClassName} onKeyUp={(e) => lang === 'english' ? undefined : keyUpEvent(e.target.name)} /></td>
                    <td><a href='javascript:void(0);' className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                        onClick={e => saveTI(e, lang)}><strong>Save</strong></a></td>
                </tr>
                {Object.keys(tableArray).map(function (key, index) {
                    // return tiArray[key].map((ele, index) => {

                    return <tr className='bg-white border-b '>
                        <td>{index + 1}</td>
                        <td>{tableArray[key][0]}</td>
                        <td>{tableArray[key][1]}</td>
                        <td>{tableArray[key][2]}</td>
                        <td>
                            <a href="javascript:void(0);" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => deleteTreatment(key, lang)}>
                                <span>Delete</span>
                            </a> / <a href="javascript:void(0);" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => editRow(key, lang)}>
                                <span>Edit</span>
                            </a>
                        </td>
                    </tr>
                    // })
                })
                }
            </tbody>
        </table>
    </div>
}