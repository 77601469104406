import React from 'react'
import Userheader from './Userheader';
import hipitIcon from '../../assets/images/hip-it-logo.971f479d.svg'

const TermsAndConditions = () => {
    return (
        <div>
            <div>
                <Userheader />

                <div className="flex min-h-screen text-white text-sm p-6 bg-transparent dark:bg-gray-900 bg-cover bg-center" style={{ backgroundImage: "url(../../assets/img/pageBack.jpg)" }}>

                    <div dir="rtl"
                        className="flex-1 font-col sm:p-5 md:p-5 xl:p-20 h-full max-w-4xl mx-auto overflow-hidden bg-cyan-900 rounded-lg shadow-xl dark:bg-gray-800"
                    >
                        <div className='h-20 w-20'>
                            <img src={hipitIcon} />
                        </div>
                        <div className='text-center'>
                            <h1 className='text-xl'>Terms and Conditions</h1>
                        </div>
                        <p className="mb-3 mt-3  dark:text-gray-400"><strong>
                            לידיעת המשתמשים באתר ובמשחק: המידע המפורסם באתר ובמשחק זה נועד לספק תוכן
                            למשחק תאדם (להלן: תאדם). המידע פה מוזן על ידי המשתמשים (להלן המשתמש או
                            הצוות) ונועד לאפשר למטופלים (להלן שחקנים או מטופלים) להתמצא בנבכי הפרוטוקול
                            באמצעות משחק תאדם. אין במידע זה כדי להוות תחליף לבדיקה רפואית פרטנית וייעוץ
                            רפואי אישי וספציפי  ואין הוא פוטר מי מהצוות לתת הדרכה נאותה למטופלים, לרבות עיון
                            בפרוטוקול והסברים שונים כפי שנהוג ללא משחק תאדם.</strong></p>
                        <br></br>
                        <h1><strong>כללי</strong></h1>
                        <p className=" dark:text-gray-400">

                            אפליקציית תאדם ומשחק תאדם פותח עי ידי איזיטק (1993) בע"מ (להלן: "איזיטק"), לנוחות הצוות
                            ולרווחת המטופלים במחלקת אונקולוגיה והמטולוגיה במרכז הרפואי רמב"ם חיפה (להלן: רמב"ם)
                            ומציע את המידע כמשחק עבור המטופלים, כאשר התוכן למשחק מוזן באמצעות שירות מקוון זה
                            ברשת האינטרנט (להלן: השירות) ע"י הצוות, וזאת בכפוף לתנאים הבאים.

                        </p>
                        <br></br>
                        <h1><strong>זכויות יוצרים</strong></h1>
                        <p className=" dark:text-gray-400">
                            לפי דיני זכויות היוצרים התקפים בישראל, ועל פי אמנות בין-לאומיות, זכויות היוצרים על הפלטפורמה
                            והמשחק ,  לרבות גרפיקת האתר, גרפיקת המשחק, וכל יישומי התוכנה הן באתר והן במובייל שייכים
                            לאיזיטק. זכויות יוצרים אלו חולשות, בין השאר על אפיון המוצר, מראה המוצר והדרך להשגת
                            המטרה של העברת המידע ומעקב אחרי המידע בין הצוות למטופלים. התוכן המוזן באמצעות
                            השירות, הכולל את הטקסט, התמונות, האיורים, המפות, קטעי הצליל, קטעי הוידיאו המוצגים כתוכן
                            בתוך המשחק (להלן: החומר המוגן), שייכים לרמב"ם, אלא אם כן נקבע באופן מפורש כי זכויות
                            היוצרים בחומר המוגן שייכות לגורם אחר.
                        </p>
                        <br></br>
                        <p className=" dark:text-gray-400">
                            המשתמש רשאי לעשות "שימוש הוגן" בחומר המוגן, לפי הכללים הקבועים בדין. שימוש הוגן כולל
                            ציטוט סביר מתוך החומר המוגן.
                        </p>
                        <br></br>
                        <p className=" dark:text-gray-400">
                            המצטט כאמור חייב לציין את המקור לציטוט, בין אם הוא רמב"ם, איזיטק  ובין אם הוא גורם אחר.
                            אסור למשתמש לעשות בחומר המוגן כל סילוף, פגימה או שינוי אחר, או כל פעולה שיש בה משום
                            הפחתת ערך ביחס לחומר המוגן, העלולה לפגוע בכבודו או בשמו של בעל זכויות היוצרים בו.
                        </p>
                        <br></br>
                        <p className=" dark:text-gray-400">
                            בכפוף לדיני זכויות יוצרים, אסור למשתמש להעתיק, להפיץ מחדש, לשדר מחדש או לפרסם חומר
                            מוגן, ללא הסכמה מראש ובכתב מאת רמב"ם.
                        </p>
                        <br></br>
                        <h1>פניות</h1>
                        <p className=" dark:text-gray-400"><strong>
                            בכל שאלה אודות שירות זה, ניתן לפנות באמצעות דואר אלקטרוני, בכתובת
                        </strong></p>
                        <p className=" dark:text-gray-400">
                            <a className='text-blue-700' href="mailto:support@hip-it.com">support@hip-it.com</a>
                        </p>
                        <br></br>
                        <h1><strong>אחריות </strong></h1>
                        <p className=" dark:text-gray-400">
                            השירות מוצע לציבור "כמות שהוא" ("As Is.)"
                            המרכז הרפואי רמב"ם ו/או איזיטק לא יישאו בכל אחריות, ישירה או עקיפה, במקרים של גילוי
                            ושימוש במידע הנמסר על ידך הנובע, במישרין או בעקיפין, מחדירות בלתי מורשות של אחרים או
                            כתוצאה ממעשים ו/או מחדלים אשר אינם בשליטתו ו/או שנגרמו ע"י צד שלישי.
                            רמב"ם ו/או איזיטק לא ישאו באחריות להתאמת השירות לצורכי המשתמש. כמו כן, לא ישאו רמב"ם
                            ו/או איזיטק באחריות לטעויות או לשגיאות בחומר המוצג בשירות.
                            רמב"ם ו/או איזיטק לא ישאו באחריות לשינויים שנעשו בחומר המוצג בשירות על ידי המשתמש או על
                            ידי כל צד ג'.
                        </p>
                        <br></br>
                        <p className=" dark:text-gray-400">
                            המשתמש בלבד יישא באחריות לאופן שבו הוא עושה שימוש בשירות.
                        </p>
                        <p className=" dark:text-gray-400">
                            רמב"ם ו/או איזיטק לא ישאו באחריות לכל נזק שנגרם למשתמש או לכל צד ג' כתוצאה ישירה או
                            עקיפה מן השימוש בשירות, לרבות נזק שנגרם עקב השימוש ביישומי תוכנה שהורדו ישירות באמצעות
                            השירות או הופעלו כתוצאה מהשימוש בשירות, לרבות יישומי אינטרנט (כגון - cgi Java, JavaScript,
                            Active-X
                        </p>
                        <br></br>
                        <p className=" dark:text-gray-400">
                            לעניין סעיף זה, "רמב"ם" משמע, לרבות תאגיד הבריאות שליד המרכז הרפואי רמב"ם ו/או עובדיהם
                            ו/א ונציגיהם. ו "איזיטק" משמע לרבות עובדיו או נציגיו מי מטעמו
                        </p>
                        <br></br>
                        <h1><strong>שמירת סודיות</strong>  </h1>
                        <p className=" dark:text-gray-400">
                            ממידע אישי שנמסר באמצעות משחק תאדם, נשמר במכשיר הנייד של המטופל בלבד, ואין לרמב"ם
                            ו/או איזיטק <strong>כל גישה אליו</strong>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TermsAndConditions