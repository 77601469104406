import { applyMiddleware, configureStore } from '@reduxjs/toolkit';
import appReducer from './Reducer';
import thunk from 'redux-thunk';

export const store = configureStore({
    reducer: appReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
    })
}, applyMiddleware(thunk))