import React from 'react'
import Userheader from './Userheader'

const Contact = () => {
    return (
        <div>
            <div>
                <Userheader />
                <div className='content-center p-6'>
                    <p className="text-gray-700 text-lg font-medium">
                        Contact Us
                        <br></br>
                        Email: <a href="mailto:support@hip-it.com" className="text-blue-500 hover:underline">support@hip-it.com</a>
                        <br></br>
                        Phone: <a href="tel:+972543977794" className="text-blue-500 hover:underline">+972-54-3977794</a>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Contact