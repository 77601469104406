import React, { useEffect } from 'react';
import Userheader from './Userheader';
import { Carousel, initTE } from "tw-elements"

const UserHome = () => {

    useEffect(() => {
        initTE({ Carousel });
    }, [])
    return (
        <div>
            <Userheader />
            <div className='content-center p-6'>
                <div id="carouselExampleCaptions" className="relative" data-te-carousel-init="" data-te-ride="carousel">
                    <div className="absolute bottom-0 left-0 right-0 z-[2] mx-[15%] mb-4 flex list-none justify-center p-0" data-te-carousel-indicators="">
                        <button type="button" data-te-target="#carouselExampleCaptions" data-te-slide-to="0" className="mx-[3px] box-content h-[3px] w-[30px] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-white bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none" aria-label="Slide 1"></button>
                        <button type="button" data-te-target="#carouselExampleCaptions" data-te-slide-to="1" className="mx-[3px] box-content h-[3px] w-[30px] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-white bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none !opacity-100" aria-label="Slide 2" data-te-carousel-active="" aria-current="true"></button>
                        <button type="button" data-te-target="#carouselExampleCaptions" data-te-slide-to="2" className="mx-[3px] box-content h-[3px] w-[30px] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-white bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none" aria-label="Slide 3"></button>
                    </div>
                    <div className="relative w-full overflow-hidden after:clear-both after:block after:content-['']">
                        <div className="relative float-left -mr-[100%] w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none hidden" data-te-carousel-item="" style={{ backfaceVisibility: "hidden" }}>
                            <img src="https://tecdn.b-cdn.net/img/Photos/Slides/img%20(15).jpg" className="block w-full" alt="..." />
                            <div className="absolute inset-x-[15%] bottom-5 hidden py-5 text-center text-white md:block">
                                <h5 className="text-xl">First slide label</h5>
                                <p>Some representative placeholder content for the first slide.</p>
                            </div>
                        </div>
                        <div className="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none !block data-[te-carousel-fade]:opacity-100 data-[te-carousel-fade]:z-[1]" data-te-carousel-item="" style={{ backfaceVisibility: "hidden" }} data-te-carousel-active="">
                            <img src="https://tecdn.b-cdn.net/img/Photos/Slides/img%20(22).jpg" className="block w-full" alt="..." />
                            <div className="absolute inset-x-[15%] bottom-5 hidden py-5 text-center text-white md:block">
                                <h5 className="text-xl">Second slide label</h5>
                                <p>Some representative placeholder content for the second slide.</p>
                            </div>
                        </div>
                        <div className="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none" data-te-carousel-item="" style={{ backfaceVisibility: "hidden" }}>
                            <img src="https://tecdn.b-cdn.net/img/Photos/Slides/img%20(23).jpg" className="block w-full" alt="..." />
                            <div className="absolute inset-x-[15%] bottom-5 hidden py-5 text-center text-white md:block">
                                <h5 className="text-xl">Third slide label</h5>
                                <p>Some representative placeholder content for the third slide.</p>
                            </div>
                        </div>
                    </div>
                    <button className="absolute bottom-0 left-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none" type="button" data-te-target="#carouselExampleCaptions" data-te-slide="prev">
                        <span className="inline-block h-8 w-8">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5"></path>
                            </svg>
                        </span>
                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Previous</span>
                    </button>
                    <button className="absolute bottom-0 right-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none" type="button" data-te-target="#carouselExampleCaptions" data-te-slide="next">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5"></path>
                            </svg>
                        </span>
                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Next</span>
                    </button>
                </div>
                <p><strong className=''>Welcome to Kiddo Cure</strong> - Your One-Stop Destination for Children's Health and Happiness!</p>

                <p>At Kiddo Cure, we believe that a happy and healthy childhood is every child's right. Our mission is to provide parents, caregivers, and children with the tools, resources, and expert guidance they need to ensure the well-being and holistic development of their little ones.</p>


                <h2 className="mb-2 text-lg font-semibold text-gray-900 dark:text-white">Why Choose Kiddo Cure?</h2>
                <ul className="max-w-md space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400">
                    <li>
                        Expert Pediatric Guidance: Our team of experienced pediatricians and child development specialists are here to answer your questions, offer valuable advice, and guide you through the challenges of parenthood.
                    </li>
                    <li>
                        Comprehensive Health Resources: Discover a wide range of articles, guides, and videos covering everything from nutrition and fitness to mental health and safety. We strive to empower parents with knowledge to make informed decisions for their children's health.
                    </li>
                    <li>
                        Fun and Educational Activities: We believe that learning should be enjoyable! Explore our collection of creative and engaging activities that promote cognitive development and foster your child's imagination.
                    </li>
                    <li>Community Support: Join our thriving community of parents and caregivers who share experiences, seek support, and connect with others who understand the joys and struggles of raising kids.</li>
                </ul>

            </div>
        </div>
    )
}

export { UserHome }