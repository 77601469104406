import React, { useEffect, useState } from 'react';
import { Input, TextArea } from '../../../Elements/CommonElements';
import { DahboardMenu } from '../DahboardMenu';
import { TopHeader } from '../TopHeader';
import { buttonCSS, errorMessage, formInputClassName } from '../../../util/contant';
import { useForm, Controller } from 'react-hook-form';
import ErrorMessage from '../ErrorMessage';
import _, { object } from 'underscore';
import { useDispatch, useSelector } from 'react-redux';
import { MultiSelect } from "react-multi-select-component";
import {
    getAllDiseasesAction, getAllMedicine, createProtocolAction, getAllProtocolListAction, actionForPrevAndNext,
    getAllMedicalConditionAction, getAllProtocolByIdAction, updateProtocolByID, getAllMainProtocolListAction
} from '../../../Store/Action';
import { englishLangId, arabicLangId, hebrewLangId, getQuery, FilterbyStringAsending, FilterbyFiledAsendingPropNameValue } from '../../../util/helper';
import PrevAndNextButtons from '../../../Elements/PrevAndNextButtons';
import { getProtocolsByIdURL } from '../../../Api/ContantUrls';
import { setLoading } from '../../../Store/Reducer';

export const Protocols = () => {
    const { protocols } = errorMessage;
    const allDiseaseStore = useSelector(state => state?.allDiseaseStore);
    const allMedicineStore = useSelector(state => state?.allMedicineStore);
    const availableLanguages = useSelector(state => state?.availableLanguages);
    const allConditionStore = useSelector(state => state?.allConditionStore);
    const allProtocolListStore = useSelector(state => state?.allProtocolListStore);
    const storeProtocolListById = useSelector(state => state?.storeProtocolListById);
    const editOptionData = useSelector(state => state?.editOptionData);
    const allMainProtocolListStore = useSelector(state => state?.allMainProtocolListStore);

    const { register, handleSubmit, watch, formState: { errors }, setError, reset, getValues } = useForm();
    const [formErrors, setFormErrors] = useState({});
    const [selected, setSelected] = useState([]);
    const [selectedMedicine, setSelectedMedicine] = useState([]);
    const [selectedContinuationof, setSelectedContinuationof] = useState([]);
    const [selectedBelongsto, setSelectedBelongsto] = useState([]);
    const [selectedCondition, setSelectedCondition] = useState([]);
    const [diseaseData, setDiseaseData] = useState([]);
    const [medicineData, setMedicineData] = useState([]);
    const [continuationofData, setContinuationofData] = useState([]);
    const [belongsToData, setBelongsToData] = useState([]);
    const [conditionData, setConditionData] = useState([]);
    const [protocolData, setProtocolData] = useState([]);
    const [protocolId, setProtocolId] = useState(getQuery("id"));
    const [selectedEditIndex, setSelectedEditIndex] = useState("");
    const [isMainProtocol, setIsMainProtocol] = useState(false);
    const [addMoreArray, setAddMoreArray] = useState(["1"]);
    const [addNonReqMoreArray, setAddNonReqMoreArray] = useState(["1"]);
    const [addGeneralMoreArray, setAddGeneralMoreArray] = useState(["1"]);
    const [addExplainMoreArray, setAddExplainMoreArray] = useState(["1"]);
    const [isupdate, setIsUpdate] = useState(false);
    const [isCalled, setIsCalled] = useState(false);
    const [isPageData, setIsPageData] = useState(false);
    const [ismediUpdated, setIsmediUpdated] = useState(false);

    const dispatch = useDispatch();
    const minOffset = 0;
    const maxOffset = 60;
    const [thisYear, selectedYear] = useState((new Date()).getFullYear());

    const [pageRenderObj, setPageRenderObj] = useState({});


    useEffect(() => {
        try {
            document.title = 'Protocols';
            if (protocolId && editOptionData.length !== 0) {
                setSelectedEditIndex(editOptionData.indexOf(protocolId))

            }

            if (allDiseaseStore && allDiseaseStore.length !== 0 && diseaseData.length === 0) {
                const filterdata = diseaseData;
                allDiseaseStore.map((data, index) => {
                    if (index === 0) {
                        filterdata.push({ label: "Select one by one", value: "", disabled: true })
                    }
                    filterdata.push({ label: data.disease[0].value, value: data._id })
                })
                setDiseaseData(filterdata);
            }
            if (allMedicineStore && allMedicineStore.length !== 0 && medicineData.length === 0) {
                const filterdata = medicineData;
                FilterbyFiledAsendingPropNameValue(allMedicineStore, "medicineShortName", "value").map((data, index) => {
                    if (index === 0) {
                        filterdata.push({ label: "Select one by one", value: "", disabled: true })
                    }
                    if (data.medicineShortName[0].value) {
                        filterdata.push({ label: data.medicineShortName[0].value, value: data._id })
                    }
                })
                setMedicineData(filterdata);
                setIsUpdate(!isupdate)
            }
            if (allConditionStore && allConditionStore.length !== 0 && conditionData.length === 0) {
                const filterdata = conditionData;
                allConditionStore.map((data, index) => {
                    if (index === 0) {
                        filterdata.push({ label: "Select one by one", value: "", disabled: true })
                    }
                    filterdata.push({ label: data.name, value: data._id })

                })
                setConditionData(filterdata);
            }

            if (allProtocolListStore && allProtocolListStore.length !== 0 && continuationofData.length === 0) {
                const filterdata = continuationofData;
                allProtocolListStore.map((data, index) => {
                    if (index === 0) {
                        filterdata.push({ label: "Select one by one", value: "", disabled: true })
                    }
                    filterdata.push({ label: data.protocolName[0].value, value: data._id })

                })
                setContinuationofData(filterdata);
            }
            if (allMainProtocolListStore && allMainProtocolListStore.length !== 0 && belongsToData.length === 0) {
                const filterdata = belongsToData;
                allMainProtocolListStore.map((data, index) => {
                    if (data?.active) {
                        if (index === 0) {
                            filterdata.push({ label: "Select one by one", value: "", disabled: true })
                        }
                        filterdata.push({ label: data.protocolName[0].value, value: data._id })
                    }

                })
                setBelongsToData(filterdata);
            }
            if (!isCalled) {
                getRequiredData();
            }
            if (storeProtocolListById && Object.keys(storeProtocolListById).length === 0) {
                getProtocolData(protocolId)
            }
            setProtocolData(storeProtocolListById)

            if (protocolId && !isPageData && Object.keys(protocolData).length !== 0) {
                constructPageData(protocolData);
                setIsPageData(true)
            }


        } catch (e) {
            console.log("use error------------->", e)
        }








    }, [allDiseaseStore, allMedicineStore, allConditionStore, protocolId, selectedEditIndex,
        allProtocolListStore, allMainProtocolListStore, storeProtocolListById, isupdate])

    const checkIsModified = () => {
        if (JSON.stringify(pageRenderObj) === JSON.stringify(getValues())) {
            return false;
        } else {
            return true
        }
    }

    const prevAndNextHandler = async (protocolId) => {
        const reqObj = {
            "protocolId": protocolId
        }
        const response = await actionForPrevAndNext(dispatch, getProtocolsByIdURL, reqObj);
        constructPageData(response);
    }

    const getProtocolData = (protocolId) => {
        const reqObj = {
            "protocolId": protocolId
        }
        dispatch(getAllProtocolByIdAction(reqObj));
    }

    const getRequiredData = async () => {
        const reqObj = {
            "getLatestFirst": true,
            "pageNumber": 1,
            "numberOfRecordsPerPage": 0,
            "filter": {
                "active": true
            }
        }

        if (Object.keys(allConditionStore).length === 0) {
            dispatch(await getAllMedicalConditionAction(reqObj))
        }
        if (allMedicineStore.length === 0) {
            dispatch(await getAllMedicine(reqObj))
        }
        if (Object.keys(allDiseaseStore).length === 0) {
            dispatch(await getAllDiseasesAction(reqObj))
        }
        if (Object.keys(allProtocolListStore).length === 0) {
            reqObj.filter = { active: true }
            dispatch(await getAllProtocolListAction(reqObj));
        }
        if (Object.keys(allMainProtocolListStore).length === 0) {
            reqObj.filter = { isMainProtocol: true }
            dispatch(await getAllMainProtocolListAction(reqObj));
        }
        setIsCalled(true)
    }

    const getFilterData = (protodata, existData) => {
        try {
            let filterValues = [];
            protodata.length !== 0 && protodata.map(val => {
                const filterData = _.find(existData, function (i) {
                    return i.value == val._id;
                });
                filterValues.push(filterData && filterData.length !== 0 ? filterData : []);
            })
            return filterValues;
        } catch (e) {
            console.log("E----------->", e)
            return [];
        }
    }

    const setPageValues = (val, pageData, engName, arName, hbName) => {
        if (val.language._id === englishLangId(availableLanguages.languageList)) {
            pageData[engName] = val.value;
        }
        if (val.language._id === arabicLangId(availableLanguages.languageList)) {
            pageData[arName] = val.value;
        }
        if (val.language._id === hebrewLangId(availableLanguages.languageList)) {
            pageData[hbName] = val.value;
        }

    }
    const constructPageData = (data = null) => {
        try {
            reset({});
            let constructData = data ? data : protocolData;
            //console.log("constructDataconstructData---------->", constructData)
            let pageData = {};
            for (let key in constructData) {
                let filterdata = _.values(constructData[key]);
                pageData.year = constructData.protocolYear;
                pageData.protoVersion = constructData.protocolVersion;
                pageData.protoDurationdays = constructData.treatmentDurationDays;
                pageData.protoDayStart = constructData.dayStart;
                pageData.generalReport = constructData.generalInstructions[0].whenToReport;
                pageData.isMainProtocol = constructData.isMainProtocol;
                pageData.diagramLineNumber = constructData?.diagramLineNumber;
                if (Array.isArray(filterdata)) {
                    filterdata.map((val) => {
                        if (key === 'protocolName') {
                            setPageValues(val, pageData, 'protoName', 'ar_protoName', 'hb_protoName')
                        }
                        if (key === 'protocolShortName') {
                            setPageValues(val, pageData, 'protoShortname', 'ar_protoShortname', 'hb_protoShortname')
                        }
                        if (key === 'protocolCharacteristics') {
                            setPageValues(val, pageData, 'protochar', 'ar_protochar', 'hb_protochar')
                        }
                        if (key === 'requiredMedicalPreparations') {
                            constructData[key].map((value, index) => {
                                pageData["reqStartday" + index] = value.startingDay;
                                pageData["requireOrderNo" + index] = parseInt(value.number);
                                pageData["reqMedPrepare" + index] = value.preparation[0].value;
                                pageData["ar_reqMedPrepare" + index] = value.preparation[1].value;
                                pageData["hb_reqMedPrepare" + index] = value.preparation[2].value;
                            })

                        }
                        if (key === 'nonRequiredMedicalPreparations') {
                            constructData[key].map((value, index) => {
                                pageData["nonreqStartday" + index] = value.startingDay;
                                pageData["nonrequireOrderNo" + index] = value.number;
                                pageData["nonreqMedPrepare" + index] = value.preparation[0].value;
                                pageData["ar_nonreqMedPrepare" + index] = value.preparation[1].value;
                                pageData["hb_nonreqMedPrepare" + index] = value.preparation[2].value;
                            })
                        }
                        if (key === 'generalInstructions') {
                            constructData[key].map((value, index) => {
                                pageData["generalOrderNo" + index] = value.number;
                                pageData["generalReport" + index] = value.whenToReport;
                                pageData["generalIns" + index] = value.instruction[0].value;
                                pageData["ar_generalIns" + index] = value.instruction[1].value;
                                pageData["hb_generalIns" + index] = value.instruction[2].value;
                            })
                        }
                        if (key === 'Explanations') {
                            constructData[key].map((value, index) => {
                                pageData["explainOrderNo" + index] = value.number;
                                pageData["explainMediaLink" + index] = value.mediaLink;
                                pageData["explainIns" + index] = value.instruction[0].value;
                                pageData["ar_explainIns" + index] = value.instruction[1].value;
                                pageData["hb_explainIns" + index] = value.instruction[2].value;
                            })
                        }

                    })
                }

                if (key === 'belongToDiseaseType') {
                    setSelected(getFilterData(constructData[key], diseaseData))
                }
                if (key === 'belongsToProtocol') {
                    setSelectedBelongsto(getFilterData(constructData[key], belongsToData))
                }
                if (key === 'medicinesIncluded') {
                    let mediArray = [];
                    if (constructData[key] && constructData[key].length !== 0) {

                        constructData[key].map((val, index) => {
                            mediArray.push({
                                label: val?.medicineShortName[0].value,
                                value: val?._id,
                            })
                        })

                        setSelectedMedicine(mediArray)
                    } else {
                        setSelectedMedicine([])
                    }
                }
                if (key === 'continuationOf') {
                    setSelectedContinuationof(getFilterData(constructData[key], continuationofData))
                }
                if (key === 'conditions') {
                    setSelectedCondition(getFilterData(constructData[key], conditionData))
                }
                if (key === 'requiredMedicalPreparations') {
                    let existArray = [];
                    constructData[key].map((val, index) => {
                        existArray.push(index + 1)
                    })
                    setAddMoreArray([...new Set(existArray)]);
                }
                if (key === 'nonRequiredMedicalPreparations') {
                    let existArray = [];
                    constructData[key].map((val, index) => {
                        existArray.push(index + 1)
                    })
                    setAddNonReqMoreArray([...new Set(existArray)]);
                }
                if (key === 'generalInstructions') {
                    let existArray = [];
                    constructData[key].map((val, index) => {
                        existArray.push(index + 1)
                    })
                    setAddGeneralMoreArray([...new Set(existArray)]);
                }
                if (key === 'Explanations') {
                    let existArray = [];
                    constructData[key].map((val, index) => {
                        existArray.push(index + 1)
                    })
                    setAddExplainMoreArray([...new Set(existArray)]);
                }
                if (key === 'isMainProtocol' && constructData[key]) {
                    setIsMainProtocol(constructData[key])
                }

            }

            setTimeout(() => {
                setPageRenderObj(pageData)
                reset(pageData)
                setIsUpdate(!isupdate)
            }, 100)


        } catch (e) {
            console.log("EEE----------->", e)
        }
    }

    const getRequiredMedicalPreparation = (data, oName, prePare1, prePare2, prePare3, startDay, key, ke1 = "preparation") => {
        let reqArray = [];
        addMoreArray.map((val, index) => {
            reqArray.push({
                number: data[oName + index],
                [ke1]: [
                    {
                        language: englishLangId(availableLanguages.languageList),
                        value: data[prePare1 + index]
                    },
                    {
                        language: englishLangId(availableLanguages.languageList),
                        value: data[prePare2 + index]
                    },
                    {
                        language: englishLangId(availableLanguages.languageList),
                        value: data[prePare3 + index]
                    }
                ],
                [key]: data[startDay + index]
            })
        })

        return reqArray;
    }

    const constructData = (data) => {

        const reqObj = {};
        const diseaseIds = [];
        const medicineIds = [];
        const conditionIds = [];
        const continuationofIds = [];
        const belongsToIds = [];
        if (selected.length !== 0) {
            selected.map(item => {
                diseaseIds.push(item.value)
            });
        }
        if (selectedMedicine.length !== 0) {
            selectedMedicine.map(item => {
                medicineIds.push(item.value)
            });
        }
        if (selectedCondition.length !== 0) {
            selectedCondition.map(item => {
                conditionIds.push(item.value)
            });
        }
        if (selectedContinuationof.length !== 0) {
            selectedContinuationof.map(item => {
                // console.log("item------------->", item)
                continuationofIds.push(item.value)
            });
        }
        if (selectedBelongsto.length !== 0) {
            selectedBelongsto.map(item => {
                belongsToIds.push(item.value)
            });
        }
        reqObj.belongToDiseaseType = diseaseIds;
        reqObj.protocolShortName = [
            {
                language: englishLangId(availableLanguages.languageList),
                value: data.protoShortname
            },
            {
                language: arabicLangId(availableLanguages.languageList),
                value: data.ar_protoShortname
            },
            {
                language: hebrewLangId(availableLanguages.languageList),
                value: data.hb_protoShortname
            }
        ];
        reqObj.protocolName = [
            {
                language: englishLangId(availableLanguages.languageList),
                value: data.protoName
            },
            {
                language: arabicLangId(availableLanguages.languageList),
                value: data.ar_protoName
            },
            {
                language: hebrewLangId(availableLanguages.languageList),
                value: data.hb_protoName
            }
        ];
        reqObj.protocolYear = data.year;
        reqObj.protocolVersion = data.protoVersion;
        reqObj.medicinesIncluded = medicineIds;
        reqObj.protocolCharacteristics = [
            {
                language: englishLangId(availableLanguages.languageList),
                value: data.protochar
            },
            {
                language: arabicLangId(availableLanguages.languageList),
                value: data.ar_protochar
            },
            {
                language: hebrewLangId(availableLanguages.languageList),
                value: data.hb_protochar
            }
        ];
        reqObj.treatmentDurationDays = data.protoDurationdays;
        reqObj.dayStart = data.protoDayStart;
        reqObj.continuationOf = continuationofIds;
        reqObj.conditions = conditionIds;
        reqObj.requiredMedicalPreparations = getRequiredMedicalPreparation(data, "requireOrderNo", "reqMedPrepare", "ar_reqMedPrepare", "hb_reqMedPrepare", "reqStartday", "startingDay");
        reqObj.nonRequiredMedicalPreparations = getRequiredMedicalPreparation(data, "nonrequireOrderNo", "nonreqMedPrepare", "ar_nonreqMedPrepare", "hb_nonreqMedPrepare", "nonreqStartday", "startingDay");
        reqObj.generalInstructions = getRequiredMedicalPreparation(data, "generalOrderNo", "generalIns", "ar_generalIns", "hb_generalIns", "generalReport", "whenToReport", "instruction");
        reqObj.Explanations = getRequiredMedicalPreparation(data, "explainOrderNo", "explainIns", "ar_explainIns", "hb_explainIns", "explainMediaLink", "mediaLink", "instruction");

        reqObj.active = true;
        reqObj.isMainProtocol = data.isMainProtocol;
        reqObj.belongsToProtocol = belongsToIds;
        reqObj.protocolQRCode = `Protocol Name:${data.protoName}  
        Protocol Short Name:${data.protoShortname} 
        Protocol Year:${data.year} 
        Protocol Version:${data.protoVersion} 
        Protocol Characteristics:${data.protochar}`
        reqObj.diagramLineNumber = parseInt(data?.diagramLineNumber);
        return reqObj;

    }

    const submitHandler = async (data, isNew = false) => {
        if (selected.length === 0) {
            register('protoDisease', { minLength: 4 });
            setError('protoDisease', { type: 'custom', message: 'Please Select belongs to disease' });
            window.scrollTo(0, 0)
        }
        if (selected.length !== 0) {
            const reqObj = constructData(data);

            if (protocolId) {
                dispatch(await updateProtocolByID({
                    "protocolId": protocolId,
                    "dataToUpdate": reqObj,
                    isNew,
                }))
            } else {
                reqObj.isNew = isNew;
                dispatch(createProtocolAction(reqObj))
            }
        }
        //  }
        return false;
    }

    const onError = (errors) => {
        window.scrollTo(0, 0)
        if (selected.length !== 0 && selectedMedicine.length !== 0) {
            setFormErrors({})
        } else {
            setFormErrors(errors)
            window.scrollTo(0, 0)
        }
    }

    const getYearOptions = () => {
        const options = [];

        for (let i = minOffset; i <= maxOffset; i++) {
            const year = thisYear + i;
            options.push(<option value={year}>{year}</option>);

        }
        return options;
    }

    const saveAndExit = (data) => {
        submitHandler(data)
    }
    const saveAndNew = (data) => {
        submitHandler(data, true);
    }
    const discardChanges = () => {
        if (protocolId) {
            constructPageData()
        } else {
            reset();
        }
        window.location.href = '/dashboard/protocollist';
    }

    const addMore = (val, from) => {
        if (from === 'req') {
            let existArray = addMoreArray;
            existArray.push(val);
            setAddMoreArray(existArray);
            setIsUpdate(!isupdate)
        }
        if (from === 'nonreq') {
            let existArray = addNonReqMoreArray;
            existArray.push(val);
            setAddNonReqMoreArray(existArray);
            setIsUpdate(!isupdate)
        }
        if (from === 'general') {
            let existArray = addGeneralMoreArray;
            existArray.push(val);
            setAddGeneralMoreArray(existArray);
            setIsUpdate(!isupdate)
        }
        if (from === 'explain') {
            let existArray = addExplainMoreArray;
            existArray.push(val);
            setAddExplainMoreArray(existArray);
            setIsUpdate(!isupdate)
        }

    }

    const deleteMore = (val, from) => {
        if (from === 'req') {
            let existArray = addMoreArray;
            var index = existArray.indexOf(val);
            if (index !== -1) {
                existArray.splice(index, 1);
            }
            setAddMoreArray(existArray);
            setIsUpdate(!isupdate)
        }
        if (from === 'nonreq') {
            let existArray = addNonReqMoreArray;
            var index = existArray.indexOf(val);
            if (index !== -1) {
                existArray.splice(index, 1);
            }
            setAddNonReqMoreArray(existArray);
            setIsUpdate(!isupdate)
        }
        if (from === 'general') {
            let existArray = addGeneralMoreArray;
            var index = existArray.indexOf(val);
            if (index !== -1) {
                existArray.splice(index, 1);
            }
            setAddGeneralMoreArray(existArray);
            setIsUpdate(!isupdate)
        }
        if (from === 'explain') {
            let existArray = addExplainMoreArray;
            var index = existArray.indexOf(val);
            if (index !== -1) {
                existArray.splice(index, 1);
            }
            setAddExplainMoreArray(existArray);
            setIsUpdate(!isupdate)
        }
    }

    const changeMainProtocol = () => {
        if (isMainProtocol) {
            setBelongsToData([]);
        }
        setIsMainProtocol(!isMainProtocol)
        setIsUpdate(isupdate);
    }

    return (
        <React.Fragment>
            <TopHeader />
            <DahboardMenu />
            <div className="mt-10">
                <div className="
                max-w-[540px] sm:max-w-[604px] md:max-w-[720px] lg:max-w-[972px]
                xl:max-w-full xl:pl-40  xl:ml-40 xl:px-12 2xl:max-w-[1400px] mx-auto
                flex">
                    <div className="flex w-full flex-col ">
                        <div className="relative overflow-hidden mb-8">
                            <ErrorMessage errors={errors} setFormErrors={setFormErrors} />

                            <div className='rounded-t-lg overflow-hidden border-t border-l border-r border-b border-gray-400 flex justify-center p-8'>
                                <form className="w-full bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 " name='conversion' onSubmit={handleSubmit(submitHandler, onError)}>
                                    {protocolId &&
                                        <PrevAndNextButtons
                                            setRedirectId={setProtocolId}
                                            editOptionData={editOptionData}
                                            selectedEditIndex={selectedEditIndex}
                                            getData={prevAndNextHandler}
                                            reset={reset}
                                            resetObj={{}}
                                            checkIsModified={checkIsModified}
                                            handleSubmit={handleSubmit}
                                            constructData={constructData}
                                            from="protocol"
                                            setSelectedEditIndex={setSelectedEditIndex}
                                        />}
                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="method">
                                            Line Number
                                        </label>
                                        <input className={formInputClassName} type="number" autoComplete='off' placeholder='English' id="diagramLineNumber " name='diagramLineNumber ' {...register("diagramLineNumber", { required: protocols.lineNumber })} />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                            Protocol Short Name
                                        </label>

                                        <input className={formInputClassName} autoComplete='off' placeholder='English' id="protoShortname" name="protoShortname" {...register("protoShortname", { required: protocols.en_pshortName })} />
                                        <input className={formInputClassName} autoComplete='off' placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }}  {...register("ar_protoShortname", { required: protocols.ar_pshortName })} />
                                        <input className={formInputClassName} autoComplete='off' placeholder='Hebrew' name="hb_protoShortname" style={{ direction: "rtl", fontSize: "120%" }}  {...register("hb_protoShortname", { required: protocols.ar_pshortName })} />

                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineDesc">
                                            Protocol Name
                                        </label>
                                        <input className={formInputClassName} autoComplete='off' placeholder='English' id="protoName" name="protoName" {...register("protoName", { required: protocols.en_pName })} />
                                        <input className={formInputClassName} autoComplete='off' placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} name="ar_protoName"  {...register("ar_protoName", { required: protocols.ar_pName })} />
                                        <input className={formInputClassName} autoComplete='off' placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }} name="hb_protoName"  {...register("hb_protoName", { required: protocols.hb_pName })} />


                                    </div>
                                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                                        <div className='text-center mt-5'>
                                            <input id="default-checkbox" type="checkbox" checked={isMainProtocol} value="" onClick={() => changeMainProtocol()} {...register("isMainProtocol")}
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label for="default-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Main Protocol</label>
                                        </div>
                                        <div className="mb-4">
                                            <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="generalDose">
                                                Protocol Year
                                            </label>

                                            <input type={'number'} maxLength="4" className={formInputClassName}
                                                onInput={(e) => (e.target.value.length > e.target.maxLength) ? e.target.value = e.target.value.slice(0, e.target.maxLength) : ""}
                                                autoComplete='off' placeholder='English' id="year" name='year' {...register("year", { required: protocols.pYear })} />

                                        </div>
                                        <div className="mb-4">
                                            <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="maximumDose">
                                                Protocol Version
                                            </label>
                                            <input className={formInputClassName} type="text" autoComplete='off' placeholder='Version' id="protoVersion" name='protoVersion' {...register("protoVersion", { required: protocols.pVersion })} />

                                        </div>
                                        <div>
                                            <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="maximumDose">
                                                Belong To Protocol
                                            </label>

                                            <MultiSelect
                                                className={formInputClassName}
                                                disabled={isMainProtocol}
                                                options={belongsToData}
                                                value={selectedBelongsto}
                                                onChange={setSelectedBelongsto}
                                                labelledBy="Select"
                                                hasSelectAll={false}
                                            />
                                        </div>
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="method">
                                            Belong to Disease
                                        </label>

                                        <MultiSelect
                                            className={formInputClassName}
                                            options={diseaseData}
                                            value={selected}
                                            onChange={setSelected}
                                            labelledBy="Select"
                                            hasSelectAll={false}
                                        />

                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="method">
                                            Medicines Incuded
                                        </label>

                                        <MultiSelect
                                            className={formInputClassName}
                                            options={medicineData.length === 0 ? [{ label: "", value: "" }] : medicineData}
                                            value={selectedMedicine}
                                            onChange={setSelectedMedicine}
                                            labelledBy="Select"
                                            hasSelectAll={false}

                                        />

                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="method">
                                            Protocol Characteristics
                                        </label>
                                        <input className={formInputClassName} autoComplete='off' placeholder='English' id="protochar" name='protochar' {...register("protochar")} />
                                        <input className={formInputClassName} autoComplete='off' placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} name="ar_protochar"  {...register("ar_protochar")} />
                                        <input className={formInputClassName} autoComplete='off' placeholder='Hebrew' name="hb_protochar" style={{ direction: "rtl", fontSize: "120%" }}  {...register("hb_protochar")} />

                                    </div>
                                    <div className="mb-4">

                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="method">
                                            Treatment Duration Days
                                        </label>
                                        <input className={formInputClassName} type="number" autoComplete='off' placeholder='English' id="protoDurationdays" name='protoDurationdays' {...register("protoDurationdays")} />


                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="method">
                                            Day Start
                                        </label>
                                        <input className={formInputClassName} type="number" autoComplete='off' placeholder='English' id="protoDayStart" name='protoDayStart' {...register("protoDayStart")} />

                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="method">
                                            Continuation Of
                                        </label>

                                        <MultiSelect
                                            className={formInputClassName}
                                            options={continuationofData}
                                            value={selectedContinuationof}
                                            onChange={setSelectedContinuationof}
                                            labelledBy="Select"
                                            hasSelectAll={false}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="method">
                                            Conditions
                                        </label>

                                        <MultiSelect
                                            className={formInputClassName}
                                            options={conditionData}
                                            value={selectedCondition}
                                            onChange={setSelectedCondition}
                                            labelledBy="Select"
                                            hasSelectAll={false}
                                        />
                                    </div>

                                    <div className="mb-4">

                                        <div className="flex justify-between">
                                            <label className="block text-gray-500 text-lg mt-3 md:text-left mb-1 md:mb-0 pr-4" htmlFor="method">
                                                Required Medical Preparations
                                            </label>
                                            <button type="button" className="bg-transparent hover:bg-transparent text-black font-bold rounded-full flex items-end" onClick={() => addMore(parseInt(addMoreArray[addMoreArray.length - 1]) + 1, "req")}>
                                                <svg className="h-6 w-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path strokeLinecap="round" strokeLinejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                                                </svg>
                                                Add More
                                            </button>
                                        </div>

                                        {addMoreArray.map((val, index) => {
                                            return <div className='border-solid border-2 border-black-600 p-9'>
                                                <div className="grid grid-cols-2 md:grid-cols-3 gap-3">

                                                    <div>
                                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="method">
                                                            Order NO
                                                        </label>
                                                        <input className={formInputClassName} value={val} type="number" autoComplete='off' placeholder='Order Number' id={"requireOrderNo" + index}  {...register("requireOrderNo" + index)} />
                                                    </div>
                                                    <div>
                                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="method">
                                                            Starting Day
                                                        </label>
                                                        <input className={formInputClassName} type="number" autoComplete='off' placeholder='Start day' id="reqStartday" name='reqStartday' {...register("reqStartday" + index)} />
                                                    </div>
                                                    {index !== 0 && <div className='text-center mt-5'>
                                                        <button type="button" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => deleteMore(val, "req")}>Delete Preparation</button>
                                                    </div>}
                                                </div>


                                                <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="method">
                                                    Preparations
                                                </label>

                                                <input className={formInputClassName} autoComplete='off' placeholder='English' id="reqMedPrepare" {...register("reqMedPrepare" + index)} />
                                                <input className={formInputClassName} autoComplete='off' placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }}  {...register("ar_reqMedPrepare" + index)} />
                                                <input className={formInputClassName} autoComplete='off' placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }}   {...register("hb_reqMedPrepare" + index)} />



                                            </div>
                                        })}
                                    </div>
                                    <div className="mb-4">
                                        <div className="flex justify-between">
                                            <label className="block text-gray-500 text-lg mt-3 md:text-left mb-1 md:mb-0 pr-4" htmlFor="method">
                                                NonRequired Medical Preparations
                                            </label>
                                            <button type="button" className="bg-transparent hover:bg-transparent text-black font-bold rounded-full flex items-end" onClick={() => addMore(parseInt(addNonReqMoreArray[addNonReqMoreArray.length - 1]) + 1, "nonreq")}>
                                                <svg className="h-6 w-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path strokeLinecap="round" strokeLinejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                                                </svg>
                                                Add More
                                            </button>
                                        </div>
                                        {addNonReqMoreArray.map((val, index) => {
                                            return <div className='border-solid border-2 border-black-600 p-9'>
                                                <div className="grid grid-cols-2 md:grid-cols-3 gap-3">
                                                    <div>
                                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="method">
                                                            Order NO
                                                        </label>
                                                        <input className={formInputClassName} value={val} type="number" autoComplete='off' placeholder='Order Number' id="nonrequireOrderNo"  {...register("nonrequireOrderNo" + index)} />
                                                    </div>
                                                    <div>
                                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="method">
                                                            Starting Day
                                                        </label>
                                                        <input className={formInputClassName} type="number" autoComplete='off' placeholder='Start day' id="nonreqStartday" {...register("nonreqStartday" + index)} />
                                                    </div>
                                                    {index !== 0 && <div className='text-center mt-5'>
                                                        <button type="button" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => deleteMore(val, "nonreq")}>Delete Preparation</button>
                                                    </div>}
                                                </div>
                                                <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="method">
                                                    Preparations
                                                </label>
                                                <input className={formInputClassName} autoComplete='off' placeholder='English' id="nonreqMedPrepare" {...register("nonreqMedPrepare" + index)} />
                                                <input className={formInputClassName} autoComplete='off' placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }}  {...register("ar_nonreqMedPrepare" + index)} />
                                                <input className={formInputClassName} autoComplete='off' placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }}  {...register("hb_nonreqMedPrepare" + index)} />



                                            </div>
                                        }
                                        )}
                                    </div>
                                    <div className="mb-4">

                                        <div className="flex justify-between">
                                            <label className="block text-gray-500 text-lg mt-3 md:text-left mb-1 md:mb-0 pr-4" htmlFor="method">
                                                General Instructions
                                            </label>
                                            <button type="button" className="bg-transparent hover:bg-transparent text-black font-bold rounded-full flex items-end" onClick={() => addMore(parseInt(addGeneralMoreArray[addGeneralMoreArray.length - 1]) + 1, "general")}>
                                                <svg className="h-6 w-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path strokeLinecap="round" strokeLinejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                                                </svg>
                                                Add More
                                            </button>
                                        </div>
                                        {addGeneralMoreArray.map((val, index) => {
                                            return <div className='border-solid border-2 border-black-600 p-9'>

                                                <div className="grid grid-cols-2 md:grid-cols-3 gap-3">
                                                    <div>
                                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="method">
                                                            Order NO
                                                        </label>
                                                        <input className={formInputClassName} type="number" autoComplete='off' placeholder='Order Number' value={val} id="generalOrderNo"  {...register("generalOrderNo" + index)} />
                                                    </div>
                                                    <div>
                                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="method">
                                                            When To Report
                                                        </label>
                                                        <input className={formInputClassName} autoComplete='off' placeholder='When To Report' type={'number'} id="generalReport" {...register("generalReport" + index)} />
                                                    </div>
                                                    {index !== 0 && <div className='text-center mt-5'>
                                                        <button type="button" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => deleteMore(val, "general")}>Delete Preparation</button>
                                                    </div>}
                                                </div>


                                                <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="method">
                                                    Instruction
                                                </label>
                                                <textarea className={formInputClassName} autoComplete='off' placeholder='English' id="generalIns"  {...register("generalIns" + index)} />
                                                <textarea className={formInputClassName} autoComplete='off' placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} {...register("ar_generalIns" + index)} />
                                                <textarea className={formInputClassName} autoComplete='off' placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }}  {...register("hb_generalIns" + index)} />



                                            </div>
                                        })}
                                    </div>
                                    <div className="mb-4">
                                        <div className="flex justify-between">
                                            <label className="block text-gray-500 text-lg mt-3 md:text-left mb-1 md:mb-0 pr-4" htmlFor="method">
                                                Explanations
                                            </label>
                                            <button type="button" className="bg-transparent hover:bg-transparent text-black font-bold rounded-full flex items-end" onClick={() => addMore(parseInt(addExplainMoreArray[addExplainMoreArray.length - 1]) + 1, "explain")}>
                                                <svg className="h-6 w-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path strokeLinecap="round" strokeLinejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                                                </svg>
                                                Add More
                                            </button>
                                        </div>

                                        {addExplainMoreArray.map((val, index) => {
                                            return <div className='border-solid border-2 border-black-600 p-9'>
                                                <div className="grid grid-cols-2 md:grid-cols-3 gap-3">
                                                    <div>
                                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="method">
                                                            Order NO
                                                        </label>
                                                        <input className={formInputClassName} autoComplete='off' type={'number'} value={val} placeholder='Order Number' id="explainOrderNo"  {...register("explainOrderNo" + index)} />
                                                    </div>
                                                    <div>
                                                        <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="method">
                                                            Media Link
                                                        </label>
                                                        <input className={formInputClassName} autoComplete='off' placeholder='Media Link' id="explainMediaLink" {...register("explainMediaLink" + index)} />
                                                    </div>
                                                    {index !== 0 && <div className='text-center mt-5'>
                                                        <button type="button" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => deleteMore(val, "explain")}>Delete Preparation</button>
                                                    </div>}
                                                </div>


                                                <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="method">
                                                    Instruction
                                                </label>
                                                {/* <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="method" type="text" /> */}
                                                <input className={formInputClassName} autoComplete='off' placeholder='English' id="explainIns" {...register("explainIns" + index)} />
                                                <input className={formInputClassName} autoComplete='off' placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} {...register("ar_explainIns" + index)} />
                                                <input className={formInputClassName} autoComplete='off' placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }}  {...register("hb_explainIns" + index)} />



                                            </div>
                                        })}
                                    </div>
                                    <div id="sticky-bottom" class="mt-8 text-center sticky-bottom">
                                        <div className="flex justify-between items-center mb-4">
                                            <a href='javascript:void(0);' className={buttonCSS.blue} onClick={handleSubmit((data) => saveAndExit(data), (err) => onError(err))}>Save & Exit</a>
                                            <a href='javascript:void(0);' className={buttonCSS.green} onClick={handleSubmit((data) => saveAndNew(data), (err) => onError(err))}>Save & New</a>
                                            <a href='javascript:void(0);' className={buttonCSS.red} onClick={() => discardChanges()}>Discard Changes</a>
                                        </div>
                                    </div>
                                    {protocolId && <PrevAndNextButtons
                                        setRedirectId={setProtocolId}
                                        editOptionData={editOptionData}
                                        selectedEditIndex={selectedEditIndex}
                                        getData={prevAndNextHandler}
                                        reset={reset}
                                        resetObj={{}}
                                        checkIsModified={checkIsModified}
                                        handleSubmit={handleSubmit}
                                        constructData={constructData}
                                        from="protocol"
                                        setSelectedEditIndex={setSelectedEditIndex}
                                    />}
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
