import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAllMedicine } from '../../../Store/Action';
import { DahboardMenu } from '../DahboardMenu';
import { TopHeader } from '../TopHeader';
import MedicineViewTable from './MedicineViewTable';

const MedicineView = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const store = useSelector(state => state);
    const { allMedicineStore } = store;

    useEffect(() => {
        if (Object.keys(allMedicineStore).length === 0) {
            getData();
        }
    }, [allMedicineStore])

    const getData = async () => {
        const reqObj = {
            "getLatestFirst": true,
            "pageNumber": 1,
            "numberOfRecordsPerPage": 0
        }
        dispatch(await getAllMedicine(reqObj));
    }
    return (
        <React.Fragment>

            <TopHeader />
            <DahboardMenu />
            <div className="mt-10 h-screen" id="page-content" style={{ transition: "all 0.3s linear 0s", paddingLeft: "0px", marginRight: "0px", marginTop: "3.5rem" }}>
                <div className="
  max-w-[540px] sm:max-w-[604px] md:max-w-[720px] lg:max-w-[972px]
  xl:max-w-full xl:px-12 2xl:max-w-[1400px] mx-auto
 flex">
                    <div className="flex w-full flex-col ">
                        <MedicineViewTable allMedicineData={allMedicineStore} />
                        {/* <div id="exampleWrapper" className="">

                            <div id="controls-carousel" className="relative w-full" data-carousel="static">
                                <div className="relative h-56 overflow-hidden rounded-lg md:h-96">
                                    <div className="hidden duration-700 ease-in-out" data-carousel-item>

                                        <MedicineViewTable allMedicineData={allMedicineStore} />

                                    </div>
                                    {<div className="hidden duration-700 ease-in-out" data-carousel-item>
                                        <img src="https://2.img-dpreview.com/files/p/E~C1000x0S4000x4000T1200x1200~articles/3925134721/0266554465.jpeg" className="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="..." />
                                    </div>
                                    <div className="hidden duration-700 ease-in-out" data-carousel-item>
                                        <img src="https://fujifilm-x.com/wp-content/uploads/2021/01/gfx100s_sample_04_thum-1.jpg" className="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="..." />
                                    </div>
                                    <div className="hidden duration-700 ease-in-out" data-carousel-item="active">
                                        <img src="https://nikonrumors.com/wp-content/uploads/2014/03/Nikon-1-V3-sample-photo.jpg" alt="..." />
                                    </div> 
                                </div>
                                <button type="button" className="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-prev>
                                    <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-black/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                                        <svg className="w-4 h-4 text-white dark:text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4" />
                                        </svg>
                                        <span className="sr-only">Previous</span>
                                    </span>
                                </button>
                                <button type="button" className="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-next>
                                    <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-black/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                                        <svg className="w-4 h-4 text-white dark:text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                                        </svg>
                                        <span className="sr-only">Next</span>
                                    </span>
                                </button>
                            </div>

                        </div> */}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export { MedicineView }