import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { testIncreament } from '../../Store/Action';
import { increment, decrement } from '../../Store/Reducer';


const Redux = () => {
    const count = useSelector(state => state);
    const dispatch = useDispatch();
    const { value } = count;
    return (
        <div><input type='text' />
            <label>{value}</label>
            <br></br>
            <button onClick={() => dispatch(testIncreament("12235"))}>clickme increment</button>
            <br></br>
            <button onClick={() => dispatch(decrement())}>clickme decrement</button>
        </div>
    )
}

export default Redux