import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { createTreatmentAction, createTreatmentFromtable, getUpdateTreatmentIdFromtable } from '../../../Store/Action';
import { setEditOptionData, setEditOptionTreatmentData, setMedicineDataById } from '../../../Store/Reducer';
import DeleteItemPopup from '../../Popups/DeleteItemPopup';
import { setCookie } from '../../../util/helper';
import { buttonCSS } from '../../../util/contant';

const MedicineTable = (props) => {
    const { medidata, medicineData, selectedData, setSelectedData, tratmentLength, setProcessData, setIsAddTreatment, isAddTreatment, dubsearchWord } = props;
    const store = useSelector(state => state);
    const { availableLanguages } = store;
    const navigate = useNavigate();
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [deletePopupData, setDeletePopupData] = useState([]);
    const dispatch = useDispatch();
    const editDataIds = [];

    const [treatmentData, setTreatmentData] = useState([])
    const [orginalData, setOriginalData] = useState([])
    const [isRefresh, setIsRefresh] = useState(false);
    const [dayFilter, setDayFilter] = useState(false)
    const [doseFilter, setDoseFilter] = useState(false);
    const [deleteIndex, setDeleteIntex] = useState(0);
    const [isfirstTime, setIsFirstTime] = useState(false);
    const [isFrom, setIsFrom] = useState("treatment");

    // const [selectedData, setSelectedData] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const { register, formState: { errors }, handleSubmit, reset, setValue, control, clearErrors } = useForm();
    let sortingArr = ['Morning', 'Noon', 'Evening', 'Night'];

    const FilterbyTitleOrder = (a, b) => {
        return a.dayCount - b.dayCount || sortingArr.indexOf(a["doseTimes"]) - sortingArr.indexOf(b["doseTimes"]);
    }

    useEffect(() => {
        if (isAddTreatment || treatmentData.length === 0) {
            clearErrors();
            if (selectedData.length > 1) {
                // Use splice to remove all elements except the last one
                selectedData.splice(0, selectedData.length - 1);
            }
            const filterData = treatmentData.length === 0 ? medidata.treatments.slice().sort(FilterbyTitleOrder) : treatmentData.slice().sort(FilterbyTitleOrder);


            setOriginalData([...filterData, ...selectedData])
            setTreatmentData([...filterData, ...selectedData]);
            setIsAddTreatment(false)
        }

    }, [isAddTreatment])


    const selectMedicine = (id, treatments, medicineId) => {
        treatments.map((val) => {
            editDataIds.push(val._id)
        })
        dispatch(setEditOptionTreatmentData(editDataIds));
        dispatch(setMedicineDataById({}))
        navigate('/dashboard/treatments?id=' + id, { state: { id: medicineId, name: 'sabaoon' } })
        //window.location.href = '/dashboard/protocols?id=' + id
    }

    const deleteData = async (reqObj) => {
        const response = await getUpdateTreatmentIdFromtable(reqObj, dispatch);
        if (response.status === 200) {
            treatmentData.splice(deleteIndex, 1)
            setTreatmentData(treatmentData)
            setDeletePopupData([])
            setDeleteIntex(0);
            toast.success("Treatment deleted successfully")
        } else {
            toast.error("Treatment deleted failed")
        }

    }

    const deletePopupOpen = (data, index) => {
        setIsFrom("treatment");
        setDeleteIntex(index);
        setDeletePopupData(data)
        setOpenDeletePopup(true);
    }

    const closeDeletePopup = () => {
        setOpenDeletePopup(false);
    }

    const closePopup = () => {
        setOpenDeletePopup(false)
    }

    const deleteMedicine = (data) => {
        setIsFrom("medicine");
        setDeletePopupData(data);
        setOpenDeletePopup(true)
    }

    const selectMedi = (id) => {
        const editDataIds = [];
        medicineData.map((val) => {
            editDataIds.push(val._id)
        })
        setCookie("selectedMedicine", id)
        setCookie("fromMedicine", id)
        if (dubsearchWord) {
            setCookie("searchWord", dubsearchWord);
        }
        dispatch(setEditOptionData(editDataIds))
        dispatch(setMedicineDataById({}))
        navigate('/dashboard/medicine?id=' + id, { state: { id: id } })
    }

    const duplicateData = (val, index) => {
        setSelectedIndex(index)
        const exisitData = JSON.parse(JSON.stringify(selectedData));
        exisitData.push(val)
        setSelectedData(exisitData)

        const insertData = JSON.parse(JSON.stringify(val));
        //const insertData = val;
        insertData.new = true;
        insertData.editIndex = exisitData.length - 1;
        const data = JSON.parse(JSON.stringify(treatmentData));

        //const data = treatmentData;
        //data.splice(index + 1, 0, insertData);
        data.push(insertData);
        setProcessData(data)
        setTreatmentData(data)
        setIsRefresh(!isRefresh)
    }

    const delteItem = (index) => {

        const data = JSON.parse(JSON.stringify(treatmentData));
        data.splice(index, 1); // 2nd parameter means remove one item only

        const exisitData = JSON.parse(JSON.stringify(selectedData));
        exisitData.pop();
        setSelectedData(exisitData);
        setTimeout(() => {
            setTreatmentData(data)
            setProcessData(data)
        }, 500)


        setIsRefresh(!isRefresh)

    }

    const constructData = async (data, index, id) => {
        const reqObj = {};
        reqObj.medicine = id;
        // reqObj.medicalCondition = data.treatmedicineCondition ? data.treatmedicineCondition : "No Condition";
        reqObj.dayCount = data["dayCount" + index];
        reqObj.dosePerDay = data["dpd" + index];
        reqObj.mediaToLink = data["linkMedia" + index];
        // reqObj.comments = data.comments;
        reqObj.doseTimes = data["doseTimes" + index];
        reqObj.dosePerTime = data["dosePerTime" + index];
        //reqObj.treatmentDuration = data.treatDuration;
        // reqObj.comments = data.comments;

        reqObj.treatmentDuration = [
            { language: availableLanguages.languageList.find(o => o.name === 'English')._id, value: data['treatDuration' + index] },
            { language: availableLanguages.languageList.find(o => o.name === 'Arabic')._id, value: data['ar_treatDuration' + index] },
            { language: availableLanguages.languageList.find(o => o.name === 'Hebrew')._id, value: data["hb_treatDuration" + index] }
        ];
        reqObj.comments = [
            { language: availableLanguages.languageList.find(o => o.name === 'English')._id, value: data["comments" + index] },
            { language: availableLanguages.languageList.find(o => o.name === 'Arabic')._id, value: data['ar_comments' + index] },
            { language: availableLanguages.languageList.find(o => o.name === 'Hebrew')._id, value: data['hb_comments' + index] }
        ];

        reqObj.medicalCondition = [
            { language: availableLanguages.languageList.find(o => o.name === 'English')._id, value: data['treatmedicineCondition' + index] },
            { language: availableLanguages.languageList.find(o => o.name === 'Arabic')._id, value: data['ar_treatmedicineCondition' + index] },
            { language: availableLanguages.languageList.find(o => o.name === 'Hebrew')._id, value: data['hb_treatmedicineCondition' + index] }
        ];

        reqObj.doseUnit = [
            { language: availableLanguages.languageList.find(o => o.name === 'English')._id, value: data['doseUnit' + index] },
            { language: availableLanguages.languageList.find(o => o.name === 'Arabic')._id, value: data['ar_doseUnit' + index] },
            { language: availableLanguages.languageList.find(o => o.name === 'Hebrew')._id, value: data['hb_doseUnit' + index] }
        ];

        reqObj.instructionsForMedicine = [
            { language: availableLanguages.languageList.find(o => o.name === 'English')._id, value: data['mediIns' + index] },
            { language: availableLanguages.languageList.find(o => o.name === 'Arabic')._id, value: data['ar_mediIns' + index] },
            { language: availableLanguages.languageList.find(o => o.name === 'Hebrew')._id, value: data['hb_mediIns' + index] }
        ];
        reqObj.explanation = [
            { language: availableLanguages.languageList.find(o => o.name === 'English')._id, value: data['explanation' + index] },
            { language: availableLanguages.languageList.find(o => o.name === 'Arabic')._id, value: data['ar_explanation' + index] },
            { language: availableLanguages.languageList.find(o => o.name === 'Hebrew')._id, value: data['hb_explanation' + index] }
        ]
        reqObj.routeOfAdministration = [
            { language: availableLanguages.languageList.find(o => o.name === 'English')._id, value: data['routeOfAdminEN' + index] },
            { language: availableLanguages.languageList.find(o => o.name === 'Arabic')._id, value: data['routeOfAdminAR' + index] },
            { language: availableLanguages.languageList.find(o => o.name === 'Hebrew')._id, value: data['routeOfAdminHB' + index] }
        ]
        const check = await createTreatmentFromtable(reqObj, dispatch);
        return check;
    }

    const submitHandler = async (data, id) => {

        const testArray = [];
        for (let i = 0; i < selectedData.length; i++) {
            const check = treatmentData.findIndex((val) => val.new === true);
            // const newIndex = tratmentLength + i
            const newIndex = check
            const callRes = await constructData(data, newIndex, id);
            if (Object.keys(callRes).length !== 0) {
                testArray.push(callRes)
                const exisitData = treatmentData;
                exisitData[(treatmentData.length - 1) + 1] = callRes
                exisitData.splice(newIndex, 1);
                sortByDoseTimes(exisitData);
            }


        }
        setSelectedData([])
    }


    const sortByDoseTimes = (data) => {
        const FilterbyTitleOrder = (a, b) => {
            return a.dayCount - b.dayCount || sortingArr.indexOf(a["doseTimes"]) - sortingArr.indexOf(b["doseTimes"]);
        }
        const filterData = data.slice().sort(FilterbyTitleOrder);
        setTreatmentData(filterData)
        setOriginalData(filterData)
        setIsFirstTime(true)
    }

    const filterData = (val, name) => {
        let check = val
        if (name === 'dayCount') {
            setDayFilter(!check);
        } else {
            setDoseFilter(!check)
        }
        setSelectedData([])


        let filterData = [];

        if (name !== 'doseTimes') {
            if (check) {
                const FilterbyTitleOrder = (a, b) => {
                    if (a[name] && b[name]) {

                        if (a[name] < b[name]) {
                            return -1;
                        }
                        if (a[name] > b[name]) {
                            return 1;
                        }
                    }
                    return 0;
                }
                filterData = orginalData.slice().sort(FilterbyTitleOrder);
            } else {
                const FilterbyTitleOrder = (a, b) => {
                    if (a[name] && b[name]) {

                        if (a[name] > b[name]) {
                            return -1;
                        }
                        if (a[name] < b[name]) {
                            return 1;
                        }
                    }
                    return 0;
                }
                filterData = orginalData.slice().sort(FilterbyTitleOrder);
            }
        } else {
            if (check) {
                const FilterbyTitleOrder = (a, b) => {
                    if (a[name] && b[name]) {

                        if (sortingArr.indexOf(a[name]) < sortingArr.indexOf(b[name])) {
                            return -1;
                        }
                        if (sortingArr.indexOf(a[name]) > sortingArr.indexOf(b[name])) {
                            return 1;
                        }
                    }
                    return 0;
                }
                filterData = orginalData.slice().sort(FilterbyTitleOrder);

            } else {
                const FilterbyTitleOrder = (a, b) => {
                    if (a[name] || b[name]) {
                        if (sortingArr.indexOf(a[name]) > sortingArr.indexOf(b[name])) {
                            return -1;
                        }
                        if (sortingArr.indexOf(a[name]) < sortingArr.indexOf(b[name])) {
                            return 1;
                        }
                    }
                    return 0;
                }
                filterData = orginalData.slice().sort(FilterbyTitleOrder);
            }

        }



        //setOriginalData(filterData)
        setTreatmentData(filterData);
        setIsRefresh(!isRefresh)
    }



    return (
        <div className='overflow-auto p-2'>
            {openDeletePopup && <DeleteItemPopup closeModal={closeDeletePopup} modalIsOpen={openDeletePopup} isFrom={isFrom} data={deletePopupData} deleteData={deleteData} />}
            {(medidata.showMedicine && !medidata.showTreatment) && <table className="table-auto w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-blue-300 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            Time&nbsp;of&nbsp;Treatment
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Instructions
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Instructions&nbsp;Before&nbsp;Medicine
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Instructions&nbsp;During&nbsp;Medicine
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Instructions&nbsp;After&nbsp;Medicine
                        </th>
                        <th scope="col" className="px-6 py-3">
                            General&nbsp;Explanation
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Supportive&nbsp;Care
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Side&nbsp;Effects
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Link&nbsp;To&nbsp;Media
                        </th>
                        {/* <th scope="col" className="px-6 py-3">
                            Action
                        </th> */}

                    </tr>
                </thead>
                <tbody>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <td className="px-6 py-4 font-medium text-gray-900 dark:text-white" style={{ width: "300px" }}>
                            <p style={{ width: "inherit" }}>{medidata.timeOfTreatment.length !== 0 ? medidata.timeOfTreatment[0].value : ""}</p>
                        </td>
                        <td className="px-6 py-4 font-medium text-gray-900 dark:text-white" style={{ width: "300px" }}>
                            <p style={{ width: "inherit" }}>{medidata.instructionsForMedicine[0].value}</p>
                        </td>
                        <td className="px-6 py-4 font-medium text-gray-900 dark:text-white" style={{ width: "300px" }}>
                            <p style={{ width: "inherit" }}>{medidata.treatmentAndInstructions.beforeMedicine[0].value + "\n"}</p>
                        </td>
                        <td className="px-6 py-4 font-medium text-gray-900 dark:text-white" style={{ width: "300px" }}>
                            <p style={{ width: "inherit" }}>{medidata.treatmentAndInstructions.duringMedicine[0].value + "\n"}</p>
                        </td>
                        <td className="px-6 py-4" style={{ width: "300px" }}>
                            <p>{medidata.treatmentAndInstructions.afterMedicine[0].value + "\n"}</p>
                        </td>
                        <td className="px-6 py-4 w-96" style={{ width: "300px" }}>
                            <p style={{ width: "inherit" }}>{medidata.generalExplanation[0].value}</p>
                        </td>
                        <td className="px-6 py-4" style={{ width: "300px" }}>
                            <p style={{ width: "inherit" }}>{medidata.supportiveCareOrRequiredFollowUp[0].value}</p>
                        </td>
                        <td className="px-6 py-4" style={{ width: "300px" }}>
                            {medidata.sideEffects.map((val, index) => {
                                return <p style={{ width: "inherit" }}>{val.sideEffectName[0]?.value}</p>
                            })}

                        </td>
                        <td className="px-6 py-4" style={{ width: "300px" }}>
                            <p style={{ width: "inherit" }}>{medidata.mediaToLink}</p>
                        </td>
                        {/* <td className="px-6 py-4">
                            <a href="javascript:void(0);" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => selectMedi(medidata._id)}>Edit</a>&nbsp;/&nbsp;
                            <a href="javascript:void(0);" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => deleteMedicine(medidata)}>Delete</a>
                        </td> */}
                    </tr>

                </tbody>
            </table>}
            <div className="flex">
                {(medidata.showMedicine && !medidata.showTreatment) && <div className='pt-4'>
                    <button type="button" className={buttonCSS.green} onClick={() => selectMedi(medidata._id)}>
                        Edit
                    </button>
                    <button type="button" className={buttonCSS.red} onClick={() => deleteMedicine(medidata)}>
                        Delete
                    </button>
                </div>}
                {medidata.showTreatment && <div className="w-1/2 p-4">
                    <p className="text-3xl text-gray-900 dark:text-white">Medical Treatment for {medidata.medicineName[0].value}</p>
                </div>}
            </div>

            {medidata.showTreatment && <div className="table-wrp block max-h-96"><table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-blue-300 dark:bg-gray-700 dark:text-gray-400 sticky" style={{ top: "-5px" }}>
                    <tr className='text-center'>
                        <th scope="col" className="px-6 py-3">
                            Actions
                        </th>
                        <th scope="col" className="px-6 py-3">
                            <div className='flex items-center'>
                                <p>Day&nbsp;Count</p>
                                <a href='javascript:void(0);' onClick={() => filterData(dayFilter, "dayCount")}>
                                    {dayFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                    </svg> :
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                        </svg>}
                                </a>

                            </div>

                        </th>
                        <th scope="col" className="px-6 py-3">
                            Medicine&nbsp;Condition
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Dose&nbsp;per&nbsp;day
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Dose&nbsp;unit
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Route&nbsp;of&nbsp;Administration
                        </th>
                        <th scope="col" className="px-6 py-3">

                            <div className='flex items-center'>
                                <p>Dose&nbsp;Times</p>
                                <a href='javascript:void(0);' onClick={() => filterData(doseFilter, "doseTimes")}>
                                    {doseFilter ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                    </svg> :
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                        </svg>}
                                </a>

                            </div>
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Recommented&nbsp;Dose Per Time
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Treatment&nbsp;Duration
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Medical&nbsp;Instruction
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Explanation
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Link&nbsp;To&nbsp;Media
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Comments
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Action
                        </th>
                    </tr>
                </thead>
                {treatmentData && treatmentData.length !== 0 ? <tbody className='overflow-y-auto'>
                    {treatmentData.map((val, index) => {

                        if (selectedData.length !== 0 && val?.new) {
                            if (val.dayCount || val.dosePerDay || val.doseTimes) {
                                setValue(`dayCount` + index, val.dayCount ? val.dayCount : "");

                                setValue(`treatmedicineCondition` + index, val.medicalCondition && val.medicalCondition[0] ? val.medicalCondition[0].value : "");
                                setValue(`ar_treatmedicineCondition` + index, val.medicalCondition && val.medicalCondition[1] ? val.medicalCondition[1].value : "");
                                setValue(`hb_treatmedicineCondition` + index, val.medicalCondition && val.medicalCondition[2] ? val.medicalCondition[2].value : "");

                                setValue(`dpd` + index, val.dosePerDay ? val.dosePerDay : "");

                                setValue(`doseUnit` + index, val.doseUnit && val.doseUnit[0] ? val.doseUnit[0].value : "");
                                setValue(`ar_doseUnit` + index, val.doseUnit && val.doseUnit[1] ? val.doseUnit[1].value : "");
                                setValue(`hb_doseUnit` + index, val.doseUnit && val.doseUnit[2] ? val.doseUnit[2].value : "");

                                setValue(`routeOfAdminEN` + index, val.routeOfAdministration && val.routeOfAdministration[0] ? val.routeOfAdministration[0].value : "");
                                setValue(`routeOfAdminAR` + index, val.routeOfAdministration && val.routeOfAdministration[1] ? val.routeOfAdministration[1].value : "");
                                setValue(`routeOfAdminHB` + index, val.routeOfAdministration && val.routeOfAdministration[2] ? val.routeOfAdministration[2].value : "");

                                setValue(`doseTimes` + index, val.doseTimes ? val.doseTimes : "");
                                setValue(`dosePerTime` + index, val.dosePerTime ? val.dosePerTime : "");
                                setValue(`treatDuration` + index, val.treatmentDuration && val.treatmentDuration[0] ? val.treatmentDuration[0].value : "");

                                setValue(`ar_treatDuration` + index, val.treatmentDuration && val.treatmentDuration[1] ? val.treatmentDuration[1].value : "");
                                setValue(`hb_treatDuration` + index, val.treatmentDuration && val.treatmentDuration[2] ? val.treatmentDuration[2].value : "");

                                setValue(`mediIns` + index, val.instructionsForMedicine && val.instructionsForMedicine[0] ? val.instructionsForMedicine[0].value : "");
                                setValue(`ar_mediIns` + index, val.instructionsForMedicine && val.instructionsForMedicine[1] ? val.instructionsForMedicine[1].value : "");
                                setValue(`hb_mediIns` + index, val.instructionsForMedicine && val.instructionsForMedicine[2] ? val.instructionsForMedicine[2].value : "");

                                setValue(`explanation` + index, val.explanation && val.explanation[0] ? val.explanation[0].value : "");
                                setValue(`ar_explanation` + index, val.explanation && val.explanation[1] ? val.explanation[1].value : "");
                                setValue(`hb_explanation` + index, val.explanation && val.explanation[2] ? val.explanation[2].value : "");

                                setValue(`linkMedia` + index, val.mediaToLink ? val.mediaToLink : "");
                                setValue(`comments` + index, val.comments && val.comments[0] ? val.comments[0].value : "");
                                setValue(`ar_comments` + index, val.comments && val.comments[1] ? val.comments[1].value : "");
                                setValue(`hb_comments` + index, val.comments && val.comments[2] ? val.comments[2].value : "");
                            }

                            return <tr className="bg-blue-200 border-b dark:bg-gray-800 dark:border-gray-700 even:bg-gray-300 odd:bg-white">

                                <td className="px-2 py-1">
                                    <a href="javascript:void(0);" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => delteItem(index)}>Delete</a>
                                </td>
                                <td className="px-2 py-1 relative">
                                    {val.dayCount === "" && <a href='/dashboard/treatments'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 absolute top-0 left-0">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607zM10.5 7.5v6m3-3h-6" />
                                        </svg></a>}

                                    {errors[`dayCount` + index] && <span className='mb-24 errSpan'>Required</span>}
                                    <Controller
                                        name={`dayCount` + index}
                                        defaultValue={val.dayCount ? val.dayCount : ""}
                                        rules={{ required: true }}
                                        control={control}
                                        render={({ field: { onChange, ref } }) => <input autoFocus="true" type={"number"} ref={ref} onChange={(e) => onChange(e.target.value)} className='mb-24 h-7 border border-black' autoComplete='off' id="dayCount" defaultValue={val.dayCount ? val.dayCount : ""} />}
                                    />


                                </td>
                                <td className="px-2 py-1">
                                    <tr className='border-spacing-0'>
                                        <Controller
                                            name={`treatmedicineCondition` + index}
                                            control={control}
                                            defaultValue={val.medicalCondition && val.medicalCondition[0] ? val.medicalCondition[0].value : ""}
                                            render={({ field: { onChange, ref } }) => <input ref={ref} onChange={e => onChange(e.target.value)} className='h-7 border border-black' style={{ fontSize: "120%" }} autoComplete='off' placeholder='English' id="treatmedicineCondition" defaultValue={val.medicalCondition && val.medicalCondition[0] ? val.medicalCondition[0].value : ""} />}
                                        />
                                    </tr>
                                    <br></br>
                                    <tr>
                                        <Controller
                                            name={`ar_treatmedicineCondition` + index}
                                            control={control}
                                            defaultValue={val.medicalCondition && val.medicalCondition[1] ? val.medicalCondition[1].value : ""}
                                            render={({ field: { onChange, ref } }) => <input ref={ref} onChange={e => onChange(e.target.value)} className='h-7 border border-black' autoComplete='off' placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} name="ar_treatmedicineCondition" id="ar_treatmedicineCondition" defaultValue={val.medicalCondition && val.medicalCondition[1] ? val.medicalCondition[1].value : ""} />}
                                        />


                                    </tr>
                                    <br></br>
                                    <tr>
                                        <Controller
                                            name={`hb_treatmedicineCondition` + index}
                                            control={control}
                                            defaultValue={val.medicalCondition && val.medicalCondition[2] ? val.medicalCondition[2].value : ""}
                                            render={({ field: { onChange, ref } }) => <input ref={ref} onChange={e => onChange(e.target.value)} className='h-7 border border-black' autoComplete='off' placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }} name="hb_treatmedicineCondition" id="hb_treatmedicineCondition" defaultValue={val.medicalCondition && val.medicalCondition[2] ? val.medicalCondition[2].value : ""} />}
                                        />
                                    </tr>
                                </td>
                                <td className="px-2 py-1">
                                    {errors[`dpd` + index] && <span className='mb-24 errSpan'>Required</span>}
                                    <Controller
                                        name={`dpd` + index}
                                        control={control}
                                        rules={{ required: true }}
                                        defaultValue={val.dosePerDay ? val.dosePerDay : ""}
                                        render={({ field: { onChange, ref } }) => <input ref={ref} onChange={e => onChange(e.target.value)} className='h-7  mb-24 border border-black' type="number" autoComplete='off' placeholder='Dose per day' id="dpd" defaultValue={val.dosePerDay ? val.dosePerDay : ""} />}
                                    />
                                </td>
                                <td className="px-2 py-1">
                                    <tr>
                                        {errors[`doseUnit` + index] && <span className='mb-24 errSpan'>Required</span>}
                                        <Controller
                                            control={control}
                                            name={`doseUnit` + index}
                                            rules={{ required: true }}
                                            defaultValue={val.doseUnit && val.doseUnit[0] ? val.doseUnit[0].value : ""}
                                            render={({ field: { onChange, ref } }) => <input ref={ref} onChange={e => onChange(e.target.value)} style={{ fontSize: "120%" }} className='h-7  border border-black' type="text" autoComplete='off' placeholder='English' id="doseUnit" defaultValue={val.doseUnit && val.doseUnit[0] ? val.doseUnit[0].value : ""} />}
                                        />
                                    </tr>
                                    <br></br>
                                    <tr>
                                        {errors[`ar_doseUnit` + index] && <span className='mb-24 errSpan'>Required</span>}
                                        <Controller
                                            name={`ar_doseUnit` + index}
                                            control={control}
                                            rules={{ required: true }}
                                            defaultValue={val.doseUnit && val.doseUnit[1] ? val.doseUnit[1].value : ""}
                                            render={({ field: { onChange, ref } }) => <input ref={ref} onChange={e => onChange(e.target.value)} className='h-7 border border-black' autoComplete='off' placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} name="ar_doseUnit" id="ar_doseUnit" defaultValue={val.doseUnit && val.doseUnit[1] ? val.doseUnit[1].value : ""} />}
                                        />

                                    </tr>
                                    <br></br>
                                    <tr>
                                        {errors[`hb_doseUnit` + index] && <span className='mb-24 errSpan'>Required</span>}
                                        <Controller
                                            name={`hb_doseUnit` + index}
                                            control={control}
                                            rules={{ required: true }}
                                            defaultValue={val.doseUnit && val.doseUnit[2] ? val.doseUnit[2].value : ""}
                                            render={({ field: { onChange, ref } }) => <input ref={ref} onChange={e => onChange(e.target.value)} className='h-7  border border-black' autoComplete='off' placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }} name="hb_doseUnit" id="hb_doseUnit" defaultValue={val.doseUnit && val.doseUnit[2] ? val.doseUnit[2].value : ""} />}
                                        />
                                    </tr>
                                </td>
                                <td className="px-2 py-1">
                                    <tr>
                                        <Controller
                                            name={`routeOfAdminEN` + index}
                                            control={control}
                                            defaultValue={val.routeOfAdministration && val.routeOfAdministration[0] ? val.routeOfAdministration[0].value : ""}
                                            render={({ field: { onChange, ref } }) => <select ref={ref} onChange={e => onChange(e.target.value)} data-te-select-init className="bg-gray-200 border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="routeOfAdminEN" name="routeOfAdminEN" defaultValue={val.routeOfAdministration && val.routeOfAdministration[0] ? val.routeOfAdministration[0].value : ""} >
                                                <option value="">Choose admin</option>
                                                <option value="IV - Central vein - Infusion">IV - Central vein - Infusion</option>
                                                <option value="PO - Oral pill/Syrup">PO - Oral pill/Syrup</option>
                                                <option value="LP - Injection to liquefy the spinal cord">LP - Injection to liquefy the spinal cord</option>
                                                <option value="SC - Subcutaneous injection">SC - Subcutaneous injection</option>
                                                <option value="LB - Leaking from a bag">LB - Leaking from a bag</option>
                                            </select>}
                                        />

                                    </tr>
                                    <br></br>
                                    <tr>

                                        <Controller
                                            name={`routeOfAdminAR` + index}
                                            control={control}
                                            defaultValue={val.routeOfAdministration && val.routeOfAdministration[1] ? val.routeOfAdministration[1].value : ""}
                                            render={({ field: { onChange, ref } }) => <select ref={ref} onChange={e => onChange(e.target.value)} data-te-select-init className="bg-gray-200 border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="routeOfAdminAR" name="routeOfAdminAR" defaultValue={val.routeOfAdministration && val.routeOfAdministration[1] ? val.routeOfAdministration[1].value : ""}>
                                                <option value="">اختر المشرف</option>
                                                <option value="IV - الوريد المركزي - التسريب">IV - الوريد المركزي - التسريب</option>
                                                <option value="PO - حبوب منع الحمل عن طريق الفم / شراب">PO - حبوب منع الحمل عن طريق الفم / شراب</option>
                                                <option value="LP - حقنة لتسييل الحبل الشوكي">LP - حقنة لتسييل الحبل الشوكي
                                                </option>
                                                <option value="SC - الحقن تحت الجلد">SC - الحقن تحت الجلد
                                                </option>
                                                <option value="SC - تسرب من الحقيبة">LB - تسرب من الحقيبة</option>
                                            </select>}
                                        />


                                    </tr>
                                    <br></br>
                                    <tr>
                                        <Controller
                                            name={`routeOfAdminHB` + index}
                                            control={control}
                                            defaultValue={val.routeOfAdministration && val.routeOfAdministration[2] ? val.routeOfAdministration[2].value : ""}
                                            render={({ field: { onChange, ref } }) => <select ref={ref} onChange={e => onChange(e.target.value)} data-te-select-init className="bg-gray-200 border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="routeOfAdminHB" name="routeOfAdminHB" defaultValue={val.routeOfAdministration && val.routeOfAdministration[2] ? val.routeOfAdministration[2].value : ""}>
                                                <option value="">בחר מנהל</option>
                                                <option value="IV - צנטר וריד - הזלפה">IV - צנטר וריד - הזלפה</option>
                                                <option value="PO - כדור/סירופ לפה">PO - כדור/סירופ לפה</option>
                                                <option value="LP - בזריקה לנזול חוט השדרה">LP - בזריקה לנזול חוט השדרה</option>
                                                <option value="SC - הזרקה תת עורית">SC - הזרקה תת עורית</option>
                                                <option value="SC - הדלפה משקית">LB - הדלפה משקית</option>
                                            </select>}
                                        />
                                    </tr>
                                </td>
                                <td className="px-2 py-1">

                                    <Controller
                                        name={`doseTimes` + index}
                                        control={control}
                                        defaultValue={val.doseTimes ? val.doseTimes : ""}
                                        render={({ field: { onChange, ref } }) => <select ref={ref} onChange={e => onChange(e.target.value)} data-te-select-init className="bg-gray-200 border-2 mb-24 w-full border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="doseTimes" defaultValue={val.doseTimes ? val.doseTimes : ""}>
                                            <option value="">Choose one</option>
                                            <option value="Morning">Morning</option>
                                            <option value="Noon">Noon</option>
                                            <option value="Evening">Evening</option>
                                            <option value="Night">Night</option>
                                        </select>}
                                    />


                                </td>
                                <td className="px-2 py-1">
                                    {errors[`dosePerTime` + index] && <span className='mb-24 errSpan'>Required</span>}
                                    <Controller
                                        name={`dosePerTime` + index}
                                        control={control}
                                        rules={{ required: true }}
                                        defaultValue={val.dosePerTime ? val.dosePerTime : ""}
                                        render={({ field: { onChange, ref } }) => <input ref={ref} onChange={e => onChange(e.target.value)} className='h-7 mb-24 border border-black' type="number" autoComplete='off' placeholder='Dose per time' id="dosePerTime" defaultValue={val.dosePerTime ? val.dosePerTime : ""} />}
                                    />


                                </td>
                                <td className="px-2 py-1">
                                    <tr>
                                        <Controller
                                            name={`treatDuration` + index}
                                            control={control}
                                            defaultValue={val.treatmentDuration && val.treatmentDuration[0] ? val.treatmentDuration[0].value : ""}
                                            render={({ field: { onChange, ref } }) => <input ref={ref} onChange={e => onChange(e.target.value)} style={{ fontSize: "120%" }} className='h-7 border border-black' type="text" autoComplete='off' placeholder='English' id="treatDuration" defaultValue={val.treatmentDuration && val.treatmentDuration[0] ? val.treatmentDuration[0].value : ""} />}
                                        />


                                    </tr>
                                    <br></br>
                                    <tr>
                                        <Controller
                                            name={`ar_treatDuration` + index}
                                            control={control}
                                            defaultValue={val.treatmentDuration && val.treatmentDuration[1] ? val.treatmentDuration[1].value : ""}
                                            render={({ field: { onChange, ref } }) => <input ref={ref} onChange={e => onChange(e.target.value)} className='h-7 border border-black' autoComplete='off' placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} name="ar_mediThrough" id="ar_treatDuration" defaultValue={val.treatmentDuration && val.treatmentDuration[1] ? val.treatmentDuration[1].value : ""} />}
                                        />


                                    </tr>
                                    <br></br>
                                    <tr>
                                        <Controller
                                            name={`hb_treatDuration` + index}
                                            control={control}
                                            defaultValue={val.treatmentDuration && val.treatmentDuration[2] ? val.treatmentDuration[2].value : ""}
                                            render={({ field: { onChange, ref } }) => <input ref={ref} onChange={e => onChange(e.target.value)} className='h-7 border border-black' autoComplete='off' placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }} id="hb_treatDuration" defaultValue={val.treatmentDuration && val.treatmentDuration[2] ? val.treatmentDuration[2].value : ""} />}
                                        />

                                    </tr>
                                </td>
                                <td className="px-2 py-1">
                                    <tr>
                                        {errors[`mediIns` + index] && <span className='mb-24 errSpan'>Required</span>}
                                        <Controller
                                            name={`mediIns` + index}
                                            control={control}
                                            defaultValue={val.instructionsForMedicine && val.instructionsForMedicine[0] ? val.instructionsForMedicine[0].value : ""}
                                            render={({ field: { onChange, ref } }) => <textarea ref={ref} onChange={e => onChange(e.target.value)} className='border border-black' autoComplete='off' placeholder='English' id="mediIns" style={{ fontSize: "120%" }} defaultValue={val.instructionsForMedicine && val.instructionsForMedicine[0] ? val.instructionsForMedicine[0].value : ""} />}
                                        />

                                    </tr>
                                    <br></br>
                                    <tr>
                                        {errors[`ar_mediIns` + index] && <span className='mb-24 errSpan'>Required</span>}
                                        <Controller
                                            name={`ar_mediIns` + index}
                                            control={control}
                                            defaultValue={val.instructionsForMedicine && val.instructionsForMedicine[1] ? val.instructionsForMedicine[1].value : ""}
                                            render={({ field: { onChange, ref } }) => <textarea ref={ref} onChange={e => onChange(e.target.value)} className='border border-black' autoComplete='off' placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} name="ar_mediIns" id="ar_mediIns" defaultValue={val.instructionsForMedicine && val.instructionsForMedicine[1] ? val.instructionsForMedicine[1].value : ""} />}
                                        />
                                    </tr>
                                    <br></br>
                                    <tr>
                                        {errors[`hb_mediIns` + index] && <span className='mb-24 errSpan'>Required</span>}
                                        <Controller
                                            name={`hb_mediIns` + index}
                                            control={control}
                                            defaultValue={val.instructionsForMedicine && val.instructionsForMedicine[2] ? val.instructionsForMedicine[2].value : ""}
                                            render={({ field: { onChange, ref } }) => <textarea ref={ref} onChange={e => onChange(e.target.value)} className='border border-black' autoComplete='off' placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }} name="hb_mediIns" id="hb_mediIns" defaultValue={val.instructionsForMedicine && val.instructionsForMedicine[2] ? val.instructionsForMedicine[2].value : ""} />}
                                        />

                                    </tr>
                                </td>
                                <td className="px-2 py-1">
                                    <tr>
                                        <Controller
                                            name={`explanation` + index}
                                            control={control}
                                            defaultValue={val.explanation && val.explanation[0] ? val.explanation[0].value : ""}
                                            render={({ field: { onChange, ref } }) => <textarea ref={ref} onChange={e => onChange(e.target.value)} style={{ fontSize: "120%" }} className='border border-black' placeholder='English' id="explanation" defaultValue={val.explanation && val.explanation[0] ? val.explanation[0].value : ""} />}
                                        />


                                    </tr>
                                    <br></br>
                                    <tr>
                                        <Controller
                                            name={`ar_explanation` + index}
                                            control={control}
                                            defaultValue={val.explanation && val.explanation[1] ? val.explanation[1].value : ""}
                                            render={({ field: { onChange, ref } }) => <textarea ref={ref} onChange={e => onChange(e.target.value)} style={{ direction: "rtl", fontSize: "120%" }} className='border border-black' placeholder='Arabic' id="explanation" defaultValue={val.explanation && val.explanation[1] ? val.explanation[1].value : ""} />}
                                        />


                                    </tr>
                                    <br></br>
                                    <tr>
                                        <Controller
                                            name={`hb_explanation` + index}
                                            control={control}
                                            defaultValue={val.explanation && val.explanation[2] ? val.explanation[2].value : ""}
                                            render={({ field: { onChange, ref } }) => <textarea ref={ref} onChange={e => onChange(e.target.value)} style={{ direction: "rtl", fontSize: "120%" }} className='border border-black' placeholder='Arabic' id="explanation" defaultValue={val.explanation && val.explanation[2] ? val.explanation[2].value : ""} />}
                                        />
                                    </tr>
                                </td>
                                <td className="px-2 py-1">
                                    <Controller
                                        name={`linkMedia` + index}
                                        control={control}
                                        defaultValue={val.mediaToLink ? val.mediaToLink : ""}
                                        render={({ field: { onChange, ref } }) => <input ref={ref} onChange={e => onChange(e.target.value)} className='border h-7 border-black' autoComplete='off' placeholder='English' id="sideTreatment" />}
                                    />


                                </td>
                                <td className="px-2 py-1">
                                    <tr>

                                        <Controller
                                            name={`comments` + index}
                                            control={control}
                                            defaultValue={val.comments && val.comments[0] ? val.comments[0].value : ""}
                                            render={({ field: { onChange, ref } }) => <textarea ref={ref} onChange={e => onChange(e.target.value)} style={{ fontSize: "120%" }} className='border border-black' autoComplete='off' placeholder='English' id="comments" name="comments" defaultValue={val.comments && val.comments[0] ? val.comments[0].value : ""} />}
                                        />


                                    </tr>
                                    <br></br>
                                    <tr>
                                        <Controller
                                            name={`ar_comments` + index}
                                            control={control}
                                            defaultValue={val.comments && val.comments[1] ? val.comments[1].value : ""}
                                            render={({ field: { onChange, ref } }) => <textarea ref={ref} onChange={e => onChange(e.target.value)} style={{ direction: "rtl", fontSize: "120%" }} className='border border-black' autoComplete='off' placeholder='Arabic' id="comments" name="comments" defaultValue={val.comments && val.comments[1] ? val.comments[1].value : ""} />}
                                        />


                                    </tr>
                                    <br></br>
                                    <tr>
                                        <Controller
                                            name={`hb_comments` + index}
                                            control={control}
                                            defaultValue={val.comments && val.comments[2] ? val.comments[2].value : ""}
                                            render={({ field: { onChange, ref } }) => <textarea ref={ref} onChange={e => onChange(e.target.value)} style={{ direction: "rtl", fontSize: "120%" }} className='border border-black' autoComplete='off' placeholder='Hebrew' id="comments" name="comments" defaultValue={val.comments && val.comments[2] ? val.comments[2].value : ""} />}
                                        />


                                    </tr>
                                </td>
                                <td className="px-2 py-1">
                                    <a href="javascript:void(0);" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => delteItem(index)}>Delete</a>
                                </td>
                            </tr>
                        }


                        return (val.dayCount && val.dosePerDay) && <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 even:bg-gray-300 odd:bg-white text-center">
                            <td className="px-2 py-1 text-start">
                                <tr>
                                    <a href="javascript:void(0);" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => selectMedicine(val._id, treatmentData, medidata._id)}>Edit</a> </tr>
                                <tr><a href="javascript:void(0);" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => deletePopupOpen(val, index)}>Delete</a></tr>
                                <tr><a href="javascript:void(0);" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => duplicateData(val, index)}>Duplicate</a></tr>
                            </td>
                            <td className="px-2 py-1">
                                {val.dayCount ? val.dayCount : ""}
                            </td>
                            <td className="px-2 py-1">
                                <div className="divide-y divide-gray-200 border-l border-r border-gray-200">
                                    <div className='p-1'>{val.medicalCondition && val.medicalCondition[0] && val.medicalCondition[0].value ? val.medicalCondition[0].value : "No Condition"}</div>
                                    <div className='p-1' style={{ direction: "rtl" }}>{val.medicalCondition && val.medicalCondition[1] && val.medicalCondition[1].value ? val.medicalCondition[1].value : "No Condition"}</div>
                                    <div className='p-1' style={{ direction: "rtl" }}>{val.medicalCondition && val.medicalCondition[2] && val.medicalCondition[2].value ? val.medicalCondition[2].value : "No Condition"}</div>
                                </div>
                            </td>
                            <td className="px-2 py-1">
                                {val.dosePerDay ? val.dosePerDay : ""}

                            </td>
                            <td className="px-2 py-1 w-96">
                                <div className="divide-y divide-gray-200 border-l border-r border-gray-200 w-72">
                                    <div className='p-1'>{val.doseUnit && val.doseUnit[0] ? val.doseUnit[0].value : ""}</div>
                                    <div className='p-1' style={{ direction: "rtl" }}>{val.doseUnit && val.doseUnit[1] ? val.doseUnit[1].value : ""}</div>
                                    <div className='p-1' style={{ direction: "rtl" }}>{val.doseUnit && val.doseUnit[2] ? val.doseUnit[2].value : ""}</div>
                                </div>
                            </td>
                            <td className="px-2 py-1">
                                <div className="divide-y divide-gray-200 border-l border-r border-gray-200">
                                    <div className='p-1'>{val.routeOfAdministration && val.routeOfAdministration[0] ? val.routeOfAdministration[0].value : ""}</div>
                                    <div className='p-1' style={{ direction: "rtl" }}>{val.routeOfAdministration && val.routeOfAdministration[1] ? val.routeOfAdministration[1].value : ""}</div>
                                    <div className='p-1' style={{ direction: "rtl" }}>{val.routeOfAdministration && val.routeOfAdministration[2] ? val.routeOfAdministration[2].value : ""}</div>
                                </div>
                            </td>
                            <td className="px-2 py-1">
                                {val.doseTimes ? val.doseTimes : ""}
                            </td>
                            <td className="px-2 py-1">
                                {val.dosePerTime ? val.dosePerTime : ""}
                            </td>
                            <td className="px-2 py-1">
                                <div className="divide-y divide-gray-200 border-l border-r border-gray-200">
                                    <div className='p-1'>{val.treatmentDuration && val.treatmentDuration[0] ? val.treatmentDuration[0].value : ""}</div>
                                    <div className='p-1' style={{ direction: "rtl" }}>{val.treatmentDuration && val.treatmentDuration[1] ? val.treatmentDuration[1].value : ""}</div>
                                    <div className='p-1' style={{ direction: "rtl" }}>{val.treatmentDuration && val.treatmentDuration[2] ? val.treatmentDuration[2].value : ""}</div>
                                </div>
                            </td>
                            <td className="px-2 py-1">
                                <div className="divide-y divide-gray-200 border-l border-r border-gray-200 w-72">
                                    <div className='p-1'>{val.instructionsForMedicine && val.instructionsForMedicine[0] ? val.instructionsForMedicine[0].value : ""}</div>
                                    <div className='p-1' style={{ direction: "rtl" }}>{val.instructionsForMedicine && val.instructionsForMedicine[1] ? val.instructionsForMedicine[1].value : ""}</div>
                                    <div className='p-1' style={{ direction: "rtl" }}>{val.instructionsForMedicine && val.instructionsForMedicine[2] ? val.instructionsForMedicine[2].value : ""}</div>
                                </div>
                            </td>
                            <td className="px-2 py-1">
                                <div className="divide-y divide-gray-200 border-l border-r border-gray-200 w-96">
                                    <div className='p-1'>{val.explanation && val.explanation[0] ? val.explanation[0].value : ""}</div>
                                    <div className='p-1' style={{ direction: "rtl" }}>{val.explanation && val.explanation[1] ? val.explanation[1].value : ""}</div>
                                    <div className='p-1' style={{ direction: "rtl" }}>{val.explanation && val.explanation[2] ? val.explanation[2].value : ""}</div>
                                </div>
                            </td>
                            <td className="px-2 py-1">
                                {val.mediaToLink ? val.mediaToLink : ""}
                            </td>
                            <td className="px-2 py-1">
                                <div className="divide-y divide-gray-200 border-l border-r border-gray-200">
                                    <div className='p-1'>{val.comments && val.comments[0] ? val.comments[0].value : ""}</div>
                                    <div className='p-1' style={{ direction: "rtl" }}>{val.comments && val.comments[1] ? val.comments[1].value : ""}</div>
                                    <div className='p-1' style={{ direction: "rtl" }}>{val.comments && val.comments[2] ? val.comments[2].value : ""}</div>
                                </div>
                            </td>
                            <td className="px-2 py-1 text-start">
                                <tr>
                                    <a href="javascript:void(0);" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => selectMedicine(val._id, treatmentData)}>Edit</a> </tr>
                                <tr><a href="javascript:void(0);" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => deletePopupOpen(val, index)}>Delete</a></tr>
                                <tr><a href="javascript:void(0);" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => duplicateData(val, index)}>Duplicate</a></tr>
                            </td>
                        </tr>
                    })}
                </tbody> :
                    <div className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <div className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            No data found
                        </div>
                    </div>
                }
            </table>
                {(selectedData && selectedData.length !== 0) && <div className="flex mt-4">
                    <div className="w-1/2">
                        <button onClick={handleSubmit((data) => submitHandler(data, medidata._id))} disabled={Object.keys(errors).length !== 0} type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" >Save</button>
                    </div>
                </div>}
            </div>}



        </div>
    )
}

export default MedicineTable