import React from 'react'
import { DahboardMenu } from './DahboardMenu'
import { TopHeader } from './TopHeader'
import { getCookie } from '../../util/helper'

export const Home = () => {
    return (
        <React.Fragment>
            <TopHeader />
            <DahboardMenu />
            <div className="mt-10" id="page-content" style={{ transition: "all 0.3s linear 0s", paddingLeft: "0px", marginRight: "0px", marginTop: "3.5rem" }}>
                <div className="
      max-w-[540px] sm:max-w-[604px] md:max-w-[720px] lg:max-w-[972px]
      xl:max-w-full xl:px-12 2xl:max-w-[1400px] mx-auto
     flex">
                    <div className="flex w-full flex-col pb-20">
                        {getCookie('adminName') ? <h1 className='text-5xl font-bold pb-2'>Hi! <span className='text-blue-900'>{getCookie('adminName').charAt(0).toUpperCase() + getCookie('adminName').slice(1)}</span> Welcome to TaDam</h1> :
                            <h1 className='text-5xl font-bold pb-2'>Welcome to TaDam</h1>}
                        <div>
                            <img className="h-auto lg:w-1/2 max-w-full" src='../assets/img/homepage8 (1).png' />
                        </div>
                        <h1 className='text-3xl font-bold'>Please choose menu item from left panal</h1>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
