import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { updateGameDetailsURL } from '../../../Api/ContantUrls';
import PrevAndNextButtons from '../../../Elements/PrevAndNextButtons';
import { createGameAction, getGameDetailsAction, getStaffUpdatedAction } from '../../../Store/Action';
import { formInputClassName, errorMessage, buttonCSS } from '../../../util/contant';
import { getQuery } from '../../../util/helper';
import { DahboardMenu } from '../DahboardMenu';
import ErrorMessage from '../ErrorMessage';
import { TopHeader } from '../TopHeader';

const Games = () => {
    //const store = useSelector(state => state);
    //const { gameDetailsStore, editOptionData } = store;
    const gameDetailsStore = useSelector(state => state?.gameDetailsStore);
    const editOptionData = useSelector(state => state?.editOptionData);
    const { GameError } = errorMessage;
    const { register, handleSubmit, watch, formState: { errors }, getValues, reset, setError, setValue } = useForm();
    const [gameId, setGameId] = useState(getQuery("id"));
    const dispatch = useDispatch();
    const [selectedEditIndex, setSelectedEditIndex] = useState("");
    const [pageRenderObj, setPageRenderObj] = useState({});

    useEffect(() => {
        if (gameId) {
            if (Object.keys(gameDetailsStore).length === 0) {
                getGameDetails(gameId);
            } else {
                setSelectedEditIndex(editOptionData.indexOf(gameId))
                constructPageParams(gameDetailsStore)
            }
        }
    }, [gameDetailsStore])

    const constructPageParams = (data) => {
        const reqObj = {};
        reqObj.name = data.name;
        reqObj.desc = data.description;
        reqObj.link = data.link;
        reqObj.iosLink = data.iosLink
        setPageRenderObj(reqObj);
        reset(reqObj)
    }

    const getGameDetails = async (id) => {
        dispatch(await getGameDetailsAction({ gameId: id }));
    }

    const constructReqObj = (data) => {
        const reqObj = {};
        reqObj.name = data.name;
        reqObj.description = data.desc;
        reqObj.link = data.link;
        reqObj.iosLink = data.iosLink
        return reqObj;
    }
    const constructParams = async (data, isNew = false) => {
        const reqObj = constructReqObj(data);


        if (gameId) {
            const apireqObj = {
                gameId,
                "dataToUpdate": reqObj
            }
            const response = await getStaffUpdatedAction(dispatch, updateGameDetailsURL, apireqObj);
            if (response?.status === 200 && response?.data) {
                toast.success("Game updated successfully")
                if (isNew) {
                    window.location.href = '/dashboard/games';
                } else {
                    window.location.href = '/dashboard/gameslist';
                }

            } else {
                toast.error("Game updated failed")
            }
        } else {
            dispatch(await createGameAction(reqObj, isNew))
        }


    }
    const submitHandler = async (data, isNew = false) => {
        constructParams(data)
    }

    const onError = (errors) => {
        console.log("errors------------->", errors)
    }

    const saveAndExit = (data) => {
        submitHandler(data, true)
    }
    const saveAndNew = (data) => {
        submitHandler(data, false)

    }
    const discardChanges = () => {
        if (gameId) {
            constructPageParams(gameDetailsStore);
        } else {
            reset({});
        }
        window.location.href = '/dashboard/gameslist';
    }

    const checkIsModified = () => {
        if (JSON.stringify(pageRenderObj) === JSON.stringify(getValues())) {
            return false;
        } else {
            return true
        }
    }

    return (
        <React.Fragment>
            <TopHeader />
            <DahboardMenu />
            <div className="mt-10">
                <div className="
max-w-[540px] sm:max-w-[604px] md:max-w-[720px] lg:max-w-[972px]
xl:max-w-full xl:pl-40  xl:ml-40 xl:px-12 2xl:max-w-[1400px] mx-auto
flex">

                    <div className="flex w-full flex-col ">

                        <div className="relative mb-8">
                            <ErrorMessage errors={errors} />
                            <form className="w-full bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 " name='conversion' onSubmit={handleSubmit(submitHandler, onError)}>
                                {gameId &&
                                    <PrevAndNextButtons
                                        checkIsModified={checkIsModified}
                                        handleSubmit={handleSubmit}
                                        constructData={constructReqObj}
                                        setRedirectId={setGameId}
                                        editOptionData={editOptionData}
                                        setSelectedEditIndex={setSelectedEditIndex}
                                        selectedEditIndex={selectedEditIndex}
                                        getData={getGameDetails}
                                        reset={reset}
                                        resetObj={{}}
                                        from="games" />}
                                <div className="mb-4">
                                    <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                        Name
                                    </label>
                                    <input className={formInputClassName} autoComplete='off' placeholder='English' id="name" name="name" {...register("name", { required: GameError.name })} />


                                </div>
                                <div className="mb-4">
                                    <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                        Description
                                    </label>
                                    <input className={formInputClassName} autoComplete='off' placeholder='English' id="desc" name="desc" {...register("desc", { required: GameError.desc })} />


                                </div>
                                <div className="mb-4">
                                    <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                        Play Store Link (Android)
                                    </label>
                                    <input className={formInputClassName} autoComplete='off' placeholder='English' id="link" name="link" {...register("link", { required: GameError.android })} />
                                </div>
                                <div className="mb-4">
                                    <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="medicineName">
                                        Play Store Link (IOS)
                                    </label>
                                    <input className={formInputClassName} autoComplete='off' placeholder='English' id="iosLink" name="iosLink" {...register("iosLink", { required: GameError.ios })} />
                                </div>
                                <div className="flex justify-between items-center mt-4">
                                    <a href='javascript:void(0);' className={buttonCSS.blue} onClick={handleSubmit((data) => saveAndExit(data), (err) => onError(err))}>Save & Exit</a>
                                    <a href='javascript:void(0);' className={buttonCSS.green} onClick={handleSubmit((data) => saveAndNew(data), (err) => onError(err))}>Save & New</a>
                                    <a href='javascript:void(0);' className={buttonCSS.red} onClick={() => { discardChanges() }}>Discard Changes</a>
                                </div>
                                {gameId &&
                                    <PrevAndNextButtons
                                        checkIsModified={checkIsModified}
                                        handleSubmit={handleSubmit}
                                        constructData={constructReqObj}
                                        setRedirectId={setGameId}
                                        editOptionData={editOptionData}
                                        setSelectedEditIndex={setSelectedEditIndex}
                                        selectedEditIndex={selectedEditIndex}
                                        getData={getGameDetails}
                                        reset={reset}
                                        resetObj={{}}
                                        from="games" />}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Games