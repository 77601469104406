import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TopHeader } from '../../TopHeader';
import { DahboardMenu } from '../../DahboardMenu';
import { buttonCSS, errorMessage, formInputClassName } from '../../../../util/contant';
import { adminRoleListcall, getAdminDetailsByIdAction, updateAdminDetailsByIdAction } from '../../../../Store/Action';
import { useForm } from 'react-hook-form';
import { getCookie } from '../../../../util/helper';
import ErrorMessage from '../../ErrorMessage';
const inputClass = "appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500";

const Profile = () => {
    const { register, formState: { errors }, reset, handleSubmit, setValue, watch } = useForm();
    const dispatch = useDispatch();
    const store = useSelector(state => state);
    const { loginDetails } = store;
    let roleList = store?.adminRoleList;
    const [selectedRole, setSelectedRole] = useState("");
    const [isDisabled, setIsDisabled] = useState(true);
    const { profileError } = errorMessage;
    useEffect(() => {

        if (roleList && Object.keys(roleList).length === 0) {
            dispatch(adminRoleListcall());
        }
        if (loginDetails && Object.keys(loginDetails).length !== 0) {
            constructPageData(loginDetails)
        }
        if (loginDetails && Object.keys(loginDetails).length === 0) {
            dispatch(getAdminDetailsByIdAction({ adminId: getCookie("adminId") }))
        }
    }, [loginDetails])

    const constructPageData = (data) => {
        const reqObj = {};
        reqObj.name = data?.name;
        reqObj.email = data?.email;
        reqObj.mobile = data?.mobile;
        reqObj.roles = data?.roles[0];
        setValue("roles", data?.roles[0])
        //  reqObj.roles = data?.roles[0];
        reset(data)
    }
    const selectRole = e => {
        setValue(e.target.name, e.target.value);
        setSelectedRole(e.target.value)
    }
    const submitHandler = (data) => {
        const reqObj = {
            "adminId": getCookie("adminId"),
            "dataToUpdate": {
                ...data
            }
        }
        dispatch(updateAdminDetailsByIdAction(reqObj));
    }

    const onError = (errors) => {
        window.scroll(0, 0)
    }
    return (

        <React.Fragment>

            <TopHeader />
            <DahboardMenu />
            <div className="mt-10">
                <div className="
          max-w-[540px] sm:max-w-[604px] md:max-w-[720px] lg:max-w-[972px]
          xl:max-w-full xl:pl-40  xl:ml-40 xl:px-12 2xl:max-w-[1400px] mx-auto
         flex">
                    <div className="flex w-full flex-col h-screen">
                        <div className="relative overflow-hidden mb-8">
                            <ErrorMessage errors={errors} setFormErrors={{}} />
                            <div className='rounded-t-lg overflow-hidden border-t border-l border-r border-b border-gray-400 flex justify-center p-8'>


                                <form className="w-full max-w-sm" onSubmit={handleSubmit(submitHandler, onError)}>
                                    <div className="md:flex md:items-center mb-6">
                                        <div className="md:w-1/3">
                                            <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="name">
                                                Name
                                            </label>
                                        </div>
                                        <div className="md:w-2/3">
                                            <input className={inputClass} id="name" type="text" {...register('name', { required: profileError.name })} disabled={isDisabled} />
                                        </div>
                                    </div>
                                    <div className="md:flex md:items-center mb-6">
                                        <div className="md:w-1/3">
                                            <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-password">
                                                Email
                                            </label>
                                        </div>
                                        <div className="md:w-2/3">
                                            <input className={inputClass} id="email" {...register('email', {
                                                required: profileError.name, pattern: {
                                                    value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                                    message: 'Enter valid mail id'
                                                }
                                            })} disabled={isDisabled} />
                                        </div>
                                    </div>
                                    <div className="md:flex md:items-center mb-6">
                                        <div className="md:w-1/3">
                                            <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="mobile">
                                                Phone
                                            </label>
                                        </div>
                                        <div className="md:w-2/3">
                                            <input className={inputClass} id="mobile" {...register('mobile', {
                                                required: profileError.name, pattern: {
                                                    value: /^(?:(?:(\+?972|\(\+?972\)|\+?\(972\))(?:\s|\.|-)?([1-9]\d?))|(0[23489]{1})|(0[57]{1}[0-9]))(?:\s|\.|-)?([^0\D]{1}\d{2}(?:\s|\.|-)?\d{4})$/,
                                                    message: 'Enter valid mobile number'
                                                }
                                            })} disabled={isDisabled} />
                                        </div>
                                    </div>
                                    <div className="md:flex md:items-center mb-6">
                                        <div className="md:w-1/3">
                                            <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-password">
                                                Role
                                            </label>
                                        </div>
                                        <div className="md:w-2/3">
                                            <select disabled={isDisabled} data-te-select-init className="border-2 border-gray-200 rounded w-full py-2 px-4 focus:bg-white focus:border-purple-500"
                                                id="roles" name="roles" {...register("roles", { required: true })} onChange={e => selectRole(e)} {...register('roles', { required: profileError.role })}>
                                                <option value="">Choose role</option>
                                                {(Object.keys(roleList).length !== 0) && roleList.map((value, index) => {
                                                    return <option value={value._id}>{value.name}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="md:flex justify-center">
                                        <div className="">
                                            <button onClick={() => setIsDisabled(!isDisabled)} className={buttonCSS.blue} type="button">
                                                Edit
                                            </button>
                                        </div>
                                        <div className="ml-10">
                                            <button className={buttonCSS.green} type="submit">
                                                Save
                                            </button>
                                        </div>

                                        <div className="ml-10">
                                            <button onClick={() => window.history.back()} className={buttonCSS.red} type="button">
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export { Profile }