import React from 'react';
import { useEffect } from 'react';
import Modal from 'react-modal';
import { buttonCSS } from '../../util/contant';

const ChangeAlertPopup = (props) => {
    const { modalIsOpen, closeModal, DiscardChanges, saveChanges, handleSubmit } = props;

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            padding: '0px',
            transform: 'translate(-50%, -50%)',
        },
    };

    useEffect(() => {

    }, [])
    return (
        <div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Example Modal"
                style={customStyles}
                ariaHideApp={false}
            >

                <div id="defaultModal">
                    <div className="relative w-full max-w-2xl max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                    Changes in Data detected
                                </h3>
                                <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="defaultModal" onClick={() => closeModal()}>
                                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>
                            <div className="items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                                <p className='right-3'><strong>You have made changes in data. what to do with changes?</strong></p>
                                <p><strong>Click to select. what to do next?</strong></p>

                                <p><strong>- Save & Next --</strong> To save changes and move next/back</p>
                                <p><strong>- Discard & Next --</strong> To cancel the changes and move next/back</p>
                                <p><strong>- close & Fix --</strong> To close this Prompt and fix page yourself</p>


                                <div class="grid grid-cols-2 md:grid-cols-3 gap-4 mt-6">
                                    <div className='text-center'>
                                        <button data-modal-hide="defaultModal" type="button" className={buttonCSS.blue} onClick={handleSubmit((data) => saveChanges(data))}>Save & Next</button>
                                    </div>
                                    <div className='text-center'>
                                        <button data-modal-hide="defaultModal" type="button" className={buttonCSS.red} onClick={() => DiscardChanges()}>Discard & Next</button>
                                    </div>
                                    <div className='text-center'>
                                        <button data-modal-hide="defaultModal" type="button" className={buttonCSS.green} onClick={() => closeModal()}>Close & Fix</button>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </Modal >
        </div >
    )
}

export default ChangeAlertPopup