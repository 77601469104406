import React, { useEffect, useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


const MedicineViewTable = (props) => {
    const allMedicineData = props.allMedicineData ? props.allMedicineData : [];
    const [currentSlide, setCurrentSlide] = useState(0);
    const buttonStyle = { fontSize: 20, padding: '5px 20px', margin: '5px 0px' };
    const containerStyle = { margin: '5px 0 20px' };

    useEffect(() => {
    }, [currentSlide])
    const getMedicineValue = (data, name) => {
        return data[name][0].value;
    }

    const prev = () => {
        let values = parseInt(currentSlide) - 1;
        if (values < 0) {
            setCurrentSlide(0)
        } else {
            setCurrentSlide(values)
        }

    }

    const next = () => {
        let values = currentSlide === parseInt(allMedicineData.length - 1) ? currentSlide : parseInt(currentSlide) + 1;
        if (values === parseInt(allMedicineData.length - 1)) {
            setCurrentSlide(allMedicineData.length - 1)
        } else {
            setCurrentSlide(values)
        }

    }
    const updateCurrentSlide = (index) => {

        if (currentSlide !== index) {
            setCurrentSlide(index)
        }
    };
    return (
        <div>
            <Carousel showIndicators={false} showArrows={false} autoPlay={false} selectedItem={currentSlide} showThumbs={false} onChange={() => (index) => updateCurrentSlide(index)}>
                {allMedicineData.map((data, index) => {
                    return <div>
                        <p>External slide value: {currentSlide}</p>
                        {/* <img src="https://react-responsive-carousel.js.org/assets/1.jpeg" />
                    <p className="legend">Legend {index}</p> */}



                        {/* <div>test {index}</div> */}
                        < table className="w-full text-sm text-left text-gray-500 dark:text-gray-400" >
                            <tbody>
                                <tr className="bg-white dark:bg-gray-800 dark:border-gray-700">
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        Medicine Name
                                    </th>
                                    <td className="px-6 py-4">
                                        {getMedicineValue(data, "medicineName")}
                                    </td>

                                </tr>
                                <tr className="bg-white dark:bg-gray-800 dark:border-gray-700">
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        Medicine Description
                                    </th>
                                    <td className="px-6 py-4">
                                        {getMedicineValue(data, "medicineDescription")}
                                    </td>

                                </tr>
                                <tr className="bg-white dark:bg-gray-800">
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        General Dose
                                    </th>
                                    <td className="px-6 py-4">
                                        {getMedicineValue(data, "generalDose")}
                                    </td>

                                </tr>
                                <tr className="bg-white dark:bg-gray-800">
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        Maximum Dose
                                    </th>
                                    <td className="px-6 py-4">
                                        {getMedicineValue(data, "maximumDose")}
                                    </td>

                                </tr>
                                <tr className="bg-white dark:bg-gray-800">
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        Method / Day
                                    </th>
                                    <td className="px-6 py-4">
                                        {getMedicineValue(data, "day")}
                                    </td>

                                </tr>
                                <tr className="bg-white dark:bg-gray-800">
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        Dose Per Day
                                    </th>
                                    <td className="px-6 py-4">
                                        {data.dosePerDay}
                                    </td>

                                </tr>
                                <tr className="bg-white dark:bg-gray-800">
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        Time Of Treatment
                                    </th>
                                    <td className="px-6 py-4">
                                        {getMedicineValue(data, "timeOfTreatment")}
                                    </td>

                                </tr>
                                <tr className="bg-white dark:bg-gray-800">
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        Instructions For Medicine
                                    </th>
                                    <td className="px-6 py-4">
                                        {getMedicineValue(data, "instructionsForMedicine")}
                                    </td>

                                </tr>
                                <tr className="bg-white dark:bg-gray-800">
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        Treatment and Instructions
                                    </th>
                                    <td className="px-6 py-4">
                                        Black
                                    </td>

                                </tr>
                                <tr className="bg-white dark:bg-gray-800">
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        General Explanation
                                    </th>
                                    <td className="px-6 py-4">
                                        {getMedicineValue(data, "generalExplanation")}
                                    </td>

                                </tr>
                                <tr className="bg-white dark:bg-gray-800">
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        Supportive Care/Required Follow up
                                    </th>
                                    <td className="px-6 py-4">
                                        {getMedicineValue(data, "supportiveCareOrRequiredFollowUp")}
                                    </td>

                                </tr>
                                <tr className="bg-white dark:bg-gray-800">
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        Side Effects
                                    </th>
                                    <td className="px-6 py-4">
                                        Black
                                    </td>

                                </tr>
                            </tbody>

                        </table>


                    </div>
                })
                }

            </Carousel>
            {/* <button onClick={() => prev()} style={buttonStyle}>
                Prev
            </button>
            <button onClick={() => next()} style={buttonStyle}>
                Next
            </button> */}
            <div className="flex justify-between mt-4">
                <button onClick={() => prev()} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Prev</button>
                <button onClick={() => next()} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">Next</button>
            </div>

        </div>
    )
}

export default MedicineViewTable