'use client';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { getTitleOrderAction } from '../../../Store/Action';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        padding: '0px',
        transform: 'translate(-50%, -50%)',
    },
};

const TitleOrder = (props) => {
    const { open, close } = props;
    const store = useSelector(state => state);
    const { storeTitleOrder } = store;
    const [titles, settitles] = useState([]);
    const dispatch = useDispatch();

    const FilterbychallengeOrder = (a, b) => {
        if (a.titleOrder < b.titleOrder) {
            return -1;
        }
        if (a.titleOrder > b.titleOrder) {
            return 1;
        }
        return 0;
    }
    useEffect(() => {
        if (storeTitleOrder.length === 0) {
            getTitles()
        }
        if (storeTitleOrder.length !== 0) {
            settitles(storeTitleOrder.slice().sort(FilterbychallengeOrder))
        }
        document.body.style.overflow = 'hidden';
        document.body.style.opacity = '1';
        return () => {
            document.body.style.overflow = 'unset';
        }
    }, [storeTitleOrder, titles])

    const getTitles = async () => {
        dispatch(await getTitleOrderAction())
    }
    return (
        <div>
            <Modal
                isOpen={open}
                onRequestClose={close}
                contentLabel="Example Modal"
                style={customStyles}
            >
                <div id="defaultModal">
                    <div className="relative">
                        <div className=" rounded-lg shadow">
                            <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                    Title Orders
                                </h3>
                                <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="defaultModal" onClick={() => close()}>
                                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>
                            <div className="grid grid-cols-1 xl:grid-cols-4 md:grid-cols-3 gap-4 h-[350px] overflow-auto pr-24 pl-24 pt-8 pb-8">
                                {titles.map((val, index) => {
                                    return <div>
                                        <div className="w-80 rounded-lg rounded-tr-lg p-2 flex border-solid border-2 border-indigo-600">
                                            <div className="relative inline-flex items-center justify-center w-10 h-9 overflow-hidden bg-indigo-300 rounded-full dark:bg-gray-600">
                                                <span className="font-medium text-gray-600 dark:text-gray-300">{val.titleOrder}</span>
                                            </div>
                                            {/* <div className="rounded-full bg-slate-500 p-4"><span>{val.titleOrder}</span></div> */}
                                            <div className="w-full pl-4">
                                                <h3 className='font-bold'>{val.challengeType}</h3>
                                                <p>{val.challengeTitle}</p>
                                            </div>
                                        </div>
                                    </div>
                                })}

                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default TitleOrder