import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { object } from 'underscore';
import { addSideEffect, getsideEffectListById, getsideEffectList, getUpdateSideEffect, actionForPrevAndNext } from '../../../Store/Action';
import { addsideEffect, sideEffectListByIds, updateSideEffects, fromMedicinePage, saveMedicineData, isfromsideEffectList } from '../../../Store/Reducer';
import { buttonCSS, errorMessage, formInputClassName } from '../../../util/contant';
import { englishLangId, arabicLangId, hebrewLangId, getQuery } from '../../../util/helper';
import { DahboardMenu } from '../DahboardMenu';
import ErrorMessage from '../ErrorMessage';
import { TopHeader } from '../TopHeader';
import { useNavigate } from 'react-router-dom';
import PrevAndNextButtons from '../../../Elements/PrevAndNextButtons';
import { getSideEffectByIdURL } from '../../../Api/ContantUrls';

const AddSideEffect = () => {
    const { register, handleSubmit, watch, formState: { errors }, getValues, reset, setValue } = useForm();
    const { medicinePage } = errorMessage;
    const dispatch = useDispatch();
    const store = useSelector(state => state);
    const { availableLanguages, isSideEffectAdded, sideEffectListById, isfromMedicinePage, editOptionData, isFromSideeffectList } = store;
    let [sideId, setSideId] = useState(getQuery('id'));
    const [isupdate, setIsupdate] = useState(false)
    const [selectedEditIndex, setSelectedEditIndex] = useState("");


    const [sideListData, setSideListData] = useState({});
    const navigation = useNavigate();
    const resetObj = {
        sidename: "",
        ar_sidename: "",
        hb_sidename: "",

        sideDesc: "",
        ar_sideDesc: "",
        hb_sideDesc: "",

        sideTreatment: "",
        ar_sideTreatment: "",
        hb_sideTreatment: "",
        linkMedia: ""
    };
    const [pageRenderObj, setPageRenderObj] = useState({});

    useEffect(() => {
        document.title = 'Side Effects';
        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 0);
        if (isSideEffectAdded && isfromMedicinePage) {
            navigation('/dashboard/medicine');
            //window.history.back();
        } else {
            dispatch(addsideEffect(false));
        }
        if (sideId && editOptionData.length !== 0) {
            setSelectedEditIndex(editOptionData.indexOf(sideId))

        }
        if (sideId) {
            setSideListData(sideEffectListById);
            constructParams(sideListData)
            // dispatch(fromMedicinePage(false));
        } else {
            reset(resetObj);
        }

        if (sideId && Object.keys(sideEffectListById).length === 0) {
            getdata(sideId);
        } else {
            setSideListData(sideEffectListById);
            //constructParams(sideEffectListById)
        }

    }, [isSideEffectAdded, sideId, sideListData, sideEffectListById])


    const getdata = async (sideId) => {
        const reqObj = {
            "sideEffectId": sideId
        }
        dispatch(await getsideEffectListById(reqObj));
    }

    const prevAndNextHandler = async (id) => {
        const reqObj = {
            "sideEffectId": id
        }
        const response = await actionForPrevAndNext(dispatch, getSideEffectByIdURL, reqObj, "medicineSideEffectDetails");
        constructParams(response);
    }


    const saveAndExit = (data) => {
        submitHandler(data, false)
    }
    const saveAndNew = (data) => {
        submitHandler(data, true);
    }
    const discardChanges = () => {
        if (sideId) {
            constructParams(sideListData)
        } else {
            reset();
        }
        window.location.href = '/dashboard/sideeffectlist';
    }

    const constructParams = (reqData) => {
        reset({});
        let pageData = {}
        for (let key in reqData) {
            pageData.linkMedia = reqData.mediaToLink ? reqData.mediaToLink : '';
            if (Array.isArray(reqData[key])) {
                reqData[key].map((data, index) => {
                    var keys = Object.keys(data);
                    if (key === 'sideEffectName') {
                        if (data[keys[0]]._id === englishLangId(availableLanguages.languageList)) {
                            pageData.sidename = data.value
                        }
                        if (data[keys[0]]._id === arabicLangId(availableLanguages.languageList)) {
                            pageData.ar_sidename = data.value
                        }
                        if (data[keys[0]]._id === hebrewLangId(availableLanguages.languageList)) {
                            pageData.hb_sidename = data.value
                        }
                    }
                    if (key === 'sideEffectDescription') {
                        if (data[keys[0]]._id === englishLangId(availableLanguages.languageList)) {
                            pageData.sideDesc = data.value
                        }
                        if (data[keys[0]]._id === arabicLangId(availableLanguages.languageList)) {
                            pageData.ar_sideDesc = data.value
                        }
                        if (data[keys[0]]._id === hebrewLangId(availableLanguages.languageList)) {
                            pageData.hb_sideDesc = data.value
                        }
                    }
                    if (key === 'sideEffectTreatment') {
                        if (data[keys[0]]._id === englishLangId(availableLanguages.languageList)) {
                            pageData.sideTreatment = data.value
                        }
                        if (data[keys[0]]._id === arabicLangId(availableLanguages.languageList)) {
                            pageData.ar_sideTreatment = data.value
                        }
                        if (data[keys[0]]._id === hebrewLangId(availableLanguages.languageList)) {
                            pageData.hb_sideTreatment = data.value
                        }
                    }

                })
            }
        }
        setPageRenderObj(pageData)
        reset(pageData)

    }

    const constructParam = (data) => {
        const reqObj = {};
        reqObj.sideEffectName = [
            { language: englishLangId(availableLanguages.languageList), value: data.sidename },
            { language: arabicLangId(availableLanguages.languageList), value: data.ar_sidename },
            { language: hebrewLangId(availableLanguages.languageList), value: data.hb_sidename }
        ]
        reqObj.sideEffectDescription = [
            { language: englishLangId(availableLanguages.languageList), value: data.sideDesc },
            { language: arabicLangId(availableLanguages.languageList), value: data.ar_sideDesc },
            { language: hebrewLangId(availableLanguages.languageList), value: data.hb_sideDesc }
        ]
        reqObj.sideEffectTreatment = [
            { language: englishLangId(availableLanguages.languageList), value: data.sideTreatment },
            { language: arabicLangId(availableLanguages.languageList), value: data.ar_sideTreatment },
            { language: hebrewLangId(availableLanguages.languageList), value: data.hb_sideTreatment }
        ]
        reqObj.mediaToLink = data.linkMedia;
        return reqObj;
    }

    const submitHandler = async (data, isNew = false) => {

        const reqObj = constructParam(data);

        if (sideId) {
            let updateObj = {}
            updateObj.sideEffectId = sideId;
            updateObj.dataToUpdate = reqObj;
            updateObj.isNew = isNew;
            const reqData = {
                "getLatestFirst": true,
                "pageNumber": 1,
                "numberOfRecordsPerPage": 0
            }
            dispatch(await getUpdateSideEffect(updateObj, isfromMedicinePage))
            setTimeout(() => {
                dispatch(getsideEffectList(reqData))
                dispatch(isfromsideEffectList(false));
                dispatch(fromMedicinePage(false));
            }, 2000);

            //navigation('/dashboard/medicine')
            // console.log("isfromMedicinePage-------->", isfromMedicinePage)
            // console.log("isFromSideeffectList===========>", isFromSideeffectList)
            // return;
            // if (isFromSideeffectList) {
            //     dispatch(isfromsideEffectList(false));
            //     setTimeout(() => {
            //         window.location.href = '/dashboard/sideeffectlist';
            //     }, 2000)
            // } else {
            //     dispatch(fromMedicinePage(false));
            //     setTimeout(() => {
            //         //  window.location.href = '/dashboard/medicine';
            //         navigation('/dashboard/medicine');
            //     }, 2000)
            // }
        } else {
            reqObj.isNew = isNew;
            dispatch(await addSideEffect(reqObj, fromMedicinePage));
            dispatch(isfromsideEffectList(false));
            dispatch(fromMedicinePage(false));
            // dispatch(updateSideEffects(true))
            //dispatch(saveMedicineData({}))
        }


    }
    const onError = () => {

    }

    const checkIsModified = () => {
        console.log("values are same1======================>", JSON.stringify(pageRenderObj))
        console.log("values are same2======================>", JSON.stringify(getValues()))
        if (JSON.stringify(pageRenderObj) === JSON.stringify(getValues())) {
            return false;
        } else {
            return true
        }
    }
    return (
        <React.Fragment>

            <TopHeader />
            <DahboardMenu />
            <div className="mt-10">
                <div className="
max-w-[540px] sm:max-w-[604px] md:max-w-[720px] lg:max-w-[972px]
xl:max-w-full xl:pl-40  xl:ml-40 xl:px-12 2xl:max-w-[1400px] mx-auto
flex">
                    <div className="flex w-full flex-col ">
                        <div className="relative overflow-hidden mb-8">
                            <ErrorMessage errors={errors} />

                            <div className='rounded-t-lg overflow-hidden border-t border-l border-r border-b border-gray-400 flex justify-center p-8'>
                                <form className="w-full bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 " name='conversion' onSubmit={handleSubmit(submitHandler, onError)}>
                                    {sideId &&
                                        <PrevAndNextButtons
                                            checkIsModified={checkIsModified}
                                            handleSubmit={handleSubmit}
                                            constructData={constructParam}
                                            setRedirectId={setSideId}
                                            editOptionData={editOptionData}
                                            selectedEditIndex={selectedEditIndex}
                                            getData={prevAndNextHandler}
                                            reset={reset} resetObj={{}}
                                            from="sideeffect"
                                            setSelectedEditIndex={setSelectedEditIndex} />}
                                    <div className="mb-4">
                                        <div className="mb-8">
                                            <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="supportiveCare">
                                                SideEffect Name
                                            </label>

                                            <input className={formInputClassName} autoComplete='off' placeholder='English' id="sidename" name="sidename" {...register("sidename", { required: medicinePage.en_sidename })} />
                                            <input className={formInputClassName} autoComplete='off' placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrire("ar_sidename")} name="ar_sidename" id="ar_sidename" {...register("ar_sidename", { required: medicinePage.ar_sidename })} />

                                            <input className={formInputClassName} autoComplete='off' placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrireHebrew("hb_sidename")} name="hb_sidename" id="hb_sidename" {...register("hb_sidename", { required: medicinePage.hb_sidename })} />

                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <div className="mb-8">
                                            <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="supportiveCare">
                                                SideEffect Description
                                            </label>

                                            <textarea className={formInputClassName} autoComplete='off' placeholder='English' id="sideDesc" name="sideDesc" {...register("sideDesc", { required: medicinePage.en_sideDesc })} />
                                            <textarea className={formInputClassName} autoComplete='off' placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrire("ar_sideDesc")} name="ar_sideDesc" id="ar_sideDesc" {...register("ar_sideDesc", { required: medicinePage.ar_sideDesc })} />

                                            <textarea className={formInputClassName} autoComplete='off' placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrireHebrew("hb_sideDesc")} name="hb_sideDesc" id="hb_sideDesc" {...register("hb_sideDesc", { required: medicinePage.hb_sideDesc })} />

                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <div className="mb-8">
                                            <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="supportiveCare">
                                                SideEffect Treatment
                                            </label>

                                            <input className={formInputClassName} autoComplete='off' placeholder='English' id="sideTreatment" name="sideTreatment" {...register("sideTreatment")} />
                                            <input className={formInputClassName} autoComplete='off' placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrire("ar_sideTreatment")} name="ar_sideTreatment" id="ar_sideTreatment" {...register("ar_sideTreatment")} />

                                            <input className={formInputClassName} autoComplete='off' placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrireHebrew("hb_sideTreatment")} name="hb_sideTreatment" id="hb_sideTreatment" {...register("hb_sideTreatment")} />

                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <div className="mb-8">
                                            <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4" htmlFor="supportiveCare">
                                                Link To Media
                                            </label>

                                            <input className={formInputClassName} autoComplete='off' placeholder='English' id="sideTreatment" name="sideTreatment" {...register("linkMedia")} />
                                            {/* <input className={formInputClassName} autoComplete='off' placeholder='Arabic' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrire("ar_sideTreatment")} name="ar_sideTreatment" id="ar_sideTreatment" {...register("ar_sideTreatment", { required: medicinePage.ar_sideTreatmnet })} />

                                            <input className={formInputClassName} autoComplete='off' placeholder='Hebrew' style={{ direction: "rtl", fontSize: "120%" }} onKeyUp={() => window.transcrireHebrew("hb_sideTreatment")} name="hb_sideTreatment" id="hb_sideTreatment" {...register("hb_sideTreatment", { required: medicinePage.hb_sideTreatmnet })} /> */}

                                        </div>
                                    </div>
                                    {/* {!sideId && <div className="container py-10 px-10 mx-0 min-w-full flex flex-col items-center">
                                        <input type='submit' className="bg-purple-900 text-white hover:bg-blue-400 font-bold py-2 px-4 mt-3 rounded" value="Add SideEffect" />

                                    </div>} */}
                                    <div className="flex justify-between items-center mb-4">
                                        <a href='javascript:void(0);' className={buttonCSS.blue} onClick={handleSubmit((data) => saveAndExit(data), (err) => onError(err))}>Save & Exit</a>
                                        <a href='javascript:void(0);' className={buttonCSS.green} onClick={handleSubmit((data) => saveAndNew(data), (err) => onError(err))}>Save & New</a>
                                        <a href='javascript:void(0);' className={buttonCSS.red} onClick={() => discardChanges()}>Discard Changes</a>
                                    </div>
                                    {sideId &&
                                        <PrevAndNextButtons
                                            checkIsModified={() => checkIsModified}
                                            handleSubmit={handleSubmit}
                                            constructData={constructParam}
                                            setRedirectId={setSideId}
                                            editOptionData={editOptionData}
                                            selectedEditIndex={selectedEditIndex}
                                            getData={prevAndNextHandler}
                                            reset={reset} resetObj={{}}
                                            from="sideeffect"
                                            setSelectedEditIndex={setSelectedEditIndex} />}
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export { AddSideEffect }