import _ from 'underscore';

export const SideEffectTable = ({ sideEffectListdata, selectSideEffect, editsideEffect, medicineId, pageData, selectedSideEffects }) => {
    const FilterbyTitleOrder = (a, b) => {

        if (a.sideEffectName[0].value < b.sideEffectName[0].value) {
            return -1;
        }
        if (a.sideEffectName[0].value > b.sideEffectName[0].value) {
            return 1;
        }
        return 0;
    }

    const filterData = sideEffectListdata.slice().sort(FilterbyTitleOrder);

    return (<div id="sideeffect" data-accordion="collapse" className='h-[500px] overflow-auto'>
        {filterData.map((data, index) => {
            //  pageData.map((dataVal, ind) => {

            const newIndex = index + 1;
            const found = selectedSideEffects.some(r => selectedSideEffects.includes(data._id))


            return <section className="col-span-10 col-start-2" key={"sidesec" + index}>
                <h2 id={`sideeffect-heading-${newIndex}`} key={"sideDiv" + index}>
                    <button type="button" className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800" data-accordion-target={`#sideeffect-body-` + newIndex} aria-expanded={newIndex === 0 ? 'true' : 'false'} aria-controls={`sideeffect-body-${newIndex}`}>
                        <span>{data.sideEffectName[0].value}</span>

                        <div className="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]" key={"sidecheck" + index}>
                            <input
                                type="checkbox"
                                value=""
                                checked={found}
                                onChange={() => selectSideEffect(data._id, index)}
                                id="checkboxDefault" />

                        </div>
                    </button>
                </h2>
                <div id={`sideeffect-body-${newIndex}`} className="hidden" aria-labelledby={`sideeffect-heading-${newIndex}`} key={"sidebody" + index}>
                    <div className="flex flex-col">

                        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                                <div className="overflow-hidden">
                                    <table className="min-w-full text-center text-sm font-light">
                                        <thead className="border-b font-medium dark:border-neutral-500">
                                            <tr>
                                                <th scope="col" className="px-6 py-4">Name</th>
                                                <th scope="col" className="px-6 py-4">Description</th>
                                                <th scope="col" className="px-6 py-4">Treatment</th>
                                                <th scope="col" className="px-6 py-4">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data.sideEffectName.map((val, ind) => {
                                                    return <tr
                                                        className="border-b border-secondary-200 bg-secondary-100 text-neutral-800" key={"sideTable" + ind}>
                                                        <td className="whitespace-nowrap px-6 py-4 font-medium">
                                                            {data.sideEffectName[ind].value}
                                                        </td>
                                                        <td className="whitespace-nowrap px-6 py-4">{data.sideEffectDescription[ind].value}</td>
                                                        <td className="whitespace-nowrap px-6 py-4">{data.sideEffectDescription[ind].value}</td>
                                                        <td className="whitespace-nowrap px-6 py-4">
                                                            <a href="javascript:void(0);" onClick={() => editsideEffect(data._id)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
                                                                <span>Edit</span>
                                                            </a></td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            //   })
            //                        }
        })}
    </div>)
}