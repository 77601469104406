import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllMainProtocolListAction, getOrderProtocolListAction } from '../../../Store/Action';
import { TopHeader } from '../TopHeader';
import { DahboardMenu } from '../DahboardMenu';
import _ from 'underscore'
import { toast } from 'react-toastify';
import { buttonCSS } from '../../../util/contant';
import { useReactToPrint } from 'react-to-print';
import { setLoading } from '../../../Store/Reducer';

const Diagram = () => {
    const storeOrderProtocolList = useSelector(state => state?.storeOrderProtocolList);
    const allMainProtocolListStore = useSelector(state => state?.allMainProtocolListStore);
    //const { storeOrderProtocolList } = store;
    const dispatch = useDispatch();
    const [arryList, setArryList] = useState([]);
    const [apiCount, setapiCount] = useState(0)
    const [mainProtocolData, setMainProtocolData] = useState([]);
    const [selectedProtoId, setSelectedProtoId] = useState("");
    const [isClicked, setIsClicked] = useState(false);

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });

    useEffect(() => {
        if (apiCount < 3) {
            //getData();
            getRequiredData();
            setapiCount(apiCount + 1)
        }
        if (allMainProtocolListStore && allMainProtocolListStore.length !== 0 && mainProtocolData.length === 0) {
            const filterdata = mainProtocolData;
            allMainProtocolListStore.map((data, index) => {
                if (data?.active) {
                    // if (index === 0) {
                    //     filterdata.push({ label: "Select one by one", value: "", disabled: true })
                    // }
                    filterdata.push({ label: data.protocolName[0].value, value: data._id })
                }

            })
            setMainProtocolData(filterdata);
        }
        setArryList(storeOrderProtocolList);

        if (apiCount === 3 && allMainProtocolListStore.length === 0) {
            toast.error("Service not available")
        }
        console.log("allMainProtocolListStore----------->", storeOrderProtocolList)
        if (storeOrderProtocolList.length !== 0) {
            constructData(storeOrderProtocolList)
        }
    }, [storeOrderProtocolList, allMainProtocolListStore]);

    const getRequiredData = async () => {
        const reqObj = {
            "getLatestFirst": true,
            "pageNumber": 1,
            "numberOfRecordsPerPage": 0,
            "filter": {
                "active": true
            }
        }
        if (Object.keys(allMainProtocolListStore).length === 0) {
            reqObj.filter = { isMainProtocol: true }
            dispatch(await getAllMainProtocolListAction(reqObj));
        }
        // setIsCalled(true)
    }

    const onChangeHandler = (e) => {
        setSelectedProtoId(e.target.value)
    }

    const getData = async (id) => {

        if (id === "") {
            toast.error("Please select main protocol")
        } else {
            setIsClicked(true)
            const reqObj = {
                "mainProtocolId": id
            }
            dispatch(await getOrderProtocolListAction(reqObj))
        }
    }

    function groupByProperty(arr, property) {
        const grouped = new Map();

        for (const item of arr) {
            const value = item[property];

            if (grouped.has(value)) {
                grouped.get(value).push(item);
            } else {
                grouped.set(value, [item]);
            }
        }

        // Convert the Map to an array of arrays
        const result = [...grouped.values()];

        return result;
    }


    const constructData = (data) => {
        const extractArry = groupByProperty(data, 'diagramLineNumber');
        const resultArray = [];
        extractArry.map((item, value) => {
            // console.log("item1---------------->", item)
            if (item.length > 1) {
                let lineArray = []
                item.map((val, ind) => {

                    //console.log("item---------------->", val)
                    lineArray.push(val.protocolShortName[0].value)
                })
                resultArray.push(lineArray)
            } else {
                resultArray.push(item[0].protocolShortName[0].value)
            }
            //console.log("resultArray---------------->", resultArray)

        })
        // const flatArray = extractArry.reduce((acc, subArray) => acc.concat(subArray), []);
        // const foundValues = flatArray.filter(value => value.line === 2);
        // console.log("foundValues---------------->", foundValues)
        setArryList(resultArray);
    }
    const printPage = async () => {
        dispatch(setLoading(true))
        setTimeout(() => {
            dispatch(setLoading(false))
            handlePrint();
        }, 2000);

    }

    return (
        <React.Fragment>
            <TopHeader />
            <DahboardMenu />
            <div className="mt-10 h-screen w-full">
                <div className="
      max-w-[540px] sm:max-w-[604px] md:max-w-[720px] lg:max-w-[972px]
      xl:max-w-full xl:pl-40  xl:ml-40 xl:px-12 2xl:max-w-[1400px] mx-auto
     flex">

                    <div className="flex w-full flex-col ">
                        <h1><strong>Protocol Structure</strong></h1>
                        <div class="flex">
                            <div class="flex items-center me-4">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="generalDose">
                                    Main Protocols
                                </label>
                            </div>
                            <div class="flex items-center me-4">
                                <select data-te-select-init className="border-2 border-gray-200 rounded w-full py-2 px-4 focus:bg-white focus:border-purple-500"
                                    id="roles" name="roles" onChange={e => onChangeHandler(e)}>
                                    <option value="">Choose main protocol</option>

                                    {mainProtocolData.length !== 0 && mainProtocolData.map(val => <option value={val.value}>{val.label}</option>)}
                                </select>
                            </div>
                            <div class="flex items-center me-4">
                                <button type="button" className={buttonCSS.blue + " h-9 mt-2 py-0"} onClick={() => getData(selectedProtoId)}>Submit</button>
                            </div>
                        </div>
                        <div className="relative overflow-hidden mt-10">
                            <table className="table-auto h-full w-full">
                                {arryList.length !== 0 ? <tbody className='border w-full text-center border-black'>
                                    {arryList.length !== 0 && arryList.map((item, index) => {
                                        if (Array.isArray(item)) {
                                            return <tr className='border border-slate-700 h-10 w-10'>{
                                                item.map((val, index) => { return val ? <td colSpan={index === item.length - 1 ? item.length + 1 : ""} className='border border-slate-700'>{val}</td> : null })
                                            }</tr>
                                        } else {
                                            return <tr className='border border-slate-700'>
                                                <td colspan="6" className='border border-slate-700'>{index === 0 ? `${item} (Main Protocol)` : item}</td>
                                            </tr>
                                        }

                                    })}

                                </tbody> :
                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                        {isClicked && <th scope="row" colSpan={15} className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            No data found
                                        </th>}
                                    </tr>}
                            </table>
                        </div>
                        {arryList.length !== 0 && <button className={buttonCSS.addbtn + " mr-2 w-40 mt-3"} onClick={(e) => printPage()}>
                            Print Protocols
                        </button>}
                        <div style={{ display: "none" }}>
                            <div className="grid grid-cols-2 gap-2 mt-5" id="printScreen" ref={componentRef}>

                                {arryList.map((item, index) => {
                                    if (Array.isArray(item)) {
                                        return item.map((val, index) => { return <div className='text-center mt-4 ml-5'><strong>{val}</strong></div> })
                                    } else {
                                        return <div className='text-center mt-4 ml-5'><strong>{index === 0 ? `${item} (Main Protocol)` : item}</strong></div>
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export { Diagram }